import React, { useEffect, useState } from 'react';
import Table from '../../common/table/table.jsx';
import TableSearch from '../../common/table/table-search.jsx';
import TableBody from '../../common/table/table-body.jsx';
import TableHeader from '../../common/table/table-header.jsx';
import { archivingActions } from '../../common/table/table-actions.js';
import StaffRow from './staff-row.jsx';
import EntityRepository from '../../../repositories/EntityRepository.js';
import { ACTION_ARCHIVE, FILTER_SKILL_OPTIONS, GET_LIST_REQUEST_PARAMS } from '../../common/constants';
import { ROLE_ADMIN, ROLE_INSTRUCTOR, ROLE_PRODUCT_MANAGER, ROLE_QUALITY_MANAGER } from '@/helpers/role';

const StaffTable = ({ importResult }) => {

  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    role: {
      '': 'Select role',
      [ROLE_ADMIN]: 'Administrator',
      [ROLE_INSTRUCTOR]: 'Instructor',
      [ROLE_QUALITY_MANAGER]: 'Quality Manager',
      [ROLE_PRODUCT_MANAGER]: 'Product Manager',
    },
    category: {
      '': 'Select category',
    },
    skill: FILTER_SKILL_OPTIONS,
  });
  const [filterValues, setFilterValues] = useState({});
  const [overlay, setOverlay] = useState({
    showModal: false,
    selectedIds: [],
    entityTitles: [],
  });
  const [requestParams, setRequestParams] = useState({
    ...GET_LIST_REQUEST_PARAMS, users: 'staff', archive: 'active',
  });
  const thead = [
    {
      title: 'Last Name',
      filterable: false,
    },
    {
      title: 'First Name',
      filterable: false,
    },
    {
      title: 'Position / Rights',
      filterable: true,
      name: 'role',
    },
    {
      title: 'Category',
      filterable: true,
      name: 'category',
    },
    {
      title: 'Skill',
      filterable: true,
      name: 'skill',
    },
  ];

  const path = '/api/users';
  const pathPdf = '/api/staff/generate-training-pdf';

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const data = await EntityRepository.getEntities(
        path,
        requestParams
      );
      if (data) {
        if (!requestParams.start) {
          setData(data.items);
          setCount(data.count);
        } else {
          setData(state => ([...state, ...data.items]));
          setCount(data.count);
        }
        setIsLoading(false);
      }
    };
    const updateFilterOptions = async () => {
      const categories = await EntityRepository.getEntities('/api/categories');
      for (const category of categories) {
        const name = category.name;
        filterOptions.category[name] = name;
      }
    };
    if (!overlay.showModal) {
      fetchData();
    }
    updateFilterOptions();
  }, [requestParams, importResult, overlay]);

  const getSelectedIdNumbers = (selectedIds) => {
    return this.state.data.filter((row) => selectedIds.includes(row.id)).map((row) => row.id_number);
  };

  const openModalRow = (row, action = ACTION_ARCHIVE) => {
    if (row.id !== undefined) {
      setOverlay({
        showModal: true,
        popupName: 'user',
        entityName: row.last_name + ' ' + row.first_name,
        entityTitles: [row.id_number],
        selectedIds: [row.id],
        action,
      });
    }
  };

  const openModalTable = (selectedIds, action = ACTION_ARCHIVE) => {
    setOverlay({
      showModal: true,
      popupName: 'user',
      entityName: 'Staffs',
      entityTitles: getSelectedIdNumbers(selectedIds),
      selectedIds,
      action,
    });
  };

  const onScrollList = (event) => {
    const scrollBottom =
      Math.ceil((event.target.scrollHeight - event.target.scrollTop) - 100) <= event.target.clientHeight;
    const loadedCount = data.length;
    if (scrollBottom && !isLoading && loadedCount < count) {
      loadAdditionalData();
    }
  };

  const loadAdditionalData = () => {
    setRequestParams(data => ({
      ...data,
      start: requestParams.start + requestParams.limit,
    }));
  };

  const loadFilterData = (field = 'like', value = '') => {
    resetPagination();

    if (field === 'role' && value !== ROLE_INSTRUCTOR) {
      setFilterValues({ role: value });
      setRequestParams({
        ...GET_LIST_REQUEST_PARAMS, users: 'staff', archive: 'active', role: value,
      });
    } else {
      setRequestParams({ ...requestParams, [field]: value });
    }
  };

  const resetPagination = () => {
    setRequestParams({
      ...GET_LIST_REQUEST_PARAMS, users: 'staff', archive: 'active',
    });
  };

  const renderRows = () => {
    const rows = data.map((item, i) => (
      <StaffRow key={i} row={item} path={path} openModal={openModalRow}/>
    ));

    if (isLoading) {
      const loadingRow = <tr key="loadingRow">
        <td colSpan="8"><i className="fa fa-spin fa-circle-o-notch"></i></td>
      </tr>;

      if (requestParams.start) {
        rows.push(loadingRow);
      } else {
        return loadingRow;
      }
    }

    return rows;
  };

  const renderColumnFilter = (column) => {
    const columnFilterOptions = filterOptions[column.name] || {};
    let selectValue = filterValues[column.name];

    if (column.name !== 'role' && filterValues.role !== ROLE_INSTRUCTOR) {
      selectValue = '';
    }

    return (
      <div className="icon-box select-box filter-select">
        <select
          name={column.name}
          onChange={({ target: { value } }) => {
            setFilterValues({
              ...filterValues,
              [column.name]: value,
            });
            loadFilterData(column.name, value);
          }}
          value={selectValue}
          disabled={column.name !== 'role' && filterValues.role !== ROLE_INSTRUCTOR}
        >
          {
            Object.keys(columnFilterOptions).map((value, index) => (
              <option value={value} key={index}>{columnFilterOptions[value]}</option>
            ))
          }
        </select>
      </div>
    );
  };

  return (
    <Table>
      <TableHeader count={count} title="Users">
        <TableSearch
          onArchiveFilter={(e) => loadFilterData('archive', e)}
          onSearch={(e) => loadFilterData('like', e)}
          getFilters={requestParams}
          pdfUrl={pathPdf}/>
      </TableHeader>
      <TableBody
        actions={archivingActions(openModalTable)}
        overlay={overlay}
        onScroll={event => onScrollList(event)}
        path={path}
        setOverlay={setOverlay}
        thead={thead}
        renderThead={(column, index) => (
          <th key={index}>
            <span>{column.title}</span>
            {
              column.filterable && renderColumnFilter(column)
            }
          </th>
        )}
        tableId="staff-table-data"
      >
        {renderRows()}
      </TableBody>
    </Table>
  );
};

export default StaffTable;
