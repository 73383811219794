import React, { useState, useEffect, useContext } from 'react';
import TabContext from './tab-context.js';
import ModalCreateTask from '../modals/modal-create-task.jsx';
import ModalDeleteTask from '../modals/modal-delete-task.jsx';
import SearchAta from '../inputs/search-ata.jsx';

function TabPart1() {
  const {
    setFormField,
    formData = {},
    ataIdToCode = {},
    canEdit = true,
  } = useContext(TabContext);

  const sessionAtaId = sessionStorage.getItem('selectedAtaId');
  const [showModalCreateTask, setShowModalCreateTask] = useState(false);
  const [showModalDeleteTask, setShowModalDeleteTask] = useState(false);
  const [selectedTaskIndex, setSelectedTaskIndex] = useState(-1);
  const [selectedAtaId, setSelectedAtaId] = useState(ataIdToCode[sessionAtaId] ? Number(sessionAtaId) : '');

  useEffect(() => () => {
    sessionStorage.setItem('selectedAtaId', selectedAtaId);
  });

  const addTask = (task) => {
    const { tasks } = formData;
    setFormField('tasks', [...tasks, task]);
  };

  const deleteTask = (index) => {
    const { tasks } = formData;
    tasks.splice(index, 1);
    setFormField('tasks', [...tasks]);
  };

  const addAta = (newAta) => {
    if (newAta.id in ataIdToCode) {
      return;
    }
    ataIdToCode[newAta.id] = newAta.code;
    setFormField('ata_ids', [...Object.keys(ataIdToCode).map(Number)]);
  };

  const deleteAta = (selectedAtaId) => {
    const { ata_ids, tasks } = formData;

    if (tasks.some((task) => (task.ata_id == selectedAtaId))) {
      return;
    }
    setFormField(
      'ata_ids',
      ata_ids.filter((ata_id) => ata_id != selectedAtaId)
    );

    delete ataIdToCode[selectedAtaId];
  };

  // const ataTasks = formData.tasks
  //   .filter((task) => (selectedAtaId == '' || task.ata_id == selectedAtaId))
  //   .sort((a, b) => (sortAlphaNum(ataIdToCode[a.ata_id], ataIdToCode[b.ata_id]) || (a.order_number - b.order_number)));

  return (
    <div className="create-new-user-dash-wrapper create-syllabus-dash-wrapper">
      <div className="create-new-user-form__wrapper create-new-syllabus-form__wrapper">
        <div className="syllabus-summary__wrapper_summary-ata">
          <div className="users-edit-history-session__title">
            Summary table edition
          </div>
          <div className="syllabus-summary__table">
            {canEdit && <SearchAta onSelect={addAta} />}
            <div className="syllabus-summary__table-result">
              <div className="syllabus-summary__table-inner scrollbar-style">
                {formData.ata_ids.map((ataId, index) => (
                  <div className="ata__wrapper" key={index}>
                    <span>ATA&nbsp;{ataIdToCode[ataId]}</span>{' '}
                    {
                      canEdit && <span className="close" onClick={() => deleteAta(ataId)}><i className="fa fa-close"></i></span>
                    }
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModalCreateTask ? (
        <ModalCreateTask
          selectedAtaId={selectedAtaId}
          taskIndex={selectedTaskIndex}
          tasks={formData.tasks}
          atas={ataIdToCode}
          onConfirm={(task) => {
            const { tasks } = formData;
            if (selectedTaskIndex !== -1) {
              tasks[selectedTaskIndex] = task;
              setFormField('tasks', [...tasks]);
            } else {
              addTask(task);
            }
            setShowModalCreateTask(false);
            setSelectedTaskIndex(-1);
          }}
          onCancel={() => {
            setShowModalCreateTask(false);
            setSelectedTaskIndex(-1);
          }}
        />
      ) : null}
      {showModalDeleteTask ? (
        <ModalDeleteTask
          onConfirm={() => {
            deleteTask(selectedTaskIndex);
            setShowModalDeleteTask(false);
            setSelectedTaskIndex(-1);
          }}
          onCancel={() => {
            setShowModalDeleteTask(false);
            setSelectedTaskIndex(-1);
          }}
        />
      ) : null}
    </div>
  );
}

export default TabPart1;
