import React, { useRef, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Request from '../../../helpers/request';
import { success, warning, danger } from '../../../redux/actions/notify';
import { useDispatch } from 'react-redux';

function ImportTasks({ setTasks = (tasks) => {} }) {
  const inputEl = useRef(null);
  const dispatch = useDispatch();
  const [inProgress, setInProgress] = useState(false);

  const onDrop = useCallback((file) => {
    const sendData = async () => {
      const formData = new FormData();
      formData.append('file', file);
      setInProgress(true);
      const { entities, errors } = await Request.post('/api/import/tasks', formData);
      setInProgress(false);
      inputEl.current.value = '';
      if (entities && entities.length) {
        if (errors && errors.length) {
          dispatch(warning(errors[0]));
        } else {
          dispatch(success('File successfully imported'));
        }
        setTasks(entities);
      } else {
        if (errors && errors.length) {
          dispatch(danger(errors[0]));
        } else {
          dispatch(danger('Nothing imported'));
        }
      }
    };

    sendData();
  }, []);

  return (
    <>
      <input
        ref={inputEl}
        accept=".csv"
        type="file"
        autoComplete="off"
        tabIndex="-1"
        maxsize="1048576"
        style={{ display: 'none' }}
        onChange={({ target: { files } }) => {
          if (!files.length) return;
          onDrop(files[0]);
        }}
      />
      <button
        className={`${!inProgress ? 'import-list__btn' : ''} btn-param`}
        onClick={() => {
          inputEl.current.click();
        }}
      >{
          inProgress
            ? 'Processing...'
            : 'Import task list'
        }</button>
    </>
  );
}

ImportTasks.propTypes = {
  setTasks: PropTypes.func,
};

export default ImportTasks;
