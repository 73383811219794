import axios from "axios";
import Response from "../helpers/response.js";

async function login(email, password) {
  try {
    const { data } = await axios.post(
      "/api/login",
      {
        password,
        email,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      }
    );

    localStorage.setItem("auth", JSON.stringify(data));
    return data;
  } catch (error) {
    const { status } = error.response;
    if (
      status == Response.HTTP_UNPROCESSABLE_ENTITY ||
      status == Response.HTTP_UNAUTHORIZED
    ) {
      return error.response.data;
    }

    return false;
  }
}

function logout() {
  localStorage.removeItem("auth");
}

function getStorageData() {
  return JSON.parse(localStorage.getItem("auth"));
}

function getHeader() {
  // return authorization header with jwt token
  const data = getStorageData();

  if (data && data.token) {
    return { Authorization: "Bearer " + data.token };
  } else {
    return {};
  }
}

function getUser() {
  const data = getStorageData();
  return data && data.user ? data.user : null;
}

function getCurrentTraining() {
  const data = getStorageData();
  return data && data.current_training ? data.current_training : null;
}

const authService = {
  login,
  logout,
  getHeader,
  getUser,
  getCurrentTraining,
};

export default authService;
