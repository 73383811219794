import React from 'react';
import TrainingsRow from './trainings-row.jsx';
import PubSub from 'pubsub-js';
import { connect } from 'react-redux';
import { hide, show } from '../../../redux/actions/loader';
import EntityRepository from '../../../repositories/EntityRepository.js';
import { GET_LIST_REQUEST_PARAMS } from '../../common/constants';
import TrainingsFilter from './common/TrainingsFilter';

class Trainings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      count: 0,
    };
    this.path = '/api/trainings';
    this.requestParams = {
      ...GET_LIST_REQUEST_PARAMS,
      status: 'current',
    };
  }

  loadFilterData = (field = 'like', value = '') => {
    this.resetPagination();
    this.requestParams = { ...this.requestParams, [field]: value };
    this.loadData();
  };

  resetPagination() {
    this.requestParams = {
      ...this.requestParams,
      ...GET_LIST_REQUEST_PARAMS,
    };
  }

  onScrollList = async (event) => {
    const { data, count } = this.state;
    const scrollBottom =
      event.target.scrollTop + event.target.offsetHeight ==
      event.target.scrollHeight;
    const loadedCount = data.length;
    if (scrollBottom && loadedCount < count) {
      this.loadData();
    }
  };

  async loadData() {
    const data = await EntityRepository.getEntities(
      this.path,
      this.requestParams
    );
    if (data.items) {
      this.setState((state) => ({
        ...state,
        data: [],
        count: 0,
      }));
      if (!this.requestParams.start) {
        this.setState((state) => ({
          ...state,
          data: [...data.items],
          count: data.count,
        }));
      } else {
        // additional data fetch when scrolling down
        this.setState((state) => ({
          ...state,
          data: [...state.data, ...data.items],
          count: data.count,
        }));
      }
      this.requestParams.start += this.requestParams.limit;
    }
  }

  async componentDidMount() {
    this.props.loaderShow();
    this.token = PubSub.subscribe(
      'Update Table',
      this.updateComponent.bind(this)
    );
    await this.loadData();
    this.props.loaderHide();
  }

  updateComponent = async () => {
    this.requestParams = { ...GET_LIST_REQUEST_PARAMS };
    this.loadData();
  };

  componentWillUnmount() {
    PubSub.unsubscribe(this.token);
  }

  renderRows = () => {
    const { data } = this.state;
    if (data instanceof Object) {
      const path = this.path;

      return data.map((item, i) => (
        <TrainingsRow
          key={i}
          row={item}
          path={path}
          openModal={this.openModalRow}
        />
      ));
    }
  };

  render() {
    return (
      <>
        <TrainingsFilter
          onFilter={this.loadFilterData}
        />
        <section className="training-session">
          <div className="container scrollbar-style" onScroll={(event) => this.onScrollList(event)}>
            <div
              className="training-session-list__wrapper"
              style={{ transition: 'ease 0.5s' }}
            >
              {this.renderRows()}
            </div>
          </div>
        </section>
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    showLoader: state.loaderReducer.showLoader,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loaderShow: () => dispatch(show()),
    loaderHide: () => dispatch(hide()),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Trainings);
