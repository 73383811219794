import React, { useState, useEffect, useContext } from 'react';
import TabContext from './tab-context.js';
import ModalCreateTask from '../modals/modal-create-task.jsx';
import ModalDeleteTask from '../modals/modal-delete-task.jsx';
import ImportTasks from '../inputs/ImportTasks';
import Select from '../../common/select.jsx';
import { alphaNum as sortAlphaNum } from '@/helpers/sort';

function TabTaskEdition() {
  const {
    setFormField,
    formData = {},
    ataIdToCode = {},
    canEdit = true,
  } = useContext(TabContext);

  const sessionAtaId = sessionStorage.getItem('selectedAtaId');
  const [showModalCreateTask, setShowModalCreateTask] = useState(false);
  const [showModalDeleteTask, setShowModalDeleteTask] = useState(false);
  const [selectedTaskIndex, setSelectedTaskIndex] = useState(-1);
  const [selectedAtaId, setSelectedAtaId] = useState(ataIdToCode[sessionAtaId] ? Number(sessionAtaId) : '');

  useEffect(() => () => {
    sessionStorage.setItem('selectedAtaId', selectedAtaId);
  });

  const addTask = (task) => {
    const { tasks } = formData;
    setFormField('tasks', [...tasks, task]);
  };

  const deleteTask = (index) => {
    const { tasks } = formData;
    tasks.splice(index, 1);
    setFormField('tasks', [...tasks]);
  };

  const addAta = (newAta) => {
    if (newAta.id in ataIdToCode) {
      return;
    }
    ataIdToCode[newAta.id] = newAta.code;
    setFormField('ata_ids', [...Object.keys(ataIdToCode).map(Number)]);
  };

  const deleteAta = (selectedAtaId) => {
    const { ata_ids, tasks } = formData;

    if (tasks.some((task) => (task.ata_id == selectedAtaId))) {
      return;
    }
    setFormField(
      'ata_ids',
      ata_ids.filter((ata_id) => ata_id != selectedAtaId)
    );

    delete ataIdToCode[selectedAtaId];
  };

  const ataTasks = formData.tasks
    .filter((task) => (selectedAtaId == '' || task.ata_id == selectedAtaId))
    .sort((a, b) => (sortAlphaNum(ataIdToCode[a.ata_id], ataIdToCode[b.ata_id]) || (a.order_number - b.order_number)));

  return (
    <div className="create-new-user-dash-wrapper create-syllabus-dash-wrapper">
      <div className="create-new-user-form__wrapper create-new-syllabus-form__wrapper">
        <div className="users-edit-history-session__wrapper users-edit-history-session__wrapper_task-edition  create-syllabus-part-1__wrapper scrollbar-style">
          <div className="users-edit-history-session__title">Task edition</div>
          <div className="syllabus-edition__wrapper">
            <div className="syllabus-edition__select-inner">
              <div className="users-edit-history-session__content">
                <div className="users-edit-history-session__active-head">
                  <div className="data-search">
                    <div className="icon-box select-box">
                      <Select
                        label="Select ATA code"
                        value={selectedAtaId}
                        name="filter_ata"
                        onChange={(e) => {
                          const { value } = e.target;
                          setSelectedAtaId(value);
                        }}
                        options={[
                          {
                            value: '',
                            title: 'ALL ATA',
                          },
                          ...Object.keys(ataIdToCode).map((ataId) => ({
                            value: ataId,
                            title: `ATA ${ataIdToCode[ataId]}`,
                          })),
                        ]}
                      />
                    </div>
                    {
                      canEdit && <div className="create-task__buttons">
                        <ImportTasks setTasks={(importedTasks) => {
                          const { tasks } = formData;
                          setFormField('tasks', [
                            ...tasks,
                            ...importedTasks.map((task) => {
                              if (!(task.ata_id in ataIdToCode)) {
                                addAta({
                                  id: task.ata_id,
                                  code: task.ata_code,
                                });
                              }

                              return {
                                ata_id: task.ata_id,
                                order_number: task.order_number,
                                category: task.category,
                                code: task.code,
                                manual_reference: task.manual_reference,
                                subject: task.subject,
                                skills: task.skills || [],
                              };
                            }),
                          ]);
                        }}/>
                        <button
                          className="create-task-btn btn-param"
                          onClick={() => setShowModalCreateTask(true)}
                        >
                          Create new task
                        </button>
                      </div>
                    }
                  </div>
                </div>
                <div className="users-edit-history-session-table__wrapper">
                  <div className="users-edit-table__inner">
                    <label><span className="ata-tasks-badge">{formData.tasks.length}</span> Tasks added in the ATA</label>
                    <table
                      className="users-table__data"
                      id="create-syllabus-table"
                    >
                      <thead className="users-table__thead">
                        <tr>
                          <th>ATA</th>
                          <th>№</th>
                          <th>Task cat.</th>
                          <th>Task code</th>
                          <th>Subject</th>
                          <th>Manual ref.</th>
                          <th>Skills</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody className="users-table__tbody">
                        {formData.tasks
                          .map((task, index) => (
                            <tr key={index} onClick={({ target }) => {
                              if (!canEdit) return;
                              setSelectedTaskIndex(index);
                              if (target.classList.contains('close')
                                || target.classList.contains('fa-close')) {
                                return setShowModalDeleteTask(true);
                              }
                              setShowModalCreateTask(true);
                            }}>
                              <td>{ataIdToCode[task.ata_id]} </td>
                              <td>{task.order_number}</td>
                              <td>
                                <span className="bage">{task.category}</span>
                              </td>
                              <td>{task.code}</td>
                              <td>
                                {task.subject}
                              </td>
                              <td>{task.manual_reference}</td>
                              <td>{task.skills.join(', ')}</td>
                              <td>
                                {canEdit && <span className="close"><i className="fa fa-close"></i></span>}
                              </td>
                            </tr>
                          )
                          )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModalCreateTask ? (
        <ModalCreateTask
          selectedAtaId={selectedAtaId}
          taskIndex={selectedTaskIndex}
          tasks={formData.tasks}
          atas={ataIdToCode}
          onConfirm={(task) => {
            const { tasks } = formData;
            if (selectedTaskIndex !== -1) {
              tasks[selectedTaskIndex] = task;
              setFormField('tasks', [...tasks]);
            } else {
              addTask(task);
            }
            setShowModalCreateTask(false);
            setSelectedTaskIndex(-1);
          }}
          onCancel={() => {
            setShowModalCreateTask(false);
            setSelectedTaskIndex(-1);
          }}
        />
      ) : null}
      {showModalDeleteTask ? (
        <ModalDeleteTask
          task={formData.tasks[selectedTaskIndex]}
          onConfirm={() => {
            deleteTask(selectedTaskIndex);
            setShowModalDeleteTask(false);
            setSelectedTaskIndex(-1);
          }}
          onCancel={() => {
            setShowModalDeleteTask(false);
            setSelectedTaskIndex(-1);
          }}
        />
      ) : null}
    </div>
  );
}

export default TabTaskEdition;
