import React from "react";
import ReactDOM from "react-dom";
import {
  HashRouter
} from "react-router-dom";
import AdminApp from "./admin-app.jsx";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import rootReducer from "./redux/rootReducer";
import reduxTunk from "redux-thunk";
const loggerMiddleware = store => next => action =>{
  const result = next(action);

  return result;
}
const store = createStore(rootReducer, applyMiddleware(loggerMiddleware, reduxTunk));
const adminAppElem = document.getElementById("adminApp");
let application =
  <Provider store={store}>
    <HashRouter>
      <AdminApp/>
    </HashRouter>
  </Provider>;

if (adminAppElem) {
  ReactDOM.render(application, adminAppElem);
}
