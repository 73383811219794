import React from 'react';
import {
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import Settings from '../settings/settings.jsx';
import PrivateRoute from './private-route.jsx';
import {
  ROLE_ADMIN,
  ROLE_TRAINEE,
  ROLE_INSTRUCTOR,
  ROLE_PRODUCT_MANAGER,
  ROLE_QUALITY_MANAGER
} from '../../helpers/role';

import Home from '../frontend/home/home.jsx';
import Login from '../login.jsx';
import Training from '../frontend/trainings/training.jsx';
import PreviewSyllabus from '../frontend/syllabus-preview/syllabus.jsx';
import TrainingSession from '../frontend/training-session/training-session';
import Trainings from '../frontend/trainings/trainings.jsx';
import Stats from '../frontend/stats/stats.jsx';
import CourseReference from '../frontend/course-reference/course-reference.jsx';
import Account from '../frontend/account/account.jsx';

function Router() {
  return (
    <Switch>
      <PrivateRoute exact path="/" component={Home}/>
      <Route exact path="/login" component={Login}/>
      <PrivateRoute roles={[ROLE_ADMIN, ROLE_INSTRUCTOR, ROLE_TRAINEE, ROLE_PRODUCT_MANAGER]} path="/trainings/:trainingId" component={Training}/>
      <PrivateRoute roles={[ROLE_ADMIN, ROLE_INSTRUCTOR, ROLE_TRAINEE, ROLE_PRODUCT_MANAGER, ROLE_QUALITY_MANAGER]} path="/syllabus-preview/:syllabusId" component={PreviewSyllabus}/>
      <PrivateRoute roles={[ROLE_ADMIN, ROLE_INSTRUCTOR, ROLE_PRODUCT_MANAGER]} path="/trainings" component={Trainings}/>
      <PrivateRoute roles={[ROLE_TRAINEE]} path="/training-session" component={TrainingSession}/>
      <PrivateRoute roles={[ROLE_ADMIN, ROLE_PRODUCT_MANAGER, ROLE_QUALITY_MANAGER, ROLE_INSTRUCTOR]} path="/settings" component={Settings}/>
      <PrivateRoute roles={[ROLE_ADMIN, ROLE_PRODUCT_MANAGER, ROLE_QUALITY_MANAGER]} path="/stats" component={Stats}/>
      <PrivateRoute roles={[ROLE_ADMIN, ROLE_PRODUCT_MANAGER, ROLE_QUALITY_MANAGER]} path="/course-reference/:id" component={CourseReference}/>
      <PrivateRoute roles={[ROLE_ADMIN, ROLE_PRODUCT_MANAGER, ROLE_QUALITY_MANAGER]} path="/course-reference" component={CourseReference}/>
      <PrivateRoute path="/account" component={Account}/>
      <Route path="*">
        <Redirect to="/"/>
      </Route>
    </Switch>
  );
}

export default Router;
