import React, { useState, useEffect } from "react";
import EntityRepository from "../../../../../repositories/EntityRepository";
import Request from "../../../../../helpers/request";
import { useTraining } from "../../common/training-context";
import date from "../../../../../helpers/date";
import { useSelector, useDispatch } from "react-redux";
import { success, warning } from "../../../../../redux/actions/notify";
import { useHistory } from "react-router-dom";

function TaskConfirmation() {
  const dispatch = useDispatch();
  const trainee = useSelector((state) => state.authReducer.user);
  const training = useTraining();

  const [isLoading, setIsLoading] = useState(false);
  const [syllabus, setSyllabus] = useState({});
  const [instructor, setInstructor] = useState(null);
  const [traineeTasks, setTraineeTasks] = useState([]);

  const history = useHistory();

  const goBack = () => {
    history.push("/training-session");
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const [resultSyllabus, resultTraineeTasks] = await Promise.all([
        EntityRepository.getEntity("api/syllabi", training.syllabus_uid),
        Request.get(
          `/api/trainings/${training.id}/trainee-validated-tasks`
        ),
      ]);
      if (!resultTraineeTasks.items || !resultTraineeTasks.items.length) {
        dispatch(warning('No validated tasks'));
        return goBack();
      }
      setSyllabus({ ...resultSyllabus });
      setInstructor({ ...resultTraineeTasks.instructor });
      setTraineeTasks([...resultTraineeTasks.items]);
      setIsLoading(false);
    };

    if (training.syllabus_uid) fetchData();
  }, [training.syllabus_uid]);

  const confirm = async () => {
    const response = await Request.put(
      `/api/trainings/${training.id}/confirm-validated-tasks`,
      {
        training_id: training.id,
      }
    );

    if (response.confirmed) {
      dispatch(success("Tasks successfully confirmed"));
      goBack();
    }
  };

  return (
    <div className="validation-assessment__wrapper">
      <div className="validation-assessment__head">
        <div className="validation-assessment__head-item">
          <div className="validation-assessment__company-logo">
            <img
              src="/adminApp/src/assets/img/dark-logo.svg"
              alt="Company logo"
            />
          </div>
          <div className="validation-assessment__head-inner">
            <div className="validation-assessment__head-title">
              Practical training element
            </div>
            <div className="validation-assessment__head-subtitle">
              {syllabus.type}
            </div>
          </div>
        </div>
      </div>

      <div className="syllabus-parts__wrapper">
        <div className="validation-tasks__inner">
          <div className="assessment-sheet__title">
            Confirmation of validated tasks
          </div>

          <div className="trainee-details__activity-panel confirmation-tasks__panel">
            <div className="inform-input-box">
              <label>Trainee Name</label>
              <input
                className="inform-input"
                type="text"
                value={`${trainee.last_name[0]}. ${trainee.first_name}`}
              />
            </div>
            <div className="inform-input-box">
              <label>Date</label>
              <input
                className="inform-input"
                type="text"
                value={`${date.getShortFormatted()}, ${date.getYear()}`}
              />
            </div>
            {
              instructor
              ? <div className="confirmation-tasks__descr">
                  <span>{`${instructor.first_name} ${instructor.last_name}`}</span>, {instructor.company} instructor, declares that you
                  have validated the following tasks:
                </div>
              : null
            }
          </div>

          <div className="user-personal__table-inform">
            <div className="users-table__wrapper">
              <table className="users-table__data">
                <thead className="users-table__thead">
                  <tr>
                    <th>ATA</th>
                    <th>№</th>
                    <th>Task category</th>
                    <th>Task code</th>
                    <th>Subject</th>
                    <th>Manual reference</th>
                    <th>Classroom</th>
                    <th>Aircraft</th>
                    <th>State</th>
                  </tr>
                </thead>
                <tbody className="users-table__tbody">
                  {isLoading ? (
                    <tr>
                      <td colSpan="9">
                        <i className="fa fa-spin fa-circle-o-notch"></i>
                      </td>
                    </tr>
                  ) : (
                    traineeTasks.map(
                      (
                        {
                          classroom_date,
                          aircraft_date,
                          task: {
                            ata_code = "",
                            order_number = "",
                            category = "",
                            code = "",
                            subject = "",
                            manual_reference = "",
                          },
                        },
                        index
                      ) => (
                        <tr
                          className={`category-${category.toLowerCase()}`}
                          key={index}
                        >
                          <td>{ata_code}</td>
                          <td>#{order_number}</td>
                          <td>
                            <span
                              className={`table-bage ${category.toLowerCase()}-bage`}
                            >
                              {category}
                            </span>
                          </td>
                          <td>{code}</td>
                          <td>{subject}</td>
                          <td>{manual_reference}</td>
                          <td className="checked-data">
                            {classroom_date
                              ? date.getNumberFormatted(classroom_date)
                              : "-"}
                          </td>
                          <td className="checked-data">
                            {aircraft_date
                              ? date.getNumberFormatted(aircraft_date)
                              : "-"}
                          </td>
                          <td>
                            <i className="fa fa-check"></i>
                          </td>
                        </tr>
                      )
                    )
                  )}
                </tbody>
              </table>
            </div>
            <div className="valid-trainee__buttons">
              <button className="no-delete__btn btn-param" onClick={goBack}>
                No, I don't agree
              </button>
              <button
                className="validate-assessment__btn btn-param"
                onClick={confirm}
              >
                Yes, I confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TaskConfirmation;
