import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Part1 from '../validations/part1/part1';
import Part2 from '../validations/part-2/part-2';
import TrainingHeader from '../common/TrainingHeader.jsx';

function Validations() {
  const { path } = useRouteMatch();

  return (<>
    <TrainingHeader/>
    <section className="training-session-trainee-list">
      <div className="container">
        <div className="validation-assessment__wrapper">
          <Switch>
            <Route path={`${path}/part-1`} component={Part1} />
            <Route path={`${path}/part-2`} component={Part2} />
          </Switch>
        </div>
        <div className="copyright">
          © Société Air France - All rights reserved
        </div>
      </div>
    </section>
  </>);
}

export default Validations;
