import authService from "../../services/auth-service.js";

export const LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'AUTH_LOGIN_FAILURE';
export const LOGOUT = 'AUTH_LOGOUT';
export const UPDATE_USER = 'UPDATE_USER';

export function login(email, password) {
  return async dispatch => {
    const data = await authService.login(email, password);

    if (!data.user) {
      return dispatch({ type: LOGIN_FAILURE, error: data });
    }

    return dispatch({ type: LOGIN_SUCCESS, user: data.user });
  };
}

export function updateUser(user) {

  return { type: UPDATE_USER, user };
}

export function logout() {
  authService.logout();
  return { type: LOGOUT };
}
