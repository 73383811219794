import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

function MyDropzone(props) {
  const { disabled = false } = props;
  const onDrop = useCallback((acceptedFiles) => {
    const acceptedSizeInMb = 1;
    const acceptedSizeInBytes = acceptedSizeInMb * 1024 * 1024;
    if (acceptedFiles.length > 0) {
      let file = acceptedFiles[0];

      if (file.size > acceptedSizeInBytes) {
        return alert(`File size exceeds ${acceptedSizeInMb} MB`);
      }

      var reader = new FileReader();

      props.setImage(props.inputName, file);

      reader.onload = function (e) {
        props.setFilePath(props.inputName, e.target.result);
      };
      reader.readAsDataURL(file);
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} disabled={disabled} name={props.inputName} />
      <div className="export-files__item">
        <div className="users-edit-history-session__title">{props.title}</div>
        {isDragActive ? (
          <div
            className={`export-files__wrapper drag-drop-class ${
              props.className || ''
            }`}
          >
            <button className="export-files__btn"></button>
          </div>
        ) : (
          <>
            {props.path ? (
              <div
                className={`export-files__wrapper active-wrapper ${
                  props.className || ''
                }`}
              >
                <img src={props.path} />
              </div>
            ) : (
              <div
                className={`export-files__wrapper archived-table-data ${
                  props.className || ''
                }`}
              >
                <button className="export-files__btn"></button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default MyDropzone;
