
import React from 'react';
import { useLocation } from 'react-router-dom';

const DashboardTab = ({to, children}) => {
  const location = useLocation();
  const match = to === location.pathname;

  return (
    <div
      onClick={ () => { window.location.hash = to; } }
      className={"tabs__title" + ( match ? " active" : "") }
      data-tab-name={"trainee"}
    >{children}
    </div>
  )
};

export default DashboardTab;
