import React from 'react';

const Interface = ({ className = '', children }) => {
  return (
    <section className={`user-interface ${className}`}>
      <div className="user-dashboard">
        <div className="container">
          <div className="user-dashboard__wrapper">
            {children}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Interface;
