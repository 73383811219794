import React, { useEffect, useState } from 'react';
import Request from '../../../../../helpers/request';
import { useSyllabus } from '../common/syllabus-context';

function SummaryTable() {
  const [data, setData] = useState([]);
  const syllabus = useSyllabus();

  useEffect(() => {
    const fetchData = async () => {
      const result = await Request.get(`/api/syllabi/${syllabus.id}/validated-ata-tasks`);
      if (result) {
        setData(result);
      }
    };

    syllabus.id ? fetchData() : '';
  }, [syllabus.id]);

  return (
    <div className="syllabus-parts__wrapper">
      <div className="syllabus-parts__inner scrollbar-style">
        <div className="assessment-sheet__title">Main Task summary table</div>

        <div className="introduction-summary__wrapper scrollbar-style">
          {
            data.map((table, tableIndex) => (
              <div className="introduction-summary__item" key={tableIndex}>
                <table className="introduction-summary__table">
                  <thead className="introduction-summary__thead">
                    <tr>
                      <th>ATA code</th>
                      <th>
                        <span className="syllabus-symbols__bage mt-bage">mt</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="introduction-summary__tbody">
                    {
                      Object.keys(table).map((ataCode, rowIndex) => (
                        <tr key={rowIndex}>
                          <td>ATA {ataCode}</td>
                          <td>
                            <span className="table-number">
                              {table[ataCode] || 0}
                            </span>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
}

export default SummaryTable;
