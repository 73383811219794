import React from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { ACTION_ARCHIVE, ACTION_RESTORE } from '../../common/constants';
import TableCheckbox from '../../common/table/table-checkbox';
import TableRowArchiveButton from '@/components/common/table/table-row-archive-button';
import TableRowFlag from '@/components/common/table/table-row-flag';

const StaffRow = (props) => {
  const match = useRouteMatch();
  const { row, openModal } = props;
  const editPass = `${match.url}/${row.id}/edit`;
  let trClassName, action;
  if (row.is_archived) {
    trClassName = 'archived-table-data';
    action = ACTION_RESTORE;
  } else {
    trClassName = '';
    action = ACTION_ARCHIVE;
  }
  row.skill.sort();

  return (
    <tr className={trClassName}>
      <td>
        <TableCheckbox id={row.id} />
      </td>
      <td>
        <NavLink
          data-id={row.id}
          data-entity={props.path}
          to={editPass}
          className="users-table__tbody-link"
        >
          <TableRowFlag id={row.id} />
          &nbsp;{row.last_name}
        </NavLink>
      </td>
      <td>
        <NavLink
          data-id={row.id}
          data-entity={props.path}
          to={editPass}
          className="users-table__tbody-link"
        >
          {row.first_name}
        </NavLink>
      </td>
      <td>
        <NavLink
          data-id={row.id}
          data-entity={props.path}
          to={editPass}
          className="users-table__tbody-link"
        >
          {row.role_title}
        </NavLink>
      </td>
      <td>
        <NavLink
          data-id={row.id}
          data-entity={props.path}
          to={editPass}
          className="users-table__tbody-link"
        >
          {row.user_categories != null &&
            row.user_categories.map((name, index) => (
              <span className="bage" key={index}>
                {name}
              </span>
            ))}
        </NavLink>
      </td>
      <td>
        <NavLink
          data-id={row.id}
          data-entity={props.path}
          to={editPass}
          className="users-table__tbody-link"
        >
          {row.skill.map((skill, index) =>
            skill != '' ? (
              <span className="bage" key={index}>
                {skill}
              </span>
            ) : null
          )}
        </NavLink>
      </td>
      <td>
        <TableRowArchiveButton action={action} onClick={() => openModal(row, action)}/>
      </td>
      <td>
        <NavLink data-id={row.id} data-entity={props.path} to={editPass}>
          <button className="edit-data" title="Edit"></button>
        </NavLink>
      </td>
    </tr>
  );
};

export default StaffRow;
