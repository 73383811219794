import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Request from '@/helpers/request';
import { useSyllabus } from '../common/syllabus-context';
import AssessmentSheet from './components/AssessmentSheet';

function AssessmentPart() {
  const syllabus = useSyllabus();
  const { trainingId, traineeId } = useParams();
  const {
    assessment_part: assessmentPart = '',
    assessment_description: assessmentDescription = '',
  } = syllabus;
  const [trainingResults, setTrainingResults] = useState([{
    id: 0,
    attempt: 1,
    is_completed: false,
    assessments: [],
    validated_mt_count: 0,
    date: null,
  }]);
  const [taskCount, setTaskCount] = useState({});

  const assessmentPartSegments = assessmentPart.split('\n\n');
  const assessmentDescriptionSegments = assessmentDescription.split('\n\n');

  useEffect(() => {
    const fetchData = async () => {
      const [trainingResultsResponse, taskCountResponse] = await Promise.all([
        Request.get(`/api/trainings/${trainingId}/trainees/${traineeId}/results`),
        Request.get(`/api/trainings/${trainingId}/trainees/${traineeId}/task-count`),
      ]);
      if (trainingResultsResponse && trainingResultsResponse.length) {
        setTrainingResults(trainingResultsResponse);
      }
      setTaskCount(taskCountResponse);
    };

    fetchData();
  }, []);

  return (
    <div className="syllabus-parts__wrapper">
      <div className="syllabus-parts__inner scrollbar-style">
        <div className="assessment-sheet__title">Assessment part</div>
        <div className="syllabus-parts__descr">
          {
            assessmentPartSegments.map((assessmentPartSegment, index) => (
              <p key={index}>{
                assessmentPartSegment
                  .split('\n')
                  .map((text, n) => (
                    <React.Fragment key={n}>{text}<br/></React.Fragment>
                  ))
              }</p>
            ))
          }
          {/* <div className="syllabus-parts__list-subtitle">
            After the practical element of the aircraft type training has been
            completed, an assessment must be performed, which must comply with
            the following :
          </div>
          <ul className="syllabus-parts__descr-list">
            <li>
              the assessment shall be performed by designated assessors
              appropriately qualified,
            </li>
            <li>the assessment shall evaluate the trainee.</li>
          </ul> */}
        </div>
        <div className="assessment-sheet__title">
          Description of this practical training assessment
        </div>
        <div className="syllabus-parts__descr">
          {
            assessmentDescriptionSegments.map((assessmentDescriptionSegment, index) => (
              <p key={index}>{
                assessmentDescriptionSegment
                  .split('\n')
                  .map((text, n) => (
                    <React.Fragment key={n}>{text}<br/></React.Fragment>
                  ))
              }</p>
            ))
          }
        </div>
        {
          trainingResults.map((trainingResult) => {
            return (
              <AssessmentSheet
                key={trainingResult.id}
                trainingResult={trainingResult}
                taskCount={taskCount}
              />
            );
          }
          )
        }
      </div>
    </div>
  );
}

export default AssessmentPart;
