import React, { useEffect, useState } from 'react';
import AtaRow from './ata-row.jsx';
import EntityRepository from '../../repositories/EntityRepository.js';
import TableSearch from '../common/table/table-search.jsx';
import Table from '../common/table/table';
import { ACTION_ARCHIVE, GET_LIST_REQUEST_PARAMS } from '../common/constants';
import TableHeader from '../common/table/table-header.jsx';
import TableBody from '../common/table/table-body.jsx';
import { archivingActions } from '../common/table/table-actions.js';
import Request from '../../helpers/request';
import RevisionModal from './common/modal-revision.jsx';

const AtaTable = (props) => {
  // eslint-disable-next-line react/prop-types
  const { thead, columns, path, importResult } = props;
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [overlay, setOverlay] = useState({
    showModal: false,
    selectedIds: [],
    entityTitles: [],
  });

  const [modalRevision, setModalRevision] = useState({
    isOpen: false,
    revisions: [],
  });

  const [requestParams, setRequestParams] = useState({
    ...GET_LIST_REQUEST_PARAMS, archive: 'active',
  });

  const pathPdf = '/api/atas/generate-training-pdf';

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const data = await EntityRepository.getEntities(
        path,
        requestParams
      );
      if (data) {
        if (!requestParams.start) {
          setData(data.items);
          setCount(data.count);
        }else{
          setData(state => ([ ...state, ...data.items ]));
          setCount(data.count);
        }
        setIsLoading(false);
      }
    };
    if (!overlay.showModal) {
      fetchData();
    }
  }, [requestParams, importResult, overlay]);

  const openModalRow = (row, action = ACTION_ARCHIVE) => {
    if (row.id !== undefined) {
      setOverlay({
        showModal: true,
        popupName: 'ata',
        entityName: row.code,
        entityTitles: [ row.id ],
        selectedIds: [ row.id ],
        action,
      });
    }
  };

  const openModalTable = (selectedIds, action = ACTION_ARCHIVE) => {
    if (!selectedIds.length) return;
    setOverlay({
      showModal: true,
      popupName: 'ata',
      entityName: 'Ata',
      entityTitles: selectedIds,
      selectedIds,
      action,
    });
  };

  const openModalRevision = async (uid) => {
    if (!uid) return;
    const [loadedRevision, loadedRevisions] = await Promise.all([
      EntityRepository.getEntity('/api/atas', uid),
      Request.get(`/api/atas/${uid}/revisions`),
    ]);

    setModalRevision({
      modalRevision: {
        isOpen: true,
        lastRevision: loadedRevision ? loadedRevision : {},
        revisions: loadedRevisions && loadedRevisions.length ? loadedRevisions : [],
      },
    });
  };

  const onScrollList = (event) => {
    const scrollBottom =
      Math.ceil((event.target.scrollHeight - event.target.scrollTop) - 100) <= event.target.clientHeight;
    const loadedCount = data.length;
    if (scrollBottom && !isLoading && loadedCount < count) {
      loadAdditionalData();
    }
  };

  const loadAdditionalData = () => {
    setRequestParams(data => ({
      ...data,
      start: requestParams.start + requestParams.limit,
    }));
  };

  const loadFilterData = (field = 'like', value = '') => {
    setRequestParams(data => ({ ...data, [field]: value }));
  };

  const renderRows = () => {
    const rows = data.map((item, i) => (
      <AtaRow
        key={i}
        row={item}
        path={path}
        columns={columns}
        openModal={openModalRow}
        openModalRevision={openModalRevision}
      />));

    if (isLoading) {
      const loadingRow = <tr key="loadingRow">
        <td colSpan="6">
          <i className="fa fa-spin fa-circle-o-notch"></i>
        </td>
      </tr>;

      if (requestParams.start) {
        rows.push(loadingRow);
      } else {
        return loadingRow;
      }
    }

    return rows;
  };

  const resetPagination = () => {
    setRequestParams({
      ...GET_LIST_REQUEST_PARAMS, archive: 'active',
    });
  };

  return (
    <Table>
      <TableHeader count={count} title="ATA">
        <TableSearch
          onArchiveFilter={(e) => loadFilterData('archive', e)}
          onSearch={(e) => loadFilterData('like', e)}
          getFilters={requestParams}
          pdfUrl={pathPdf}
          archiveFilterOptions={[
            {
              className: 'curent-btn',
              title: 'active',
              value: 'active',
              isActive: true,
            },
            {
              className: 'complete-btn',
              title: 'archived',
              value: 'archived',
            },
            {
              className: 'all-users-btn',
              title: 'all',
              value: 'all',
            },
          ]}
        >
          <div className="data-sort__box">
            <span>Sort by</span>
            <select
              id="ata-sort"
              name="sort"
              onChange={({ target: { value } }) => {
                loadFilterData('sort', value);
              }}
            >
              <option value="asc">Ascending</option>
              <option value="desc">Descending</option>
            </select>
          </div>
        </TableSearch>
      </TableHeader>
      <TableBody
        actions={archivingActions(openModalTable)}
        overlay={overlay}
        onScroll={event => onScrollList(event)}
        path={path}
        setOverlay={setOverlay}
        thead={thead}
        lastThColSpan={3}
        tableId="ata-list-table"
      >
        {renderRows()}
      </TableBody>
      {
        modalRevision.isOpen
          ? <RevisionModal
            revisions={modalRevision.revisions}
            lastRevision={modalRevision.lastRevision}
            closeModal={() => {
              this.setState({
                modalRevision: {
                  isOpen: false,
                  revisions: [],
                  lastRevision: {},
                },
              });
            }}
          />
          : null
      }
    </Table>
  );
};

export default AtaTable;
