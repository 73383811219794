import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Pages from './pages/pages';
import SyllabusMain from './syllabus-main';
import TaskConfirmation from './task-confirmation/task-confirmation';
import TrainingHeader from '../common/TrainingHeader.jsx';

function Syllabus() {
  const { path } = useRouteMatch();

  return (<>
    <TrainingHeader/>
    <section className="training-session-trainee-list">
      <div className="container">
        <Switch>
          <Route path={`${path}/`} component={SyllabusMain} exact/>
          <Route path={`${path}/pages`} component={Pages}/>
          <Route path={`${path}/task-confirmation`} component={TaskConfirmation} exact/>
        </Switch>
        <div className="copyright">
          © Société Air France - All rights reserved
        </div>
      </div>
    </section>
  </>);
}

export default Syllabus;
