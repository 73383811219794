import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import date from '../../../helpers/date';

const TrainingsRow = ({ row }) => (
  <div
    className={`training-session__item ${
      row.completed ? 'completed' : 'current'
    }-training-session`}
  >
    <NavLink
      to={`/trainings/${row.id}`}
      className="training-session__item-link"
    >
      <div className="training-session__item-content training-session__item-inform">
        <div className="training-session__item-title">
          training session
          <span className="training-session__status-bage">{row.status}</span>
        </div>
        <div className="training-session__code-inform">
          <span className="training-session__item-number">
            {row.session_number}
          </span>
          <span className="training-session__item-code">
            TC: {row.training_code}
          </span>
        </div>
      </div>
      <div className="training-session__item-content content-wrapper">
        <div className="training-session__item-syllabus content-inner">
          <div className="training-session__item-subtitle">Syllabus</div>
          <div className="training-session__item-syllabus-name">
            {row.syllabus_title}
          </div>
        </div>
        <div className="training-session__item-instructor content-inner">
          <div className="training-session__item-subtitle">Instructor(s)</div>
          {row.instructor_names.map((item, index) => {
            return <span key={index} className="instructor-name">{item}</span>;
          })}
        </div>
        <div className="training-session__item-trainee content-inner">
          <div className="training-session__item-subtitle">Trainee(s)</div>
          <span className="trainee-number">{row.trainee_count}</span>
        </div>
      </div>
      <div className="training-session__item-content training-session__item-date">
        <div className="training-session__item-subtitle">Dates</div>
        <div className="date-wrapper">
          <span className="date-from">
            {date.getFormatted(row.date_start, true)}
          </span>
          <span className="date-to">
            {date.getFormatted(row.date_end, true)}
          </span>
        </div>
      </div>
      <div className="training-session__item-content open-info__btn">
        <i className="fa fa-angle-right"></i>
      </div>
    </NavLink>
  </div>
);

TrainingsRow.propTypes = {
  row: PropTypes.object.isRequired,
};

export default withRouter(TrainingsRow);
