import React from 'react';
import { NavLink, withRouter, useRouteMatch } from 'react-router-dom';
import TableCheckbox from '../common/table/table-checkbox';
import { ACTION_ARCHIVE, ACTION_RESTORE } from '../common/constants';
import TableRowArchiveButton from '@/components/common/table/table-row-archive-button';
import TableRowFlag from '@/components/common/table/table-row-flag';

const AtaRow = (props) => {
  const match = useRouteMatch();
  const { row, openModal, openModalRevision } = props;
  const editAta = `${match.url}/${row.u_id}/edit`;
  let trClassName, action;
  if (row.is_archived) {
    trClassName = 'archived-table-data';
    action = ACTION_RESTORE;
  } else {
    trClassName = '';
    action = ACTION_ARCHIVE;
  }

  return (
    <tr className={trClassName}>
      <td>
        <TableCheckbox id={row.id} />
      </td>
      <td>
        <NavLink
          data-id={row.u_id}
          data-entity={props.path}
          to={{
            pathname: `${editAta}`,
            state: {
              filter: `${props.filter}`,
            },
          }}
          className="users-table__tbody-link"
        >
          {row.code}
        </NavLink>
        <TableRowFlag id={row.id} />
      </td>
      <td>
        <NavLink
          data-id={row.u_id}
          data-entity={props.path}
          to={{
            pathname: `${editAta}`,
            state: {
              filter: `${props.filter}`,
            },
          }}
          className="users-table__tbody-link"
        >
          {row.description}
        </NavLink>
      </td>
      <td>
        <div className="number-version more-version">
          0{row.version}
          {row.version > 1 ? (
            <span
              className="number-version-icon pointer"
              onClick={() => openModalRevision(row.u_id)}
            ></span>
          ) : (
            ''
          )}
        </div>
      </td>
      <td>
        <TableRowArchiveButton action={action} onClick={() => openModal(row, action)}/>
      </td>
      <td>
        <NavLink
          data-id={row.u_id}
          data-entity={props.path}
          to={{
            pathname: `${editAta}`,
            state: {
              filter: `${props.filter}`,
            },
          }}
        >
          <button title="Edit" className="edit-data"></button>
        </NavLink>
      </td>
    </tr>
  );
};

export default withRouter(AtaRow);
