import React from "react";
import date from "../../../helpers/date.js";

function CreateRevisionModal({close, handleChange, confirmAction, isDisable, comment}) {
    if(comment.length > 0) {
        isDisable = false;
    }
  return (
    <div className="overlay">
    <div className="modal-act-element__create-task modal-version scrollbar-style" id="modal-revision-comment">
        <div className="modal-act-element__wrapper">
            <div className="modal-act-element__head">
                <div className="modal-act-element__title">
                    Revision commentary
                </div>
                <div className="modal-act-element__sesion-date">
                    <span>{date.getFormatted()}</span>
                </div>
            </div>
            <div className="modal-version__wraper">
                <label>Description of the modification</label>
                <div className="version-descr-wrapper">
                    <div className="version-descr-inner">
                        <textarea className="scrollbar-style" name="revision_comment" id="revision-comment" onChange={handleChange}></textarea>
                    </div>
                </div>
            </div>
            <button className="no-delete__btn btn-param" onClick={close}>Cancel</button>
            <button className="no-delete__btn btn-param" onClick={confirmAction} disabled={isDisable}>Create</button>
        </div>
    </div>
</div>
  );
}

export default CreateRevisionModal;
