import React from "react";
import { useSelector } from 'react-redux';

function PreLoader() {
  const showLoader = useSelector(state => state.loaderReducer.showLoader);
  if (!showLoader) {
    return null;
  }
  return (
    <div className="circle-preloader__overlay">
        <i className="circle-preloader__loader"></i>
    </div>
  );
}

export default PreLoader;
