import React, { useEffect, useState, useRef } from 'react';
import Request from '../../../helpers/request';
import StatsHeader from './stats-header';
import IconDownload from '../../common/icons/icon-download';
import StatsProgressbar from './stats-progressbar';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { hide, show } from '../../../redux/actions/loader';

function Stats() {
  const dispatch = useDispatch();
  const { state: locationState } = useLocation();
  const [trainingList, setTrainingList] = useState([]);
  const [traineeGroupValue, setTraineeGroupValue] = useState('');
  const [traineeGroups, setTraineeGroups] = useState([]);
  const [training, setTraining] = useState({});
  const [stats, setStats] = useState([]);
  const getEmptyFiltersObject = () => ({
    training: null,
    traineeGroup: null,
  });
  const filtersObject = useRef(getEmptyFiltersObject());

  const resetFilters = () => {
    filtersObject.current = getEmptyFiltersObject();
  };

  const setFilter = (name, value) => {
    filtersObject.current[name] = value;
  };

  const fetchStats = async () => {
    dispatch(show());
    const result = await Request.get(`api/stats?filters=${JSON.stringify(filtersObject.current)}`);
    setStats([...result]);
    dispatch(hide());
  };

  const generatePdf = () => window.open(`api/stats/generate-pdf?filters=${JSON.stringify(filtersObject.current)}`, '_blank');

  const mountTraining = (training) => {
    if (training) {
      setFilter('training', training.id);
      setTraineeGroups(Array.isArray(training.trainee_groups) ? training.trainee_groups : []);
      setTraining(training);
    }
  };

  const onChangeTraining = (value) => {
    resetFilters();
    setTraineeGroupValue('');
    if (value === '') {
      setTraining({});
      return setTraineeGroups([]);
    }

    const id = Number(value);
    const training = trainingList.find((training) => training.id === id);
    mountTraining(training);
  };

  useEffect(() => {
    const fetchTrainingList = async () => {
      dispatch(show());
      const result = await Request.get('api/stats/training-list');
      setTrainingList([...result]);
      dispatch(hide());

      if (locationState && locationState.trainingId) {
        const training = result.find((training) => training.id === locationState.trainingId);
        mountTraining(training);
      }

      fetchStats();
    };

    if (!trainingList.length) fetchTrainingList();

  }, []);

  const onChangeTraineeGroup = (value) => {
    setTraineeGroupValue(value);
    if (value === '') {
      setFilter('traineeGroup', null);
      return;
    }

    const id = Number(value);
    setFilter('traineeGroup', id);
  };

  const numFormatter = (num) => {
    return num > 999 ? (num / 1000).toFixed(1) + 'K' : ('00' + num).slice(-3);
  };

  return (
    <>
      <StatsHeader dateStart={training.date_start} dateEnd={training.date_end}/>
      <section className="training-statistics">
        <div className="container">
          <div className="training-statistics__filter-panel">
            <div className="training-statistics__filter-criterion">
              <div className="icon-box select-box">
                <select
                  value={training.id}
                  onChange={({ target: { value } }) => onChangeTraining(value)}>
                  <option value="">All trainings</option>
                  {
                    trainingList.map((training, index) => (
                      <option value={training.id} key={index}>#{training.session_number}</option>
                    ))
                  }
                </select>
              </div>
              <div className="icon-box select-box">
                <select value={traineeGroupValue} onChange={({ target: { value } }) => onChangeTraineeGroup(value)}>
                  <option value="">All groups</option>
                  {
                    traineeGroups.map((traineeGroup, index) => (
                      <option value={traineeGroup.id} key={index}>
                        {traineeGroup.name}
                      </option>
                    ))
                  }
                </select>
              </div>
              <button
                className="training-statistics__filter-btn btn-param"
                onClick={fetchStats}
              >
                Filter out
              </button>
            </div>
            <div>
              <button
                className="dovnload__btn"
                onClick={() => generatePdf()}>
                <IconDownload />
              </button>
            </div>
          </div>

          <div className="training-statistics__cards">
            {
              stats.map((item, index) => (
                <div className="training-statistics__item" key={index}>
                  <div className="training-statistics__item-title">
                    <span className="number">{item.total}</span>
                    <span className="item-title">{item.title}</span>
                    {
                      item.category !== 'all'
                        ? <span className={`cards__bage ${item.category.toLowerCase()}-bage`}>{item.category}</span>
                        : null
                    }
                  </div>
                  <div className="training-statistics__inner">
                    <div className="task-card validatet-task">
                      <span className="task-card__subtitle">Validated</span>
                      <span className="task-card__number">{numFormatter(item.validated)}</span>
                    </div>
                    <div className="task-card remaining-task">
                      <span className="task-card__subtitle">Remaining</span>
                      <span className="task-card__number">{numFormatter(item.remaining)}</span>
                    </div>
                    <div className="statistic-cards persent-card">
                      <div className="statistic-text">
                        Validation <br />
                        Percentage
                      </div>
                      <StatsProgressbar value={item.validated_percent}/>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </section>
    </>
  );
}

export default Stats;
