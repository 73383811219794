import React from "react";
import PubSub from "pubsub-js";
import { connect } from "react-redux";
import { hide, show } from "../../../redux/actions/loader";
import { updateUser } from "../../../redux/actions/auth";
import { success, warning } from "../../../redux/actions/notify";
import Request from "../../../helpers/request";
import TextInput from "../../common/textInput.jsx";
import DatePicker from "react-datepicker";
import ErrorRepository from "../../../repositories/ErrorRepository.js";
import Select from "react-select";
import EntityRepository from "../../../repositories/EntityRepository";
import {
  ROLE_ADMIN,
  ROLE_TRAINEE,
  ROLE_INSTRUCTOR,
} from "../../../helpers/role";

class Account extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: {
        email: "",
        password: null,
        id_number: "",
        first_name: "",
        last_name: "",
        role_title: "",
        birth_place: "",
        category: null,
        skill: [],
        user_categories: [],
        notification: false,
      },
      errors: [],
      isLoading: false,
      options: [],
      traineeId: null,
      avatarButtonColor: "#2392EA",
      avatarFile: null,
      avatarPreview: null,
      isOpen: {
        oldPassword: false,
        newPassword: false,
      }
    };
    this.path = "/api/account";
  }

  async loadData() {
    const data = await Request.get(this.path);
    if (data) {
      this.setState({ userData: data });
    }
  }

  async componentDidMount() {
    this.props.loaderShow();
    this.token = PubSub.subscribe(
      "Update Table",
      this.updateComponent.bind(this)
    );
    const {
      authUser: { roles },
    } = this.props;
    await this.loadData();
    if (roles.includes(ROLE_ADMIN) || roles.includes(ROLE_INSTRUCTOR)) {
      await this.getTrainees();
    }
    this.props.loaderHide();
  }

  async getTrainees() {
    const data = await Request.get("/api/users/all-trainees");
    let options = [];
    data.map((item) => {
      options.push({
        value: item.id,
        label: item.first_name + " " + item.last_name,
      });
    });
    this.setState({ traineesOptions: options });
  }

  updateComponent = async () => {
    this.loadData();
  };

  componentWillUnmount() {
    PubSub.unsubscribe(this.token);
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.clearErrorField(name);
    if (name == "roles") {
      this.setState({
        userData: {
          ...this.state.userData,
          roles: [value],
        },
      });
    } else {
      this.setState({
        userData: {
          ...this.state.userData,
          [name]: value,
        },
      });
    }
  };

  getFile = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = ({ target: { result } }) => {
      this.setState({
        avatarFile: file,
        avatarPreview: result,
      });
    };
    reader.readAsDataURL(file);
  };

  dateChange = (date) => {
    this.clearErrorField("birth_date");
    this.setState({
      userData: {
        ...this.state.userData,
        birth_date: date,
      },
    });
  };

  clearErrorField = (fieldName) => {
    const { errors } = this.state;
    if (errors[fieldName] && errors[fieldName] != "") {
      this.setState({ errors: { ...errors, [fieldName]: "" } });
    }
  };

  changeNotification = (checked) => {
    this.setState({
      userData: { ...this.state.userData, notification: checked },
    });
  };

  setTraineeId = (id) => {
    this.setState({ traineeId: id });
  };

  // changeType = () => {
  //   const { oldPassword } = this.state;
  //   document.querySelector('#old_pass').type = oldPassword ? 'password' : 'text';

  //   this.setState({
  //     oldPassword: oldPassword ? false : true
  //   })
  // };
  // changeTypeNew = () => {
  //   const { newPassword } = this.state;

  //   document.querySelector('#new_pass').type = newPassword ? 'password' : 'text';

  //   this.setState({
  //     newPassword: newPassword ? false : true
  //   })
  // }

  async resetPassword() {
    if (this.state.traineeId === null) {
      this.props.warning("Please select an trainee.");
      return;
    }

    const response = await EntityRepository.updateEntity(
      "/api/users",
      `${this.state.traineeId}/reset-trainee-pass`
    );

    if (response.update === true) {
      this.props.success("Password successfully reseted!");
    }
  }

  async updatePassword() {
    const { changePass } = this.state;

    if (changePass.old_pass === null) {
      this.props.warning("Please input old password.");
      return;
    }

    if (changePass.new_pass === null) {
      this.props.warning("Please input new password.");
      return;
    }

    const response = await EntityRepository.updateEntity(
      "/api/users",
      "update-pass",
      {
        old_pass: changePass.old_pass,
        new_pass: changePass.new_pass,
      }
    );

    if (response.update === true) {
      this.props.success("Password successfully reseted!");

      this.setState({
        changePass: {
          old_pass: null,
          new_pass: null,
        },
      });
      document.querySelectorAll("input[type=password]").forEach((item) => {
        item.value = "";
      });
    } else if (response.message) {
      this.props.warning(response.message);
    }
  }

  changePass = (value, name) => {
    this.setState({ changePass: { ...this.state.changePass, [name]: value } });
  };

  async updateUser() {
    const { userData, avatarFile: avatar } = this.state;
    let data = new FormData();
    data.append("avatar", avatar);

    const response = await EntityRepository.updateEntity(
      "/api/users",
      userData.id,
      userData
    );

    if (response.update === true) {
      if (avatar) {
        const image = await Request.post(
          `/api/users/${userData.id}/save-avatar`,
          data
        );
        this.setState({ avatarFile: null });
        if (image.upload === true) {
          this.props.updateUser(image.user);
        } else {
          this.props.success(image.message);
          return;
        }
      } else {
        this.props.updateUser(response.user);
      }

      PubSub.publish("Update Table", true);
      this.props.success("Information data changed success!");
    } else {
      this.setState((state) => ({ ...state, errors: response.errors }));
    }
  }

  toogleType = (name) => {
    const { isOpen } = this.state; 
    this.setState({
      ...this.state,
      isOpen:{
        ...isOpen,
        [name]:!isOpen[name]
      }
    });
  }

  render() {
    const {
      errors,
      userData,
      traineesOptions,
      avatarFile,
      avatarPreview,
      avatarButtonColor,
      isOpen,
    } = this.state;
    const {
      authUser: { roles },
    } = this.props;

    const avatarImage =
      (avatarPreview ? avatarPreview : userData.avatar) ||
      "/adminApp/src/assets/icons/user-icon.svg";
    return (
      <>
        <div className="training-settings__wrapper">
          <div className="user-interface__subheader">
            <div className="container">
              <div className="user-interface__subheader-wrapper">
                <div className="user-interface__subheader-title">Account</div>
              </div>
            </div>
          </div>
        </div>
        <section className="account">
          <div className="container">
            <div className="account__wrapper">
              <div className="account__item">
                <div className="account__title">Profile</div>
                <div className="account__user-board">
                  <div className="account__user-name icon-box">
                    {userData.first_name + " " + userData.last_name}
                  </div>

                  <div
                    className={
                      "icon-box" +
                      ErrorRepository.getErrorClassName(errors, "first_name")
                    }
                  >
                    <TextInput
                      inputName={"first_name"}
                      inputLabel={"First Name"}
                      inputValue={userData.first_name}
                      errors={errors}
                      changeInput={this.handleChange}
                    />
                  </div>

                  <div
                    className={
                      "icon-box" +
                      ErrorRepository.getErrorClassName(errors, "last_name")
                    }
                  >
                    <TextInput
                      inputName={"last_name"}
                      inputLabel={"Last Name"}
                      inputValue={userData.last_name}
                      errors={errors}
                      changeInput={this.handleChange}
                    />
                  </div>

                  <div
                    className={
                      "icon-box create-user-birth" +
                      ErrorRepository.getErrorClassName(
                        this.state.errors,
                        "birth_date"
                      )
                    }
                  >
                    <label htmlFor="create-staff-birth">Date of birth</label>
                    <DatePicker
                      selected={Date.parse(userData.birth_date)}
                      onChange={this.dateChange}
                      dateFormat="LLL dd, yyyy"

                    />
                    <span className="wrror-masage">This field is unvalid.</span>
                  </div>

                  <div className="icon-box disabled-box">
                    <label>Category</label>
                    <div className="category-user-inner scrollbar-style">
                      {userData.user_categories.map((name, index) => (
                        <span className="edit-category-name">{name}</span>
                      ))}
                    </div>
                  </div>

                  <div className="icon-box disabled-box">
                    <label>ID number</label>
                    <input type="text" value={userData.id_number} disabled />
                  </div>

                  <div className="account__user-photo">
                    <img src={avatarImage} alt="" />
                    <input
                      type="file"
                      name="avatar"
                      id="avatar"
                      style={{ display: `none` }}
                      onChange={this.getFile}
                    />
                    <label
                      className="upload-user-photo"
                      style={{
                        background: `${
                          avatarFile ? avatarButtonColor : "#576B8C"
                        }`,
                      }}
                      htmlFor="avatar"
                    ></label>
                  </div>

                  <div
                    className={
                      "icon-box" +
                      ErrorRepository.getErrorClassName(errors, "birth_place")
                    }
                  >
                    <TextInput
                      inputName={"birth_place"}
                      inputLabel={"Place of Birth"}
                      inputValue={userData.birth_place}
                      errors={errors}
                      changeInput={this.handleChange}
                    />
                  </div>

                  <div className="icon-box disabled-box">
                    <label>Skill</label>
                    <div className="category-user-inner ">
                      {userData.skill.map((item, i) => {
                        return (
                          <span key={i} className="edit-category-name">
                            {item}
                          </span>
                        );
                      })}
                    </div>
                  </div>

                  <div className="icon-box disabled-box">
                    <label>Email address</label>
                    <input type="text" value={userData.email} disabled />
                  </div>
                </div>
                <div
                  className="account__notif-switch pointer"
                  onClick={(e) => {
                    e.preventDefault;
                    this.updateUser();
                  }}
                >
                  <div className="account__switch-title">Save</div>
                </div>
                <div
                  className={`account__notif-switch ${
                    userData.notification ? "is-active" : ""
                  }`}
                >
                  <div className="account__switch-title">notifications</div>
                  <div className="account__switch-wrapper">
                    <input
                      className="notif-switch"
                      id="notif-switch"
                      type="checkbox"
                      checked={userData.notification}
                      onChange={(e) =>
                        this.changeNotification(e.target.checked)
                      }
                    />
                    <label
                      className="notif-switch__label"
                      htmlFor="notif-switch"
                    >
                      <span className="notif-switch__disabled">disable</span>
                      <span className="notif-switch__enabled">enabled</span>
                    </label>
                  </div>
                </div>
              </div>

              <div className="account__item account__item-password">
                <div className="account__item-inner">
                  <div className="account__title">My password</div>
                  <div className="account__password-wrap">
                    <div className="password__wrappew">
                      <TextInput
                        inputType={isOpen.oldPassword ? "text" : "password"}
                        inputName={"old_pass"}
                        inputLabel={"My old password"}
                        changeInput={(e) => {
                          this.changePass(e.target.value, e.target.name);
                        }}
                      />
                      <span
                         className={`password-icon ${isOpen.oldPassword ? 'active' : ''}`}
                        onClick={() => this.toogleType("oldPassword")}
                      ></span>
                    </div>
                    <div className="password__wrappew">
                      <TextInput
                        inputType={isOpen.newPassword ? "text" : "password"}
                        inputName={"new_pass"}
                        inputLabel={"My new password"}
                        changeInput={(e) => {
                          this.changePass(e.target.value, e.target.name);
                        }}
                      />
                      <span
                        className={`password-icon ${isOpen.newPassword ? 'active' : ''}`}
                        onClick={() => this.toogleType("newPassword")}
                      ></span>
                    </div>
                    <button
                      className="save-btn btn-param pointer"
                      onClick={(e) => {
                        e.preventDefault;
                        this.updatePassword();
                      }}
                    >
                      Save
                    </button>
                  </div>
                </div>
                {roles.includes(ROLE_ADMIN) ||
                roles.includes(ROLE_INSTRUCTOR) ? (
                  <div className="account__item-inner">
                    <div className="account__title trainee-pass-title">
                      Trainee password
                    </div>
                    <div className="account__password-wrap trainee-account__wrap">
                      <div className="icon-box select-box">
                        <label>Select a trainee</label>
                        <Select
                          options={traineesOptions}
                          onChange={(e) => {
                            this.setTraineeId(e.value);
                          }}
                        />
                      </div>
                      <button
                        className="save-btn btn-param"
                        onClick={(e) => {
                          e.preventDefault;
                          this.resetPassword();
                        }}
                      >
                        Reset password
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    authUser: state.authReducer.user,
    showLoader: state.loaderReducer.showLoader,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loaderShow: () => dispatch(show()),
    loaderHide: () => dispatch(hide()),
    updateUser: (user) => dispatch(updateUser(user)),
    success: (message) => dispatch(success(message)),
    warning: (message) => dispatch(warning(message)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Account);
