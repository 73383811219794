export const CHANGE = "CHANGE";
export const REMOVE = "REMOVE";

export function change(notifications = []) {
  return {
    type: CHANGE,
    notifications,
  };
}

export function remove(notification = {}) {
  return {
    type: REMOVE,
    notification,
  };
}
