
import React, { useEffect } from 'react';
import { useTable } from "./table-context";
import { warning } from "../../../redux/actions/notify";
import { useDispatch } from "react-redux";

const TableMoreTooltip = ({ setShowMoreTooltip, actions = [] }) => {
  const dispatch = useDispatch();
  const { state } = useTable();
  useEffect(() => {
    const onBodyClick = () => {
      setTimeout(()=>{
        setShowMoreTooltip(false);
        document.body.removeEventListener("click", onBodyClick);
      }, 100);
    };

    document.body.addEventListener("click", onBodyClick);
  }, []);

  const onItemClick = (handler) => {
    const { selectedIds } = state;

    if (selectedIds.size) {
      handler(Array.from(state.selectedIds));
    } else {
      dispatch(warning('Choose items'));
    }
  };

  return (
    <div className="table__tooltip">
      <ul>
      {
        actions.map(({title, handler}, index) => (
          <li key={index} onClick ={() => onItemClick(handler)}>{title}</li>
        ))
      }
      </ul>
    </div>
  );
};

export default TableMoreTooltip;
