import React from "react";

function ValidationRange({
  name = "",
  label = "",
  value = "",
  onChange = () => {},
  errors = {},
  disabled = false,
}) {
  return (
    <>
      <label htmlFor={name}>{label}</label>
      <div className="range__box">
        <span className="range-percent min-percent">0%</span>
        <div className="range__wrapper">
          <span>
            <output
              name="ageOutputName"
              id="ageOutputId"
              style={{
                left: `${value}%`,
              }}
            >
              {value}
            </output>
          </span>
          <input
            type="range"
            name={name}
            id={name}
            min="0"
            max="100"
            value={value}
            onChange={(e) => {
              const {value} = e.target;
              onChange(Number(value));
            }}
            disabled={disabled}
          />

          <div
            className="range-progress"
            style={{
              width: `${value * 0.98}%`,
            }}
          ></div>
        </div>
        <span className="range-percent max-percent">100%</span>
      </div>
      {errors[name] && errors[name] != "" ? (
        <span className="wrror-masage">{errors[name]}</span>
      ) : null}
    </>
  );
}

export default ValidationRange;
