import React from 'react';
import PropTypes from 'prop-types';
import { useSyllabus } from '../../common/syllabus-context';
import date from '@/helpers/date';

function AssessmentSheet({ trainingResult, taskCount }) {
  const syllabus = useSyllabus();
  const { assessment_criteria: assessmentCriteria = [] } = syllabus;
  const { MT = {} } = taskCount;
  const isFilled = trainingResult.id !== 0;

  return (
    <div>
      <div className="assessment-sheet__title">{trainingResult.attempt < 2 ? 'CONTINUOUS ASSESSMENT SHEET' : 'REMEDIAL ASSESSMENT SHEET'}</div>
      <table className="assessment-table">
        <thead className="assessment-table__thead">
          <tr>
            <th rowSpan="2">Competence Criteria</th>
            <th colSpan="2">
              Performance result <sup>(1)</sup>
            </th>
          </tr>
          <tr>
            <th>Unsatisfactory</th>
            <th>Satisfactory</th>
          </tr>
        </thead>
        <tbody className="assessment-table__tbody">
          {assessmentCriteria.map((item) => {
            const isSatisfied = trainingResult.assessments.includes(item.number);

            return (
              <tr key={item.number}>
                <td>{item.criteria}</td>
                <td>
                  <label className="table-radiobutton">
                    <input type="radio" name={`criteria-${trainingResult.id}-${item.number}`}
                      readOnly
                      disabled
                      checked={isFilled && !isSatisfied}
                    />
                    <span className="table-custom-checkbox"></span>
                  </label>
                </td>
                <td>
                  <label className="table-radiobutton">
                    <input type="radio" name={`criteria-${trainingResult.id}-${item.number}`}
                      disabled
                      checked={isFilled && isSatisfied}
                    />
                    <span className="table-custom-checkbox"></span>
                  </label>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="assessment-result__text">
        * Only failed competencies need to be re-assessed
      </div>

      <div className="assessment-result">
        <div className="assessment-result__head">
          <div className="assessment-result__title">
            RESULT OF THE ASSESSMENT <sup>(2)</sup> :
          </div>
          <div className="assessment-result__checkbox">
            <span>Successful</span>
            <label className="table-checkbox">
              <input type="radio" name={`result-${trainingResult.id}`} checked={isFilled && trainingResult.is_completed} disabled/>
              <span className="table-custom-checkbox"></span>
            </label>
          </div>
          <div className="assessment-result__checkbox">
            <span>Remedial</span>
            <label className="table-checkbox">
              <input type="radio" name={`result-${trainingResult.id}`} checked={isFilled && !trainingResult.is_completed} disabled/>
              <span className="table-custom-checkbox"></span>
            </label>
          </div>
        </div>
        <div className="assessment-result__wrapper">
          <div className="assessment-result__item">
            <table className="assessment-result__table">
              <tbody>
                <tr>
                  <td>
                    Number of Main Tasks in this practical training element
                  </td>
                  <td>{MT.total}</td>
                </tr>
                <tr>
                  <td>
                    Number of Main Tasks performed by the Trainee ({MT.min_task_threshold} mini to
                    PASS)
                  </td>
                  <td>{trainingResult.validated_mt_count}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="assessment-result__item">
            <table className="assessment-result__signature">
              <tbody>
                <tr>
                  <td>TRAINEE SIGNATURE&nbsp;:&nbsp;</td>
                  <td></td>
                </tr>
                <tr>
                  <td>ASSESSOR SIGNATURE&nbsp;:&nbsp;</td>
                  <td>DATE&nbsp;:&nbsp;<span>{isFilled && date.getFormatted(trainingResult.date)}</span></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="assessment-result__text">
        (1) Report U or S in the concerned box.
      </div>
      <div className="assessment-result__text">
        (2) The assessment is successful when all criteria are filled in and no
        criterion is indicated Unsatisfactory.
      </div>
    </div>
  );
}

AssessmentSheet.propTypes = {
  trainingResult: PropTypes.object.isRequired,
  taskCount: PropTypes.object.isRequired,
};

export default AssessmentSheet;
