import React from 'react';

function Tabs({ activeTab, tabs, setActiveTab, checkValidation }) {
  return (
    <div className="new-syllabus__tabs-title">
      {tabs.map((tab, index) => (
        <div
          key={index}
          className={
            tab.key === activeTab.key
              ? 'tabs-syllabus__title active'
              : 'tabs-syllabus__title'
          }
          onClick={() => {
            if (checkValidation()) {
              setActiveTab(tabs[index]);
            }
          }}
        >
          {tab.title}
        </div>
      ))}
    </div>
  );
}

export default Tabs;
