import React from 'react';
import { Page, Document, StyleSheet, Font, View, Image } from '@react-pdf/renderer';
import Syllabus from '@/components/common/syllabus-pdf/syllabus';
import Revisions from '@/components/common/syllabus-pdf/revisions';
import Introduction from '@/components/common/syllabus-pdf/introduction';
import Practical from '@/components/common/syllabus-pdf/practical';
import TaskSummaryTable from '@/components/common/syllabus-pdf/taskSummaryTable';
import Tasks from '@/components/common/syllabus-pdf/tasks';
import Assessment from '@/components/common/syllabus-pdf/assessment';
import TrainingResult from "@/components/common/training-pdf/trainingResult";

const styles = StyleSheet.create({
  page: {
    fontSize: 14,
    paddingLeft: 25,
    paddingRight: 25,
    paddingTop: 20,
    paddingBottom: 20,
    margin: 0,
  },
  centerImage: {
    alignItems: 'left',
    flexGrow: 1,
  },
  block: {
    width: 200,
    height: 200,
  },
  image: {
    width: '100%'
  },
});

Font.register({
  family: 'Open Sans',
  fonts: [
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 },
  ],
});

// eslint-disable-next-line react/prop-types
const Table = ({ data }) => {

  return (
    <Document>
      {/* eslint-disable-next-line react/prop-types */}
      {(data.syllabus.photo || data.syllabus.logo) && (
        <Page bookmark={`Syllabus: ${data.syllabus.title}`}  wrap={false} orientation="landscape" size="A4" style={styles.page}>
          {data.syllabus.logo && (
            <View style={[styles.centerImage, styles.block]}>
              {/* eslint-disable-next-line react/prop-types */}
              <Image style={styles.image} src={data.syllabus.logo}/>
            </View>
          )}
          {data.syllabus.photo && (
            <View style={styles.centerImage}>
              {/* eslint-disable-next-line react/prop-types */}
              <Image style={styles.image} src={data.syllabus.photo}/>
            </View>
          )}
        </Page>
      )}
      <Page bookmark="Main information" orientation="landscape" size="A4" style={styles.page}>
        {/* eslint-disable-next-line react/prop-types */}
        <Syllabus data={data.syllabus}/>
      </Page>
      {/* eslint-disable-next-line react/prop-types */}
      {data.syllabus.revisions.length > 0 && (
        <Page bookmark="Revisions" orientation="landscape" size="A4" style={styles.page}>
        {/* eslint-disable-next-line react/prop-types */}
        <Revisions data={data.syllabus.revisions}/>
      </Page>)}
      <Page bookmark="Introduction" orientation="landscape" size="A4" style={styles.page}>
        {/* eslint-disable-next-line react/prop-types */}
        <Introduction data={data.syllabus}/>
      </Page>
      <Page bookmark="Practical" orientation="landscape" size="A4" style={styles.page}>
        {/* eslint-disable-next-line react/prop-types */}
        <Practical data={data.syllabus}/>
      </Page>
      {
        // eslint-disable-next-line react/prop-types
        data.taskSummaryTable.length > 0 && (
          <Page bookmark="Task Summary Table" orientation="landscape" size="A4" style={styles.page}>
            {/* eslint-disable-next-line react/prop-types */}
            <TaskSummaryTable data={data.taskSummaryTable}/>
          </Page>
        )
      }
      <Page bookmark="Tasks" orientation="landscape" size="A4" style={styles.page}>
        {/* eslint-disable-next-line react/prop-types */}
        <Tasks data={data.syllabus.tasks}/>
      </Page>
      <Page bookmark="Assessment" orientation="landscape" size="A4" style={styles.page}>
        {/* eslint-disable-next-line react/prop-types */}
        <Assessment data={data.syllabus}/>
      </Page>
      <Page bookmark="Training Result" orientation="landscape" size="A4" style={styles.page}>
        {/* eslint-disable-next-line react/prop-types */}
        <TrainingResult data={data.trainingResults ? data.trainingResults : []} assessment={data.syllabus.assessment_criteria} extendedTaskStats={data.extendedTaskStats}/>
      </Page>
    </Document>
  );
};

export default Table;
