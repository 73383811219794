import React from 'react';
import { NavLink } from 'react-router-dom';
import TextInput from '../../common/textInput.jsx';
import DatePicker from 'react-datepicker';
import EntityRepository from '../../../repositories/EntityRepository.js';
import ErrorRepository from '../../../repositories/ErrorRepository.js';
import { SKILL_B1, SKILL_B2 } from '../../common/constants';
import { connect } from 'react-redux';
import { success, danger } from '../../../redux/actions/notify';
import SearchCategories from '../common/auto-search';
import {
  ROLE_ADMIN,
  ROLE_INSTRUCTOR,
  ROLE_TRAINEE
} from '../../../helpers/role';
import AgreeChanges from '../../modals/modal-agree-changes';
import date from '@/helpers/date';

class StaffCreate extends React.Component {
  constructor(props) {
    super();
    this.state = {
      id: props.match.params.id,
      formData: {
        first_name: '',
        last_name: '',
        birth_place: '',
        birth_date: null,
        id_number: '',
        email: '',
        role: null,
        category: null,
        company: null,
        notification: false,
        skill: [],
        user_categories: [],
      },
      roles: [],
      user_categories: [],
      categoryAndSkills: false,
      errors: {},
      agreeModal: false,
      changes: false,
    };
  }

  createUser = async (event) => {
    event.preventDefault();
    const { formData } = this.state;
    try {
      const response = await EntityRepository.createEntity('/api/staff/create', formData);
      if (response.create === true) {
        this.props.success('User successfully created');
        this.props.history.push({
          pathname: '/settings/users/staff',
          reference: response.user,
        });
      } else if (response.errors) {
        this.setState((state) => ({ ...state, errors: response.errors }));
      }
    } catch (error) {
      this.props.danger(error.message);
    }

  };

  async componentDidMount() {
    this.getRoles();
  }

  getRoles = async () => {
    const { formData } = this.state;
    const roles = await EntityRepository.requestRoles();
    const options = [];
    for (const [key, value] of Object.entries(roles)) {
      if (key !== ROLE_TRAINEE) {
        options.push(<option value={key} key={key}>{value}</option>);
      }
    }
    this.setState({
      formData: {
        ...formData,
        role: Object.keys(roles)[0],
      },
      roles: options,
    });
  };

  changeRole = (role) => {
    this.setState({ changes: true });

    this.setState({
      categoryAndSkills: role === ROLE_INSTRUCTOR,
      formData: {
        ...this.state.formData,
        role,
      },
    });
  }

  clearErrorField = (fieldName) => {
    const { errors } = this.state;
    if (errors[fieldName] && errors[fieldName] != "") {
      this.setState({ errors: { ...errors, [fieldName]: "" } });
    }
  };

  setFormField = (name, value) => {
    this.clearErrorField(name);
    this.setState({
      changes: true,
      formData: { ...this.state.formData, [name]: value },
    });
  }

  handleChange = ({ target: { name, value } }) => this.setFormField(name, value);

  skillChange = ({ target: { checked, value } }) => {
    const { skill } = this.state.formData;
    const newArray = checked
      ? skill.includes(value)
        ? skill
        : [...skill, value]
      : skill.filter((item) => item !== value);

    this.setFormField('skill', newArray);
  };

  dateChange = (date) => this.setFormField('birth_date', date);

  changeIdNumber = ({ target: { name, value } }) => this.setFormField(name, value.toUpperCase());

  addCategory = (newData) => {
    let suggestion = this.state.formData.user_categories;

    if (suggestion.filter(e => e === newData.name).length > 0) {
      return;
    }
    suggestion.push(newData.name);

    let categories = this.state.user_categories;
    let tempArr = {
      name: newData.name,
    };
    categories.push(tempArr);

    this.setState((state) => (
      {
        ...state,
        formData: { ...this.state.formData, ['user_categories']: suggestion },
        user_categories: categories,
      }
    ));
  };

  deleteCategory = (e) => {
    let formcategories = [...this.state.formData.user_categories];
    let categories = [...this.state.user_categories];
    let deletedId = e.target.parentElement.getAttribute('data-name');

    formcategories.map((data, index) => {
      if (data == deletedId) {
        formcategories.splice(formcategories.indexOf(data.name), 1);
        categories.splice(categories.indexOf(data.name), 1);
      }
    });

    this.setState((state) => (
      {
        ...state,
        formData: { ...this.state.formData, user_categories: formcategories },
        user_categories: categories,
      }
    ));
  };

  createCategory = (value) => {
    this.setState({ changes: true });

    this.addCategory({ name: value });
  }

  goBack = (event) => {
    event.preventDefault();

    if (this.state.changes) {
      return this.setState({ agreeModal: true });
    }

    return this.props.history.push("/settings/users/staff");
  };

  confirmAgree = () => {
    this.setState({ agreeModal: false, changes: false });
    return this.props.history.push("/settings/users/staff");
  };

  cancelAgree = () => {
    return this.setState({ agreeModal: false });
  };

  render() {
    const { errors, categoryAndSkills, formData, agreeModal } = this.state;

    return (
      <section className="create-new-user users-edit-wrapper">
        <div className="container">
          <ul className="users-edit__breadcrumb">
            <li>
              <NavLink exact to={'/settings/users'}>
                User
              </NavLink>
            </li>
            <li>
              <NavLink exact to={'/settings/users/staff'}>
                Staff
              </NavLink>
            </li>

            <li className="active">
              <span>New Staff</span>
            </li>
          </ul>
          <div className="create-new-user__wrapper">
            <div className="create-new-user__top-box">
              <div className="create-new-staff__box">
                <div className="create-new-user__badge">
                  <span>new</span>
                </div>
                <div className="create-new-user__user-status">
                  <select
                    id="new-staff-status"
                    name="role"
                    onChange={({ target: { value } }) => this.changeRole(value)}
                  >
                    {this.state.roles}
                  </select>
                </div>
              </div>
            </div>
            <div className="create-new-user__board">
              <form className="create-new-user__form">
                <div className="create-new-user__board-wrapper">
                  <div
                    className={
                      'icon-box' +
                      ErrorRepository.getErrorClassName(errors, 'first_name')
                    }
                  >
                    <TextInput
                      inputName={'first_name'}
                      inputLabel={'First Name'}
                      errors={errors}
                      changeInput={this.handleChange}
                    />
                  </div>
                  <div
                    className={
                      'icon-box' +
                      ErrorRepository.getErrorClassName(errors, 'last_name')
                    }
                  >
                    <TextInput
                      inputName={'last_name'}
                      inputLabel={'Last Name'}
                      errors={errors}
                      changeInput={this.handleChange}
                    />
                  </div>
                  <div
                    className={
                      'icon-box create-user-birth' +
                      ErrorRepository.getErrorClassName(errors, 'birth_date')
                    }
                  >
                    <label htmlFor="create-staff-birth">Date of birth</label>
                    <DatePicker
                      selected={formData.birth_date}
                      onChange={this.dateChange}
                      dateFormat={date.inputDateFormat}
                      showYearDropdown
                      yearDropdownItemNumber={15}
                      maxDate={new Date()}
                    />
                    <span className="wrror-masage">This field is unvalid.</span>
                  </div>
                  <div
                    className={
                      'icon-box' +
                      ErrorRepository.getErrorClassName(errors, 'birth_place')
                    }
                  >
                    <TextInput
                      inputName={'birth_place'}
                      inputLabel={'Place of Birth'}
                      errors={errors}
                      changeInput={this.handleChange}
                    />
                  </div>
                  {categoryAndSkills ? (
                    <>
                      <div className="category-user-search">
                        <SearchCategories
                          onSelect={this.addCategory}
                          createCategory={this.createCategory}
                        />
                        <div className="search-result__wrapper">
                          {formData.user_categories.map((name, index) => (
                            <span className="category-name">
                              {name}
                              <button
                                className="delete-category-name"
                                data-name={name}
                                onClick={this.deleteCategory}
                              >
                                <i className="fa fa-close"></i>
                              </button>
                            </span>
                          ))}
                        </div>
                      </div>
                      <div
                        className={
                          'icon-box staff-skill__box' +
                          ErrorRepository.getErrorClassName(errors, 'skill')
                        }
                      >
                        <label>Skill</label>
                        <div className="staff-skill__wrapper">
                          <div className="staff-skill__inner">
                            <label className="category-checkbox">
                              <input
                                type="checkbox"
                                name={'skill'}
                                value={SKILL_B1}
                                errors={errors}
                                onClick={this.skillChange}
                              />
                              <span className="category-custom-checkbox"></span>
                            </label>
                            <span className="category-name">B1</span>
                          </div>
                          <div className="staff-skill__inner">
                            <label className="category-checkbox">
                              <input
                                type="checkbox"
                                name={'skill'}
                                value={SKILL_B2}
                                onClick={this.skillChange}
                              />
                              <span className="category-custom-checkbox"></span>
                            </label>
                            <span className="category-name">B2</span>
                          </div>
                        </div>
                        <span className="wrror-masage">{errors.skill}</span>
                      </div>
                    </>
                  ) : null}

                  <div
                    className={
                      'icon-box' +
                      ErrorRepository.getErrorClassName(errors, 'id_number')
                    }
                  >
                    <TextInput
                      inputName={'id_number'}
                      inputLabel={'ID number'}
                      inputValue={formData.id_number}
                      changeInput={this.changeIdNumber}
                      errors={errors}
                    />
                  </div>
                  <div
                    className={
                      'icon-box' +
                      ErrorRepository.getErrorClassName(errors, 'email')
                    }
                  >
                    <TextInput
                      inputName={'email'}
                      errors={errors}
                      inputLabel={'Email address'}
                      changeInput={this.handleChange}
                    />
                  </div>
                </div>
                <div className="create-new-user__buttons">
                  <button
                    className="cancel-btn btn-param"
                    onClick={this.goBack}
                  >
                    Cancel
                  </button>
                  <button
                    className="create-btn btn-param"
                    onClick={this.createUser}
                  >
                    Create
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {agreeModal && (
          <AgreeChanges
            cancelAgree={this.cancelAgree}
            confirmAgree={this.confirmAgree}
          />
        )}
      </section>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    success: (message) => dispatch(success(message)),
    danger: (message) => dispatch(danger(message)),
  };
}

export default connect(null, mapDispatchToProps)(StaffCreate);
