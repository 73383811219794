import React, { useRef, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Request from '../../../helpers/request';
import { success, warning, danger } from '../../../redux/actions/notify';
import { useDispatch } from 'react-redux';

function ImportAssessments({ setAssessments = (assessments) => {} }) {
  const inputEl = useRef();
  const dispatch = useDispatch();
  const [inProgress, setInProgress] = useState(false);

  const onDrop = useCallback((file) => {
    const sendData = async () => {
      const formData = new FormData();
      formData.append('file', file);
      setInProgress(true);
      try {
        const { entities, errors } = await Request.post('/api/import/syllabus-assessments', formData);
        if (entities && entities.length) {
          if (errors && errors.length) {
            dispatch(warning(errors[0]));
          } else {
            dispatch(success('File successfully imported'));
          }
          setAssessments(entities);
        }
      } catch (error) {
        const { errors } = error;
        if (errors && errors.length) {
          dispatch(danger(errors[0]));
        } else {
          dispatch(danger('Nothing imported'));
        }
      } finally {
        setInProgress(false);
        inputEl.current.value = '';
      }
    };

    sendData();
  }, []);

  return (
    <>
      <input
        ref={inputEl}
        accept=".csv"
        type="file"
        autoComplete="off"
        tabIndex="-1"
        maxsize="1048576"
        style={{ display: 'none' }}
        onChange={({ target: { files } }) => {
          if (!files.length) return;
          onDrop(files[0]);
        }}
      />
      <button
        className="download-assessment__btn"
        onClick={() => {
          inputEl.current.click();
        }}
        disabled={inProgress}
      >{
          inProgress
            ? 'Processing...'
            : 'Import assessment list'
        }</button>
    </>
  );
}

ImportAssessments.propTypes = {
  setAssessments: PropTypes.func,
};

export default ImportAssessments;
