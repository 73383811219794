import React, {useEffect, useState} from 'react';
import AtaTable from './ata-table.jsx';
import TableSitebar from '../common/table/table-sidebar.jsx';

const Ata = () => {
  const [importResult, setImportResult] = useState({
    inProgress: false,
    processed: false,
    importedCount: 0,
    totalCount: 0,
  });

  const state = {
    thead: ['Code', 'Description', 'Version'],
    columns: ['code', 'description', 'version'],
    content: {
      infoTitle: 'Ata',
      createTitle: 'Add new ata',
      createButtonUrl: '/settings/atas/create',
      importUrl: '/api/import/atas',
    },
  };

  return (
    <section className="user-interface ata-interface">
      <div className="user-dashboard">
        <div className="container">
          <div className="user-dashboard__wrapper">
            <TableSitebar content={state.content} importResult={importResult} setImportResult={setImportResult}/>
            <div className="user-dashboard__main-panel">
              <div className="staff-data__tabs-wrapper">
                <div className="tabs__titles">
                  <div className="dashboard__title">ATA list</div>
                </div>
                <AtaTable path={'api/atas'} thead={state.thead} columns={state.columns} importResult={importResult}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Ata;
