import { CHANGE, REMOVE } from "../actions/notification";

const initialState = [];

export default function notificationReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE:
      return action.notifications;
    case REMOVE:
      return state.filter((notification) => notification.id !== action.notification.id);
  }

  return state;
}
