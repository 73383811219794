import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const ValidateTaskModal = ({ trainingId, cancelPopup }) => {
  useEffect(() => {
    setTimeout(() => {
      cancelPopup();
    }, 1500);
  });

  return (
    <div className="overlay" role="button" tabIndex="0" onKeyPress={cancelPopup} onClick={cancelPopup}>
      <div className="modal-act-element" id="validate-tasks-modal">
        <div className="modal-act-element__wrapper">
          <div className="modal-act-element__head">
            <div className="modal-act-element__title">
              <div className="modal-archiving-element__icon"></div>
              <div>
                <div className="modal-act-element__training-name">
                  Training session <span>#{trainingId}</span>
                </div>
                <div className="modal-act-element__subtitle">
                  Task validation
                </div>
              </div>
            </div>
          </div>
          <div className="modal-delete-element__warning">
            Tasks have been successfully validated!
          </div>
        </div>
      </div>
    </div>
  );
};

ValidateTaskModal.propTypes = {
  trainingId: PropTypes.number.isRequired,
  cancelPopup: PropTypes.func,
};

export default ValidateTaskModal;
