import React, { useEffect, useState, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Request from '../../../../../helpers/request';
import date from '../../../../../helpers/date';
import TableFilterGroup from '../../../../common/table/table-filter-group.jsx';
import ModalValidateTask from '../modals/modal-validate-task';
import { useTraining } from '../../common/training-context';

function TaskTable() {
  const { trainingId, traineeId } = useParams();
  const { id: userId } = useSelector(state => state.authReducer.user);
  const dispatch = useDispatch();
  const [tasks, setTasks] = useState([]);
  const [filtersString, setFiltersString] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [isRowLocked, setIsRowLocked] = useState([]);
  const history = useHistory();
  const training = useTraining();

  const filtersObject = useRef({});
  const selectedTask = useRef(null);
  const setFilter = (name, value) => {
    filtersObject.current[name] = value;
    setFiltersString(JSON.stringify(filtersObject.current));
  };
  useEffect(() => {
    const fetchData = async () => {
      const result = await Request.get(`/api/trainings/${trainingId}/trainees/${traineeId}/tasks?filters=${filtersString}`);
      setTasks(result);
    };

    fetchData();
  }, [traineeId, filtersString, isRowLocked]);

  const toggleLockRow = (id) => {
    if (!isRowLocked.includes(id)) {
      setIsRowLocked(data => [...data, id]);
    }else{
      setIsRowLocked(isRowLocked.filter(item => item !== id));
    }
  };

  const openModal = (task) => {
    selectedTask.current = task;
    setShowModal(true);
  };

  const confirmModal = () => {
    const trainee = training.trainees.find(({ id }) => id == traineeId);
    const task = selectedTask.current;
    selectedTask.current = null;
    setShowModal(false);
    if (!trainee || !task) return;
    const trainee_task = task.trainee_task || {};
    // if (userId !== trainee_task.instructor_id) {
    //   return dispatch(warning('You can\'t edit this task'));
    // }
    history.push({
      pathname: `/trainings/${trainingId}/validations/part-1`,
      state: { ids: [traineeId], traineeNames: [`${trainee.last_name[0]}. ${trainee.first_name}`] },
    });
  }

  return (
    <>
      <div className="trainee-details__activity-panel">
        <div className="data-search">
          <i className="search-icon fa fa-search"></i>
          <input className="data-search__field" type="text" placeholder="Search" onChange={({ target }) => setFilter('like', target.value)} />
        </div>
        <div className="sort-panel">
          <div className="data-sort__box">
            <span>Sort by</span>
            <select onChange={({ target }) => setFilter('sort', target.value)}>
              <option value="">ATA code</option>
              <option value="category">Task category</option>
              <option value="code">Task code</option>
            </select>
          </div>
          <TableFilterGroup
            classModifier="mr-16"
            onFilter={(value) => setFilter('category', value)}
            options={[
              {
                className: 'active-users-btn',
                title: 'mt',
                value: 'MT',
              },
              {
                className: 'archived-users-btn',
                title: 'at',
                value: 'AT',
              },
              {
                className: 'all-users-btn',
                title: 'all',
                value: '',
                isActive: true,
              },
            ]}/>
          <TableFilterGroup
            onFilter={(value) => setFilter('state', value)}
            options={[
              {
                className: 'completed-users-btn',
                title: 'validated',
                value: 'validated',
              },
              {
                className: 'unfinished-users-btn',
                title: 'remaining',
                value: 'remaining',
              },
              {
                className: 'all-users-btn',
                title: 'all',
                value: '',
                isActive: true,
              },
            ]}/>
        </div>
      </div>

      <div className="user-personal__table-inform">
        <div className="users-table__wrapper">
          <table className="users-table__data trainee-details__table">
            <thead className="users-table__thead">
              <tr>
                <th>ATA</th>
                <th>№</th>
                <th>Instructor</th>
                <th>Task category</th>
                <th>Skill</th>
                <th>Task code</th>
                <th>Subject</th>
                <th>Manual reference</th>
                <th>Classroom</th>
                <th>Aircraft</th>
                <th>State</th>
                <th></th>
              </tr>
            </thead>
            <tbody className="users-table__tbody">
              {
                tasks.map((task, index) => {
                  const {
                    id,
                    ata_code,
                    order_number,
                    category,
                    skills = [],
                    code,
                    subject,
                    manual_reference,
                  } = task;
                  const trainee_task = task.trainee_task || {};

                  return (
                    <tr
                      className={`category-${category.toLowerCase()} ${trainee_task.is_confirmed ? 'validate-task' : ''}`}
                      key={index}>
                      <td>{ata_code}</td>
                      <td>#{order_number}</td>
                      <td>{trainee_task.instructor_first_name} {trainee_task.instructor_last_name}</td>
                      <td><span className={`table-bage ${category.toLowerCase()}-bage`}>{category}</span></td>
                      <td>{
                        skills.map((skill, index) => (
                          <span key={index} className="bage">{skill}</span>
                        ))
                      }</td>
                      <td>{code}</td>
                      <td>{subject}</td>
                      <td>{manual_reference}</td>
                      <td className={trainee_task.is_classroom_passed ? "checked-data" : undefined}>
                        <span className="users-table__tbody-link" onClick={() => isRowLocked.includes(id) || trainee_task.is_validated ? openModal(task) : ''}>
                          <span className="table-ceck-icon"><i className="fa fa-check-square-o"></i></span>
                          {
                            trainee_task.classroom_date ? date.getNumberFormatted(trainee_task.classroom_date) : "00\xa0/\xa000\xa0/\xa000"
                          }
                        </span>
                      </td>
                      <td className={trainee_task.is_aircraft_passed ? "checked-data" : undefined}>
                        <span className="users-table__tbody-link" onClick={() => isRowLocked.includes(id) || trainee_task.is_validated ? openModal(task) : ''}>
                          <span className="table-ceck-icon"><i className="fa fa-check-square-o"></i></span>
                          {
                            trainee_task.aircraft_date ? date.getNumberFormatted(trainee_task.aircraft_date) : "00\xa0/\xa000\xa0/\xa000"
                          }
                        </span>
                      </td>
                      <td className={trainee_task.is_validated ? "checked-task" : undefined}>
                        <span className="users-table__tbody-link" onClick={() => isRowLocked.includes(id) || trainee_task.is_validated ? openModal(task) : ''}>
                          {
                            trainee_task.is_validated
                              ? (<span className="validated-table-icon"><i className="fa fa-check"></i></span>)
                              : (<span className="insufficient-level"><i className="fa fa-minus"></i></span>)
                          }
                        </span>
                      </td>
                      <td>
                        <span className="users-table__tbody-link" onClick={() => toggleLockRow(id)}>
                          {/*{*/}
                          {/*  trainee_task.is_validated*/}
                          {/*    ? (<span className="closed-table-icon"><i className="fa fa-lock"></i></span>)*/}
                          {/*    : (<span className="insufficient-level"><i className="fa fa-unlock"></i></span>)*/}
                          {/*}*/}
                          <span className="insufficient-level">
                            {!trainee_task.is_validated ? <i className={`fa fa-${!isRowLocked.includes(id) ? 'lock' : 'unlock'}`}></i> : ''}
                          </span>
                        </span>
                      </td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </div>
      </div>
      {showModal &&
      <ModalValidateTask
        onCancel={() => setShowModal(false)}
        comfirmModal={confirmModal}
        sessionNumber={training.session_number}
      />
      }
    </>
  );
}

export default TaskTable;
