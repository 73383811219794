export const ACTION_ARCHIVE = 'archive';
export const ACTION_RESTORE = 'restore';
export const GET_LIST_REQUEST_PARAMS = {
  start: 0,
  limit: 20,
};
export const SKILL_B1 = 'B1';
export const SKILL_B2 = 'B2';
export const FILTER_SKILL_OPTIONS = {
  '': 'Select skill',
  [SKILL_B1]: 'B1',
  [SKILL_B2]: 'B2',
};
