import { NOTIFY, HIDE } from "../actions/notify";

const initialState = {
  isVisible: false,
  message: null,
  status: 'success',
};

export default function notifyReducer(state = initialState, action) {

  switch (action.type) {
    case NOTIFY:
      return {
        isVisible: true,
        message: action.message,
        status: action.status,
      };

    case HIDE:
      return {
        isVisible: false,
      };
  }
  return state;
}
