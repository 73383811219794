import React, { useState, useEffect } from 'react';
import { Switch, Route, useRouteMatch, useParams } from 'react-router-dom';
import { SyllabusProvider } from './common/syllabus-context';
import EntityRepository from '../../../../repositories/EntityRepository';
import SyllabusHeader from './common/syllabus-header';
import Introduction from './introduction/introduction';
import Part1 from './part-1';
import Part2 from './part-2/part-2';
import SummaryTable from './summary-table/summary-table';
import Description from './description/description';
import Revisions from './revisions/revisions';

function Pages() {
  const { path } = useRouteMatch();
  const { syllabusId } = useParams();

  const [syllabus, setSyllabus] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const result = await EntityRepository.getEntity('api/syllabi', syllabusId);
      setSyllabus({ ...result });
    };

    fetchData();
  }, []);

  return (
    <div className="validation-assessment__wrapper">
      <SyllabusProvider syllabus={syllabus}>
        <SyllabusHeader />
        <Switch>
          <Route path={`${path}/revisions`} component={Revisions} />
          <Route path={`${path}/introduction`} component={Introduction} />
          <Route path={`${path}/description`} component={Description} />
          <Route path={`${path}/summary-table`} component={SummaryTable} />
          <Route path={`${path}/part-1`} component={Part1} />
          <Route path={`${path}/part-2`} component={Part2} />
        </Switch>
      </SyllabusProvider>
    </div>
  );
}

export default Pages;
