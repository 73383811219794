import React, { useState } from "react";
import SearchTrainees from "../inputs/search-trainees.jsx";
import date from "../../../helpers/date";

function ModalTraineeEdit({ 
    trainees: initTrainees = {}, 
    onConfirm = () => {}, 
    onCancel = () => {}, 
  }) {
    const isCreateMode = Object.keys(initTrainees).length == 0;
    const [trainees, setTrainees] = useState({...initTrainees});
    const addTrainee = (newTrainee) => {
      const id = Number(newTrainee.id);
      if (trainees.hasOwnProperty(id)) {
        return;
      }
  
      setTrainees({
        ...trainees, 
        [id]: {
          id,
          lastNameShort: newTrainee.last_name.substr(0, 1) + ".",
          firstName: newTrainee.first_name,
          idNumber: newTrainee.id_number,
          company: newTrainee.company,
        }
      });
    };
  
    const deleteTrainee = (id) => {
      delete trainees[id];
      setTrainees({...trainees});
    };

    return (
      <div className="overlay">
        <div
          className="modal-act-element__create-task scrollbar-style"
          id="add-trainees-modal"
        >
          <div className="modal-act-element__wrapper">
            <div className="modal-act-element__head">
              <div className="modal-act-element__title">
                {isCreateMode ? 'Add trainees': 'Edit list of trainee'}
                <span className="trainees-modal__subtitle">
                  Select one or more trainees
                </span>
              </div>
              <div className="modal-act-element__sesion-date">
                <span>{date.getFormatted()}</span>
              </div>
            </div>
            <div className="trainees-modal__wrapper">
              <div className="modal-list__title">Trainee list</div>
              <div className="users-edit-history-session__content">
                <div className="users-edit-history-session__active-head trainee__search">
                  <div className="icon-box data-search">
                    <SearchTrainees onSelect={addTrainee} />
                  </div>
                </div>
                <div className="users-edit-history-session-table__wrapper">
                  <div className="trainees-modal__table-title">
                    Selected trainees
                    <span className="trainees-modal__table-bage">
                      {Object.keys(trainees).length}
                    </span>
                  </div>
                  <div className="users-edit-table__inner">
                    <table
                      className="users-table__data"
                      id="trainee-list-modal"
                    >
                      <tbody className="users-table__tbody">
                        {Object.keys(trainees).map((id, index) => (
                          <tr key={index}>
                            <td>{trainees[id].lastNameShort}</td>
                            <td>{trainees[id].firstName}</td>
                            <td>{trainees[id].idNumber}</td>
                            <td>{trainees[id].company}</td>
                            <td>
                              <span
                                className="close"
                                onClick={() => {deleteTrainee(id)}}
                              >
                                <i className="fa fa-close"></i>
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div className="create-new-user__buttons">
              <button
                onClick={onCancel}
                className="cancel-btn btn-param"
              >
                Cancel
              </button>
              <button
                onClick={() => onConfirm(trainees)}
                className={
                   `${isCreateMode ? 'create-btn' : 'save-btn'} btn-param`
                }
              >
                {isCreateMode ? 'Create' : 'Save'}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

export default ModalTraineeEdit;
