import React from 'react';
import PropTypes from 'prop-types';
import Autosuggest from '@/components/common/autosuggest';
import ErrorRepository from '@/repositories/ErrorRepository.js';

function SearchManualRef({ value, setValue, errors }) {

  return (
    <div
      className={`icon-box ${ErrorRepository.getErrorClassName(
        errors,
        'manual_reference'
      )}`}
    >
      <Autosuggest
        url="/api/tasks"
        getFilters={(manualReference) => ({
          manualReference,
        })}
        inputProps={{
          value,
          placeholder: 'Enter text...',
          name: 'manual_reference',
          onChange: ({ target }) => setValue(target.value),
        }}
        onSuggestionSelected={(event, { suggestion }) => {
          setValue(suggestion.manual_reference);
        }}
        theme={{
          container: 'input-box',
        }}
        getSuggestionValue={(suggestion) => suggestion.manual_reference}
        renderSuggestion={(suggestion) => (
          <span key={suggestion.id}>
            {suggestion.manual_reference}
          </span>
        )}
      >
        { inputProps => (
          <>
            <label htmlFor="manual_reference">Manual reference</label>
            <input
              {...inputProps}
            />
            { (errors.manual_reference && errors.manual_reference != '') ? (
              <span className="wrror-masage">{errors.manual_reference}</span>
            ) : null}
          </>
        )}
      </Autosuggest>
    </div>
  );
}

SearchManualRef.propTypes = {
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  errors: PropTypes.object,
};

SearchManualRef.defaultProps = {
  errors: {},
};

export default SearchManualRef;
