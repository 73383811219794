import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ROLE_INSTRUCTOR } from '@/helpers/role';
import EntityRepository from '@/repositories/EntityRepository';

function SelectInstructor({ onSelect, selectedIds, skill, isLocked, courseReferenceNumber }) {
  const [instructors, setInstructors] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await EntityRepository.getEntities('/api/users', {
        role: ROLE_INSTRUCTOR,
        skill,
        category: categoryFilter,
        archive: 'active',
        limit: 100,
      });
      if (response && Array.isArray(response.items)) setInstructors(response.items);
    };

    fetchData();
  }, [skill, categoryFilter, courseReferenceNumber]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await EntityRepository.getEntities('/api/categories');
      if (response && Array.isArray(response)) setCategories(response);
    };

    fetchData();
  }, []);

  return (
    <div className="training-inform__box">
      <div className={`icon-box select-box ${isLocked && 'disabled-box'}`}>
        <label htmlFor="instructor">Assign instructor(s)</label>
        <select
          id="instructor"
          name="instructor"
          onChange={({ target: { value } }) => {
            if (!value) return;

            const selectedId = Number(value);
            const instructor = instructors.find(({ id }) => id === selectedId);
            onSelect(instructor);
          }}
          disabled={isLocked}
        >
          <option value="">Select Instructor</option>
          {
            instructors
              .filter(({ id }) => !selectedIds.includes(id))
              .map(({ id, first_name, last_name }) => (
                <option key={id} value={id}>{first_name}&nbsp;{last_name}</option>
              ))
          }
        </select>
      </div>
      <div className={`icon-box select-box ${isLocked && 'disabled-box'}`}>
        <label htmlFor="filter-category">Category</label>
        <select
          id="filter-category"
          name="filter-category"
          value={categoryFilter}
          onChange={({ target }) => setCategoryFilter(target.value)}
        >
          <option value="">Select category</option>
          {
            categories.map((category, index) => (
              <option value={category.name} key={index}>{category.name}</option>
            ))
          }
        </select>
      </div>
    </div>
  );
}

SelectInstructor.propTypes = {
  skill: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
  selectedIds: PropTypes.array,
  isLocked: PropTypes.bool,
  courseReferenceNumber: PropTypes.string,
};

SelectInstructor.defaultProps = {
  skill: [],
  selectedIds: [],
  isLocked: false,
  courseReferenceNumber: '',
};

export default SelectInstructor;
