import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import date from '@/helpers/date';

const styles = StyleSheet.create({
  title: {
    width: '20%',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
  },
  header: {
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    fontSize: 11,
  },
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginTop: 10,
    padding: 4,
    alignContent: 'center',
    justifyContent: 'center',
  },
  td: {
    fontSize: 11,
    width: '25%',
    textAlign: 'center',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    height: '100%',
    paddingTop: 5,
    paddingBottom: 5,
    paddingRight: 2,
    paddingLeft: 2,
  },
});

const TrainingResultRows = (prop) => {
  const { data, assessment, extendedTaskStats } = prop;

  const rows = data.map((result, index) => {
    return (
      <Fragment key={index.toString()}>
        <View style={{ flexDirection: 'row' }} wrap={false}>
          <Text style={{ fontFamily: 'Open Sans', fontWeight: 'bold', marginTop: 20  }}>{result.attempt < 2 ? 'CONTINUOUS' : 'REMEDIAL'} ASSESSMENT SHEET*</Text>
        </View>
        <View style={styles.tableContainer} wrap={false}>
          <View style={{ flexDirection: 'row', width: '100%' }}>
            <Text style={[styles.header, { width: '50%', paddingTop: 5, marginTop: 10 , marginLeft: 30 }]}>Competence Criteria</Text>
            <Text style={[styles.header, { width: '50%', textAlign: 'center' }]}>Performance result(1)</Text>
          </View>
          <View style={{ flexDirection: 'row', width: '100%' }} wrap={false}>
            <Text style={[styles.header, { width: '60%' }]}/>
            <Text style={[styles.header, { width: '20%', textAlign: 'center' }]}>Unsatisfactory</Text>
            <Text style={[styles.header, { width: '20%', textAlign: 'center' }]}>Satisfactory</Text>
          </View>
        </View>
        {assessment.map((item, index) => {
          return (
            <View style={{ flexDirection: 'row' }} key={index.toString()} wrap={false}>
              <Text style={[styles.td, { width: '60%' }]}>{item.criteria}</Text>
              <Text style={[styles.td, { width: '20%', fontSize: 16 }]}>{!result.assessments.includes(item.number) ? 'o' : 'x'}</Text>
              <Text style={[styles.td, { width: '20%', fontSize: 16 }]}>{result.assessments.includes(item.number) ? 'o' : 'x'}</Text>
            </View>
          );
        })}
        <View style={{ flexDirection: 'row' }}>
          <Text style={{ fontSize: 9 }}>* Only failed competencies need to be re-assessed</Text>
        </View>
        <View style={{ flexDirection: 'row', marginTop: 30 }}>
          <Text style={{ fontFamily: 'Open Sans', fontWeight: 'bold', width: '80%' }}>RESULT OF THE ASSESSMENT (2)</Text>
          <Text style={{ width: '20%' }}>{result.is_completed ? 'Successful' : 'Remedial'}</Text>
        </View>
        <View style={{ flexDirection: 'row', marginTop: 15 }}>
          <Text style={[styles.td, { width: '80%' }]}>Number of Main Tasks in this practical training element</Text>
          <Text style={[styles.td, { width: '20%' }]}>{extendedTaskStats.task_count}</Text>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <Text style={[styles.td, { width: '80%' }]}>{`Number of Main Tasks performed by the Trainee (${extendedTaskStats.min_task_threshold} mini to PASS)`}</Text>
          <Text style={[styles.td, { width: '20%' }]}>{result.validated_mt_count}</Text>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <Text style={{ fontSize: 9 }}>(1) Report U or S in the concerned box.</Text>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <Text style={{ fontSize: 9 }}>(2) The assessment is successful when all criteria are filled in and no criterion is indicated
            Unsatisfactory.</Text>
        </View>
        <View style={{ flexDirection: 'row', marginTop: 15 }}>
          <Text style={{ width: '100%', textAlign: 'left', fontFamily: 'Open Sans', fontSize: 12, fontWeight: 'bold' }}>DATE: {date.getFormatted(result.date)}</Text>
        </View>
        <View style={{ flexDirection: 'row', marginTop: 15 }}>
          <Text style={{ width: '60%', textAlign: 'left', fontFamily: 'Open Sans', fontSize: 12, fontWeight: 'bold' }}>Trainee: {result.trainee_name}</Text>
          <Text style={{ width: '40%', textAlign: 'left', fontFamily: 'Open Sans', fontSize: 12, fontWeight: 'bold' }}>Trainee signature:</Text>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <Text style={{ width: '60%', textAlign: 'left', fontFamily: 'Open Sans', fontSize: 12, fontWeight: 'bold' }}>Assessor: {result.assessor_name}</Text>
          <Text style={{ width: '40%', textAlign: 'left', fontFamily: 'Open Sans', fontSize: 12, fontWeight: 'bold' }}>Assessor signature:</Text>
        </View>
      </Fragment>
    );
  });

  return <Fragment>{rows}</Fragment>;
};

const TrainingResultRowsExample = (prop) => {
  const { assessment } = prop;

    return (
      <Fragment>
        <View style={{ flexDirection: 'row' }} wrap={false}>
          <Text style={{ fontFamily: 'Open Sans', fontWeight: 'bold', marginTop: 20  }}> ASSESSMENT SHEET*</Text>
        </View>
        <View style={styles.tableContainer} wrap={false}>
          <View style={{ flexDirection: 'row', width: '100%' }}>
            <Text style={[styles.header, { width: '50%', paddingTop: 5, marginTop: 10 , marginLeft: 30 }]}>Competence Criteria</Text>
            <Text style={[styles.header, { width: '50%', textAlign: 'center' }]}>Performance result(1)</Text>
          </View>
          <View style={{ flexDirection: 'row', width: '100%' }} wrap={false}>
            <Text style={[styles.header, { width: '60%' }]}/>
            <Text style={[styles.header, { width: '20%', textAlign: 'center' }]}>Unsatisfactory</Text>
            <Text style={[styles.header, { width: '20%', textAlign: 'center' }]}>Satisfactory</Text>
          </View>
        </View>
        {assessment.map((item, index) => {
          return (
            <View style={{ flexDirection: 'row' }} key={index.toString()} wrap={false}>
              <Text style={[styles.td, { width: '60%' }]}>{item.criteria}</Text>
              <Text style={[styles.td, { width: '20%', fontSize: 16 }]}/>
              <Text style={[styles.td, { width: '20%', fontSize: 16 }]}/>
            </View>
          );
        })}
        <View style={{ flexDirection: 'row' }}>
          <Text style={{ fontSize: 9 }}>* Only failed competencies need to be re-assessed</Text>
        </View>
        <View style={{ flexDirection: 'row', marginTop: 30 }}>
          <Text style={{ fontFamily: 'Open Sans', fontWeight: 'bold', width: '80%' }}>RESULT OF THE ASSESSMENT (2)</Text>
          <Text style={{ width: '20%' }}/>
        </View>
        <View style={{ flexDirection: 'row', marginTop: 15 }}>
          <Text style={[styles.td, { width: '80%' }]}>Number of Main Tasks in this practical training element</Text>
          <Text style={[styles.td, { width: '20%' }]}/>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <Text style={[styles.td, { width: '80%' }]}>{`Number of Main Tasks performed by the Trainee (  mini to PASS)`}</Text>
          <Text style={[styles.td, { width: '20%' }]}/>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <Text style={{ fontSize: 9 }}>(1) Report U or S in the concerned box.</Text>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <Text style={{ fontSize: 9 }}>(2) The assessment is successful when all criteria are filled in and no criterion is indicated
            Unsatisfactory.</Text>
        </View>
        <View style={{ flexDirection: 'row', marginTop: 15 }}>
          <Text style={{ width: '100%', textAlign: 'left', fontFamily: 'Open Sans', fontSize: 12, fontWeight: 'bold' }}>DATE: </Text>
        </View>
        <View style={{ flexDirection: 'row', marginTop: 15 }}>
          <Text style={{ width: '60%', textAlign: 'left', fontFamily: 'Open Sans', fontSize: 12, fontWeight: 'bold' }}>Trainee: </Text>
          <Text style={{ width: '40%', textAlign: 'left', fontFamily: 'Open Sans', fontSize: 12, fontWeight: 'bold' }}>Trainee signature:</Text>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <Text style={{ width: '60%', textAlign: 'left', fontFamily: 'Open Sans', fontSize: 12, fontWeight: 'bold' }}>Assessor: </Text>
          <Text style={{ width: '40%', textAlign: 'left', fontFamily: 'Open Sans', fontSize: 12, fontWeight: 'bold' }}>Assessor signature:</Text>
        </View>
      </Fragment>
    );
};

const TrainingResult = (props) => {
  const { data } = props;

  if (data.length > 0) {
    if (data[0].assessments.length === 0) {
      return (<TrainingResultRowsExample {...props}/>);
    } else {
      return (<TrainingResultRows {...props}/>)
    }
  }

  return (<TrainingResultRowsExample {...props}/>)
};

export default TrainingResult;
