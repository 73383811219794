import React, { useEffect, useState } from 'react';
import {
  NavLink,
  useHistory
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { warning } from '../../../redux/actions/notify';
import authService from '../../../services/auth-service';
import date from '../../../helpers/date';
import IconCalendar from '../../common/icons/icon-calendar';
import Request from '../../../helpers/request';
import EntityRepository from '../../../repositories/EntityRepository';
import Progressbar from './progressbar';

function TrainingSession() {
  const history = useHistory();
  const user = useSelector(state => state.authReducer.user);
  const currentTraining = authService.getCurrentTraining();
  const dispatch = useDispatch();

  if (!currentTraining) {
    dispatch(warning('You have no active trainings'));
    return null;
  }

  const [isConfirmPresenceActive, setIsConfirmPresenceActive] = useState(false);
  const [taskCount, setTaskCount] = useState({
    AT: {
      validated: 0,
      remaining: 0,
      total: 0,
    },
    MT: {
      validated: 0,
      remaining: 0,
      total: 0,
    },
  });
  const validatedMtPercent = taskCount.MT.validated_percent || 0;
  const [syllabus, setSyllabus] = useState({});
  const { introduction = '' } = syllabus;
  const introductionParts = introduction.split('\n').filter((s) => s != '');

  useEffect(() => {
    const fetchData = async () => {
      const [resultSyllabus, resultTaskCount] = await Promise.all([
        EntityRepository.getEntity('api/syllabi', currentTraining.syllabus_uid),
        Request.get(`/api/trainings/${currentTraining.id}/trainees/${user.id}/task-count`),
      ]);

      setTaskCount({ ...resultTaskCount });
      setSyllabus({ ...resultSyllabus });

      const validatedTasks = await Request.get(`/api/trainings/${currentTraining.id}/trainee-validated-tasks`);
      setIsConfirmPresenceActive(validatedTasks.items && validatedTasks.items.length);
    };

    if (currentTraining.syllabus_id && user.id) fetchData();
  }, [currentTraining.syllabus_id, user.id]);

  return (
    <>
      <div className="training-settings__wrapper">
        <div className="user-interface__subheader">
          <div className="container">
            <div className="user-interface__subheader-wrapper">
              <div className="user-interface__subheader-title">
                <span className="trainee-page__subheader-title">
                  training session
                </span>
                <span className="trainee-page training-session__number">
                  #{currentTraining.session_number}
                </span>
                <span className="training-session__status-bage current-bage">
                  current
                </span>
              </div>

              <div className="session-period">
                <span className="calendar-icon">
                  <IconCalendar />
                </span>
                <span className="date-from">{date.getShortFormatted(currentTraining.date_start)}</span>
                <span className="date-to">{date.getShortFormatted(currentTraining.date_end)}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="trainee-home">
        <div className="container">
          <div className="trainee-home__wrapper">
            <div className="tarinee-home__content">
              <div className="trainee-home__content-head">
                <div className="trainee-home__logo">
                  <img src="/adminApp/src/assets/img/white-logo.svg" alt="" />
                </div>
                <div className="trainee-home__session-title">
                  <span>Syllabus</span>
                  <span className="trainee-home__sullabus-name">
                    {syllabus.title}
                  </span>
                </div>
              </div>

              <div className="tarinee-home__content-item">
                <div className="tarinee-home__item-title">
                  <span>Practical training element</span>
                  <span className="qualification-type">
                    {syllabus.type}
                  </span>
                </div>
                <ul className="tarinee-home__item-links">
                  <li>
                    <NavLink
                      to={`/trainings/${currentTraining.id}/trainees/${user.id}/syllabus/pages/introduction`}
                      className="tarinee-home__item-link">
                      Introduction
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={`/trainings/${currentTraining.id}/trainees/${user.id}/syllabus/pages/part-1`}
                      className="tarinee-home__item-link">
                      Tasks
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={`/trainings/${currentTraining.id}/trainees/${user.id}/syllabus/pages/part-2`}
                      className="tarinee-home__item-link">
                      Assessment
                    </NavLink>
                  </li>
                </ul>
              </div>
              <div className="tarinee-home__item-inner">
                <div className="tarinee-home__item-box scrollbar-style">
                  <div className="trainee-home__box-title">Introduction</div>
                  <div className="tarinee-home__item-text">
                    {
                      introductionParts.map((introductionPart, index) => (
                        <p key={index}>{introductionPart}</p>
                      ))
                    }
                  </div>
                </div>
                <div className="tarinee-home__item-img">
                  {
                    syllabus.photo
                      ? <img src={syllabus.photo} alt="" />
                      : null
                  }
                </div>
              </div>
            </div>

            <div className="tarinee-home__statistic">
              <div className="tarinee-home__statistic-head">
                Your stats <span>Overview</span>
              </div>
              <div className="tarinee-home__statistic-item">
                <div className="statistic-cards">
                  <div className="statistic-cards-head additional-head">
                    Additional Tasks
                  </div>
                  <div className="statistic-cards__box">
                    <div className="card-item statistic-cards__title">
                      Validated
                    </div>
                    <div className="card-item statistic-cards__title">
                      Remaining
                    </div>
                    <div className="card-item statistic-cards__number statistic-valid__number">
                      {taskCount.AT.validated}
                    </div>
                    <div className="card-item statistic-cards__number">
                      {taskCount.AT.remaining}
                    </div>
                  </div>
                </div>

                <div className="statistic-cards">
                  <div className="statistic-cards-head main-task-head">
                    Main Tasks
                  </div>
                  <div className="statistic-cards__box">
                    <div className="card-item statistic-cards__title">
                      Validated
                    </div>
                    <div className="card-item statistic-cards__title">
                      Remaining
                    </div>
                    <div className="card-item statistic-cards__number statistic-valid__number">
                      {taskCount.MT.validated}
                    </div>
                    <div className="card-item statistic-cards__number">
                      {taskCount.MT.remaining}
                    </div>
                  </div>
                </div>

                <div className="statistic-cards persent-card">
                  <span className="statistic-cards__bage">mt</span>

                  <Progressbar value={validatedMtPercent}/>
                </div>
                <div className="statistic-buttons">
                  <NavLink
                    to={`/trainings/${currentTraining.id}/trainees/${user.id}/syllabus/pages/part-1`}
                    className="detal__btn btn-param">
                    See detail
                  </NavLink>
                  <button
                    className="confirm__btn btn-param"
                    onClick={() => history.push(`/trainings/${currentTraining.id}/syllabus/task-confirmation`)}
                    disabled={!isConfirmPresenceActive}
                  >
                    Confirm presence
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default TrainingSession;
