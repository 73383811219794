import { combineReducers } from "redux";
import authReducer from "./reducers/auth-reducer.js";
import loaderReducer from "./reducers/loader-reducer.js";
import notifyReducer from "./reducers/notify-reducer";
import notificationReducer from "./reducers/notification-reducer";
import filterReducer from "./reducers/filter-reducer";

export default combineReducers({
  authReducer,
  loaderReducer,
  notifyReducer,
  notificationReducer,
  filterReducer,
});
