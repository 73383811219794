import React from "react";
import {
  Redirect
} from "react-router-dom";
import {
  ROLE_ADMIN,
  ROLE_TRAINEE,
  ROLE_INSTRUCTOR,
  ROLE_PRODUCT_MANAGER,
  ROLE_QUALITY_MANAGER,
} from "../../../helpers/role";
import { useSelector } from "react-redux";

function Home() {
  const { roles } = useSelector(state => state.authReducer.user);

  if (roles.includes(ROLE_ADMIN) || roles.includes(ROLE_INSTRUCTOR)) {
    return <Redirect to="/trainings" />;
  }

  if (roles.includes(ROLE_TRAINEE)) {
    return <Redirect to="/training-session" />;
  }

  if (roles.includes(ROLE_PRODUCT_MANAGER)) {
    return <Redirect to="/trainings" />;
  }

  if (roles.includes(ROLE_QUALITY_MANAGER)) {
    return <Redirect to="/settings/syllabi" />;
  }

  return null;
}

export default Home;
