import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { hide } from "../../redux/actions/notify";

function NotificationMessage() {
  const { isVisible, message, status } = useSelector(
    (state) => state.notifyReducer
  );
  const dispatch = useDispatch();

  if (!isVisible) {
    return null;
  }

  setTimeout(() => dispatch(hide()), 2000);

  return (
    <div className={`notif__wrapper notif-${status}`} onClick={() => dispatch(hide())}>
      <span className="notif-close">&times;</span>
      {message}
    </div>
  );
}

export default NotificationMessage;
