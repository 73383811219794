import React, { useEffect, useState } from 'react';
import Table from '@/components/common/table/table';
import TableHeader from '@/components/common/table/table-header';
import TableSearch from '@/components/common/table/table-search';
import TableFilterGroup from '@/components/common/table/table-filter-group';
import TableBody from '@/components/common/table/table-body';
import { archivingActions } from '@/components/common/table/table-actions';
import ModalRevision from '@/components/syllabus/modals/modal-revision';
import { ACTION_ARCHIVE, ACTION_RESTORE, GET_LIST_REQUEST_PARAMS } from '@/components/common/constants';
import SyllabusRow from '@/components/syllabus/syllabus-row';
import Request from '@/helpers/request';
import EntityRepository from '@/repositories/EntityRepository';

const SyllabusTable = ({ importResult }) => {
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [overlay, setOverlay] = useState({
    showModal: false,
    selectedIds: [],
    entityTitles: [],
  });

  const [modalRevision, setModalRevision] = useState({
    isOpen: false,
    revisions: [],
  });

  const [requestParams, setRequestParams] = useState({
    ...GET_LIST_REQUEST_PARAMS, archive: 'active',
  });

  const thead = ['Model', 'Course Reference', 'QT title', 'Date', 'Version'];
  const path = '/api/syllabi';
  const pathPdf = '/api/syllabi/generate-training-pdf';
  const lastThColSpan = 3;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const data = await EntityRepository.getEntities(
        path,
        requestParams
      );
      if (data) {
        if (!requestParams.start) {
          setData(data.items);
          setCount(data.count);
        }else{
          setData(state => ([ ...state, ...data.items ]));
          setCount(data.count);
        }
        setIsLoading(false);
      }
    };
    if (!overlay.showModal) {
      fetchData();
    }
  }, [requestParams, importResult, overlay]);

  const openModalRow = (row, action = ACTION_ARCHIVE) => {
    if (row.id !== undefined) {
      setOverlay({
        showModal: true,
        popupName: 'syllabus',
        entityName: row.title,
        entityTitles: [row.id],
        selectedIds: [row.id],
        eventTitle: action === ACTION_RESTORE ? 'Restoring' : 'Archiving',
        action,
      });
    }
  };

  const openModalTable = (selectedIds, action = ACTION_ARCHIVE) => {
    setOverlay({
      showModal: true,
      popupName: 'syllabus',
      entityName: 'Syllabus',
      entityTitles: selectedIds,
      eventTitle: action === ACTION_RESTORE ? 'Restoring' : 'Archiving',
      selectedIds,
      action,
    });
  };

  const openModalRevision = async (syllabusId) => {
    if (!syllabusId) return;

    const loadedRevisions = await Request.get(
      `/api/syllabi/${syllabusId}/revisions`
    );

    setModalRevision({
      modalRevision: {
        isOpen: true,
        revisions:
          loadedRevisions && loadedRevisions.length ? loadedRevisions : [],
      },
    });
  };

  const renderRows = () => {
    const rows = data.map((item, i) => (
      <SyllabusRow
        key={i}
        row={item}
        path={path}
        openModalRevision={openModalRevision}
        openModal={openModalRow}
      />
    ));

    if (isLoading) {
      const loadingRow = <tr key="loadingRow">
        <td colSpan="9"><i className="fa fa-spin fa-circle-o-notch"></i></td>
      </tr>;

      if (requestParams.start) {
        rows.push(loadingRow);
      } else {
        return loadingRow;
      }
    }

    return rows;
  };

  const onScrollList = (event) => {
    const scrollBottom =
      Math.ceil((event.target.scrollHeight - event.target.scrollTop) - 100) <= event.target.clientHeight;
    const loadedCount = data.length;
    if (scrollBottom && !isLoading && loadedCount < count) {
      loadAdditionalData();
    }
  };

  const loadAdditionalData = () => {
    setRequestParams(data => ({
      ...data,
      start: requestParams.start + requestParams.limit,
    }));
  };

  const loadFilterData = (field = 'like', value = '') => {
    resetPagination();
    setRequestParams(data => ({ ...data, [field]: value }));
  };

  const resetPagination = () => {
    setRequestParams({
      ...GET_LIST_REQUEST_PARAMS, archive: 'active',
    });
  };

  return (
    <Table>
      <TableHeader count={count} title="Syllabus">
        <TableSearch
          onArchiveFilter={(e) => loadFilterData('archive', e)}
          onSearch={(e) => loadFilterData('like', e)}
          getFilters={requestParams}
          pdfUrl={pathPdf}
        >
          <TableFilterGroup
            classModifier="syllabus-filter__buttons"
            field="model"
            onFilter={(e) => loadFilterData('model', e)}
            options={[
              {
                className: 'boeing-filter-btn',
                title: 'boeing',
                value: 'boeing',
              },
              {
                className: 'airbus-filter-btn',
                title: 'airbus',
                value: 'airbus',
              },
              {
                className: 'all-syllabus-btn',
                title: 'all',
                value: 'all',
                isActive: true,
              },
            ]}
          />
        </TableSearch>
      </TableHeader>
      <TableBody
        actions={archivingActions(openModalTable)}
        overlay={overlay}
        onScroll={(event) => onScrollList(event)}
        path={path}
        setOverlay={setOverlay}
        thead={thead}
        lastThColSpan={lastThColSpan}
        tableId="syllabus-list-table"
      >
        {renderRows()}
      </TableBody>
      {modalRevision.isOpen ? (
        <ModalRevision
          revisions={modalRevision.revisions}
          onCancel={() => {
            setModalRevision({
              modalRevision: {
                isOpen: false,
                revisions: [],
              },
            });
          }}
        />
      ) : null}
    </Table>
  );
};

export default SyllabusTable;
