import React from 'react';
import { Page, Document, StyleSheet, Font, View, Image } from '@react-pdf/renderer';
import Syllabus from '@/components/common/training-pdf/syllabus';
import Training from '@/components/common/training-pdf/training';
import Trainee from '@/components/common/training-pdf/trainee';
import Instructors from '@/components/common/training-pdf/instructors';
import Tasks from '@/components/common/training-pdf/tasks';
import TrainingResult from '@/components/common/training-pdf/trainingResult';
import Introduction from '@/components/common/training-pdf/introduction';
import Revisions from '@/components/common/training-pdf/revisions';
import Practical from '@/components/common/training-pdf/practical';
import AtaSummary from '@/components/common/training-pdf/ataSummary';
import TaskSummaryTable from '@/components/common/training-pdf/taskSummaryTable';
import Assessment from '@/components/common/training-pdf/assessment';


const styles = StyleSheet.create({
  page: {
    fontSize: 14,
    paddingLeft: 25,
    paddingRight: 25,
    paddingTop: 20,
    paddingBottom: 20,
    margin: 0,
  },
  centerImage: {
    alignItems: 'left',
    flexGrow: 1,
  },
  block: {
    width: 200,
    height: 200,
  },
  image: {
    width: '100%'
  },
});

Font.register({
  family: 'Open Sans',
  fonts: [
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 },
  ],
});

// eslint-disable-next-line react/prop-types
const Table = ({ data, trainee }) => {
  return (
    <Document>
      {/* eslint-disable-next-line react/prop-types */}
      {(data.syllabus.photo || data.syllabus.logo) && (
        <Page bookmark={`Syllabus: ${data.syllabus.title}`}  wrap={false} orientation="landscape" size="A4" style={styles.page}>
          {data.syllabus.logo && (
            <View style={[styles.centerImage, styles.block]}>
              {/* eslint-disable-next-line react/prop-types */}
              <Image style={styles.image} src={data.syllabus.logo}/>
            </View>
          )}
          {data.syllabus.photo && (
            <View style={styles.centerImage}>
              {/* eslint-disable-next-line react/prop-types */}
              <Image style={styles.image} src={data.syllabus.photo}/>
            </View>
          )}
        </Page>
      )}
      <Page bookmark="Main information" orientation="landscape" size="A4" style={styles.page}>
        {/* eslint-disable-next-line react/prop-types */}
        <Syllabus data={data.syllabus}/>
        {/* eslint-disable-next-line react/prop-types */}
        <Training data={data.training}/>
        {/* eslint-disable-next-line react/prop-types */}
        <Trainee data={data.training.trainees} id={trainee}/>
        {/* eslint-disable-next-line react/prop-types */}
        <Instructors data={data.training.instructors}/>
      </Page>
      {/* eslint-disable-next-line react/prop-types */}
      {data.syllabus.revisions.length > 0 && (
        <Page bookmark="Revisions" orientation="landscape" size="A4" style={styles.page}>
          {/* eslint-disable-next-line react/prop-types */}
          <Revisions data={data.syllabus.revisions}/>
      </Page>)}
      <Page bookmark="Introduction" orientation="landscape" size="A4" style={styles.page}>
        {/* eslint-disable-next-line react/prop-types */}
        <Introduction data={data.syllabus}/>
      </Page>
      <Page bookmark="Practical" orientation="landscape" size="A4" style={styles.page}>
        {/* eslint-disable-next-line react/prop-types */}
        <Practical data={data.syllabus}/>
      </Page>
      <Page bookmark="Ata Summary Table" orientation="landscape" size="A4" style={styles.page}>
        {/* eslint-disable-next-line react/prop-types */}
        <AtaSummary data={data.ataSummary}/>
      </Page>
      <Page bookmark="Task Summary Table" orientation="landscape" size="A4" style={styles.page}>
        {/* eslint-disable-next-line react/prop-types */}
        {data.taskSummaryTable.length > 0 && (<TaskSummaryTable data={data.taskSummaryTable} trainee={trainee}/>)}
      </Page>
      <Page bookmark="Tasks" orientation="landscape" size="A4" style={styles.page}>
        {/* eslint-disable-next-line react/prop-types */}
        <Tasks data={data.tasks} trainee={trainee}/>
      </Page>
      <Page bookmark="Assessment" orientation="landscape" size="A4" style={styles.page}>
        {/* eslint-disable-next-line react/prop-types */}
        <Assessment data={data.syllabus}/>
      </Page>
      <Page bookmark="Training Result" orientation="landscape" size="A4" style={styles.page}>
        {/* eslint-disable-next-line react/prop-types */}
        <TrainingResult data={data.trainingResults ? data.trainingResults : []} assessment={data.syllabus.assessment_criteria} extendedTaskStats={data.extendedTaskStats}/>
      </Page>
    </Document>
  );
};

export default Table;
