import React from 'react';

const TableHeader = ({ title, count, children}) => {
  return (
    <>
      <div className="compositions-data">
        <span className="number">{count}</span>
        <span className="category">{title}</span>
      </div>
      {children}
    </>
  );
}

export default TableHeader;
