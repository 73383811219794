import React from 'react';
import DashboardTab from './dashboard-tab.jsx';

const Dashboard = ({ title, tabs = [], children }) => {
  return (
    <div className="user-dashboard__main-panel">
      <div className="staff-data__tabs-wrapper">
        <div className="tabs__titles">
          {title ? <div className="dashboard__title">{title}</div> : null}
          {
            tabs.map(
              ({title, url}, index) => 
                (<DashboardTab key={index} to={url}>{title}</DashboardTab>)
            ) 
          }
        </div>
        { children }
      </div>
    </div>
  );
};

export default Dashboard;
