import React from "react";

class TextInput extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      inputType = "text",
      className = "",
      inputName,
      inputLabel,
      changeInput,
      inputValue,
      disabled = false,
      placeholder = "Enter text…",
      errors = {},
    } = this.props;

    return (
      <>
        <label htmlFor={inputName}>{inputLabel}</label>
        <input
          type={inputType}
          id={inputName}
          placeholder={placeholder}
          name={inputName}
          onChange={changeInput}
          value={inputValue}
          disabled={disabled}
          className={className}
        />
        { (errors[inputName] && errors[inputName] != '') ? (
          <span className="wrror-masage">{errors[inputName]}</span>
        ) : null}
      </>
    );
  }
}

export default TextInput;
