import React from "react";
import date from "../../helpers/date";

const AgreeChanges = ({ cancelAgree, confirmAgree }) => {
  return (
    <div className="overlay">
      <div className="modal-act-element" id="archiving-element-modal">
        <div className="modal-act-element__wrapper">
          <div className="modal-act-element__head">
            <div className="modal-act-element__title">
              {/* <div className="modal-archiving-element__icon">
                <span></span>
              </div> */}
              <div>
                {/* <div className="modal-act-element__training-name">
                  ATA
                  <span></span>
                </div> */}
                {/* <div className="modal-act-element__subtitle">ATA</div> */}
              </div>
            </div>
            <div className="modal-act-element__sesion-date">
              <span>{date.getFormatted()}</span>
            </div>
          </div>
          <div className="modal-delete-element__warning">
            Do you want to cancel the creation in progress?
          </div>
        </div>
        <div className="modal-act-element__btn">
          <button className="no-delete__btn btn-param" onClick={cancelAgree}>
            No
          </button>
          <button className="archive__btn btn-param" onClick={confirmAgree}>
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};

export default AgreeChanges;
