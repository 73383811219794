import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  title: {
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    width: '100%',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 20,
  },
  header: {
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    fontSize: 11,
    textAlign: 'center',
  },
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginTop: 10,
    width: '100%',
  },
  td: {
    fontSize: 11,
    textAlign: 'center',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    paddingTop: 5,
    paddingBottom: 5,
    paddingRight: 2,
    paddingLeft: 2,
  },
});

const TaskSummaryTableRows = (prop) => {
  const { data, trainee } = prop;

  const rows = Object.keys(data).map((key, index)=> {
    return (
      <View style={{ flexDirection: 'row' }} key={index}>
        <Text style={[styles.td, { width: '70%' }]}>{key}</Text>
        <Text style={[styles.td, { width: '30%' }]}>{trainee && data[key]}</Text>
      </View>
    );
  });

  return <Fragment>{rows}</Fragment>;
};

const TaskSummaryTable = (props) => {
  // eslint-disable-next-line react/prop-types
  const { data, trainee } = props;

  return (
    <Fragment>
      <View style={styles.row}>
        <Text style={styles.title}>Main task summary table: </Text>
      </View>
      <View style={{ flexDirection: 'row' }}>
        {/* eslint-disable-next-line react/prop-types */}
        {data.map((item, index) => (
          <View style={{ width: '25%', paddingLeft: 2, paddingRight: 2 }} key={index}>
            <View style={styles.tableContainer}>
              <Text style={[styles.header, { width: '70%' }]}>ATA Code</Text>
              <Text style={[styles.header, { width: '30%' }]}>MT</Text>
            </View>
            <TaskSummaryTableRows data={item} trainee={trainee}/>
          </View>
        ))}
      </View>

    </Fragment>
  );
};

export default TaskSummaryTable;
