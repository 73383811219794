import React from 'react';
import { useSyllabus } from '@/components/frontend/trainings/syllabus/pages/common/syllabus-context';

function Part2() {
  const syllabus = useSyllabus();

  return (
    <div className="syllabus-parts__wrapper">
      <div className="syllabus-parts__inner scrollbar-style">
        <div className="assessment-sheet__title">
          Assessment part
        </div>
        <div className="syllabus-parts__descr">
          <p>
            {syllabus.assessment_part}
          </p>
        </div>
        <div className="assessment-sheet__title">
          Description of this practical training assessment part
        </div>
        <div className="syllabus-parts__descr">
          <p>
            {syllabus.assessment_description}
          </p>
        </div>
        <div className="assessment-sheet__title">
          Assessment criteria
        </div>
        <div className="syllabus-parts__descr">
          <div className="syllabus-changes__inner scrollbar-style">
            <table className="users-table__data">
              <thead className="users-table__thead">
                <tr>
                  <th>Assessment number</th>
                  <th>Competence criteria</th>
                </tr>
              </thead>
              <tbody className="users-table__tbody">
                { syllabus.assessment_criteria !== undefined ?
                  syllabus.assessment_criteria.map((assessment, index) => (
                    <tr key={index}>
                      <td>
                        <span>{assessment.number}</span>
                      </td>
                      <td>
                        <span>{assessment.criteria}</span>
                      </td>
                    </tr>
                  ))
                  : ''
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Part2;
