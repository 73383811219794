

class ErrorRepository {
  constructor () {

  }

  checkNotEmpty(value) {
    return (!value || value.toString() === '') ? 'This value should not be blank.' : '';
  }

  getErrorClassName(errorsObject = {}, key = ""){
    if (errorsObject[key]){
      return errorsObject[key] !== "" ? " error" : "";
    }
    return "";
  }
}

export default new ErrorRepository();