import React from "react";
import date from "../../../../../helpers/date";

function ModalValidateTask({
    onCancel,
    comfirmModal,
    sessionNumber
}) {

  return (
    <>
    <div className="overlay">
    <div className="modal-act-element" id="edit-task-modal">
        <div className="modal-act-element__wrapper">
            <div className="modal-act-element__head">
                <div className="modal-act-element__title">
                    <div className="modal-archiving-element__icon"><span></span></div>
                    <div>
                        <div className="modal-act-element__training-name">
                            Training session <span>#{sessionNumber}</span> 
                        </div>
                        <div className="modal-act-element__subtitle">
                            Editing task result
                        </div>
                    </div>
                    
                </div>
                <div className="modal-act-element__sesion-date">
                    <span>{date.getFormatted()}</span>
                </div>
            </div>
            <div className="modal-delete-element__warning">
                Are you sure you want to change the result of this task?
            </div>
        </div>
        <div className="modal-act-element__btn">
            <button className="no-delete__btn btn-param" onClick={onCancel}>Cancel</button>
            <button className="save-btn btn-param" onClick={comfirmModal}>Yes, edit</button>
        </div>
    </div>
    
    </div>
    </>
    );
}

export default ModalValidateTask;