import React from 'react';

function Part1() {
  return (
    <div className="syllabus-part__wrapper">
      <h1 className="syllabus-part__title">part 1</h1>
      <div className="syllabus-part__subtitle">
        Location Functional/operational test service and ground handling
        removal/installation Minimu equipment list
      </div>
    </div>
  );
}

export default Part1;
