import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  ROLE_ADMIN,
  ROLE_PRODUCT_MANAGER,
  ROLE_QUALITY_MANAGER,
  ROLE_INSTRUCTOR,
} from '../../helpers/role';

function Menu() {
  const user = useSelector((state) => state.authReducer.user);
  const { roles } = user;

  return (
    <ul className="user-interface__subheader-buttons">
      {roles.includes(ROLE_ADMIN) || roles.includes(ROLE_QUALITY_MANAGER)  ? (
        <>
          <li className="user-interface__subheader-item">
            <NavLink
              className="user-interface__subheader-btn"
              activeClassName="subheader-btn-active"
              to="/settings/atas"
            >
              ATA
            </NavLink>
          </li>
        </>
      ) : null}
      {roles.includes(ROLE_ADMIN) || roles.includes(ROLE_QUALITY_MANAGER) || roles.includes(ROLE_PRODUCT_MANAGER)  ? (
        <>
          <li className="user-interface__subheader-item">
            <NavLink
              className="user-interface__subheader-btn"
              activeClassName="subheader-btn-active"
              to="/settings/syllabi"
            >
              Syllabus
            </NavLink>
          </li>
        </>
      ) : null}
      {roles.includes(ROLE_ADMIN) ||
      roles.includes(ROLE_PRODUCT_MANAGER) ||
      roles.includes(ROLE_QUALITY_MANAGER) ||
      roles.includes(ROLE_INSTRUCTOR) ? (
        <li className="user-interface__subheader-item">
          <NavLink
            className="user-interface__subheader-btn"
            activeClassName="subheader-btn-active"
            to="/settings/users"
            isActive={(match, { pathname }) => {
              return pathname.includes('/settings/users');
            }}
          >
            User
          </NavLink>
        </li>
      ) : null}
      {roles.includes(ROLE_ADMIN) ||
      roles.includes(ROLE_PRODUCT_MANAGER) || roles.includes(ROLE_INSTRUCTOR) ? (
        <li className="user-interface__subheader-item">
          <NavLink
            className="user-interface__subheader-btn"
            activeClassName="subheader-btn-active"
            to="/settings/trainings"
          >
            Training
          </NavLink>
        </li>
      ) : null}
    </ul>
  );
}

export default withRouter(Menu);
