import React, { useEffect, useState } from "react";
import { useTraining } from "../../common/training-context";
import { useParams } from "react-router-dom";
import Request from "../../../../../helpers/request";
import date from "../../../../../helpers/date";

function ModalTrainee({
  traineeId,
  onCancel = () => {} 
}) {
  const { trainingId } = useParams();
  const [selectedTraineeId, setSelectedTraineeId] = useState(traineeId);
  const [traineeDetail, setTraineeDetail] = useState({
    trainee: {},
    traineeGroup: {},
  });
  const training = useTraining();
  const { trainees: trainingTrainees = [] } = training;

  useEffect(() => {
    const fetchData = async () => {
      const result = await Request.get(
        `/api/trainings/${trainingId}/trainees/${selectedTraineeId}/detail`
      );
      setTraineeDetail({
        trainee: result.trainee,
        traineeGroup: result.trainee_group,
      });
    };

    if (selectedTraineeId) fetchData();

    const cssClassOpenModal = 'open-modal';
    if (!document.body.classList.contains(cssClassOpenModal)) {
      document.body.classList.add(cssClassOpenModal);
    }

    return () => {
      document.body.classList.remove(cssClassOpenModal);
    };
  }, [selectedTraineeId]);

  const { trainee, traineeGroup } = traineeDetail;

  return (
    <div className="overlay">
      <div className="modal-act-element" id="trainee-inform-modal">
        <div className="modal-act-element__wrapper">
          <div className="modal-act-element__head">
            <div className="modal-act-element__title">Trainee profil</div>
            <div className="modal-act-element__sesion-date">
              <span>{date.getFormatted()}</span>
            </div>
          </div>
          <div className="modal-act-element__inner">
            <div className="modal-act-element__subtitle">Trainee selection</div>
            <div className="modal-trainee-inform__inner">
              <div className="modal-trainee-inform__select">
                <div className="data-sort__box">
                  <select
                    value={selectedTraineeId || ""}
                    onChange={({ target: { value } }) =>
                      setSelectedTraineeId(Number(value))
                    }
                  >
                    {trainingTrainees.map((trainingTrainee, index) => (
                      <option
                        value={trainingTrainee.id}
                        key={index}
                      >{`${trainingTrainee.last_name[0]}. ${trainingTrainee.first_name}`}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="trainee-inform__wrapper">
                <div className="trainee-inform__inner">
                  <div className="user-photo-modal">
                    <img src="/adminApp/src/assets/icons/user-icon.svg" alt="" />
                  </div>
                  <div className="user-iform-box">
                    <div className="user-name">
                      {`${trainee.last_name ? trainee.last_name[0] : ""}. ${
                        trainee.first_name
                      }`}
                    </div>
                    <div className="about-trainee__group-inform">
                      <span className="about-trainee__group-name">
                        {traineeGroup.name != ""
                          ? traineeGroup.name
                          : "No Group"}
                        &nbsp;-&nbsp;
                      </span>
                      <span
                        className="about-trainee__group-color"
                        style={{
                          color: traineeGroup.color,
                        }}
                      >
                        {traineeGroup.color_title}
                      </span>
                      <span
                        className="group-color__bage"
                        style={{
                          backgroundColor: traineeGroup.color,
                        }}
                      ></span>
                    </div>
                    <div className="user-email">
                      <a href={`mailto:${trainee.email}`}>{trainee.email}</a>
                    </div>
                  </div>
                </div>

                <div className="trainee-main-inform__inner">
                  <ul className="about-user-main-inform__list">
                    <li>Date of birth:</li>
                    <li>Place of birth:</li>
                    <li>Company:</li>
                    <li>ID number:</li>
                  </ul>
                  <ul className="trainee-main-inform__list">
                    <li className="date-of-birth">{date.getFormatted(trainee.birth_date)}</li>
                    <li className="place-of-birth">{trainee.birth_place}</li>
                    <li className="company-name">{trainee.company}</li>
                    <li className="id-number">{trainee.id_number}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <button className="back-btn btn-param" onClick={onCancel}>
            Thanks, go back
          </button>
        </div>
      </div>
    </div>
  );
}

export default ModalTrainee;
