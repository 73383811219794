import React from 'react';
import Request from '../../helpers/request';
import Tabs from './common/syllabus-tabs.jsx';
import { connect } from 'react-redux';
import { success, danger } from '../../redux/actions/notify';
import { withRouter } from 'react-router-dom';
import TabContext from './tabs/tab-context.js';
import TabDescription from './tabs/tab-description.jsx';
import TabIntroduction from './tabs/tab-introduction.jsx';
import TabTaskEdition from './tabs/tab-task-edition.jsx';
import TabPart1 from './tabs/tab-part1.jsx';
import TabPart2 from './tabs/tab-part2.jsx';
import AgreeChanges from '../modals/modal-agree-changes';
import {
  ROLE_ADMIN,
  ROLE_PRODUCT_MANAGER,
  ROLE_INSTRUCTOR
} from '@/helpers/role';
import ImportRevision from '@/components/syllabus/inputs/ImportRevision.jsx';

class SyllabusCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        model: null,
        title: null,
        introduction: null,
        practical: null,
        description: null,
        validation_rules: null,
        assessment_part: null,
        assessment_description: null,
        assessment_criteria: [],
        validation: 0,
        date: null,
        ata_ids: [],
        tasks: [],
        revisions: [],
        is_draft: true,
        is_new_version: true,
      },
      errors: {},
      activeTab: {},
      comment: '',
      isDisabled: false,
      logo: '',
      photo: '',
      agreeModal: false,
      changes: false,
    };

    this.tabs = [
      {
        active: true,
        key: 'description',
        title: 'Description',
      },
      {
        active: false,
        key: 'introduction',
        title: 'Introduction',
      },
      {
        active: false,
        key: 'part1',
        title: 'Ata',
        wrapperClass: 'create-syllabus-dash-wrapper',
      },
      {
        active: false,
        key: 'edition',
        title: 'Task edition',
      },
      {
        active: false,
        key: 'part2',
        title: 'Assessment',
        wrapperClass: 'create-syllabus-dash-wrapper',
      },
    ];

    this.ataIdToCode = {};
    this.tabValidation = null;
    const { roles } = props.user;
    this.canEdit = roles.includes(ROLE_ADMIN) || roles.includes(ROLE_PRODUCT_MANAGER) || roles.includes(ROLE_INSTRUCTOR);
  }

  checkData = () => {
    const { formData } = this.state;
    const ignoreFields = ['photo', 'logo', 'is_draft', 'is_new_version', 'revisions'];
    for (let [field, value] of Object.entries(formData)) {
      if (!ignoreFields.includes(field) && (value === null || value == 0)) {
        this.setState({ isDisabled: true });

        return;
      }
    }
    this.setState({ isDisabled: false });
  };

  clearError = (fieldName) => {
    const { errors } = this.state;

    if (errors[fieldName] && errors[fieldName] != '') {
      this.setError(fieldName, '');
    }
  };

  setFormField = (field, value) => {
    this.setState({ changes: true });

    this.clearError(field);
    this.setState((state) => ({
      ...state,
      formData: { ...this.state.formData, [field]: value },
    }));
    this.checkData();
  };

  setImage = (field, value) => {
    this.setState({ changes: true });

    this.setState({ [field]: value });
  };

  setError = (field, message) => {
    this.setState((state) => ({
      ...state,
      errors: { ...state.errors, [field]: message },
    }));
  };

  handleChange = (e) => {
    this.setState({ changes: true });

    const { name, value, checked } = e.target;

    if (name === 'is_draft') {
      this.setFormField(name, checked);
    } else {
      this.setFormField(name, value);
    }

    this.checkData();
  };

  setActiveTab = (tab) => {
    this.setTabValidation(null);
    this.setState((state) => ({ ...state, activeTab: tab }));
    this.checkData();
  };

  create = async () => {
    const { logo, photo } = this.state;
    let images = new FormData();

    images.append('logo', logo);
    images.append('photo', photo);

    const { formData, isDisabled, isDraft } = this.state;
    if (isDisabled || !this.checkTabValidation()) return;
    for (const tab of this.tabs) {
      if (!tab.isValidated) {
        this.setActiveTab(tab);
        if (!this.checkTabValidation()) {
          return;
        }
      }
    }
    delete formData.logo;
    delete formData.photo;

    this.setState({ isDisabled: true });
    const response = await Request.post('/api/syllabi/create', formData);

    if (response.id) {
      const res = await Request.post(
        `/api/syllabi/${response.id}/save-images`,
        images
      );
      if (res.upload === false) {
        this.props.warning(res.message);
      }
      this.props.success('Syllabus successfully created');
      this.props.history.push({
        pathname: '/settings/syllabi',
        reference: response,
      });
    } else {
      this.props.danger('Server error');
      this.setState({ isDisabled: false });
    }
  };

  componentDidMount() {
    this.setState({
      activeTab: this.tabs[0],
    });
  }

  setTabValidation = (tabValidation) => {
    this.tabValidation = tabValidation;
  };

  checkTabValidation = () => {
    const { activeTab } = this.state;
    const validationResult =
      !this.tabValidation || this.tabValidation(this.setError);
    activeTab.isValidated = validationResult;

    return validationResult;
  };

  goBack = (event) => {
    event.preventDefault();

    if (this.state.changes) {
      return this.setState({ agreeModal: true });
    }

    return this.props.history.push('/settings/syllabi');
  };

  confirmAgree = () => {
    this.setState({ agreeModal: false, changes: false });

    return this.props.history.push('/settings/syllabi');
  };

  cancelAgree = () => {
    return this.setState({ agreeModal: false });
  };

  setRevisions = (revisions) => {
    this.setFormField('revisions', revisions);
  };

  render() {
    const { activeTab, formData, errors, isDisabled, agreeModal } = this.state;
    const { key: activeTabKey, wrapperClass = '' } = activeTab;
    const canEdit = this.canEdit;

    return (
      <section className="create-new-user users-edit-wrapper">
        <div className="container">
          <div className="users-edit-head__inner">
            <ul className="users-edit__breadcrumb">
              <li>
                <a href="#/settings/syllabi">Syllabus</a>
              </li>
              <li className="active">
                <span>New Syllabus</span>
              </li>
            </ul>
            <div className="version-panel">
              <div className="version-panel__item">
                <label className="create-new-user__badge">
                  <span>Draft:
                    <input
                      className="version-checkbox"
                      type="checkbox"
                      name="is_draft"
                      onChange={this.handleChange}
                      defaultChecked={formData.is_draft}
                    />
                  </span>
                </label>
              </div>
              <div className="version-panel__item">
                <div className="create-new-user__badge">
                  <ImportRevision setRevisions={this.setRevisions}/>
                </div>
              </div>
            </div>
          </div>
          <div className="create-new-user__wrapper">
            <div className="create-new-user__top-box">
              <div className="create-new-user__badge">
                <span>new</span>
              </div>
            </div>
            <div className="new-syllabus__main-panel edit-syllabus__main-panel">
              <TabContext.Provider
                value={{
                  handleChange: this.handleChange,
                  setFormField: this.setFormField,
                  formData,
                  errors,
                  setValidation: this.setTabValidation,
                  ataIdToCode: this.ataIdToCode,
                  setImage: this.setImage,
                  canEdit: this.canEdit,
                }}
              >
                <div className="new-syllabus__tabs-wrapper">
                  <Tabs
                    checkValidation={this.checkTabValidation}
                    tabs={this.tabs}
                    activeTab={activeTab}
                    setActiveTab={this.setActiveTab}
                  />
                  <div className="new-syllabus__tabs-content">
                    <div
                      className={`create-new-user-dash-wrapper ${wrapperClass}`}
                    >
                      {{
                        description: <TabDescription/>,
                        introduction: <TabIntroduction/>,
                        part1: <TabPart1/>,
                        edition: <TabTaskEdition/>,
                        part2: <TabPart2/>,
                      }[activeTabKey] || null}
                      <div className="create-new-user__buttons syllabus-tabs__btns">
                        <button
                          className="cancel-btn btn-param"
                          onClick={this.goBack}
                        >
                          Cancel
                        </button>
                        <button
                          className="create-btn btn-param "
                          onClick={this.create}
                          disabled={!canEdit || isDisabled}
                        >
                          Create
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </TabContext.Provider>
            </div>
          </div>
        </div>
        {agreeModal ? (
          <AgreeChanges
            cancelAgree={this.cancelAgree}
            confirmAgree={this.confirmAgree}
          />
        ) : (
          ''
        )}
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.authReducer.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    success: (message) => dispatch(success(message)),
    danger: (message) => dispatch(danger(message)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SyllabusCreate)
);
