import React from "react";
import { useSelector } from "react-redux";
import { ROLE_TRAINEE } from "../../../../../../helpers/role";
import Part1 from "./part-1";
import TaskDetail from "./task-detail";

function IndexPart1() {
  const { roles } = useSelector(state => state.authReducer.user);

  if (roles.includes(ROLE_TRAINEE)) {
    return <TaskDetail />;
  }

  return <Part1 />;
}

export default IndexPart1;
