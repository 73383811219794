import React from "react";

function IconCalendar() {
  return (
    <svg
      width="17px"
      height="17px"
      viewBox="0 0 17 17"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="P3"
          transform="translate(-969.000000, -410.000000)"
          stroke="#FFFFFF"
        >
          <g
            id="content-settings-base"
            transform="translate(0.000000, 180.000000)"
          >
            <g id="content-settings-2">
              <g
                id="Content-user-base"
                transform="translate(76.000000, 152.000000)"
              >
                <g id="Bloc-date" transform="translate(633.000000, 0.000000)">
                  <g id="Group" transform="translate(117.000000, 34.000000)">
                    <g id="date">
                      <g id="dates" transform="translate(0.000000, 30.000000)">
                        <g
                          id="poubelle-copy"
                          transform="translate(134.000000, 5.000000)"
                        >
                          <g
                            id="poubelle"
                            transform="translate(9.000000, 10.000000)"
                          >
                            <g id="calendar">
                              <rect
                                id="Rectangle-3"
                                strokeWidth="2"
                                x="1"
                                y="2"
                                width="15"
                                height="13"
                                rx="2"
                              ></rect>
                              <line
                                x1="5"
                                y1="0"
                                x2="5"
                                y2="4"
                                id="Line-2"
                                strokeWidth="2"
                                strokeLinecap="round"
                              ></line>
                              <line
                                x1="12"
                                y1="0"
                                x2="12"
                                y2="4"
                                id="Line-2-Copy"
                                strokeWidth="2"
                                strokeLinecap="round"
                              ></line>
                              <line
                                x1="1.5"
                                y1="7.5"
                                x2="15.5"
                                y2="7.5"
                                id="Line-4"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></line>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default IconCalendar;
