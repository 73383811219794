import React from 'react';
import './assets/scss/admin-app.scss';
import Header from './components/common/header.jsx';
import Router from './components/router/router.jsx';
import { connect } from 'react-redux';
import PreLoader from './components/common/loader.jsx';
import NotificationMessage from './components/common/notification-message';
import Request from './helpers/request';
import { change } from './redux/actions/notification';

class AdminApp extends React.Component {
  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    const { loggedIn } = this.props;

    if (loggedIn) {
      const notifications = await Request.get('/api/notifications/received');
      if (notifications && notifications.length) {
        this.props.changeNotifications(notifications);
      }
    }
  }

  openMenu = () => {
    document.body.setAttribute('class', 'menubar-hoverable header-fixed  menubar-visible');
  }

  closeMenu = () => {
    document.body.setAttribute('class', 'menubar-hoverable header-fixed');
  }

  render() {
    const menu = {
      open: this.openMenu,
      close: this.closeMenu,
    };

    const { loggedIn } = this.props;

    return (
      <>
        <PreLoader />
        <NotificationMessage />
        {loggedIn ? (
          <Header menu={menu} />
        ) : null}
        <Router />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    loggedIn: state.authReducer.loggedIn,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeNotifications: (notifications) => dispatch(change(notifications)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminApp);
