import React from "react";
import { NavLink, useRouteMatch } from "react-router-dom";
import { ACTION_ARCHIVE, ACTION_RESTORE } from "../../common/constants";
import TableCheckbox from "../../common/table/table-checkbox";
import TableRowArchiveButton from '@/components/common/table/table-row-archive-button';
import TableRowFlag from "@/components/common/table/table-row-flag";

const TraineeRow = (props) => {
  const match = useRouteMatch();
  const { row, path, openModal } = props;
  const editPass = `${match.url}/${row.id}/edit`;
  let trClassName, action;

  if (row.is_archived) {
    trClassName = 'archived-table-data';
    action = ACTION_RESTORE;
  } else {
    trClassName = '';
    action = ACTION_ARCHIVE;
  }

    return (
      <tr className={trClassName}>
        <td>
          <TableCheckbox id={row.id} />
        </td>
        <td><NavLink
          data-id={row.id}
          data-entity={path}
          to={editPass}
          className="users-table__tbody-link"
        >
          <TableRowFlag id={row.id}/>&nbsp;{row.last_name}
        </NavLink>

        </td>
        <td>
          <NavLink
            data-id={row.id}
            data-entity={path}
            to={editPass}
            className="users-table__tbody-link"
          >{row.first_name}
          </NavLink>
        </td>
        <td>
          {row.id_number}
        </td>
        <td>
          <NavLink
            data-id={row.id}
            data-entity={path}
            to={editPass}
            className="users-table__tbody-link"
          >
            {row.company}
          </NavLink>
        </td>
        <td>
         <TableRowArchiveButton action={action} onClick={() => openModal(row, action)}/>
        </td>
        <td>
          <NavLink
            data-id={row.id}
            data-entity={path}
            to={editPass}>
            <button title="Edit" className="edit-data"></button>
          </NavLink>
        </td>
      </tr>
  );
}

  export default TraineeRow;
