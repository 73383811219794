import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import date from '@/helpers/date';

const Part1Row = ({
  row,
  classroomDate,
  aircraftDate,
  validatedTask = {
    classroom_date: classroomDate,
    aircraft_date: aircraftDate,
  },
  setValidatedTaskField,
  changeCheckBox,
  fetchValidatedTrainees,
  trainingDateRange,
}) => {
  const [isLocked, setIsLocked] = useState(true);
  const isConfirmed = row.is_confirmed_all_trainee_tasks;

  const checkboxHandler = (name) =>
    ({ target: { checked } }) => {
      changeCheckBox(name, checked);
    };

  useEffect(() => {
  }, [isLocked]);

  const toggleLockRow = () => {
    setIsLocked(!isLocked);
  };

  return (
    <tr className={`category-${row.category.toLowerCase()} ${validatedTask.is_validated ? 'validate-task' : ''}`}>
      <td>{row.ata_code}</td>
      <td>#{row.order_number}</td>
      <td>
        <span className={`table-bage ${row.category.toLowerCase()}-bage`}>{row.category}</span>
      </td>
      <td>{row.code}</td>
      <td>{row.subject}</td>
      <td>{row.manual_reference}</td>
      <td className={`${(validatedTask.is_classroom_passed) ? 'checked-data' : ''}`}>
        <div className="valodation-table__wrapper">
          <button className="user-icon" onClick={(e) => {
            e.preventDefault;
            row.trainee_task_count ?
              !isLocked ? fetchValidatedTrainees(row.id, 'classroom') : ''
              :
              isLocked ? fetchValidatedTrainees(row.id, 'classroom') : '';
          }}>
            <i className="fa fa-user-o"></i>
          </button>
          <div className="icon-box select-box">
            <DatePicker
              dateFormat={date.inputDateFormat}
              selected={validatedTask.classroom_date ? new Date(validatedTask.classroom_date) : ''}
              value={validatedTask.classroom_date ? new Date(validatedTask.classroom_date) : '00\xa0/\xa000\xa0/\xa000'}
              className="pointer"
              minDate={new Date(trainingDateRange.date_start)}
              maxDate={new Date(trainingDateRange.date_end)}
              disabled={row.trainee_task_count || !validatedTask.is_classroom_passed ? isLocked : !isLocked}
              onChange={(date) => setValidatedTaskField('classroom_date', date)}
            />
          </div>
          <label className="table-checkbox">
            <input type="checkbox" data-id={row.id} className="is_classroom_passed"
              name={row.id}
              onChange={checkboxHandler('is_classroom_passed')}
              disabled={validatedTask.is_validated ? isLocked : !isLocked}
              checked={(validatedTask.is_classroom_passed)}/>
            <span className="table-custom-checkbox"></span>
          </label>
        </div>
      </td>
      <td className={`${(validatedTask.is_aircraft_passed) ? 'checked-data' : ''}`}>
        <div className="valodation-table__wrapper">
          <button className="user-icon" onClick={(e) => {
            e.preventDefault;
            row.trainee_task_count ?
              !isLocked ? fetchValidatedTrainees(row.id, 'aircraft') : ''
              :
              isLocked ? fetchValidatedTrainees(row.id, 'aircraft') : '';
          }}>
            <i className="fa fa-user-o"></i>
          </button>
          <div className="icon-box select-box">
            <DatePicker
              dateFormat={date.inputDateFormat}
              selected={validatedTask.aircraft_date ? new Date(validatedTask.aircraft_date) : ''}
              value={validatedTask.aircraft_date ? new Date(validatedTask.aircraft_date) : '00\xa0/\xa000\xa0/\xa000'}
              className="pointer"
              minDate={new Date(trainingDateRange.date_start)}
              maxDate={new Date(trainingDateRange.date_end)}
              onChange={(date) => setValidatedTaskField('aircraft_date', date)}
              disabled={row.trainee_task_count || !validatedTask.is_aircraft_passed ? isLocked : !isLocked}
            />
          </div>
          <label className="table-checkbox">
            <input type="checkbox" className="is_aircraft_passed"
              data-id={row.id}
              name={row.id}
              onChange={checkboxHandler('is_aircraft_passed')}
              disabled={validatedTask.is_validated ? isLocked : !isLocked}
              checked={(validatedTask.is_aircraft_passed)}/>
            <span className="table-custom-checkbox"></span>
          </label>
        </div>
      </td>
      <td>
        <span className="insufficient-level">
          <i className={`fa fa-${validatedTask.is_validated ? 'check' : 'minus'}`}></i>
        </span>
        {/* <span className="validated-table-icon"><i className="fa fa-check"></i></span> */}
      </td>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <td onClick={toggleLockRow}>
        {/*<span className="insufficient-level"><i className="fa fa-minus"></i></span>*/}
        {/* <span className="insufficient-level"><i className="fa fa-unlock"></i></span>*/}
        <span className="closed-table-icon">
          {validatedTask.is_validated
            ?
            <i className={`fa fa-${isLocked ? 'lock' : 'unlock'}`}></i>
            :
            <i className={`fa fa-${!isLocked ? 'lock' : 'unlock'}`}></i>
          }
        </span>
      </td>
    </tr>
  );
};

Part1Row.propTypes = {
  row: PropTypes.object.isRequired,
  validatedTask: PropTypes.object,
  trainingDateRange: PropTypes.object,
  changeCheckBox: PropTypes.func,
  setValidatedTaskField: PropTypes.func,
  fetchValidatedTrainees: PropTypes.func,
  classroomDate: PropTypes.string,
  aircraftDate: PropTypes.string,
};

export default withRouter(Part1Row);
