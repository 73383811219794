import React from "react";
import { useTraining } from './training-context';
import date from "../../../../helpers/date";

const ValidateModal = ({
  confirmPopup,
  cancelPopup
}) => {
  const training = useTraining();
  return (
    <div className="overlay">
		<div className="modal-act-element" id="validate-modal">
			<div className="modal-act-element__wrapper">
                <div className="modal-act-element__head">
                    <div className="modal-act-element__title">
                        <div className="modal-archiving-element__icon"><span></span></div>
                        <div>
                            <div className="modal-act-element__training-name">
                              Training session <span>#{training.session_number}</span>
                            </div>
                            <div className="modal-act-element__subtitle">
                                Assessment part
                            </div>
                        </div>

                    </div>
                    <div className="modal-act-element__sesion-date">
                    <span>{date.getFormatted(new Date())}</span>
                    </div>
                </div>
                <div className="modal-delete-element__warning">
					A trainee has reached the validation threshold.
					<span>Continue towards the assessment?</span>
                </div>
            </div>
            <div className="modal-act-element__btn">
                <button className="no-delete__btn btn-param" onClick={cancelPopup}>Cancel</button>
                <button className="archive__btn btn-param" onClick={confirmPopup}>Yes, go</button>
            </div>
        </div>
	</div>
  );
};

export default ValidateModal;
