export const SHOW = "SHOW";
export const HIDE = "HIDE";

export function show() {
  return {
    type: SHOW,
    data: 1
  };
}export function hide() {
  return {
    type: HIDE,
    data: 0
  };
}