import {ACTION_ARCHIVE, ACTION_RESTORE} from '../constants';

export const archivingActions = (openModal) => {
  return [
    {
      title: 'Archiving element',
      handler: (selectedIds) => openModal(selectedIds, ACTION_ARCHIVE)
    },
    {
      title: 'Restore element',
      handler: (selectedIds) => openModal(selectedIds, ACTION_RESTORE)
    },
  ];
}
