import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTraining } from '../common/training-context';
import Request from '../../../../helpers/request';
import TaskTable from './table/task-table.jsx';
import ModalTrainee from './modals/modal-trainee';
import ValidateModal from '../common/validate-modal';
import TrainingHeader from '../common/TrainingHeader.jsx';
import ModalNewSession from '@/components/frontend/trainings/trainees/modals/modal-trainee-add-to-new-session';

function Trainee() {
  const history = useHistory();
  const { trainingId, traineeId } = useParams();
  const training = useTraining();
  const { trainees: trainingTrainees = [] } = training;
  const [traineeDetail, setTraineeDetail] = useState({
    taskCount: {
      AT: {
        validated: 0,
        remaining: 0,
        total: 0,
      },
      MT: {
        validated: 0,
        remaining: 0,
        total: 0,
        is_reached_validation_threshold: false,
      },
    },
    trainee: {},
    traineeGroup: {},
  });
  const [showModalTrainee, setShowModalTrainee] = useState(false);
  const [showModalValidate, setShowModalValidate] = useState(false);
  const [showNewSessionModal, setShowNewSessionModal] = useState(false);

  const { taskCount, trainee, traineeGroup } = traineeDetail;
  const validatedMtPercent = taskCount.MT.validated_percent || 0;

  useEffect(() => {
    const fetchData = async () => {
      const [resultDetail, resultTaskCount] = await Promise.all([
        Request.get(`/api/trainings/${trainingId}/trainees/${traineeId}/detail`),
        Request.get(`/api/trainings/${trainingId}/trainees/${traineeId}/task-count`),
      ]);

      setTraineeDetail({
        trainee: resultDetail.trainee,
        traineeGroup: resultDetail.trainee_group,
        taskCount: resultTaskCount,
      });
    };

    fetchData();
  }, [traineeId]);

  const Circle = ({ value = 0 }) => {
    const radius = 52;
    const circumference = 2 * Math.PI * radius;
    const offset = circumference - value / 100 * circumference;

    return (
      <circle
        className="progress-ring__circle"
        stroke="#50E3C2"
        strokeWidth="8"
        cx="56"
        cy="56"
        r={radius}
        fill="#6C81A2"
        style={{
          strokeDasharray: `${circumference} ${circumference}`,
          strokeDashoffset: offset,
        }}
      />
    );
  };

  const getCurrentTraineeIndex = () => {
    for(let index = 0, len = trainingTrainees.length; index < len; index++) {
      if (trainingTrainees[index].id == traineeId) {
        return index;
      }
    }
  };

  const nextTrainee = () => {
    const traineeIndex = getCurrentTraineeIndex();
    const nextTraineeIndex = ((traineeIndex + 1) < trainingTrainees.length) ? (traineeIndex + 1) : 0;
    const nextTrainee = trainingTrainees[nextTraineeIndex];

    history.push(`/trainings/${trainingId}/trainees/${nextTrainee.id}`);
  };

  const prevTrainee = () => {
    const traineeIndex = getCurrentTraineeIndex();
    const prevTraineeIndex = (traineeIndex - 1) ? (traineeIndex - 1) : (trainingTrainees.length - 1);
    const prevTrainee = trainingTrainees[prevTraineeIndex];

    history.push(`/trainings/${trainingId}/trainees/${prevTrainee.id}`);
  };

  return (
    <>
      <TrainingHeader/>
      <section className="training-session-trainee-list">
        <div className="container">
          <div className="training-session-trainee-list__wrapper">
            <div className="training-session-trainee-list__head trainee-page__head">
              <div className="compositions-data">
                <span className="training-session-trainee-list__title">Trainee detail</span>
                <span className="number">
                  {trainee && trainee.last_name && `${trainee.last_name[0]}. ${trainee.first_name}`}
                </span>
              </div>
              <div className="users-edit-head__buttons">
                <button className="prev-user__btn" style={{ padding: 9, position: 'relative', left: -4, top: -4 }} onClick={() => setShowNewSessionModal(true)} title="Add to new session">
                  <i style={{ fontSize: 14 }}>Add to new session</i>
                </button>
                <button className="prev-user__btn" onClick={prevTrainee}>
                  <i className="fa fa-angle-left"></i>
                </button>
                <button className="next-user__btn" onClick={nextTrainee}>
                  <i className="fa fa-angle-right"></i>
                </button>
              </div>
            </div>

            <div className="trainee-details__inform-panel">
              <div className="inform-panel__about-trainee flex-card">
                <div className="about-trainee__head">
                  <div className="about-trainee__inner">
                    <span className="about-trainee__name">
                      {`${trainee.first_name} ${trainee.last_name}`}
                    </span>
                    <span className="about-trainee__email">
                      {trainee.email}
                    </span>
                  </div>
                  <button className="about-trainee__detail__btn" onClick={() => setShowModalTrainee(true)}></button>
                </div>
                <div className="about-trainee__group-inform">
                  <span className="about-trainee__group-name">
                    {traineeGroup.name != '' ? traineeGroup.name : 'No Group'}
                  &nbsp;-&nbsp;
                  </span>
                  <span className="about-trainee__group-color" style={{
                    color: traineeGroup.color,
                  }}>{traineeGroup.color_title} </span>
                  <span className="group-color__bage" style={{
                    backgroundColor: traineeGroup.color,
                  }}></span>
                </div>
              </div>
              <div className="statistic-cards flex-card">
                <div className="statistic-cards-head additional-head">
                Additional Tasks
                </div>
                <div className="statistic-cards__box">
                  <div className="card-item statistic-cards__title">
                  Validated
                  </div>
                  <div className="card-item statistic-cards__title">
                  Remaining
                  </div>
                  <div className="card-item statistic-cards__number statistic-valid__number">
                    {taskCount.AT.validated}
                  </div>
                  <div className="card-item statistic-cards__number">
                    {taskCount.AT.remaining}
                  </div>
                </div>
              </div>

              <div className="statistic-cards flex-card">
                <div className="statistic-cards-head main-task-head">
              Main Tasks
                </div>
                <div className="statistic-cards__box">
                  <div className="card-item statistic-cards__title">
                Validated
                  </div>
                  <div className="card-item statistic-cards__title">
                Remaining
                  </div>
                  <div className="card-item statistic-cards__number statistic-valid__number">
                    {taskCount.MT.validated}
                    {
                      taskCount.MT.is_reached_validation_threshold
                        ? (<button className="validate__btn" onClick={() => setShowModalValidate(true)}></button>)
                        : null
                    }
                  </div>
                  <div className="card-item statistic-cards__number">
                    {taskCount.MT.remaining}
                  </div>
                </div>
              </div>

              <div className="statistic-cards persent-card flex-card">
                <span className="statistic-cards__bage">mt</span>
                <div className="statistic-card__progress-bar">
                  <svg className="progress-ring" width="112" height="112">
                    <Circle value={validatedMtPercent} />
                  </svg>
                  <div className="progress-per">
                    <input type="text" className="percent" value={validatedMtPercent} disabled />
                  </div>
                </div>
              </div>
            </div>
            <TaskTable />

            {/*<div className="ata-summary__item">*/}
            {/*  <div className="assessment-sheet__title">*/}
            {/*    Main Task summary table*/}
            {/*  </div>*/}
            {/*  <div className="introduction-summary__item">*/}
            {/*    <table className="introduction-summary__table">*/}
            {/*      <thead className="introduction-summary__thead">*/}
            {/*      <tr>*/}
            {/*        <th>ATA code</th>*/}
            {/*        <th><span className="syllabus-symbols__bage at-bage">at</span></th>*/}
            {/*        <th><span className="syllabus-symbols__bage mt-bage">mt</span></th>*/}
            {/*      </tr>*/}
            {/*      </thead>*/}
            {/*      <tbody className="introduction-summary__tbody">*/}
            {/*      <tr>*/}
            {/*        <td></td>*/}
            {/*        <td></td>*/}
            {/*        <td></td>*/}
            {/*      </tr>*/}
            {/*      </tbody>*/}
            {/*    </table>*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div className="copyright">
        © Société Air France - All rights reserved
            </div>
          </div>
        </div>
        {
          showModalValidate
            ? <ValidateModal
              confirmPopup={() => {
                history.push({
                  pathname: `/trainings/${trainingId}/validations/part-2`,
                  state: {
                    selectedTrainees: [ trainee ],
                  },
                });
              }}
              cancelPopup={() => setShowModalValidate(false)}
            />
            : null
        }
        {
          showModalTrainee
            ? (<ModalTrainee traineeId={traineeId} onCancel={() => setShowModalTrainee(false)}/>)
            : null
        }
        {
          showNewSessionModal
            ? (<ModalNewSession training={training} traineeId={traineeId} onCancel={() => setShowNewSessionModal(false)}/>)
            : null
        }
      </section>
    </>
  );
}

export default Trainee;
