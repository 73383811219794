import React, { useState } from 'react';
import Select from '../../common/select.jsx';
import TextInput from '../../common/textInput.jsx';
import TextArea from '../../common/textarea.jsx';
import ErrorRepository from '../../../repositories/ErrorRepository.js';
import date from '../../../helpers/date';
import SearchManualRef from '../inputs/SearchManualRef';

const SKILL_B1 = 'B1';
const SKILL_B2 = 'B2';

function ModalCreateTask({
  taskIndex = -1,
  tasks = [],
  selectedAtaId,
  atas,
  onConfirm,
  onCancel,
}) {
  const isCreateMode = taskIndex === -1;
  const [task, setTask] = useState(tasks[taskIndex] || {
    ata_id: Number(selectedAtaId),
    order_number: 0,
    category: '',
    code: '',
    manual_reference: '',
    subject: '',
    skills: [],
  });

  const [errors, setErrors] = useState({});

  const clearError = (field) => {
    if (errors[field] && errors[field] != '') {
      setErrors((errors) => ({ ...errors, [field]: '' }));
    }
  };

  const checkValidation = () => {
    const fields = ['ata_id', 'skills', 'subject', 'order_number', 'category', 'code', 'manual_reference'];
    let result = true;

    for (const field of fields) {
      const value = task[field];
      const errorMessage = ErrorRepository.checkNotEmpty(value);
      if (errorMessage !== '') {
        setErrors((errors) => ({ ...errors, [field]: errorMessage }));
        result = false;
      }
    }

    for (const [loopIndex, loopTask] of tasks.entries()) {
      if (loopIndex !== taskIndex && loopTask.ata_id === task.ata_id && loopTask.order_number === task.order_number) {
        setErrors((errors) => ({ ...errors, order_number: 'The order number is already taken' }));
        result = false;
      }
    }

    return result;
  };

  const manualRefChange = (manual_reference) => {
    clearError('manual_reference');
    setTask((task) => ({ ...task, manual_reference }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    clearError(name);
    setTask((task) => ({ ...task, [name]: value }));
  };

  const skillChange = (e) => {
    const { name, value, checked } = e.target;
    const array = task[name];
    clearError(name);
    const newArray = checked ?
      (array.includes(value) ? array : [...array, value])
      : (array.filter(item => item !== value));

    setTask((task) => ({ ...task, [name]: newArray }));
  };

  return (
    <div className="overlay">
      <div
        className="modal-act-element__create-task scrollbar-style show"
        id="create-syllabus-task"
      >
        <div className="modal-act-element__wrapper">
          <div className="modal-act-element__head">
            <div className="modal-act-element__title">{isCreateMode ? 'New task' : 'Edit task'}</div>
            <div className="modal-act-element__sesion-date">
              <span>{date.getFormatted()}</span>
            </div>
          </div>

          <form className="create-syllabus-task__form">
            <div className="create-new-user__board-wrapper create-task__board-wrapper">
              <div className={`icon-box select-box ${ErrorRepository.getErrorClassName(errors, 'ata_id')}`}>
                <Select
                  label="ATA code"
                  name="ata_id"
                  value={task.ata_id}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    clearError(name);
                    setTask((task) => ({ ...task, [name]: Number(value) }));
                  }}
                  options={[
                    {
                      title: 'Select ATA',
                    },
                    ...Object.keys(atas).map(
                      (id) => ({
                        value: id,
                        title: atas[id],
                      })
                    ),
                  ]}
                  errors={errors}
                />
              </div>

              <div className={`icon-box ${ErrorRepository.getErrorClassName(errors, 'order_number')}`}>
                <TextInput
                  id="create-order-number"
                  placeholder="Enter number..."
                  inputName="order_number"
                  inputLabel="N° (Order number)"
                  inputType="number"
                  inputValue={task.order_number}
                  changeInput={(e) => {
                    const { name, value } = e.target;
                    clearError(name);
                    setTask((task) => ({ ...task, [name]: Number(value) }));
                  }}
                  errors={errors}
                />
              </div>
              <div className={`icon-box select-box ${ErrorRepository.getErrorClassName(errors, 'category')}`}>
                <Select
                  label="Task category"
                  name="category"
                  value={task.category}
                  onChange={handleChange}
                  options={[
                    {
                      title: 'Select category',
                    },
                    {
                      value: 'MT',
                      title: 'Main Task - MT',
                    },
                    {
                      value: 'AT',
                      title: 'Additional Task - AT',
                    },
                  ]}
                  errors={errors}
                />
              </div>
              <div className={`icon-box select-box ${ErrorRepository.getErrorClassName(errors, 'code')}`}>
                <Select
                  label="Task code"
                  name="code"
                  value={task.code}
                  onChange={handleChange}
                  options={[
                    {
                      title: 'Select code',
                    },
                    {
                      value: 'LOC',
                      title: 'LOC',
                    },
                    {
                      value: 'FOT',
                      title: 'FOT',
                    },
                    {
                      value: 'SGH',
                      title: 'SGH',
                    },
                    {
                      value: 'R/I',
                      title: 'R/I',
                    },
                    {
                      value: 'MEL',
                      title: 'MEL',
                    },
                    {
                      value: 'TS',
                      title: 'TS',
                    },
                  ]}
                  errors={errors}
                />
              </div>
              <SearchManualRef value={task.manual_reference} setValue={manualRefChange} errors={errors} />
              {/* <div className={`icon-box ${ErrorRepository.getErrorClassName(errors, 'manual_reference')}`}>
                <TextInput
                  id="manual_reference"
                  inputValue={task.manual_reference}
                  inputName="manual_reference"
                  inputLabel="Manual reference"
                  inputType="text"
                  changeInput={handleChange}
                  errors={errors}
                />
              </div> */}
              <div className={`icon-box staff-skill__box ${ErrorRepository.getErrorClassName(errors, 'skills')}`}>
                <label>Skill</label>
                <div className="staff-skill__wrapper">
                  <div className="staff-skill__inner">
                    <label className="category-checkbox">
                      <input
                        type="checkbox"
                        name="skills"
                        onClick={skillChange}
                        value={SKILL_B1}
                        defaultChecked={task.skills.includes(SKILL_B1)}
                      />
                      <span className="category-custom-checkbox"></span>
                    </label>
                    <span className="category-name">{SKILL_B1}</span>
                  </div>
                  <div className="staff-skill__inner">
                    <label className="category-checkbox">
                      <input
                        type="checkbox"
                        name="skills"
                        value={SKILL_B2}
                        onClick={skillChange}
                        defaultChecked={task.skills.includes(SKILL_B2)}
                      />
                      <span className="category-custom-checkbox"></span>
                    </label>
                    <span className="category-name">{SKILL_B2}</span>
                  </div>
                </div>
                {
                  (errors.skills && errors.skills !== '') ? (
                    <span className="wrror-masage">{errors.skills}</span>
                  ) : null
                }
              </div>
              <div className={`icon-box tasc-descr-box ${ErrorRepository.getErrorClassName(errors, 'subject')}`}>
                <TextArea
                  inputClass="scrollbar-style"
                  id="task-deskr"
                  label="Subject"
                  inputName="subject"
                  inputValue={task.subject}
                  onChange={handleChange}
                  errors={errors}
                />
              </div>
            </div>
            <div className="create-new-user__buttons">
              <button className="cancel-btn btn-param" onClick={() => {
                onCancel(task);
              }}>
                Cancel
              </button>
              <button className="create-btn btn-param" onClick={(e) => {
                e.preventDefault();
                if (checkValidation()) onConfirm(task);
              }}>
                {isCreateMode ? 'Create' : 'Save'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ModalCreateTask;
