import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  title: {
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    width: '20%',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 20,
  },
  header: {
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    fontSize: 11,
    width: '25%',
    textAlign: 'center',
  },
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginTop: 10,
    padding: 4,
    alignContent: 'center',
    justifyContent: 'center',
  },
  td: {
    fontSize: 11,
    width: '25%',
    textAlign: 'center',
    borderWidth: 1,
    borderTop: 'none',
    borderStyle: 'solid',
    borderColor: '#000000',
    height: '100%',
    paddingTop: 5,
    paddingBottom: 5,
    paddingRight: 2,
    paddingLeft: 2,
  },
});

const tableHeader = [
  'First name',
  'Last name',
  'ID number',
  'Signature',
];

const InstructorRows = (prop) => {
  const { data } = prop;

  const rows = data.map(instructor => {
    return (
      <View style={{ flexDirection: 'row' }} key={instructor.id.toString()}>
        <Text style={styles.td}>{instructor.first_name}</Text>
        <Text style={styles.td}>{instructor.last_name}</Text>
        <Text style={styles.td}>{instructor.id_number}</Text>
        <Text style={styles.td}/>
      </View>
    );
  });

  return <Fragment>{rows}</Fragment>;
};
const Instructors = (props) => {
  return (
    <Fragment>
      <View style={styles.row}>
        <Text style={styles.title}>Instructors: </Text>
      </View>
      <View style={styles.tableContainer}>
        {tableHeader.map((item, index) => <Text style={styles.header} key={index}>{item}</Text>)}
      </View>
      <InstructorRows {...props}/>
    </Fragment>
  );
};

export default Instructors;
