import React, { useEffect, useState } from "react";
import date from "../../../../../helpers/date";
import { useTraining } from "../../common/training-context";

function ModalTraineeList({ onCancel = () => {}, showTrainee = () => {} }) {
  const training = useTraining();
  const { trainees = [] } = training;

  useEffect(() => {
    const cssClassOpenModal = 'open-modal';
    if (!document.body.classList.contains(cssClassOpenModal)) {
      document.body.classList.add(cssClassOpenModal);
    }

    return () => {
      document.body.classList.remove(cssClassOpenModal);
    };
  }, []);

  return (
    <div className="overlay">
      <div className="modal-act-element__create-task scrollbar-style">
        <div className="modal-act-element__wrapper">
          <div className="modal-act-element__head">
            <div className="modal-act-element__title">Trainee list</div>
            <div className="modal-act-element__sesion-date">
              <span>{date.getFormatted()}</span>
            </div>
          </div>
          <div className="trainees-modal__wrapper">
            <div className="modal-list__title">
              List <span>#{training.session_number}</span>
            </div>
            <div className="users-edit-history-session__content">
              <div className="users-edit-history-session-table__wrapper">
                <div className="trainees-modal__table-title">
                  Total trainee
                  <span className="trainees-modal__table-bage">{training.trainee_count}</span>
                </div>
                <div className="users-edit-table__inner">
                  <table className="users-table__data" id="trainee-list-modal">
                    <tbody className="users-table__tbody">
                      {
                        trainees.map((trainee, index) => (
                          <tr key={index}>
                            <td>{trainee.last_name[0]}.</td>
                            <td>{trainee.first_name}</td>
                            <td>{trainee.id_number}</td>
                            <td>{trainee.company}</td>
                            <td>
                              <span className="close" onClick={() => showTrainee(trainee.id)}>
                                <i className="fa fa-eye"></i>
                              </span>
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="trainee-list-modal__buttons">
            <button className="no-delete__btn btn-param" onClick={onCancel}>Thanks, go back</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalTraineeList;
