import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Request from '../../../helpers/request';
import { success, warning, danger } from '../../../redux/actions/notify';
import { useDispatch } from 'react-redux';

function ImportTrainees({ setTrainees = (trainees) => {} }) {
  const dispatch = useDispatch();
  const [inProgress, setInProgress] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [fileName, setFileName] = useState(null);

  const onDrop = useCallback((file) => {
    const sendData = async () => {
      const formData = new FormData();
      formData.append('file', file);
      setInProgress(true);

      try {
        const { entities, errors } = await Request.post('/api/import/training-trainees', formData);
        setInProgress(false);
        if (entities && entities.length) {
          setIsCompleted(true);
          setFileName(file.name);
          if (errors && errors.length) {
            dispatch(warning(errors[0]));
          } else {
            dispatch(success('File successfully imported'));
          }

          const trainees = {};
          for (const entity of entities) {
            trainees[entity.id] = {
              id: entity.id,
              lastNameShort: entity.last_name.substr(0, 1) + '.',
              firstName: entity.first_name,
              idNumber: entity.id_number,
              company: entity.company,
            };
          }
          setTrainees(trainees);
        } else {
          if (errors && errors.length) {
            dispatch(danger(errors[0]));
          } else {
            dispatch(danger('Nothing imported'));
          }
        }
      } catch (e) {
        setInProgress(false);
        dispatch(danger('Server error'));
      }

    };

    sendData();
  }, []);

  return (
    <>
      <input
        id="upload-button"
        accept=".csv"
        type="file"
        autoComplete="off"
        tabIndex="-1"
        maxsize="1048576"
        style={{ display: 'none' }}
        onChange={({ target: { files } }) => {
          if (!files.length) return;
          onDrop(files[0]);
        }}
      />
      <button
        className={`${!inProgress ? 'import-list__btn' : ''} btn-param`}
        onClick={() => {
          document.getElementById('upload-button').click();
        }}
        disabled={isCompleted}
      >{
          inProgress
            ? 'Processing...'
            : (isCompleted ? fileName : 'Import trainee list')
        }</button>
    </>
  );
}

ImportTrainees.propTypes = {
  setTrainees: PropTypes.func,
};

export default ImportTrainees;
