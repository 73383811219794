import React from "react";
import { NavLink } from "react-router-dom";
import TextInput from "./../common/textInput.jsx";
import TextArea from "./../common/textarea.jsx";
import EntityRepository from "../../repositories/EntityRepository";
import ErrorRepository from "../../repositories/ErrorRepository";
import { connect } from "react-redux";
import { success, danger } from "../../redux/actions/notify";
import AgreeChanges from "../modals/modal-agree-changes";

class AtaCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ataData: {
        code: "",
        description: "",
      },
      errors: {},
      modalRevision: false,
      agreeModal: false,
      changes: false,
    };
  }

  confirmAction = async (event) => {
    event.preventDefault();
    let data = this.state.ataData;
    try {
      let response = await EntityRepository.createEntity("api/atas/create", data);
      if (response.create) {
        this.props.success("Ata successfully created");
        this.props.history.push({
          pathname: "/settings/atas",
          reference: response.ata,
        });
      } else {
        this.setState({ errors: response.errors });

      }
    } catch (e) {
      this.props.danger(e.errors.code);
    }
  };

  handleChange = (e) => {
    this.setState({ changes: true });
    const { name, value } = e.target;
    this.clearErrorField(name);

    this.setState({
      ataData: {
        ...this.state.ataData,
        [name]: value,
      },
    });
  };

  clearErrorField = (fieldName) => {
    const { errors } = this.state;
    if (errors[fieldName] && errors[fieldName] != "") {
      this.setState({ errors: { ...errors, [fieldName]: "" } });
    }
  };

  goBack = (event) => {
    event.preventDefault();
    const { code, description } = this.state.ataData;

    if (code.trim() == "" && description.trim() == "") {
      this.setState({ changes: false });
      return this.props.history.push("/settings/atas");
    }

    if (this.state.changes) {
      return this.setState({ agreeModal: true });
    }
  };

  confirmAgree = () => {
    this.setState({ agreeModal: false, changes: false });
    return this.props.history.push("/settings/atas");
  };

  cancelAgree = () => {
    return this.setState({ agreeModal: false });
  };

  render() {
    const { errors, agreeModal } = this.state;
    return (
      <>
        <section className="create-new-user users-edit-wrapper">
          <div className="container">
            <ul className="users-edit__breadcrumb">
              <li>
                <NavLink to={"/settings/atas"}>ATA</NavLink>
              </li>
              <li className="active">
                <span>New ATA</span>
              </li>
            </ul>
            <div className="create-new-user__wrapper">
              <div className="create-new-user__top-box">
                <div className="create-new-user__badge">
                  <span>new</span>
                </div>
                <div className="users-edit__btn-box"></div>
              </div>
              <div className="create-new-user__board">
                <form
                  className="create-new-user__form ata-form"
                  id={"new-ata-form"}
                >
                  <div className="create-new-user__board-wrapper">
                    <div
                      className={
                        "icon-box" +
                        ErrorRepository.getErrorClassName(errors, "code")
                      }
                    >
                      <TextInput
                        inputName={"code"}
                        inputLabel={"ATA code"}
                        errors={errors}
                        changeInput={this.handleChange}
                      />
                    </div>

                    <div
                      className={
                        "icon-box ata-descr-box" +
                        ErrorRepository.getErrorClassName(errors, "description")
                      }
                    >
                      <TextArea
                        id={"create-ata-descr"}
                        label={"Description"}
                        inputName={"description"}
                        onChange={this.handleChange}
                        errors={errors}
                      />
                    </div>
                  </div>
                  <div className="create-new-user__buttons">
                    <button
                      className="cancel-btn btn-param"
                      onClick={this.goBack}
                    >
                      Cancel
                    </button>
                    <button
                      className="create-btn btn-param"
                      onClick={this.confirmAction}
                    >
                      Create
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        {agreeModal ? (
          <AgreeChanges
            cancelAgree={this.cancelAgree}
            confirmAgree={this.confirmAgree}
          />
        ) : (
          ""
        )}
      </>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    success: (message) => dispatch(success(message)),
    danger: (message) => dispatch(danger(message)),
  };
}
export default connect(null, mapDispatchToProps)(AtaCreate);
