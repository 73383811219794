import axios from 'axios';
import authService from '../services/auth-service.js';
import Response from './response.js';

class Request {
  static async request(url, method = 'get', data) {
    try {
      const response = await axios.request({
        url,
        method,
        data,
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          ...authService.getHeader(),
        },
      });

      return response.data;
    } catch (error) {
      const { status } = error.response;

      if (status === Response.HTTP_UNAUTHORIZED) {
        authService.logout();
        location.reload(true);
      } else if (status === Response.HTTP_UNPROCESSABLE_ENTITY) {
        return error.response.data;
      } else if (status === Response.HTTP_BAD_REQUEST || status === Response.HTTP_INTERNAL_SERVER_ERROR) {
        throw error.response.data;
      }

      throw error;
    }
  }

  static async get(url) {
    return Request.request(url, 'get');
  }

  static async post(url, data) {
    return Request.request(url, 'post', data);
  }

  static async put(url, data) {
    return Request.request(url, 'put', data);
  }

  static async delete(url) {
    return Request.request(url, 'delete');
  }
}

export default Request;
