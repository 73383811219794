import Request from "../../../helpers/request";
import React from "react";
import { NavLink } from "react-router-dom";
import TextInput from "../../common/textInput.jsx";
import DatePicker from "react-datepicker";
import EntityRepository from "../../../repositories/EntityRepository.js";
import ErrorRepository from "../../../repositories/ErrorRepository.js";
import ModalArchiving from "../../common/modal-archiving.jsx";
import TraineeTrainings from "./trainee-trainings.jsx";
import { ACTION_ARCHIVE, ACTION_RESTORE } from "../../common/constants.js";
import { connect } from "react-redux";
import { hide, show } from "../../../redux/actions/loader";
import { success, danger } from "../../../redux/actions/notify";
import ButtonSinglePdf from "../../common/table/button-single-pdf";
import AgreeChanges from "../../modals/modal-agree-changes";
import date from "@/helpers/date";
import {ROLE_PRODUCT_MANAGER, ROLE_QUALITY_MANAGER} from "@/helpers/role";
import {Tooltip} from "@mui/material";

class TraineeUpdate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: Number(props.match.params.id),
      formData: {
        id: null,
        first_name: "",
        last_name: "",
        birth_date: "",
        birth_place: "",
        id_number: "",
        email: "",
        password: "",
        company: "",
      },
      modalArchivingConfig: {
        showModal: false,
      },
      errors: [],
      agreeModal: false,
      changes: false,
    };
  }


  updateUser = async (event) => {
    event.preventDefault();
    let data = this.state.formData;
    data.birth_date = data.birth_date.toLocaleString();
    let response = await EntityRepository.updateEntity(
      "/api/trainees",
      this.state.id,
      data
    );
    if (response.update === true) {
      this.props.success("User successfully saved");
      this.props.history.push("/settings/users/trainee");
    } else {
      this.setState((state) => ({ ...state, errors: response.errors }));
      this.props.danger("Server error");
    }
  };

  setUserData = (data) => {
    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        id: data.id,
        first_name: data.first_name || "",
        last_name: data.last_name || "",
        birth_date: data.birth_date || "",
        birth_place: data.birth_place || "",
        id_number: data.id_number || "",
        email: data.email || "",
        company: data.company || "",
        is_archived: data.is_archived,
      },
    }));
  };

  async componentDidMount() {
    this.props.loaderShow();
    const formData = await Request.get(`/api/users/${this.state.id}`);
    this.setUserData(formData);
    this.props.loaderHide();
  }

  getSingleUrl = () => {
    return `/api/trainees/generate-pdf?id=${this.state.id}`
  };

  clearErrorField = (fieldName) => {
    const { errors } = this.state;
    if (errors[fieldName] && errors[fieldName] != "") {
      this.setState({ errors: { ...errors, [fieldName]: "" } });
    }
  };

  setFormField = (name, value) => {
    this.clearErrorField(name);
    this.setState({
      changes: true,
      formData: { ...this.state.formData, [name]: value },
    });
  }

  handleChange = ({ target: { name, value } }) => this.setFormField(name, value);

  changeIdNumber = ({ target: { name, value } }) => this.setFormField(name, value.toUpperCase());

  dateChange = (date) => this.setFormField('birth_date', date);

  openModalArchiving = () => {
    const { formData } = this.state;
    let action = ACTION_ARCHIVE;
    let eventTitle;

    if (formData.is_archived) {
      action = ACTION_RESTORE;
      eventTitle = "Restoring";
    } else {
      action = ACTION_ARCHIVE;
      eventTitle = "Archiving";
    }

    if (formData.id !== undefined) {
      this.setState({
        modalArchivingConfig: {
          showModal: true,
          popupName: "user",
          entityName: `${formData.last_name} ${formData.first_name}`,
          entityTitles: [formData.id_number],
          selectedIds: [formData.id],
          eventTitle,
          action,
        },
      });
    }
  };

  confirmModaArchiving = async () => {
    const { selectedIds, action } = this.state.modalArchivingConfig;

    if (action === ACTION_ARCHIVE) {
      const response = await EntityRepository.archiveEntities(
        "/api/users",
        selectedIds
      );
      if (response.archived === true) {
        this.props.history.push("/settings/users/trainee");
      }
    } else if (action === ACTION_RESTORE) {
      const response = await EntityRepository.restoreEntities(
        "/api/users",
        selectedIds
      );
      if (response.restored === true) {
        this.props.history.push("/settings/users/trainee");
      }
    }
    this.cancelModaArchiving();
  };

  cancelModaArchiving = () => {
    this.setState({
      modalArchivingConfig: {
        showModal: false,
        entityName: "",
        entityTitles: [],
        selectedIds: [],
      },
    });
  };

  goBack = (event) => {
    event.preventDefault();

    if (this.state.changes) {
      return this.setState({ agreeModal: true });
    }

    return this.props.history.push("/settings/users/trainee");
  };

  confirmAgree = () => {
    this.setState({ agreeModal: false, changes: false });
    return this.props.history.push("/settings/users/trainee");
  };

  cancelAgree = () => {
    return this.setState({ agreeModal: false });
  };

  render() {
    const { id, errors, modalArchivingConfig, formData, agreeModal } = this.state;
    const { user } = this.props;

    return (
      <section className="edit-trainee__wrapper users-edit-wrapper">
        <div className="container">
          <div className="users-edit-head__inner">
            <ul className="users-edit__breadcrumb">
              <li>
                <NavLink exact to={"/settings/users"}>
                  User
                </NavLink>
              </li>
              <li>
                <NavLink exact to={"/settings/users/trainee"}>
                  Trainee
                </NavLink>
              </li>
              <li className="active">
                <span>{`${formData.first_name} ${formData.last_name}`}</span>
              </li>
            </ul>
          </div>

          <div className="create-new-user__wrapper">
            <div className="create-new-user__top-box">
              <div className="create-new-user__badge">
                <span>editing</span>
              </div>
              <div className="users-edit__btn-box">
                {/*<ButtonSinglePdf url={`/api/trainees/${id}/generate-training-pdf`} className='save-file-btn' />*/}
                <Tooltip title={"Archive"}>
                  <button
                    className="add-archive-btn"
                    onClick={this.openModalArchiving}
                  >
                  <span
                    className={
                      this.state.formData.is_archived
                        ? "restore-archive"
                        : "add-archive"
                    }
                  ></span>
                  </button>
                </Tooltip>
              </div>
            </div>
            <div className="create-new-user-dash-wrapper">
              <div className="create-new-user-form__wrapper">
                <form className="create-new-user-form">
                  <div className="create-new-user__board-wrapper">
                    <div
                      className={
                        "icon-box" +
                        ErrorRepository.getErrorClassName(errors, "first_name")
                      }
                    >
                      <TextInput
                        inputName={"first_name"}
                        inputValue={this.state.formData.first_name}
                        inputLabel={"First Name"}
                        changeInput={this.handleChange}
                        errors={errors}
                      />
                    </div>
                    <div
                      className={
                        "icon-box" +
                        ErrorRepository.getErrorClassName(errors, "last_name")
                      }
                    >
                      <TextInput
                        inputName={"last_name"}
                        inputValue={this.state.formData.last_name}
                        inputLabel={"Last Name"}
                        changeInput={this.handleChange}
                        errors={errors}
                      />
                    </div>
                    <div
                      className={`icon-box create-user-birth${ErrorRepository.getErrorClassName(
                        errors,
                        "birth_date"
                      )}`}
                    >
                      <label htmlFor="edit-trainee-birth">Date of birth</label>
                      <DatePicker
                        selected={Date.parse(this.state.formData.birth_date)}
                        onChange={this.dateChange}
                        showYearDropdown
                        yearDropdownItemNumber={15}
                        maxDate={new Date()}
                        dateFormat={date.inputDateFormat}
                      />
                      <span className="wrror-masage">
                        This field is incorrect
                      </span>
                    </div>
                    <div
                      className={
                        "icon-box" +
                        ErrorRepository.getErrorClassName(errors, "birth_place")
                      }
                    >
                      <TextInput
                        inputName={"birth_place"}
                        inputValue={this.state.formData.birth_place}
                        inputLabel={"Place of birth"}
                        changeInput={this.handleChange}
                        errors={errors}
                      />
                    </div>
                    <div
                      className={
                        "icon-box" +
                        ErrorRepository.getErrorClassName(errors, "id_number")
                      }
                    >
                      <TextInput
                        inputName={"id_number"}
                        inputValue={this.state.formData.id_number}
                        inputLabel={"ID number"}
                        changeInput={this.changeIdNumber}
                        errors={errors}
                      />
                    </div>
                    <div
                      className={
                        "icon-box" +
                        ErrorRepository.getErrorClassName(errors, "email")
                      }
                    >
                      <TextInput
                        inputName={"email"}
                        inputValue={this.state.formData.email}
                        inputLabel={"Email address"}
                        changeInput={this.handleChange}
                        errors={errors}
                      />
                    </div>
                    <div
                      className={
                        "icon-box" +
                        ErrorRepository.getErrorClassName(errors, "password")
                      }
                    >
                      <TextInput
                        inputName={"password"}
                        inputLabel={"Password"}
                        inputType={user.id !== id ? "text" : "password"}
                        changeInput={this.handleChange}
                        errors={errors}
                      />
                    </div>
                    <div
                      className={`icon-box ${ErrorRepository.getErrorClassName(
                        errors,
                        "email"
                      )}`}
                    >
                      <TextInput
                        inputName="company"
                        inputLabel="Company"
                        inputValue={this.state.formData.company}
                        changeInput={this.handleChange}
                        errors={errors}
                      />
                    </div>
                  </div>
                  <div className="create-new-user__buttons">
                    <button
                      className="cancel-btn btn-param"
                      onClick={this.goBack}
                    >
                      Cancel
                    </button>
                    <button
                      className="save-btn btn-param"
                      disabled={user.roles.includes(ROLE_QUALITY_MANAGER) || user.roles.includes(ROLE_PRODUCT_MANAGER)}
                      onClick={this.updateUser}
                    >
                      Save
                    </button>
                  </div>
                </form>
                <TraineeTrainings />
              </div>
            </div>
          </div>
          {modalArchivingConfig.showModal ? (
            <ModalArchiving
              config={modalArchivingConfig}
              confirmPopup={this.confirmModaArchiving}
              cancelPopup={this.cancelModaArchiving}
            />
          ) : null}
        </div>
        {agreeModal ? (
          <AgreeChanges
            cancelAgree={this.cancelAgree}
            confirmAgree={this.confirmAgree}
          />
        ) : (
          ""
        )}
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.authReducer.user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    success: (message) => dispatch(success(message)),
    danger: (message) => dispatch(danger(message)),
    loaderShow: () => dispatch(show()),
    loaderHide: () => dispatch(hide()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TraineeUpdate);
