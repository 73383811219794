import React, {useState} from 'react';
import Request from '@/helpers/request';
import Table from '@/components/common/training-pdf/table';
import {BlobProvider} from '@react-pdf/renderer';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import {Tooltip} from "@mui/material";


// eslint-disable-next-line react/prop-types
const Pdf = ({buttonClass, trainee = null, training, isTrainee, styles = {}}) => {
  const [data, setData] = useState(null);
  const [requesting, setRequesting] = useState(false);
  let url = `/api/trainings/${training}/trainees/${trainee}/generate-pdf?isTrainee=${isTrainee}`;

  if (!trainee) {
    url = `/api/trainings/${training}/generate-pdf`;
  }

  async function fetchData() {
    setRequesting(true);
    Request.get(url).then(res => {
      setData(res);
      setRequesting(false);
    }).catch(() => {
      setRequesting(false);
    });
  }

  if (data && !requesting) {
    return <>
      {data && (
        <BlobProvider document={<Table data={data} trainee={trainee} training={training} isTrainee={isTrainee}/>}>
          {({url, loading, error}) => {
            if (!loading && url) {
              return (
                <a href={url} target="_blank" rel="noreferrer">
                  ouvrir
                </a>
              );
            }
            if (error) {
              return <p>An error occurred</p>;
            }

            return (
              <span>
                <FontAwesomeIcon icon={faSpinner} spin/> creation...
              </span>);
          }}
        </BlobProvider>
      )}
    </>;
  } else if (!data && !requesting) {
    return <>
      <Tooltip title={"Download pdf"}>
        <button className={buttonClass} style={styles} onClick={fetchData}>
          <span></span>
        </button>
      </Tooltip>
    </>;
  } else if (!data && requesting) {
    return (
      <span>
        <FontAwesomeIcon icon={faSpinner} spin/> creation...
      </span>);
  }

  return <></>;
};

export default Pdf;
