import React, { useEffect, useState } from 'react';
import Request from '../../../../../helpers/request.js';
import { useSyllabus } from '../common/syllabus-context';
import date from '../../../../../helpers/date';

function Revisions() {
  const syllabus = useSyllabus();
  const [revisions, setRevisions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await Request.get(
        `/api/syllabi/${syllabus.id}/revisions`
      );
      setRevisions(result);
    };

    if (syllabus && syllabus.id) fetchData();

  }, [syllabus]);

  return (
    <div className="syllabus-changes__wrapper">
      <div className="syllabus-changes__title">List of revisions</div>
      <div className="syllabus-changes__inner scrollbar-style">
        <table className="users-table__data">
          <thead className="users-table__thead">
            <tr>
              <th>Version</th>
              <th>Date</th>
              <th>Reason of change</th>
            </tr>
          </thead>
          <tbody className="users-table__tbody">
            {
              revisions.map((revision, index) => (
                <tr key={index}>
                  <td>
                    <span>{('0' + revision.version).slice(-2)}</span>
                  </td>
                  <td>
                    <span>{date.getNumberFormatted(revision.date)}</span>
                  </td>
                  <td>
                    <span>{revision.reason_for_change}</span>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Revisions;
