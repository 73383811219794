import React, { useEffect } from "react";
import { useTable } from "./table-context";

const TableCheckbox = ({ id = null }) => {
  const { state, dispatch } = useTable();

  useEffect(() => {
    dispatch({
      type: 'addId',
      id
    });

    return () => {
      dispatch({
        type: 'deleteId',
        id
      });
      dispatch({
        type: 'deleteSelectedId',
        id
      });
    };
  }, [state.ids.has(id)]);

  return (
    <label className="table-checkbox">
      <input
        type="checkbox"
        checked={state.selectedIds.has(id)}
        onChange={({ target: { checked } }) => {
          if (checked) {
            dispatch({
              type: 'addSelectedId',
              id
            });
          } else {
            dispatch({
              type: 'deleteSelectedId',
              id
            });

            if (state.isAllChecked) {
              dispatch({
                type: 'setIsAllChecked',
                checked: false,
              });
            }
          }
        }}
      />
      <span className="table-custom-checkbox"></span>
    </label>
  );
};

export default TableCheckbox;
