import React, {useState} from "react";
import Interface from '../common/interface.jsx';
import Dashboard from '../common/dashboard.jsx';
import TableSidebar from '../common/table/table-sidebar.jsx';
import TrainingsTable from "./training-table.jsx";

function Trainings({ match }) {
  const { url } = match;
  const [importResult, setImportResult] = useState({
    inProgress: false,
    processed: false,
    importedCount: 0,
    totalCount: 0,
  });
  return (
    <Interface className="training-interface">
      <TableSidebar
        importResult={importResult}
        setImportResult={setImportResult}
        content={{
          infoTitle: 'Training',
          createTitle: 'Add new training',
          createButtonUrl: `${url}/create`,
          importUrl: '/api/import/trainings',
      }}/>
      <Dashboard title="TRAINING LIST">
        <TrainingsTable importResult={importResult}/>
      </Dashboard>
    </Interface>
  );
}

export default Trainings;
