import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  title: {
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    width: '100%',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 20,
  },
  header: {
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    fontSize: 11,
    width: '50%',
    textAlign: 'center',
  },
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginTop: 10,
    padding: 4,
    alignContent: 'center',
    justifyContent: 'center',
  },
  td: {
    fontSize: 11,
    width: '50%',
    textAlign: 'center',
    borderWidth: 1,
    borderTop: 'none',
    borderStyle: 'solid',
    borderColor: '#000000',
    height: '100%',
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 2,
    paddingLeft: 2,
  },
});

const AtaSummary = (props) => {
  {/* eslint-disable-next-line react/prop-types */}
  const { data } = props;

  return (
    <Fragment>
      <View style={styles.row}>
        <Text style={styles.title}>ATA summary table: </Text>
      </View>
      <View style={styles.tableContainer}>
        <Text style={styles.header}>AT</Text>
        <Text style={styles.header}>MT</Text>
      </View>
      <View style={{ flexDirection: 'row' }}>
        {/* eslint-disable-next-line react/prop-types */}
        <Text style={styles.td}>{ data[0].AT }</Text>
        {/* eslint-disable-next-line react/prop-types */}
        <Text style={styles.td}>{ data[0].MT }</Text>
      </View>
    </Fragment>
  );
};

export default AtaSummary;
