import React, {useState} from 'react';
import { useSelector } from 'react-redux';
import Interface from '../common/interface.jsx';
import Dashboard from '../common/dashboard.jsx';
import StaffTable from './staff/staff-table.jsx';
import TraineeTable from './trainee/trainee-table.jsx';
import {
  Switch,
  Redirect,
  Route,
  useRouteMatch
} from 'react-router-dom';
import TableSidebar from '../common/table/table-sidebar.jsx';
import {
  ROLE_ADMIN,
  ROLE_PRODUCT_MANAGER,
  ROLE_QUALITY_MANAGER,
  ROLE_INSTRUCTOR
} from '@/helpers/role';

function Users() {
  // The `path` lets us build <Route> paths that are
  // relative to the parent route, while the `url` lets
  // us build relative links.
  const { path, url } = useRouteMatch();
  const user = useSelector(state => state.authReducer.user);
  const { roles } = user;
  const [importResult, setImportResult] = useState({
    inProgress: false,
    processed: false,
    importedCount: 0,
    totalCount: 0,
  });

  const sidebarContent = {
    infoTitle: 'User',
    createTitle: 'Add new user',
    createButtonUrl: '',
    importUrl: '/api/import/users',
  };

  const routes = [];
  const tabs = [];

  if (roles.includes(ROLE_ADMIN) || roles.includes(ROLE_PRODUCT_MANAGER) || roles.includes(ROLE_QUALITY_MANAGER)) {
    routes.push({
      path: `${path}/staff`,
      exact: true,
      sidebar: () => <TableSidebar
        importResult={importResult}
        setImportResult={setImportResult}
        content={{
          ...sidebarContent,
          createButtonUrl:
            roles.includes(ROLE_ADMIN)
              ? `${path}/staff/create`
              : null,
        }} />,
      main: () => <StaffTable importResult={importResult}/>,
    });
    tabs.push({ title: 'Staff', url: `${url}/staff` });
  }

  routes.push({
    path: `${path}/trainee`,
    exact: true,
    sidebar: () => <TableSidebar
      importResult={importResult}
      setImportResult={setImportResult}
      content={{
        ...sidebarContent,
        createButtonUrl:
          roles.includes(ROLE_ADMIN)
            ? `${path}/trainee/create`
            : null,
      }} />,
    main: () => <TraineeTable importResult={importResult}/>,
  });
  tabs.push({ title: 'Trainee', url: `${url}/trainee` });

  return (
    <Interface>
      <Switch>
        {routes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            children={<route.sidebar />}
          />
        ))}
        <Route exact path={path}>
          <Redirect to={`${path}/staff`} />
        </Route>
      </Switch>
      <Dashboard tabs={tabs}>
        <Switch>
          {routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              children={<route.main />}
            />
          ))}
          <Route exact path={path}>
            <Redirect to={routes[0].path} />
          </Route>
        </Switch>
      </Dashboard>
    </Interface>
  );
}

export default Users;
