import React, { useEffect, useState } from "react";
import date from '../../../../../helpers/date';
import Request from '../../../../../helpers/request';

function ModalRevision({
  syllabusId,
  onCancel = () => {},
}) {

  const [revisions, setRevisions] = useState([]);
  const [selectedRevision, setSelectedRevision] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const result = await Request.get(
        `/api/syllabi/${syllabusId}/revisions`
      );
      setSelectedRevision(result[0] || {});
      setRevisions(result);
    };

    if (syllabusId) fetchData();

    const cssClassOpenModal = 'open-modal';
    if (!document.body.classList.contains(cssClassOpenModal)) {
      document.body.classList.add(cssClassOpenModal);
    }

    return () => {
      document.body.classList.remove(cssClassOpenModal);
    };
  }, [syllabusId]);

  const changeRevision = (e) => {
    const { value: selectedId } = e.target;

    for (const revision of revisions) {
      if (revision.id == selectedId) {
        return setSelectedRevision(revision);
      }
    }
  };

  return (
    <div className="overlay">
      <div className="modal-act-element version-modal">
        <div className="modal-act-element__wrapper">
          <div className="modal-act-element__head">
            <div className="modal-act-element__title">
              <div className="modal-archiving-element__icon">
                <span></span>
              </div>
              <div>
                <div className="modal-act-element__title">Revision commentary</div>
              </div>
            </div>
            <div className="modal-act-element__sesion-date">
              <span>{date.getFormatted()}</span>
            </div>
          </div>
          <div className="modal-descr__wrapper">
            <div className="icon-box version-number__box">
              <label htmlFor="ata-version-number">Select version</label>
              <select id="ata-version-number" onChange={changeRevision}>
                {revisions.map((item, index) => (
                   <option value={item.id} key={index}>{`Version ${('0' + item.version).slice(-2)}`}</option>
                ))}
              </select>
            </div>
            <div className="version-create-inform__box">
              <div className="icon-box version-create-inform__inner">
                <label>Author</label>
                <div className="version-create__author">
                  <span>{selectedRevision.author_name}</span>
                </div>
              </div>
              <div className="icon-box version-create-inform__inner">
                <label htmlFor="version-ata-create-date">Date</label>
                <input
                  id="version-ata-create-date"
                  type="text"
                  value={date.getFormatted(selectedRevision.date)}
                  disabled
                />
              </div>
            </div>
            <div className="version-descr__box">
              <label>Commentary</label>
              <div className="version-descr__inner">
                <div className="textarea-wrapper">
                  <textarea
                    id="ata-version-descr"
                    value={selectedRevision.commentary}
                    disabled
                    >
                  </textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-act-element__btn">
          <button className="back__btn btn-param" onClick={() => onCancel()}>
            Thanks, go back
            </button>
        </div>
      </div>
    </div>
  );
}

export default ModalRevision;
