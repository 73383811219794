import React from 'react';
import Part1Row from './part1-row.jsx';
import PubSub from 'pubsub-js';
import { connect } from 'react-redux';
import { hide, show } from '../../../../../redux/actions/loader';
import EntityRepository from '../../../../../repositories/EntityRepository.js';
import { GET_LIST_REQUEST_PARAMS } from '../../../../common/constants';
import ValidateTaskModal from '../../validations/common/validate-task-modal';
import TaskStatus from '../../validations/common/filters/filter-task-status';
import TaskCategory from '../../validations/common/filters/filter-task-category';
import Request from '@/helpers/request';

class Part1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      trainingDateRange: {},
      isLoading: false,
      count: 0,
      trainingId: this.props.match.params.trainingId,
      traineeIds:
        this.props.location.state === undefined
          ? JSON.parse(localStorage.getItem('traineeIds'))
          : this.props.location.state.ids,
      traineeNames:
        this.props.location.state === undefined
          ? JSON.parse(localStorage.getItem('traineeNames'))
          : this.props.location.state.traineeNames,
      isOpen: false,
      validatedTasks: {},
      traineeTasks: [],
    };
    this.path = `/api/trainings/${this.state.trainingId}/validation-tasks`;
    this.requestParams = { ...GET_LIST_REQUEST_PARAMS, sort: 'ata' };
    localStorage.setItem('traineeIds', JSON.stringify(this.state.traineeIds));
    localStorage.setItem(
      'traineeNames',
      JSON.stringify(this.state.traineeNames)
    );

    this.loadFilterData = this.loadFilterData.bind(this);
    this.onScrollList = this.onScrollList.bind(this);
    this.renderRows = this.renderRows.bind(this);
    this.fetchValidatedTrainees = this.fetchValidatedTrainees.bind(this);
  }

  loadFilterData(field = 'like', value = '') {
    this.resetPagination();
    this.requestParams = { ...this.requestParams, [field]: value };
    this.loadData();
  }

  resetPagination() {
    this.requestParams = {
      ...this.requestParams,
      ...GET_LIST_REQUEST_PARAMS,
    };
  }

  onScrollList = async (event) => {
    const { isLoading, data, count } = this.state;
    const scrollBottom =
      Math.ceil((event.target.scrollHeight - event.target.scrollTop) - 100) <= event.target.clientHeight;
    const loadedCount = data.length;
    if (scrollBottom && !isLoading && loadedCount < count) {
      this.loadData();
    }
  };

  async loadData() {
    const { trainingId, traineeIds } = this.state;
    this.setState({ isLoading: true });
    const data = await EntityRepository.getEntities(
      this.path,
      this.requestParams
    );
    const traineeTasks = await Request.get(
      `/api/trainings/${trainingId}/trainees/${traineeIds[0]}/tasks`
    );
    this.setState({ traineeTasks });
    this.setState({ isLoading: false });

    this.setState({ trainingDateRange: {
      date_start: data.date_start,
      date_end: data.date_end,
    } });
    if (data && Array.isArray(data.items)) {
      this.setState((state) => {
        const { validatedTasks } = state;

        for (const item of data.items) {
          if (item.trainee_task) {
            validatedTasks[item.id] = item.trainee_task;
            delete item.trainee_task;
          }
        }

        return {
          ...state,
          validatedTasks,
          count: data.count,
          data: !this.requestParams.start ? data.items :
            [
              ...state.data,
              ...data.items,
            ],
        };
      });
      this.requestParams.start += this.requestParams.limit;
    }
  }

  async componentDidMount() {
    this.props.loaderShow();
    this.token = PubSub.subscribe(
      'Update Table',
      this.updateComponent.bind(this)
    );
    this.requestParams = {
      ...this.requestParams,
      traineeIds: this.state.traineeIds,
    };
    await this.loadData();
    this.props.loaderHide();
  }

  updateComponent() {
    this.loadData();
  }

  componentWillUnmount() {
    PubSub.unsubscribe(this.token);
  }

  async fetchValidatedTrainees(id, place) {
    let data = await EntityRepository.getEntity(
      'api/trainee-tasks',
      `${id}/validated/${place}?traineesIds=${JSON.stringify(
        this.state.traineeIds
      )}`
    );
    let trainees = '';
    data.map((item, i) => {
      trainees += `idNumber: ${item.id_number} name: ${item.first_name[0]}. ${item.last_name} \n`;
    });
    alert(trainees);
  }

  renderRows = () => {
    const { isLoading, data = [], validatedTasks, trainingDateRange, traineeTasks } = this.state;

    const rows = data.map((item, i) => {
      let classDate = null, airDate = null;
      traineeTasks.forEach(value => {
        if (item.id === value.id) {
          if (value.trainee_task && value.trainee_task.classroom_date) {
            classDate = value.trainee_task.classroom_date;
          }
          if (value.trainee_task && value.trainee_task.aircraft_date) {
            airDate = value.trainee_task.aircraft_date;
          }
        }
      });

      return (
        <Part1Row
          key={i}
          row={item}
          validatedTask={validatedTasks[item.id]}
          trainingDateRange={trainingDateRange}
          classroomDate={classDate}
          aircraftDate={airDate}
          setValidatedTaskField={this.setValidatedTaskField.bind(this, item.id)}
          changeCheckBox={this.changeCheckBox.bind(this, item.id)}
          fetchValidatedTrainees={this.fetchValidatedTrainees}
        />
      );

    });

    if (isLoading) {
      const loadingRow = <tr key="loadingRow">
        <td colSpan="6">
          <i className="fa fa-spin fa-circle-o-notch"></i>
        </td>
      </tr>;

      if (this.requestParams.start) {
        rows.push(loadingRow);
      } else {
        return loadingRow;
      }
    }

    return rows;
  }

  setValidatedTaskField(id, name, value) {
    const { validatedTasks } = this.state;

    if (!validatedTasks[id]) {
      validatedTasks[id] = {
        task: null,
        aircraft_date: null,
        classroom_date: null,
        is_aircraft_passed: false,
        is_classroom_passed: false,
        is_locked: false,
        is_validated: false,
      };
    }

    if (validatedTasks[id].hasOwnProperty(name)) {
      validatedTasks[id][name] = value;
    }

    this.setState((state) => ({ ...state, validatedTasks }));
  }

  changeCheckBox(id, name, checked) {
    const checkboxDateField = {
      is_classroom_passed: 'classroom_date',
      is_aircraft_passed: 'aircraft_date',
    };

    this.setValidatedTaskField(id, name, checked);
    this.setValidatedTaskField(id, checkboxDateField[name], checked ? new Date() : null);
  }

  checkedAll(e) {
    const { checked, name } = e.target;

    document.querySelectorAll(`.${name}`).forEach((item) =>
      this.changeCheckBox(Number(item.dataset.id), name, checked)
    );
  }

  async validateTasks() {
    const { validatedTasks, traineeIds, trainingId } = this.state;

    const requestData = {
      tasks: [],
      trainee_ids: traineeIds,
      training_id: parseInt(trainingId),
    };

    for (const [taskId, validatedTask] of Object.entries(validatedTasks)) {
      const item = {
        ...validatedTask,
        task_id: Number(taskId),
        trainee: undefined,
        is_validated: true,
      };
      requestData.tasks.push(item);
    }

    const response = await EntityRepository.createEntity(
      '/api/trainee-tasks/create',
      requestData
    );

    if (response) {
      this.props.loaderShow();
      PubSub.publish('Update Table', true);
      this.props.loaderHide();
      this.openPopup();
    }
  }

  cancelPopup() {
    const { trainingId } = this.props.match.params;
    this.setState({ isOpen: false });
    this.props.history.push(`/trainings/${trainingId}/trainees`);
  }

  openPopup() {
    this.setState({ isOpen: true });
  }

  render() {
    const { traineeNames, trainingId, isOpen } = this.state;

    return (
      <>
        <div className="validation-assessment__wrapper">
          <div className="syllabus-parts__wrapper">
            <div className="validation-tasks__inner">
              <div className="assessment-sheet__title">Task validation</div>
              <div className="validation-tasks__select-wrapper">
                <div className="validation-tasks__select-box">
                  <label>Select(ed) trainee(s)</label>
                  <div className="validation-tasks__select-item">
                    {traineeNames.map((traineeName, i) => (
                      <span className="student-bage" key={i}>
                        {traineeName}
                      </span>
                    )
                    )}
                  </div>
                </div>
              </div>

              <div className="trainee-details__activity-panel">
                <div className="data-search">
                  <i className="search-icon fa fa-search"></i>
                  <input
                    className="data-search__field"
                    type="text"
                    placeholder="ATA Search"
                    onChange={(e) => {
                      e.preventDefault;
                      this.loadFilterData('like', e.target.value);
                    }}
                  />
                </div>
                <div className="data-sort__box">
                  <span>Sort by</span>
                  <select
                    onChange={({ target }) =>
                      this.loadFilterData('sort', target.value)
                    }
                  >
                    <option value="ata">ATA code</option>
                    <option value="category">Task category</option>
                    <option value="code">Task code</option>
                  </select>
                </div>
                <div className="sort-panel">
                  <TaskCategory
                    parentClass={'data-search__buttons-filter mr-16'}
                    onFilter={this.loadFilterData.bind(this, 'category')}
                    options={[
                      {
                        className: 'active-users-btn',
                        title: 'mt',
                        value: 'mt',
                      },
                      {
                        className: 'archived-users-btn',
                        title: 'at',
                        value: 'at',
                      },
                      {
                        className: 'all-users-btn',
                        title: 'all',
                        value: 'all',
                        isActive: true,
                      },
                    ]}
                  />
                  <TaskStatus
                    parentClass={'data-search__buttons-filter'}
                    onFilter={this.loadFilterData.bind(this, 'status')}
                    options={[
                      {
                        className: 'completed-users-btn',
                        title: 'validated',
                        value: 'validated',
                      },
                      {
                        className: 'unfinished-users-btn',
                        title: 'remaining',
                        value: 'remaining',
                      },
                      {
                        className: 'all-users-btn',
                        title: 'all',
                        value: 'all',
                        isActive: true,
                      },
                    ]}
                  />
                </div>
              </div>

              <div className="user-personal__table-inform">
                <div
                  className="users-table__wrapper"
                  onScroll={(event) => this.onScrollList(event)}
                  style={{ transition: 'ease 0.5s' }}
                >
                  <table className="users-table__data validation-tasks__table">
                    <thead className="users-table__thead">
                      <tr>
                        <th>ATA</th>
                        <th>№</th>
                        <th>Task category</th>
                        <th>Task code</th>
                        <th>Subject</th>
                        <th>Manual reference</th>
                        <th>
                          <div className="valodation-table__wrapper">
                            {/*<label className="table-checkbox">*/}
                            {/*  <input*/}
                            {/*    type="checkbox"*/}
                            {/*    id="is_classroom_passed"*/}
                            {/*    name="is_classroom_passed"*/}
                            {/*    onClick={this.checkedAll.bind(this)}*/}
                            {/*  />*/}
                            {/*  <span className="table-custom-checkbox"></span>*/}
                            {/*</label>*/}
                            <span>Classroom</span>
                          </div>
                        </th>
                        <th>
                          <div className="valodation-table__wrapper">
                            <span>Aircraft</span>
                            {/*<label className="table-checkbox">*/}
                            {/*  <input*/}
                            {/*    type="checkbox"*/}
                            {/*    id="is_aircraft_passed"*/}
                            {/*    name="is_aircraft_passed"*/}
                            {/*    onClick={this.checkedAll.bind(this)}*/}
                            {/*  />*/}
                            {/*  <span className="table-custom-checkbox"></span>*/}
                            {/*</label>*/}
                          </div>
                        </th>
                        <th>State</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody className="users-table__tbody">
                      {this.renderRows()}
                    </tbody>
                  </table>
                </div>
                <div className="valid-trainee__buttons">
                  <button
                    className="no-delete__btn btn-param"
                    onClick={(e) => {
                      e.preventDefault;
                      this.props.history.goBack();
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="validate-tasks__btn btn-param"
                    onClick={this.validateTasks.bind(this)}
                  >
                    Validate tasks
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isOpen ? (
          <ValidateTaskModal
            trainingId={trainingId}
            cancelPopup={this.cancelPopup.bind(this)}
          />
        ) : null}
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    showLoader: state.loaderReducer.showLoader,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loaderShow: () => dispatch(show()),
    loaderHide: () => dispatch(hide()),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Part1);
