import React, { createContext, useContext } from 'react';

const TrainingContext = createContext({});

export const TrainingProvider = ({ training, children }) =>(
  <TrainingContext.Provider value={training}>
    {children}
  </TrainingContext.Provider>
);

export const useTraining = () => useContext(TrainingContext);
