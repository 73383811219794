import React, { useCallback, useRef, useState } from 'react';
import Request from '@/helpers/request';
import { danger, success, warning } from '@/redux/actions/notify';
import { useDispatch } from 'react-redux';

// eslint-disable-next-line react/prop-types
const ImportRevision = ({ syllabus, setRevisions }) => {
  const inputEl = useRef(null);
  const dispatch = useDispatch();
  const [inProgress, setInProgress] = useState(false);

  const onDrop = useCallback((file, syllabusId) => {
    const sendData = async () => {
      const formData = new FormData();
      formData.append('file', file);
      setInProgress(true);
      const { entities, errors } = await Request.post(`/api/import/revisions/${syllabusId}`, formData);
      setInProgress(false);
      inputEl.current.value = '';
      if (entities && entities.length) {
        if (errors && errors.length) {
          setInProgress(false);
          dispatch(warning(errors[0]));
        } else {
          dispatch(success('File successfully imported'));
        }
        setRevisions(entities);
      } else {
        setInProgress(false);
        if (errors && errors.length) {
          dispatch(danger(errors[0]));
        } else {
          dispatch(danger('Nothing imported'));
        }
      }
    };

    sendData();
  }, []);

  return (
    <>
      <input
        ref={inputEl}
        accept=".csv"
        type="file"
        autoComplete="off"
        tabIndex="-1"
        maxsize="1048576"
        style={{ display: 'none' }}
        onChange={({ target: { files } }) => {
          if (!files.length) return;
          onDrop(files[0], syllabus);
        }}
      />
      <span
        className="import-revision"
        onClick={() => {
          inputEl.current.click();
        }}>{
          inProgress
            ? 'Processing...'
            : 'Import revisions'
        }</span>
    </>
  );
};
export default ImportRevision;
