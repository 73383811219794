import React, { useState, useEffect } from 'react';
import {
  Switch,
  useParams,
  Route,
  Redirect,
  useRouteMatch
} from 'react-router-dom';
import PrivateRoute from '@/components/router/private-route';
import Trainee from './trainees/trainee.jsx';
import TraineesList from './trainees/trainees-list.jsx';
import Validations from './validations/validations';
import Syllabus from './syllabus/syllabus';
import { TrainingProvider } from './common/training-context';
import EntityRepository from '../../../repositories/EntityRepository.js';
import {
  ROLE_ADMIN,
  ROLE_INSTRUCTOR,
  ROLE_PRODUCT_MANAGER
} from '@/helpers/role';

function Training() {
  const { path, url } = useRouteMatch();
  const { trainingId } = useParams();

  const [training, setTraining] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const result = await EntityRepository.getEntity('api/trainings', trainingId);
      setTraining({ ...result });
    };

    fetchData();
  }, []);

  return (
    <TrainingProvider training={training}>
      <Switch>
        <Route path={`${path}/syllabus`} component={Syllabus} />
        <Route path={`${path}/trainees/:traineeId/syllabus`} component={Syllabus} />
        <Route exact path={`${path}/trainees/:traineeId`} component={Trainee} />
        <PrivateRoute exact path={`${path}/trainees`} roles={[ROLE_ADMIN, ROLE_INSTRUCTOR, ROLE_PRODUCT_MANAGER]} component={TraineesList} />
        <PrivateRoute path={`${path}/validations`} roles={[ROLE_ADMIN, ROLE_INSTRUCTOR]} component={Validations} />
        <Route exact path={path}>
          <Redirect to={`${url}/trainees`} />
        </Route>
      </Switch>
    </TrainingProvider>
  );
}

export default Training;
