import React, { useState } from 'react';
import PropTypes from 'prop-types';

function SelectColor({
  usedColors = [],
  color: activeColor,
  onSelect = () => {},
}) {
  const COLORS = {
    '#C52A2A': 'Red color',
    '#5E9DBF': 'Blue color',
    '#FCB37E': 'Yellow color',
    '#59D262': 'Green color',
  };
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`custom-select ${isOpen ? 'active' : ''}`}>
      <div className="group-color-box" onClick={() => setIsOpen(!isOpen)}>
        <span className="group-color-name" style={{ color: activeColor || '#6C81A2' }}>{COLORS[activeColor] || 'Select a color'}</span>
        <span className="group-color-bage" style={{ backgroundColor: activeColor }}></span>
      </div>
      <div className="select__body">
        {
          Object.keys(COLORS)
            .filter(color => (color === activeColor || !usedColors.includes(color)))
            .map((color, index) => (
              <div
                key={index}
                className="select__item"
                onClick={() => {
                  onSelect(color);
                  setIsOpen(false);
                }}>
                <span
                  className="select__bage"
                  style={{ backgroundColor: color || '#dddddd' }}>
                </span>
              </div>
            ))
        }
      </div>
    </div>
  );
}

SelectColor.propTypes = {
  usedColors: PropTypes.array.isRequired,
  color: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
};

SelectColor.defaultProps = {
  usedColors: [],
  color: '#6C81A2',
};

export default SelectColor;
