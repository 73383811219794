import React from "react";

function Select({
  name = '',
  value,
  className = '',
  label = '',
  options = [],
  onChange = () => {},
  errors = {},
  disabled = false,
}) {


  return (
    <>
      <label htmlFor={name}>{label}</label>
      <select
        className={className}
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
      >
        {
          options.map(({ title, value }, index) => (
            <option value={value || ''} key={index}>{title}</option>
          ))
        }
      </select>
      {(errors[name] && errors[name] != '') ? (
        <span className="wrror-masage">{errors[name]}</span>
      ) : null}
    </>
  );
}

export default Select;
