import React from 'react';
import { ACTION_ARCHIVE, ACTION_RESTORE } from '@/components/common/constants';

const TableRowArchiveButton = ({ action = ACTION_ARCHIVE, ...rest }) => {
  let buttonTitle, buttonClassName;

  if (action === ACTION_RESTORE) {
    buttonTitle = 'Restore';
    buttonClassName = 'restore-archive';
  } else if (action === ACTION_ARCHIVE) {
    buttonTitle = 'Archive';
    buttonClassName = 'add-archive';
  }

  return (
    <button
      title={buttonTitle}
      className={buttonClassName}
      {...rest}
    ></button>
  );
};

export default TableRowArchiveButton;
