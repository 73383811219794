import React from 'react';

function Textarea(props) {
  const { errors = {}, inputName, disabled = false } = props;

  return (
    <>
      <label htmlFor={inputName}>{props.label}</label>
      <textarea
        className={props.inputClass}
        id={inputName}
        placeholder={'Enter text…'}
        name={inputName}
        required=""
        onChange={props.onChange}
        value={props.inputValue}
        disabled={disabled}
      />
      {errors[inputName] && errors[inputName] != '' ? (
        <span className="wrror-masage">{errors[inputName]}</span>
      ) : null}
    </>
  );
}

export default Textarea;
