import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PubSub from 'pubsub-js';
import EntityRepository from '../../../repositories/EntityRepository.js';
import ModalArchiving from '../modal-archiving.jsx';
import { ACTION_ARCHIVE, ACTION_RESTORE } from '../constants';
import TableMoreTooltip from './table-more-tooltip.jsx';
import { success } from '../../../redux/actions/notify';
import { useTable } from './table-context';

const defaultRenderThead = (title, index) => (<th key={index}>{title}</th>);

const TableBody = ({
  actions = [],
  children,
  overlay,
  onScroll,
  path,
  setOverlay,
  thead = [],
  renderThead = defaultRenderThead,
  lastThColSpan = 2,
  tableId = '',
}) => {
  const [showMoreTooltip, setShowMoreTooltip] = useState(false);
  const dispatch = useDispatch();
  const { state: tableState, dispatch: tableDispatch } = useTable();

  const sendData = async () => {
    const { selectedIds, action } = overlay;

    if (action === ACTION_ARCHIVE) {
      const response = await EntityRepository.archiveEntities(path, selectedIds);
      dispatch(success('Successfully archived'));
      return response.archived;
    } else if (action === ACTION_RESTORE) {
      const response = await EntityRepository.restoreEntities(path, selectedIds);
      dispatch(success('Successfully restored'));
      return response.restored;
    }

    return false;
  };

  const changeAllCheckboxes = (checked = false) => {
    tableDispatch({
      type: 'setIsAllChecked',
      checked,
    });

    tableDispatch({
      type: 'setSelectedIds',
      ids: checked ? tableState.ids : [],
    });
  };

  const cancelPopup = () => {
    setOverlay({
      showModal: false,
      entityName: '',
      selectedIds:  [],
      entityTitles: [],
    });
  };

  return (
    <>
      { showMoreTooltip ?
        <TableMoreTooltip setShowMoreTooltip={setShowMoreTooltip} actions={actions}/>
        : null
      }
      <div
        className="users-table__wrapper"
        onScroll={onScroll}
        style={{ transition: 'ease 0.5s' }}
      >
        <table className="users-table__data" id={tableId}>
          <thead className="users-table__thead">
            <tr>
              <th>
                <label className={'table-checkbox'}>
                  <input
                    type="checkbox"
                    checked={tableState.isAllChecked}
                    onChange={() => {
                      const newIsChecked = !tableState.isAllChecked;
                      changeAllCheckboxes(newIsChecked);
                    }}
                  />
                  <span className="table-custom-checkbox"></span>
                </label>
              </th>
              {
                thead.map(renderThead)
              }
              <th colSpan={lastThColSpan}>
                {
                  actions.length
                    ? (<button className="table__more-act-btn" onClick = { () => setShowMoreTooltip(true) }>
                      <i className="fa fa-ellipsis-h"></i>
                    </button>)
                    : null
                }
              </th>
            </tr>
          </thead>
          <tbody className="users-table__tbody">
            {children}
          </tbody>
        </table>
        {
          overlay.showModal ?
            <ModalArchiving
              config={overlay}
              confirmPopup={() => {
                const isOk = sendData();
                if (isOk) {
                  setTimeout(() => {
                    PubSub.publish('Update Table', true);
                    changeAllCheckboxes(false);
                  }, 3000);
                }
                cancelPopup();
              }}
              cancelPopup={cancelPopup}
            />
            : null
        }
      </div>
    </>
  );
};

export default TableBody;
