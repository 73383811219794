import Request from '../helpers/request';

class getEntityRepository {

  async archiveEntities(path, ids)
  {
    return Request.put(`${path}/archive`, ids);
  }

  async getEntities(path, filters = {})
  {
    return Request.get(`${path}?filters=${JSON.stringify(filters)}`);
  }

  async generatePdf(path, filters = {})
  {
    return Request.get(`${path}?filters=${JSON.stringify(filters)}`);
  }

  async generateSinglePdf(path, id)
  {
    return Request.get(`${path}/${id}`);
  }

  async requestRoles()
  {
    return Request.get('/api/users/creation-roles');
  }

  async getRelations(path, itemId)
  {
    return Request.post(`${path}/get-relations`, { itemId });
  }

  async sortingRelations(path, id, sortData)
  {
    return Request.post(`${path}${id}&sortData=${JSON.stringify(sortData)}`);
  }

  async getEntity(path, id)
  {
    return Request.get(`${path}/${id}`);
  }

  async removeEntity(path, id)
  {
    return Request.get(`${path}/${id}/delete`);
  }

  async restoreEntities(path, ids)
  {
    return Request.put(`${path}/restore`, ids);
  }

  async updateEntity(path, id, data)
  {
    return Request.put(`${path}/${id}`, JSON.stringify(data));
  }

  async createEntity(path, data)
  {
    return Request.post(`${path}`, JSON.stringify(data));
  }
}

export default new getEntityRepository();
