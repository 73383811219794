import React, { useReducer } from "react";
import { TableProvider, initialState } from "./table-context";
import tableReducer from "./table-reducer";

const Table = ({ children }) => {
  return (
    <TableProvider>
      <div className="staff-data__tabs-content">
        <div className="staff-data__tabs-item">
          {children}
        </div>
      </div>
    </TableProvider>
  );
};

export default Table;
