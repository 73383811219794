import Autosuggest from 'react-autosuggest';
import EntityRepository from "../../../repositories/EntityRepository.js";
import React, { useState } from "react";

/* --------------- */
/*    Component    */
/* --------------- */

function renderSuggestion(suggestion) {
    return (
        <span key={suggestion.id}>{suggestion.name}</span>
    );
}

function SearchCategories({ onSelect = () => {}, createCategory = () => {}}) {
    const [value, setValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [active, isActive] = useState(false)

    const loadSuggestions = async (value) => {
        const data = await EntityRepository.getEntities('/api/categories', { like: value });
        if (data && data.length){
            setSuggestions([...data]);
        }else{
          isActive(true)
        }
    };

    return (
        <Autosuggest
            theme={{
                // container: 'react-autosuggest__container',
                container:                'icon-box input-box',
                containerOpen:            'react-autosuggest__container--open',
                input:                    'react-autosuggest__input',
                inputOpen:                'react-autosuggest__input--open',
                inputFocused:             'react-autosuggest__input--focused',
                suggestionsContainer:     'react-autosuggest__suggestions-container',
                suggestionsContainerOpen: 'react-autosuggest__suggestions-container--open',
                suggestionsList:          'react-autosuggest__suggestions-list',
                suggestion:               'react-autosuggest__suggestion',
                suggestionFirst:          'react-autosuggest__suggestion--first',
                suggestionHighlighted:    'react-autosuggest__suggestion--highlighted',
                sectionContainer:         'react-autosuggest__section-container',
                sectionContainerFirst:    'react-autosuggest__section-container--first',
                sectionTitle:             'react-autosuggest__section-title',
            }}
            suggestions={suggestions}
            onSuggestionSelected={(event, { suggestion }) => {
                setValue('');
                onSelect(suggestion);
            }}
            onSuggestionsFetchRequested={({ value }) => loadSuggestions(value)}
            onSuggestionsClearRequested={() => setSuggestions([])}
            getSuggestionValue={(suggestion) => (suggestion.name)}
            renderSuggestion={renderSuggestion}
            inputProps={{
                id: 'category-search',
                placeholder: "Search by name…",
                value,
                onChange: (event, { newValue }) => {
                    setValue(newValue);
                },
            }}
            renderInputComponent={ inputProps => (
                <>
                    <label htmlFor="category-search">
                        Category
                    </label>
                    <input {...inputProps} />
                    <span className="add-category pointer" onClick={e => {
                      e.preventDefault
                      createCategory(value)
                      setValue('')
                      isActive(false)
                      }} style={{display : `${active ? 'block' : 'none'}`}}>add</span>
                </>
            )}
        />
    );

}

export default SearchCategories;
