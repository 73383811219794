import React, { useEffect, useState } from 'react';
import date from '../../../../../helpers/date';
import Request from '@/helpers/request';
import { useHistory } from 'react-router-dom';

// eslint-disable-next-line react/prop-types
const ModalNewSession = ({ onCancel, training, traineeId }) => {
  const history = useHistory();

  const [trainings, setTrainings] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      // eslint-disable-next-line react/prop-types
      const request = await Request.get(`/api/syllabi/syllabus-trainings/${training.syllabus_id}`);
      setTrainings(request);
    };
    if (!trainings) {
      fetchData();
    }
  }, [trainings]);

  const addToTraining = (id) => {
    setData({
      from: training.id,
      to: id,
      trainee: parseInt(traineeId),
    });

  };

  const sendData = async () => {
    const request = await Request.post('/api/trainings/add-trainee-to-new-session', data);
    if (request) {
      history.push(`/trainings/${data.to}/trainees`);
    }
  };

  return (
    <div className="overlay">
      <div
        className="modal-act-element__create-task scrollbar-style"
        id="add-trainees-modal"
      >
        <div className="modal-act-element__wrapper">
          <div className="modal-act-element__head">
            <div className="modal-act-element__title">
              <span className="trainees-modal__subtitle">
                  Select training
              </span>
            </div>
            <div className="modal-act-element__sesion-date">
              <span>{date.getFormatted()}</span>
            </div>
          </div>
          <div className="trainees-modal__wrapper">
            <div className="modal-list__title">Trainings list</div>
            <div className="users-edit-history-session__content">
              <div className="users-edit-history-session-table__wrapper">
                <div className="users-edit-table__inner">
                  <table
                    className="users-table__data"
                    id="trainee-list-modal"
                  >
                    <tbody className="users-table__tbody">
                      {
                        trainings && trainings.map((item, index) => (
                          <tr className="validate-task" key={index} onClick={() => addToTraining(item.id)}>
                            <td>{item.session_number}</td>
                            <td>{item.syllabus_title}</td>
                            <td>Trainees count: {item.trainees.length}</td>
                            <td>id: {item.id}</td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="create-new-user__buttons">
            <button
              className="cancel-btn btn-param"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              onClick={sendData}
              disabled={!data}
              className="save-btn btn-param"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalNewSession;
