import React from "react";
import IconCalendar from "../../common/icons/icon-calendar";
import date from "../../../helpers/date";

function StatsHeader({
  dateStart: initDateStart = null,
  dateEnd: initDateEnd = null,
}) {
  const dateEnd = initDateEnd ? new Date(initDateEnd) : new Date();
  let dateStart;
  if (!initDateStart) {
    const date = new Date();
    date.setMonth(date.getMonth() - 1);
    dateStart = date;
  } else {
    dateStart = new Date(initDateStart);
  }

  return (
    <div className="training-settings__wrapper">
      <div className="user-interface__subheader">
        <div className="container">
          <div className="user-interface__subheader-wrapper validation-assessment__subheader-wrapper">
            <div className="start-link__wrapper">
              <a className="start__link" href="#">
                stats
              </a>
            </div>
            <div className="session-period">
              <span className="calendar-icon">
                <IconCalendar />
              </span>
              <span className="date-from">{date.getShortFormatted(dateStart)}</span>
              <span className="date-to">{date.getShortFormatted(dateEnd)}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StatsHeader;
