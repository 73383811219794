export const NOTIFY = "NOTIFY";
export const HIDE = "HIDE";

export function notify(message, status = 'success') {
  return {
    type: NOTIFY,
    message,
    status,
  };
}

export function success(message) {
  return notify(message, 'success');
}

export function warning(message) {
  return notify(message, 'warning');
}

export function danger(message) {
  return notify(message, 'danger');
}

export function hide() {
  return {
    type: HIDE,
  };
}
