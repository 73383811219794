import React from "react";
import { useLocation } from "react-router-dom";

const TableRowFlag = ({ id }) => {
  const { reference } = useLocation();

  return (<span className={`table-archive-flag ${reference && reference.id === id && 'table-archive-flag--new'}`}></span>);
};

export default TableRowFlag;
