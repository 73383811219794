import Autosuggest from 'react-autosuggest';
import EntityRepository from "../../../repositories/EntityRepository.js";
import React, { useState } from "react";

/* --------------- */
/*    Component    */
/* --------------- */

function renderSuggestion(suggestion) {
    return (
        <span key={suggestion.id}>{suggestion.first_name} {suggestion.last_name}</span>
    );
}

function SearchTrainees({ onSelect = () => {} }) {
    const [value, setValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);

    const loadSuggestions = async (value) => {
        const data = await EntityRepository.getEntities('/api/users', { like: value, users: 'trainee' });

        if (data.items && data.items.length){
            setSuggestions([...data.items]);
        }
    };

    return (
        <Autosuggest
            theme={{
                // container: 'react-autosuggest__container',
                container:                'icon-box search-box',
                containerOpen:            'react-autosuggest__container--open',
                input:                    'react-autosuggest__input',
                inputOpen:                'react-autosuggest__input--open',
                inputFocused:             'react-autosuggest__input--focused',
                suggestionsContainer:     'react-autosuggest__suggestions-container',
                suggestionsContainerOpen: 'react-autosuggest__suggestions-container--open',
                suggestionsList:          'react-autosuggest__suggestions-list',
                suggestion:               'react-autosuggest__suggestion',
                suggestionFirst:          'react-autosuggest__suggestion--first',
                suggestionHighlighted:    'react-autosuggest__suggestion--highlighted',
                sectionContainer:         'react-autosuggest__section-container',
                sectionContainerFirst:    'react-autosuggest__section-container--first',
                sectionTitle:             'react-autosuggest__section-title',
            }}
            suggestions={suggestions}
            onSuggestionSelected={(event, { suggestion }) => {
                setValue('');
                onSelect(suggestion);
            }}
            onSuggestionsFetchRequested={({ value }) => loadSuggestions(value)}
            onSuggestionsClearRequested={() => setSuggestions([])}
            getSuggestionValue={(suggestion) => (suggestion.username)}
            renderSuggestion={renderSuggestion}
            inputProps={{
                id: 'trainee-search',
                placeholder: "Search",
                value,
                onChange: (event, { newValue }) => {
                    if (newValue !== undefined) setValue(newValue);
                },
            }}
            renderInputComponent={ inputProps => (
                <>
                    <i className="search-icon fa fa-search"></i>
                    <input {...inputProps} />
                </>
            )}
        />
    );

}

export default SearchTrainees;
