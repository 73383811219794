import React, {useEffect} from 'react';
import date from '../../../helpers/date.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

function CreateRevisionModal({ close, handleChange, confirmAction, isDisable, comment, isFetch }) {
  if(comment.length > 0) {
    isDisable = false;
  }

  useEffect(() => {
  }, [isFetch]);

  return (
    <div className="overlay">
      <div className="modal-act-element__create-task modal-version scrollbar-style" id="modal-revision-comment">
        <div className="modal-act-element__wrapper">
          <div className="modal-act-element__head">
            <div className="modal-act-element__title">
                    Revision commentary
            </div>
            <div className="modal-act-element__sesion-date">
              <span>{date.getFormatted()}</span>
            </div>
          </div>
          <div className="modal-version__wraper">
            <label>Competence criteria</label>
            <div className="version-descr-wrapper">
              <div className="version-descr-inner">
                <textarea className="scrollbar-style" name="revision_comment" id="revision-comment" onChange={handleChange}></textarea>
              </div>
            </div>
          </div>
          <button className="no-delete__btn btn-param" onClick={close}>Thanks, go back</button>
          <button className="create-btn btn-param" onClick={confirmAction} disabled={isDisable}>
            { isFetch ?
              <span>
                <FontAwesomeIcon icon={faSpinner} spin /> request...
              </span>
              :
              'Create'
            }
          </button>
        </div>
      </div>
    </div>
  );
}

export default CreateRevisionModal;
