import React, { useRef } from 'react';
import {
  NavLink,
  useRouteMatch,
  useParams
} from 'react-router-dom';
import { useSyllabus } from '../common/syllabus-context';
import Slider from '@bit/akiran.react-slick.slider';

function SyllabusHeader() {
  const { url } = useRouteMatch();
  const { traineeId } = useParams();
  const syllabus = useSyllabus();
  const refSlider = useRef();
  const nextSlide = () => {
    const { current: slider } = refSlider;
    slider.slickNext();
  };

  const prevSlide = () => {
    const { current: slider } = refSlider;
    slider.slickPrev();
  };

  return (
    <div className="validation-assessment__head">
      <div className="validation-assessment__head-item">
        <div className="validation-assessment__company-logo">
          <img
            src="/adminApp/src/assets/img/dark-logo.svg"
            alt="Company logo"
          />
        </div>
        <div className="validation-assessment__head-inner">
          <div className="validation-assessment__head-title">
            Practical training element
          </div>
          <div className="validation-assessment__head-subtitle">
            {syllabus.course_reference_number}
          </div>
        </div>
      </div>
      <div className="validation-assessment__head-item head-item-right">
        <div className="users-edit-head__buttons">
          <button className="prev-user__btn" onClick={prevSlide}>
            <i className="fa fa-angle-left"></i>
          </button>
          <button className="next-user__btn" onClick={nextSlide}>
            <i className="fa fa-angle-right"></i>
          </button>
        </div>
        {/* user-interface__subheader-buttons */}
        <ul className="slick-container">
          <Slider
            ref={refSlider}
            {...{
              dots: false,
              infinite: true,
              speed: 500,
              slidesToShow: 3,
              slidesToScroll: 3,
              arrows: false,
              slide: 'li',
            }}
          >
            <li>
              <NavLink
                className="user-interface__subheader-btn"
                activeClassName="subheader-btn-active"
                to={`${url}/revisions`}
              >
                Revisions
              </NavLink>
            </li>
            <li>
              <NavLink
                className="user-interface__subheader-btn"
                activeClassName="subheader-btn-active"
                to={`${url}/introduction`}
              >
                Introduction
              </NavLink>
            </li>
            <li>
              <NavLink
                className="user-interface__subheader-btn"
                activeClassName="subheader-btn-active"
                to={`${url}/description`}
              >
                Description
              </NavLink>
            </li>
            <li>
              <NavLink
                className="user-interface__subheader-btn"
                activeClassName="subheader-btn-active"
                to={`${url}/summary-table`}
              >
                Summary table
              </NavLink>
            </li>
            <li>
              <NavLink
                className="user-interface__subheader-btn"
                activeClassName="subheader-btn-active"
                to={`${url}/part-1`}
              >
                Tasks
              </NavLink>
            </li>
            <li>
              <NavLink
                className="user-interface__subheader-btn"
                activeClassName="subheader-btn-active"
                to={`${url}/part-2`}
              >
                Assessment
              </NavLink>
            </li>
            {
              traineeId && <li>
                <NavLink
                  className="user-interface__subheader-btn"
                  activeClassName="subheader-btn-active"
                  to={`${url}/assessment-part`}
                >
                  Assessment Part
                </NavLink>
              </li>
            }
          </Slider>
        </ul>
      </div>
    </div>
  );
}

export default SyllabusHeader;
