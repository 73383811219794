import React, { useContext, useState } from "react";
import TabContext from "./tab-context.js";
import Select from "../../common/select.jsx";
import TextArea from "../../common/textarea.jsx";
import ErrorRepository from "../../../repositories/ErrorRepository.js";
import ImportAssessments from "../inputs/ImportAssessments";

function TabPart2() {
  const {
    handleChange,
    formData = {},
    errors = {},
    setValidation,
    setFormField,
    canEdit = true,
  } = useContext(TabContext);

  const getNewAssessmentNumber = () => (formData.assessment_criteria.length + 1);

  const [assessmentCriteria, setAssessmentCriteria] = useState(formData.assessment_criteria[0] || {
    number: getNewAssessmentNumber(),
    criteria: '',
  });

  const changeAssessmentCriteria = (number) => {
    const criteria = formData.assessment_criteria.find((criteria) => criteria && criteria.number === number);
    setAssessmentCriteria(criteria || {
      number,
      criteria: ''
    });
  };

  setValidation((setError) => {
    const fields = ['assessment_part', 'assessment_description'];
    let result = true;

    for (const field of fields) {
      const value = formData[field];
      const errorMessage = ErrorRepository.checkNotEmpty(value);
      if (errorMessage !== '') {
        setError(field, errorMessage);
        result = false;
      }
    }

    for (const criteria of formData.assessment_criteria) {
      if (!criteria.criteria) {
        setError('assessment_criteria', 'Fill all assessment criteria');
        result = false;
        break;
      }
    }

    return result;
  });

  return (
      <div className="create-new-user-form__wrapper create-new-syllabus-form__wrapper">
        <div className="syllabus-summary__wrapper">
          <div className="syllabus-introduction__form syllabus-part-2__form">
            <div className={`icon-box form__inner ${ErrorRepository.getErrorClassName(errors, 'assessment_part')}`}>
              <TextArea
                id={"assessment-descr"}
                label={"Assessment part"}
                inputName={"assessment_part"}
                inputValue={formData.assessment_part}
                onChange={handleChange}
                errors={errors}
                disabled={!canEdit}
              />
            </div>
            <div className={`icon-box form__inner ${ErrorRepository.getErrorClassName(errors, 'assessment_description')}`}>
              <TextArea
                id={"training-practic-descr"}
                label={"Description of this practical training assessment part"}
                inputName={"assessment_description"}
                inputValue={formData.assessment_description}
                onChange={handleChange}
                errors={errors}
                disabled={!canEdit}
              />
            </div>
          </div>
        </div>

        <div className="users-edit-history-session__wrapper  create-syllabus-part-2__wrapper scrollbar-style">

          <div className="users-edit-history-session__title assessment-title">
           <span>Edit assessment</span>
           <ImportAssessments setAssessments={(importedAssessments) => {
            if (importedAssessments && importedAssessments.length) {
              setFormField('assessment_criteria', importedAssessments);
              setAssessmentCriteria(importedAssessments[0] || {
                number: 1,
                criteria: '',
              });
            }
          }}/>
            {/* <button className="download-assessment__btn">Import assessments</button> */}
          </div>
          <div className="create-syllabus-part-2__inner">
            <div className={`icon-box select-box ${ErrorRepository.getErrorClassName(errors, 'assessment_number')}`}>
                <Select
                  label="Select assessment number"
                  name="assessment_number"
                  onChange={(e) => {
                    const {value} = e.target;
                    if (value === '') return;
                    const number = Number(value);
                    changeAssessmentCriteria(number);
                  }}
                  value={assessmentCriteria.number}
                  options={
                    [
                      ...formData.assessment_criteria.map(({ number }) => ({
                        value: number,
                        title: `Assessment number ${number}`
                      })),
                      {
                        value: getNewAssessmentNumber(),
                        title: 'New Assessment'
                      }
                    ]

                  }
                  errors={errors}
                />
            </div>
            <div className="syllabus-introduction__form syllabus-part-2__form">
              <div className={`icon-box form__inner ${ErrorRepository.getErrorClassName(errors, 'assessment_criteria')}`}>
                <TextArea
                  id={"competence-descr"}
                  label={"Competence criteria"}
                  inputName={"assessment_criteria"}
                  inputValue={assessmentCriteria.criteria}
                  onChange={({target: { value }}) => {
                    const { number } = assessmentCriteria;
                    const criteria = {
                      number,
                      criteria: value,
                    };
                    if (number === getNewAssessmentNumber()) {
                      formData.assessment_criteria.push(criteria);
                    } else {
                      if (value === '') {
                        formData.assessment_criteria[number - 1] = criteria;
                        const newAssessmentCriteria = formData.assessment_criteria
                          .filter(item => item.number !== number)
                          .map((item, index) => (item.number = index + 1, item));
                        setFormField('assessment_criteria', newAssessmentCriteria);
                        changeAssessmentCriteria(newAssessmentCriteria.length + 1);

                        return;
                      }
                      formData.assessment_criteria[number - 1] = criteria;
                    }
                    setFormField('assessment_criteria', formData.assessment_criteria);
                    setAssessmentCriteria(criteria);
                  }}
                  errors={errors}
                  disabled={!canEdit}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default TabPart2;
