import React from "react";

function getCurrentDate() {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth();
  let year = newDate.getFullYear();

  return monthNames[month] + " " + date + ", " + year;
}

const ModalArchiving = ({ config, cancelPopup, confirmPopup }) => {
  return (
    <div className="overlay">
      <div className="modal-act-element" id="archiving-element-modal">
        <div className="modal-act-element__wrapper">
          <div className="modal-act-element__head">
            <div className="modal-act-element__title">
              <div className="modal-archiving-element__icon">
                <span></span>
              </div>
              <div>
                <div className="modal-act-element__training-name">
                  ATA
                  <span> #{config.revisionNumber}</span>
                </div>
                <div className="modal-act-element__subtitle">
                  {config.action} ATA
                </div>
              </div>
            </div>
            <div className="modal-act-element__sesion-date">
              <span>{getCurrentDate()} </span>
            </div>
          </div>
          <div className="modal-delete-element__warning">
            Are you sure you want to {config.action} this this element?
          </div>
        </div>
        <div className="modal-act-element__btn">
          <button className="no-delete__btn btn-param" onClick={cancelPopup}>
            Cancel
          </button>
          <button className="archive__btn btn-param" onClick={confirmPopup}>
            Yes, {config.action} it
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalArchiving;
