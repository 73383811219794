import React from 'react';
import {Tooltip} from "@mui/material";

const ButtonSinglePdf = ({
  url = '',
  className = 'dowload-btn',
}) => (
  <>
    <Tooltip title={"Download pdf"}>
      <button
        title="Download as PDF"
        className={className}
        onClick={() => {
          window.open(url, '_blank');
        }}
      >
        <span></span>
      </button>
    </Tooltip>
  </>
);

export default ButtonSinglePdf;
