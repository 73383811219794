import * as ReactAutosuggest from 'react-autosuggest';
import EntityRepository from '../../repositories/EntityRepository.js';
import React, { useState } from 'react';

function Autosuggest({
  url = '',
  getFilters = () => {},
  children = () => {},
  theme = {},
  ...rest
}) {
  const [suggestions, setSuggestions] = useState([]);

  const loadSuggestions = async (value) => {
    const data = await EntityRepository.getEntities(url, getFilters(value));

    if (data.items && data.items.length) {
      setSuggestions([...data.items]);
    }
  };

  const newTheme = {
    container: 'react-autosuggest__container',
    containerOpen: 'react-autosuggest__container--open',
    input: 'react-autosuggest__input',
    inputOpen: 'react-autosuggest__input--open',
    inputFocused: 'react-autosuggest__input--focused',
    suggestionsContainer: 'react-autosuggest__suggestions-container',
    suggestionsContainerOpen: 'react-autosuggest__suggestions-container--open',
    suggestionsList: 'react-autosuggest__suggestions-list',
    suggestion: 'react-autosuggest__suggestion',
    suggestionFirst: 'react-autosuggest__suggestion--first',
    suggestionHighlighted: 'react-autosuggest__suggestion--highlighted',
    sectionContainer: 'react-autosuggest__section-container',
    sectionContainerFirst: 'react-autosuggest__section-container--first',
    sectionTitle: 'react-autosuggest__section-title',
    ...theme,
  };

  return (
    <ReactAutosuggest
      {...rest}
      theme={newTheme}
      suggestions={suggestions}
      onSuggestionsFetchRequested={({ value }) => loadSuggestions(value)}
      onSuggestionsClearRequested={() => setSuggestions([])}
      renderInputComponent={children}
    />
  );
}

export default Autosuggest;
