import React, { useState } from "react";
import date from "../../../helpers/date";
import Request from "../../../helpers/request";

function ModalRevision({
    revisions = [],
    lastRevision = {},
    closeModal = () => {},
}) {
    const [revision, setRevision] = useState(lastRevision);

    const changeRevision = async (id) => {
        const loadedRevision = await Request.get(`/api/atas/${id}/revision`);
        if (loadedRevision) setRevision(loadedRevision);
    };

    function makeRevisions(){
        const revisionsList = revisions.map(function(item, i){
          return <option key={i} value={item.id}>ATA{item.code} #{item.version}</option>;
        });
        return revisionsList.reverse();
    }
  return (
    <div className="overlay">
    <div className="modal-act-element__create-task modal-version scrollbar-style" id="modal-revision-comment">
        <div className="modal-act-element__wrapper">
            <div className="modal-act-element__head">
                <div className="modal-act-element__title">
                    Revision commentary
                </div>
                <div className="modal-act-element__sesion-date">
                    <span>{date.getFormatted()}</span>
                </div>
            </div>
            <div className="modal-version__wraper">
                <div className="select-box">
                    <label htmlFor="version-number">Select version</label>
                    <select id="version-number" onChange={
                        ({target: { value }}) => changeRevision(value)
                        }>
                        {makeRevisions()}
                   </select>
                </div>
                <div className="modal-version__inner">
                    <div className="box-info">
                        <label>Author</label>
                            <div className="version-author"><span>{revision.short_name}</span></div>
                    </div>
                    <div className="box-info">
                        <label htmlFor="version-date">Date</label>
                        <input id="version-date" type="text" value={date.getFormatted(revision.last_version_date)} disabled/>
                    </div>
                </div>
                <label>Description of the modification</label>
                <div className="version-descr-wrapper">
                    <div className="version-descr-inner">
                        <textarea className="scrollbar-style"  id="version-descr" value={revision.revision_comment} disabled></textarea>
                    </div>
                </div>
            </div>
            <button className="no-delete__btn btn-param" onClick={closeModal}>Cancel</button>
        </div>
    </div>
</div>
  );
}

export default ModalRevision;
