import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import date from '@/helpers/date';

const styles = StyleSheet.create({
  title: {
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    width: '100%',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
  },
  header: {
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    fontSize: 11,
    textAlign: 'center',
    paddingTop: 5,
    paddingBottom: 5,
    paddingRight: 2,
    paddingLeft: 2,
  },
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginTop: 10,
    width: '100%',
  },
  td: {
    fontSize: 11,
    textAlign: 'center',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    paddingTop: 5,
    paddingBottom: 5,
    paddingRight: 2,
    paddingLeft: 2,
  },
});

const TasksRows = (prop) => {
  const { data } = prop;

  const rows = data.map((task, index)=> {
    return (
      <View style={{ flexDirection: 'row' }} key={index.toString()} wrap={false}>
        <Text style={[styles.td, { width: '7%' }]}>{task.ata_code}</Text>
        <Text style={[styles.td, { width: '7%' }]}>#{task.order_number}</Text>
        <Text style={[styles.td, { width: '8%' }]}>{task.category}</Text>
        <Text style={[styles.td, { width: '8%' }]}>{task.code}</Text>
        <Text style={[styles.td, { width: '26%' }]}>{task.subject}</Text>
        <Text style={[styles.td, { width: '12%' }]}>{task.manualReference}</Text>
        <Text style={[styles.td, { width: '10%' }]}>-</Text>
        <Text style={[styles.td, { width: '10%' }]}>-</Text>
        <Text style={[styles.td, { width: '12%' }]}>-</Text>
      </View>
    );
  });

  return <Fragment>{rows}</Fragment>;
};

const Tasks = (props) => {
  return (
    <Fragment>
      <View style={styles.row}>
        <Text style={styles.title}>Tasks added in the ATA: </Text>
      </View>
      <View style={styles.tableContainer}>
        <Text style={[styles.header, { width: '7%' }]}>ATA</Text>
        <Text style={[styles.header, { width: '7%' }]}>#</Text>
        <Text style={[styles.header, { width: '8%' }]}>Task cat.</Text>
        <Text style={[styles.header, { width: '8%' }]}>Task code</Text>
        <Text style={[styles.header, { width: '26%' }]}>Subject</Text>
        <Text style={[styles.header, { width: '12%' }]}>Manual ref.</Text>
        <Text style={[styles.header, { width: '10%' }]}>Classroom</Text>
        <Text style={[styles.header, { width: '10%' }]}>Aircraft</Text>
        <Text style={[styles.header, { width: '12%' }]}>Instructor</Text>
      </View>
      <TasksRows {...props}/>
    </Fragment>
  );
};

export default Tasks;
