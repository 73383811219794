import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import Request from '../../../../../../helpers/request';
import date from '../../../../../../helpers/date';
import TableFilterGroup from '../../../../../common/table/table-filter-group';
import { useTraining } from '../../../common/training-context';

function TaskDetail() {
  const user = useSelector(state => state.authReducer.user);
  const training = useTraining();
  const [tasks, setTasks] = useState([]);
  const [filtersString, setFiltersString] = useState('');

  const filtersObject = useRef({});
  const setFilter = (name, value) => {
    filtersObject.current[name] = value;
    setFiltersString(JSON.stringify(filtersObject.current));
  };

  useEffect(() => {
    const fetchData = async () => {

      const result = await Request.get(`/api/trainings/${training.id}/trainees/${user.id}/tasks?filters=${filtersString}`);
      setTasks(result);
    };

    if(training.id) fetchData();
  }, [training.id, filtersString]);

  return (
    <>
      <div className="trainee-details__activity-panel">
        <div className="data-search">
          <i className="search-icon fa fa-search"></i>
          <input className="data-search__field" type="text" placeholder="ATA Search" onChange={({ target }) => setFilter('like', target.value)} />
        </div>
        <div className="sort-panel">
          <div className="data-sort__box">
            <span>Sort by</span>
            <select onChange={({ target }) => setFilter('sort', target.value)}>
              <option value="">ATA code</option>
              <option value="category">Task category</option>
              <option value="code">Task code</option>
            </select>
          </div>
          <TableFilterGroup
            classModifier="mr-16"
            onFilter={(value) => setFilter('category', value)}
            options={[
              {
                className: 'active-users-btn',
                title: 'mt',
                value: 'MT',
              },
              {
                className: 'archived-users-btn',
                title: 'at',
                value: 'AT',
              },
              {
                className: 'all-users-btn',
                title: 'all',
                value: '',
                isActive: true,
              },
            ]}/>
          <TableFilterGroup
            onFilter={(value) => setFilter('state', value)}
            options={[
              {
                className: 'completed-users-btn',
                title: 'validated',
                value: 'validated',
              },
              {
                className: 'unfinished-users-btn',
                title: 'remaining',
                value: 'remaining',
              },
              {
                className: 'all-users-btn',
                title: 'all',
                value: '',
                isActive: true,
              },
            ]}/>
        </div>
      </div>

      <div className="user-personal__table-inform">
        <div className="validation-assessment__subtitle"><span>05</span> – Time limits/maintenance checks</div>
        <div className="users-table__wrapper">
          <table className="users-table__data">
            <thead className="users-table__thead">
              <tr>
                <th>ATA</th>
                <th>№</th>
                <th>Task category</th>
                <th>Task code</th>
                <th>Subject</th>
                <th>Manual reference</th>
                <th>Classroom</th>
                <th>Aircraft</th>
                <th>State</th>
                <th></th>
              </tr>
            </thead>
            <tbody className="users-table__tbody">
              {
                tasks.map(({
                  ata_code,
                  order_number,
                  category,
                  code,
                  subject,
                  manual_reference,
                  trainee_task = {},
                }, index) => {
                  trainee_task = trainee_task || {};
                  return (
                    <tr
                      className={`category-${category.toLowerCase()} ${trainee_task.is_confirmed ? 'validate-task' : ''}`}
                      key={index}>
                      <td>{ata_code}</td>
                      <td>#{order_number}</td>
                      <td><span className={`table-bage ${category.toLowerCase()}-bage`}>{category}</span></td>
                      <td>{code}</td>
                      <td>{subject}</td>
                      <td>{manual_reference}</td>
                      <td className={trainee_task.is_classroom_passed ? 'checked-data' : undefined}>
                        <span className="users-table__tbody-link">
                          <span className="table-ceck-icon"><i className="fa fa-check-square-o"></i></span>
                          {
                            trainee_task.classroom_date
                              ? date.getNumberFormatted(trainee_task.classroom_date)
                              : '00\xa0/\xa000\xa0/\xa000'
                          }
                        </span>
                      </td>
                      <td className={trainee_task.is_aircraft_passed ? 'checked-data' : undefined}>
                        <span className="users-table__tbody-link">
                          <span className="table-ceck-icon"><i className="fa fa-check-square-o"></i></span>
                          {
                            trainee_task.aircraft_date
                              ? date.getNumberFormatted(trainee_task.aircraft_date)
                              : '00\xa0/\xa000\xa0/\xa000'
                          }
                        </span>
                      </td>
                      <td className={`${trainee_task.is_aircraft_passed || trainee_task.is_classroom_passed ? 'checked-task' : ''}`}>
                        <span className="users-table__tbody-link">
                          {
                            trainee_task.is_aircraft_passed || trainee_task.is_classroom_passed
                              ? (<span className="validated-table-icon"><i className="fa fa-check"></i></span>)
                              : (<span className="insufficient-level"><i className="fa fa-minus"></i></span>)
                          }
                        </span>
                      </td>
                      <td>
                        <span className="users-table__tbody-link">
                          {
                            trainee_task.is_locked
                              ? (<span className="closed-table-icon"><i className="fa fa-lock"></i></span>)
                              : (<span className="insufficient-level"><i className="fa fa-minus"></i></span>)
                          }
                        </span>
                      </td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default TaskDetail;
