import Autosuggest from 'react-autosuggest';
import EntityRepository from "../../../repositories/EntityRepository.js";
import React, { useState } from "react";

/* --------------- */
/*    Component    */
/* --------------- */

function renderSuggestion(suggestion) {
  return (
    <span>{suggestion.code}</span>
  );
}

function SearchAta({ onSelect = () => {} }) {
  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  const loadSuggestions = async (value) => {
    const data = await EntityRepository.getEntities('/api/atas', { like: value });
    if (data.items && data.items.length) setSuggestions([...data.items]);
  };

  return (
      <Autosuggest 
        theme={{
          // container: 'react-autosuggest__container',
          container:                'syllabus-summary__table-search',
          containerOpen:            'react-autosuggest__container--open',
          input:                    'react-autosuggest__input',
          inputOpen:                'react-autosuggest__input--open',
          inputFocused:             'react-autosuggest__input--focused',
          suggestionsContainer:     'react-autosuggest__suggestions-container',
          suggestionsContainerOpen: 'react-autosuggest__suggestions-container--open',
          suggestionsList:          'react-autosuggest__suggestions-list',
          suggestion:               'react-autosuggest__suggestion',
          suggestionFirst:          'react-autosuggest__suggestion--first',
          suggestionHighlighted:    'react-autosuggest__suggestion--highlighted',
          sectionContainer:         'react-autosuggest__section-container',
          sectionContainerFirst:    'react-autosuggest__section-container--first',
          sectionTitle:             'react-autosuggest__section-title',
        }}
        suggestions={suggestions}
        onSuggestionSelected={(event, { suggestion }) => {
          setValue('');
          onSelect(suggestion);
        }}
        onSuggestionsFetchRequested={({ value }) => loadSuggestions(value)}
        onSuggestionsClearRequested={() => setSuggestions([])}
        getSuggestionValue={(suggestion) => (suggestion.code)}
        renderSuggestion={renderSuggestion}
        inputProps={{
          id: 'syllabys-ata-search',
          placeholder: "Search by ATA code…",
          value,
          onChange: (event, { newValue }) => {
            if (newValue !== undefined) setValue(newValue);
          },
        }} 
        renderInputComponent={ inputProps => (
          <>
            <label htmlFor="syllabys-ata-search">
              Add ATA in summary table
            </label>
            <input {...inputProps} />
          </>
        )}
        />
  );

}

export default SearchAta;
