import React from 'react';
import { NavLink } from 'react-router-dom';
import { SKILL_B1, SKILL_B2 } from '../common/constants';
import TextInput from './../common/textInput.jsx';
import Select from './../common/select.jsx';
import SelectInstructor from './inputs/SelectInstructor';
import EntityRepository from '../../repositories/EntityRepository';
import ErrorRepository from '../../repositories/ErrorRepository';
import ModalTraineeEdit from './modals/modal-trainee-edit.jsx';
import TrainingCalendar from './inputs/TrainingCalendar.jsx';
import ButtonTraineesList from './buttons/button-trainees-list.jsx';
import Request from '../../helpers/request';
import { connect } from 'react-redux';
import { success, danger } from '../../redux/actions/notify';
import ImportTrainees from './inputs/ImportTrainees.jsx';
import AgreeChanges from '../modals/modal-agree-changes.jsx';

class TrainingCreate extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      formData: {
        syllabus: null,
        session_number : '',
        skill: [],
        instructors : [],
        trainees: [],
        date_start: new Date(),
        date_end: new Date(),
      },
      course_reference_number : '',
      value: '',
      trainees: {},
      instructors: [],
      syllabi : [],
      data    : [],
      errors  : {},
      showModalTraineeEdit: false,
      agreeModal: false,
      isFormDataChanged: false,
    };
  }

    closeModalTraineeEdit = () => {
      this.setState({
        showModalTraineeEdit: false,
      });
    };

    openModalTraineeEdit = () => {
      this.setState({
        showModalTraineeEdit: true,
      });
    };

    handleChange = ({ target: { name, value } }) => {

      if(name == 'syllabus') {
        this.state.syllabi.map((item) => {
          if (item.value === Number(value)) {
            this.setState((state) => ({ ...state, course_reference_number: item.course_reference_number }));
            this.setState((state) => ({ ...state, instructors: [] }));
            this.setFormField('instructors', []);
          }
        });
        this.setFormField(name, Number(value));
      }else {
        this.setFormField(name, value);
      }
    };

    async getSyllabuses() {
      const data = await EntityRepository.getEntities('api/syllabi',
        { start: 0, limit: 1000000, archive: 'active' });

      const syllabi = [
        { title: 'Select Syllabus' },
        ...data.items.map(item => ({
          value: item.id,
          title: item.title,
          course_reference_number: item.course_reference_number,
        })),
      ];

      this.setState((state) => ({ ...state, syllabi }));
    }

    componentDidMount() {
      this.getSyllabuses();
    }

  addInstructor = (instructor) => {
    const { instructors } = this.state.formData;

    if (instructors.includes(instructor.id)) {
      return;
    }
    instructors.push(instructor.id);

    this.setState((state) => (
      {
        ...state,
        formData: {
          ...this.state.formData,
          instructors,
        },
        instructors: [
          ...state.instructors,
          {
            username: instructor.first_name + ' ' + instructor.last_name,
            id: instructor.id,
          },
        ],
      }
    ));
  };

  deleteInstructor = (deletedId) => {
    const { instructors, formData } = this.state;

    this.setState((state) => (
      {
        ...state,
        formData: {
          ...this.state.formData,
          instructors: formData.instructors.filter((id) => (id !== deletedId)),
        },
        instructors: instructors.filter((instructor) => (instructor.id !== deletedId)),
      }
    ));
  };

  skillChange = ({ target: { checked, value } }) => {
    const { skill } = this.state.formData;

    const newArray = checked
      ? skill.includes(value)
        ? skill
        : [...skill, value]
      : skill.filter((item) => item !== value);

    this.setFormField('skill', newArray);
  };

    setError = (field, message) => {
      this.setState((state) => (
        {
          ...state,
          errors: { ...state.errors, [field]: message },
        }
      ));
    };

    clearError = (fieldName) => {
      const { errors } = this.state;

      if (errors[fieldName] && errors[fieldName] != '') {
        this.setError(fieldName, '');
      }
    }

    setFormField = (field, value) => {
      this.clearError(field);
      this.setState((state) => (
        {
          ...state,
          isFormDataChanged: true,
          formData: { ...this.state.formData, [field]: value },
        }
      ));
    };

    goBack = () => {
      this.props.history.push('/settings/trainings');
    }

    onCancel = () => {
      if (this.state.isFormDataChanged) {
        return this.setState({ agreeModal: true });
      }

      this.goBack();
    }

    confirmAgree = () => {
      this.setState({ agreeModal: false, isFormDataChanged: false });

      return this.goBack();
    };

    cancelAgree = () => {
      return this.setState({ agreeModal: false });
    };

    create = async () => {
      const { formData } = this.state;
      formData.date_start = new Date(formData.date_start).toDateString();
      formData.date_end = new Date(formData.date_end).toDateString();
      const response = await Request.post('/api/trainings/create', formData);
      if (response.id) {
        this.props.success('Training successfully created');
        this.props.history.push({
          pathname: '/settings/trainings',
          reference: response,
        });
        this.goBack();
      } else if (response.errors) {
        this.setState(state => ({ ...state, errors: response.errors }));
      } else {
        this.props.danger('Server error');
      }
    };

    setTrainees = (trainees = {}) => {
      this.setState((state) => (
        {
          ...state,
          formData: {
            ...this.state.formData,
            trainees: Object.keys(trainees),
          },
          trainees: { ...trainees },
        }
      ));
    };

    getClearError = (fieldName) => {
      let errors = [... this.state.errors];
      let index = 0;
      switch (fieldName) {
        case 'code': index = 0;break;
        case 'description': index = 1;break;
      }
      if(errors.length > 0){
        errors[index].message = '';
        this.setState({ errors : errors });
      }
    };

    render() {
      const {
        formData,
        instructors,
        trainees,
        showModalTraineeEdit,
        agreeModal,
        errors,
        course_reference_number,
      } = this.state;
      let traineeListButton = '';
      if (formData.trainees.length) {
        traineeListButton = <ButtonTraineesList
          number={formData.sessionNumber}
          viewOnClick={this.openModalTraineeEdit}
          editOnClick={this.openModalTraineeEdit}
        />;
      } else {
        traineeListButton = <button onClick={this.openModalTraineeEdit} className="add-trainees__btn btn-param">Add trainees</button>;
      }

      return (
        <>
          <section className="create-new-user users-edit-wrapper">
            <div className="container">
              <ul className="users-edit__breadcrumb">
                <li><NavLink to={'/settings/trainings'}>Training </NavLink></li>
                <li className="active"><span>New training session</span></li>
              </ul>
              <div className="create-new-user__wrapper">
                <div className="create-new-user__top-box">
                  <div className="create-new-user__badge"><span>new</span></div>
                </div>
                <div className="new-training__main-panel">
                  <div className="training__wrapper">
                    <div className="training__item">
                      <div className="training-inform">
                        <div className={`icon-box select-box ${ErrorRepository.getErrorClassName(errors, 'syllabus')}`}>
                          <Select
                            label="Entitled Syllabus"
                            name="syllabus"
                            onChange={this.handleChange}
                            id={'create-training-select-syllabus'}
                            options={this.state.syllabi}
                            errors={errors}
                          />
                        </div>
                        <div className="training-inform__box">
                          <div className={'icon-box text-input-box' + ErrorRepository.getErrorClassName(errors, 'training_code')}>
                            <TextInput
                              className="event-disabled"
                              inputName={'course_reference'}
                              inputLabel={'Course Reference'}
                              inputValue={course_reference_number}
                            />
                          </div>
                          <div className={'icon-box text-input-box' + ErrorRepository.getErrorClassName(errors, 'session_number')}>
                            <TextInput
                              inputName={'session_number'}
                              inputLabel={'Session number'}
                              inputValue={formData.sessionNumber}
                              changeInput={this.handleChange}
                              errors={errors}
                            />
                          </div>
                        </div>
                        <div
                          className={
                            'icon-box staff-skill__box' +
                                                ErrorRepository.getErrorClassName(errors, 'skill')
                          }
                        >
                          <label>Skill</label>
                          <div className="staff-skill__wrapper">
                            <div className="staff-skill__inner">
                              <label className="category-checkbox">
                                <input
                                  type="checkbox"
                                  name={'skill'}
                                  value={SKILL_B1}
                                  errors={errors}
                                  onClick={this.skillChange}
                                />
                                <span className="category-custom-checkbox"></span>
                              </label>
                              <span className="category-name">B1</span>
                            </div>
                            <div className="staff-skill__inner">
                              <label className="category-checkbox">
                                <input
                                  type="checkbox"
                                  name={'skill'}
                                  value={SKILL_B2}
                                  onClick={this.skillChange}
                                />
                                <span className="category-custom-checkbox"></span>
                              </label>
                              <span className="category-name">B2</span>
                            </div>
                          </div>
                          <span className="wrror-masage">
                            {errors.skill}
                          </span>
                        </div>
                        <SelectInstructor
                          onSelect={this.addInstructor}
                          skill={formData.skill}
                          courseReferenceNumber={course_reference_number}
                          selectedIds={formData.instructors}
                          isLocked={!formData.skill.length}
                        />
                        <div className="search-result__wrapper">
                          {instructors.map(({ id, username }) =>
                            <span key={id} className="instructor-name">
                              {username}
                              <button
                                className="delete-instructor-name"
                                onClick={() => this.deleteInstructor(id)}
                              >
                                <i className="fa fa-close"></i>
                              </button>
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="training-trainee-list">
                        <label>Trainee list</label>
                        <div className="training-btn__container">
                          {traineeListButton}
                          <span>or</span>
                          <ImportTrainees setTrainees={this.setTrainees} />
                        </div>
                      </div>
                    </div>
                    <TrainingCalendar
                      startDate={formData.date_start}
                      endDate={formData.date_end}
                      onChange={({ selection }) => {
                        this.setState({
                          formData: {
                            ...formData,
                            date_start: selection.startDate,
                            date_end: selection.endDate,
                          },
                        });
                      }}
                    />
                  </div>
                  <div className="create-new-user__buttons">
                    <button
                      className="cancel-btn btn-param"
                      onClick={this.onCancel}
                    >
                                        Cancel
                    </button>
                    <button
                      className="create-btn btn-param"
                      onClick={this.create}
                    >Create</button>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {
            agreeModal
              ? <AgreeChanges
                cancelAgree={this.cancelAgree}
                confirmAgree={this.confirmAgree}
              />
              : null
          }
          {
            showModalTraineeEdit
              ? <ModalTraineeEdit
                onConfirm={(trainees) => {
                  this.setTrainees(trainees);
                  this.closeModalTraineeEdit();
                }}
                onCancel={this.closeModalTraineeEdit}
                trainees={trainees}
              />
              : null
          }
        </>
      );
    }
}

function mapDispatchToProps(dispatch) {
  return {
    success: (message) => dispatch(success(message)),
    danger: (message) => dispatch(danger(message)),
  };
}

export default connect(null, mapDispatchToProps)(TrainingCreate);
