import React, { useContext } from 'react';
import TabContext from './tab-context.js';
import ValidationRange from '../inputs/validation-range.jsx';
import Select from '../../common/select.jsx';
import TextInput from '../../common/textInput.jsx';
import DatePicker from 'react-datepicker';
import MyDropzone from '../../common/image-upload.jsx';
import ErrorRepository from '../../../repositories/ErrorRepository.js';

function TabDescription () {
  const {
    handleChange,
    setFormField,
    formData = {},
    errors = {},
    setValidation,
    setImage,
    canEdit,
  } = useContext(TabContext);

  setValidation((setError) => {
    const fields = ['date', 'model', 'title', 'validation'];
    let result = true;

    for (const field of fields) {
      const value = formData[field];
      const errorMessage = ErrorRepository.checkNotEmpty(value);
      if (errorMessage !== '') {
        setError(field, errorMessage);
        result = false;
      }
    }

    return result;
  });

  return (
    <div className="create-new-user-form__wrapper create-new-syllabus-form__wrapper">
      <form
        className="create-new-user-form create-new-syllabus-descr-form"
        id="create-new-syllabus-descr-form"
      >
        <div className="create-new-user__board-wrapper create-new-syllabus__board-wrapper">
          <div className={`create-syllabus-category-box ${ErrorRepository.getErrorClassName(errors, 'model')}`}>
            {/* <TextInput
              inputName={"model"}
              inputLabel={"Category / model"}
              inputValue={formData.model}
              changeInput={handleChange}
              errors={errors}
            /> */}
            <Select
              label="Category / model"
              name="model"
              onChange={handleChange}
              value={formData.model}
              options={[
                {
                  title: 'Select model',
                },
                {
                  value: 'airbus',
                  title: 'Airbus',
                },
                {
                  value: 'boeing',
                  title: 'Boeing',
                },
              ]}
              errors={errors}
              disabled={!canEdit}
            />
          </div>
          &nbsp;
          <div className={`icon-box create-user-birth ${ErrorRepository.getErrorClassName(errors, 'date')}`}>
            <label htmlFor="create-staff-birth">Date</label>
            <DatePicker
              placeholderText="Select Date"
              dateFormat="MMMM d, yyyy"
              selected={formData.date}
              onChange={(value) => setFormField('date', value)}
              disabled={!canEdit}
            />
            { (errors['date'] && errors['date'] != '') ? (
              <span className="wrror-masage">{errors['date']}</span>
            ) : null}
          </div>
          {
            (formData.course_reference_number !== '' && formData.course_reference_number !== undefined) ?
              <div>
                <label htmlFor="create-staff-birth">Course Reference</label>
                <div className="syllabus-descr__text">
                  {formData.course_reference_number}
                </div>
              </div> : ''
          }
          <div className={`icon-box create-syllabus-tq-box ${ErrorRepository.getErrorClassName(errors, 'title')}`}>
            <TextInput
              inputName={'title'}
              inputLabel={'TQ Title'}
              inputValue={formData.title}
              changeInput={handleChange}
              errors={errors}
              disabled={!canEdit}
            />
          </div>
          <div className={`icon-box create-syllabus-range-box ${ErrorRepository.getErrorClassName(errors, 'validation')}`}>
            <ValidationRange
              label="Validation threshold"
              name="validation"
              onChange={(value) => setFormField('validation', value)}
              value={formData.validation}
              errors={errors}
              disabled={!canEdit}
            />
          </div>
        </div>
        {/* <div className="create-new-user__buttons">
                  <button className="cancel-btn btn-param">Cancel</button>
                  <button className="create-btn btn-param">Create</button>
              </div> */}
      </form>
      <div className="users-edit-history-session__wrapper create-syllabus-session__wrapper">
        {/* <div className="export-files__item">
                  <div className="users-edit-history-session__title">
                      Import logo
                  </div>
                  <div className="export-files__wrapper export-logo">
                      <button className="export-files__btn"></button>
                  </div>
              </div>
              <div className="export-files__item">
                  <div className="users-edit-history-session__title">
                      Import photo
                  </div>
                  <div className="export-files__wrapper">
                      <button className="export-files__btn"></button>
                  </div>
              </div> */}
        <MyDropzone
          inputName={'logo'}
          title={'Import logo'}
          setFilePath={setFormField}
          path={formData.logo}
          className="export-logo"
          setImage={setImage}
          disabled={!canEdit}
        />
        <MyDropzone
          inputName={'photo'}
          title={'Import photo'}
          setFilePath={setFormField}
          path={formData.photo}
          setImage={setImage}
          disabled={!canEdit}
        />
      </div>
    </div>
  );
}

export default TabDescription;
