import React from 'react';

function Part2() {
  return (
    <div className="syllabus-part__wrapper">
      <h1 className="syllabus-part__title">part 2</h1>
      <div className="syllabus-part__subtitle">Assessment</div>
    </div>
  );
}

export default Part2;
