import React, { useCallback, useState } from 'react';
import { useTable } from './table-context';
import Request from '@/helpers/request';

function ButtonPdf({ url, getFilters = () => {} }) {
  const { state } = useTable();
  const [download, setDownload] = useState(false);

  const generatePdf = useCallback(() => {
    const ids = Array.from(state.selectedIds);
    const filters = {
      ...getFilters(),
      ids: ids.length ? ids : undefined,
      start: 0,
      limit: 100,
    };
    setDownload(!download);
    const url = `${url}?filters=${JSON.stringify(filters)}`;
    Request.pdf(url).then(res => {
      if (res) {
        setDownload(false);
      }
    }).catch(() => {
      setDownload(false);
    });
    // window.open(`${url}?filters=${JSON.stringify(filters)}`, '_blank');
  }, [state]);

  return (<></>
    // <div className={`archive__btn ${download ? 'download-in-progress' : ''}`} onClick={generatePdf}>
    //   <button></button>
    // </div>
  );
}

export default ButtonPdf;
