import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { ACTION_ARCHIVE, ACTION_RESTORE } from '../common/constants';
import date from '../../helpers/date';
import TableCheckbox from '../common/table/table-checkbox';
import TableRowArchiveButton from '@/components/common/table/table-row-archive-button';
import TableRowFlag from '@/components/common/table/table-row-flag';
import Pdf from "@/components/common/syllabus-pdf";

const SyllabusRow = ({ row, match, openModal, openModalRevision }) => {
  const { url } = match;
  const editPass = `${url}/${row.u_id}/edit`;
  let trClassName, action;

  if (row.archived) {
    trClassName = 'archived-table-data';
    action = ACTION_RESTORE;
  } else {
    trClassName = '';
    action = ACTION_ARCHIVE;
  }

  return (
    <tr className={trClassName}>
      <td>
        <TableCheckbox id={row.id} />
      </td>
      <td style={{ textTransform: 'capitalize' }}>
        <NavLink to={editPass} className="users-table__tbody-link">
          <TableRowFlag id={row.id}/>{row.model}
        </NavLink>
      </td>
      <td>
        <NavLink to={editPass} className="users-table__tbody-link">{row.course_reference_number}</NavLink>
      </td>
      <td>
        <NavLink to={editPass} className="users-table__tbody-link">{row.title}</NavLink>
      </td>
      <td>
        <NavLink to={editPass} className="users-table__tbody-link">{date.getNumberFormatted(row.date)}</NavLink>
      </td>
      <td>
        <div className="number-version more-version">
          {
            !row.is_draft
              ?
              <>
                <span>{row.version}</span>
                <span
                  className="number-version-icon pointer"
                  onClick={() => {
                    openModalRevision(row.id);
                  }}
                ></span>
              </>
              : 'draft'
          }
        </div>
      </td>
      <td>
        <Pdf buttonClass="download-file-btn" syllabus={row}/>
      </td>
      <td>
        <TableRowArchiveButton action={action} onClick={() => openModal(row, action)}/>
      </td>
      <td>
        <NavLink to={editPass}>
          <button title="Edit" className="edit-data"></button>
        </NavLink>
      </td>
    </tr>
  );
};

export default withRouter(SyllabusRow);
