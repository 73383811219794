import React, { useState } from "react";
import date from "../../../helpers/date";
import Request from '@/helpers/request';
import { useHistory } from 'react-router-dom';

function ModalRevision({
  revisions = [],
  onCancel = () => {},
  updateData
}) {

  const [selectedRevision, setSelectedRevision] = useState(revisions[0] || {});
  const history = useHistory();

  function changeRevision(e) {
    const { value: selectedId } = e.target;

    for (const revision of revisions) {
      if (revision.id == selectedId) {
        return setSelectedRevision(revision);
      }
    }
  }

  async function changeCurrentVersion() {
    await Request.get(`/api/syllabi/current-version/${selectedRevision.id}`);
    onCancel();
    updateData();
  }

  return (
    <div className="overlay">
      <div
        className="modal-act-element__create-task modal-version scrollbar-style"
        id="modal-revision-comment"
      >
        <div className="modal-act-element__wrapper">
          <div className="modal-act-element__head">
            <div className="modal-act-element__title">Revision commentary</div>
            <div className="modal-act-element__sesion-date">
              <span>{date.getFormatted()}</span>
            </div>
          </div>
          <div className="modal-version__wraper">
            <div className="select-box">
              <label htmlFor="version-number">Select version</label>
              <select id="version-number" onChange={changeRevision}>
                {revisions.map((item, index) => (
                   <option value={item.id} key={index}>{`Version ${('0' + item.version).slice(-2)}`}</option>
                ))}
              </select>
            </div>
            <div className="modal-version__inner">
              <div className="box-info">
                <label>Author</label>
                <div className="version-author">
                  <span>{selectedRevision.author_name}</span>
                </div>
              </div>
              <div className="box-info">
                <label htmlFor="version-date">Date</label>
                <input
                  id="version-date"
                  type="text"
                  value={date.getFormatted(selectedRevision.date)}
                  disabled
                />
              </div>
            </div>
            <label>Commentary</label>
            <div className="version-descr-wrapper">
              <div className="version-descr-inner">
                <textarea
                  className="scrollbar-style"
                  id="version-descr"
                  value={selectedRevision.reason_for_change}
                  disabled
                ></textarea>
              </div>
            </div>
          </div>
          <button
            className="create-btn btn-param"
            onClick={changeCurrentVersion}
          >
            Make this version is current?
          </button>
          <button
            className="no-delete__btn btn-param"
            onClick={onCancel}
          >
            Thanks, go back
          </button>
        </div>
      </div>
    </div>
  );
}

export default ModalRevision;
