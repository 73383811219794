import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import EntityRepository from '@/repositories/EntityRepository';
import { useTraining } from '@/components/frontend/trainings/common/training-context';
import Request from '../../../../../helpers/request';
import ModalResult from './modals/ModalResult';

function Part2() {
  const training = useTraining();
  const { trainingId } = useParams();
  const { state: locationState } = useLocation();
  const history = useHistory();

  const [formData, setFormData] = useState({
    date: new Date(),
    trainees: [],
    assessments: [],
    unsatisfactory: [],
  });

  const [trainees, setTrainees] = useState([]);

  const [assessments, setAssessments] = useState({});
  const [isSatisfied, setIsSatisfied] = useState(false);
  const [isUnsatisfied, setIsUnsatisfied] = useState(false);
  const [modalResult, setModalResult] = useState({
    isVisible: false,
    isCompleted: false,
    sessionNumber: null,
  });

  const user = useSelector(state => state.authReducer.user);

  const setFormField = (field, value) => {
    setFormData((state) => ({
      ...state,
      [field]: value,
    }));
  };

  useEffect(() => {
    if (locationState && locationState.selectedTrainees) {
      const { selectedTrainees = [] } = locationState;
      setTrainees(selectedTrainees.map(({
        id,
        last_name,
        first_name,
        id_number,
        company,
      }) => ({
        id,
        lastNameShort: `${last_name[0]}.`,
        firstName: first_name,
        idNumber: id_number,
        company: company,
      })));
      setFormField('trainees', selectedTrainees.map(({ id }) => id));
    }
  }, []);

  const [assessmentCriteria, setAssessmentCriteria] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const result = await EntityRepository.getEntity('api/syllabi', training.syllabus_uid);

      if (result && result.assessment_criteria && Array.isArray(result.assessment_criteria)) {
        setAssessmentCriteria([...result.assessment_criteria]);
      }
    };

    if (training.syllabus_uid) fetchData();
  }, [training.syllabus_uid]);

  const removeTrainee = (id) => {
    const newTrainees = trainees.filter(({ id: traineeId }) => traineeId !== id);

    setTrainees(newTrainees);

    setFormField('trainees', newTrainees.map(({ id }) => id));
  };

  const onAssessmentChange = ({ target: { name, value, checked } }) => {
    const index = Number(name);
    const array = formData['assessments'];
    const unsatisfactory = formData['unsatisfactory'];
    const criteriaNumber = assessmentCriteria[index].number;

    const newArray = (checked && value == '2') ?
      (array.includes(criteriaNumber) ? array : [...array, criteriaNumber])
      : (array.filter(item => item !== criteriaNumber));

    const newUnsatisfactory= (checked && value == '1') ?
      (unsatisfactory.includes(criteriaNumber) ? unsatisfactory : [...unsatisfactory, criteriaNumber])
      : (unsatisfactory.filter(item => item !== criteriaNumber));

    if (checked) {
      setAssessments((state) => ({ ...state, [index]: value }));
    }

    setFormField('assessments', newArray);
    setFormField('unsatisfactory', newUnsatisfactory);
    setIsUnsatisfied(false);
    setIsSatisfied(false);
  };

  const checkSatisfied = ({ target: { checked } }) => {
    const values = {};
    const array = [];

    for (let n = 0; n < assessmentCriteria.length; n++) {
      if (checked) {
        array.push(assessmentCriteria[n].number);
        values[n] = '2';
      } else {
        values[n] = false;
      }
    }

    setFormField('assessments', array);
    setFormField('unsatisfactory', []);
    setAssessments({ ...values });
    setIsSatisfied(checked);
    setIsUnsatisfied(false);
  };

  const checkUnsatisfied = ({ target: { checked } }) => {
    const values = {};
    const array = [];
    for (let n = 0; n < assessmentCriteria.length; n++) {
      if (checked) {
        array.push(assessmentCriteria[n].number);
        values[n] = '1';
      } else {
        values[n] = false;
      }
    }

    setFormField('assessments', []);
    setFormField('unsatisfactory', array);
    setAssessments({ ...values });
    setIsUnsatisfied(checked);
    setIsSatisfied(false);
  };

  const save = async () => {
    if (!formData.trainees.length) {
      return;
    }
    try {
      const response = await Request.post(`/api/trainings/${trainingId}/result`, formData);
      if (response.isProcessed) {
        setModalResult((state) => (
          {
            ...state,
            isVisible: true,
            isCompleted: response.isCompleted,
            sessionNumber: response.sessionNumber,
          }));
      }

    } catch (error) {
      if (error.message) {
        setModalResult({
          isVisible: true,
          message: error.message,
        });
      }
    }
    setTimeout(function () {
      history.push(`/trainings/${training.id}/trainees`);
    }, 4000);
  };

  return (
    <div className="assessment-sheet__wrapper">
      <div className="assessment-sheet__item">
        <div className="assessment-sheet__title">
          Continuous assessment sheet
        </div>
        <div className="assessment-sheet__inner">
          <div className="assessment-sheet__head">
            <div className="expert-box">
              <label>Assessor</label>
              <div className="experts">
                <span className="expert-name">{`${user.first_name} ${user.last_name}`}</span>
              </div>
            </div>
            <div className="icon-box data-box">
              <label>Select a date</label>
              <DatePicker
                className="input-date"
                placeholderText="Select Date"
                selected={formData.date}
                dateFormat="MMMM d, yyyy"
                onChange={(value) => setFormField('date', value)}
              />
            </div>
          </div>
          <div className="assessment-sheet__tabel">
            <label>Selected trainees</label>
            <div className="assessment-sheet__tabel-wrapper">
              <div className="compositions-data">
                <span className="number">{trainees.length}</span>
              </div>
              <div className="assessment-sheet__tabel-inner scrollbar-style">
                <table className="assessment-sheet__users-list">
                  <tbody className="users-list__tbody">
                  {
                    trainees.map((trainee, index) => (
                      <tr key={index}>
                        <td>{trainee.lastNameShort}</td>
                        <td>{trainee.firstName}</td>
                        <td>{trainee.idNumber}</td>
                        <td>{trainee.company}</td>
                        <td>
                          <button className="delete-table-btn"
                                  onClick={() => removeTrainee(trainee.id)}>
                            <i className="fa fa-close"></i>
                          </button>
                        </td>
                      </tr>
                    ))
                  }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="competence-criteries__item">
        <div className="competence-criteries__inner">
          <div className="competence-criteries__table-wrapper  scrollbar-style">
            <table className="competence-criteries__table">
              <thead className="competence-criteries__thead">
              <tr>
                <th>Competence criteria</th>
                <th>
                  <span className="table-subtitle">Unsatisfactory</span>
                  <label className="table-checkbox">
                    <input
                      type="checkbox"
                      name="unsatisfied"
                      id="unsatisfactory-checkbox-main"
                      onChange={checkUnsatisfied}
                      checked={isUnsatisfied}
                    />
                    <span className="table-custom-checkbox"></span>
                  </label>
                </th>
                <th>
                  <span className="table-subtitle">Satisfactory</span>
                  <label className="table-checkbox">
                    <input
                      type="checkbox"
                      name="assessments"
                      id="satisfactory-checkbox-main"
                      checked={isSatisfied}
                      onChange={checkSatisfied}/>
                    <span className="table-custom-checkbox"></span>
                  </label>
                </th>
              </tr>
              </thead>
              <tbody className="competence-criteries__tbody">
              {
                assessmentCriteria.map(({ criteria }, index) => (
                  <tr key={index}>
                    <td>{criteria}</td>
                    <td>
                      <label className="table-radiobutton">
                        <input
                          type="radio"
                          name={index}
                          value="1"
                          checked={assessments[index] === '1'}
                          onChange={onAssessmentChange}/>
                        <span className="table-custom-checkbox"></span>
                      </label>
                    </td>
                    <td>
                      <label className="table-radiobutton">
                        <input
                          type="radio"
                          name={index}
                          value="2"
                          checked={assessments[index] === '2'}
                          onChange={onAssessmentChange}/>
                        <span className="table-custom-checkbox"></span>
                      </label>
                    </td>
                  </tr>
                ))
              }
              </tbody>
            </table>
          </div>


          {/* <!-- competence-criteries__control-panel accepts classes valid-criteries and invalid-criteries. Default state without this classes --> */}

          <div
            className={`competence-criteries__control-panel ${formData.assessments.length === assessmentCriteria.length ? 'valid-criteries' : 'invalid-criteries'}`}>
            <div className="control-panel__inform">
              <span className="remedial">Remedial</span>
              <span className="success">Successful</span>
            </div>
            <div className="control-panel__result">
              <span className="result-text">Result of the assessment</span>
              <span className="remedial-result"></span>
              <span className="success-result"></span>
            </div>
          </div>

        </div>

        {/* <!-- btn-param competence-criteries__btn default state without active-btn class --> */}
        <button
          className={`btn-param competence-criteries__btn ${formData.assessments.length || formData.unsatisfactory.length ? 'active-btn' : ''}`}
          onClick={save}
          disabled={(formData.unsatisfactory.length + formData.assessments.length) !== assessmentCriteria.length}
        >Send
        </button>
      </div>
      {
        modalResult.isVisible
          ? (<ModalResult
            isCompleted={modalResult.isCompleted}
            sessionNumber={modalResult.sessionNumber}
            message={modalResult.message}
            onCancel={() => {
              history.push(`/trainings/${training.id}/trainees`);
            }}
          />)
          : null
      }
    </div>
  );
}

export default Part2;
