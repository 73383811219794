import React from "react";
import Menu from "./menu.jsx";
class Subheader extends React.Component {
  constructor(props) {
    super(props);
    
  }
  render() {
    return (
        <div className="training-settings__wrapper">
          <div className="user-interface__subheader">
            <div className="container">
              <div className="user-interface__subheader-wrapper">
                <div className="user-interface__subheader-title">
                  training settings
                </div>
                <Menu/>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default Subheader;