import React from 'react';
import { DateRange } from 'react-date-range';
import date from '../../../helpers/date.js';
import PropTypes from 'prop-types';

function TrainingCalendar({
  startDate,
  endDate,
  onChange = () => {},
  keyCalendar = 'selection',
  isLocked = false,
}) {
  const selectionRange = {
    startDate: new Date(startDate),
    endDate: new Date(endDate),
    key: keyCalendar,
  };

  return (
    <div className="training__item training-calendar__item">
      <div className="calendar__wrapper">
        <label>Enter dates</label>
        <div className="icon-box calendar-date-box">
          <div className="training-date"></div>
          <div className="training-date"></div>
        </div>
      </div>
      <div className="calendar__inner">
        <DateRange
          navigatorRenderer={(currentFocusedDate, changeShownDate) => {
            return (
              <div className="calendar-navigation-wrapper">
                <div className="calendar-navigation">
                  <i
                    className="prev"
                    onClick={() => {
                      const month = currentFocusedDate.getMonth() - 1;
                      changeShownDate(currentFocusedDate.setMonth(month));
                    }}
                  >
                    PREV
                  </i>
                  {date.getMonthNames()[currentFocusedDate.getMonth()]}

                  <i
                    className="next"
                    onClick={() => {
                      const month = currentFocusedDate.getMonth() + 1;
                      changeShownDate(currentFocusedDate.setMonth(month));
                    }}
                  >
                    NEXT
                  </i>
                </div>
                <div className="calendar-navigation">
                  <i
                    className="prev"
                    onClick={() => {
                      const year = currentFocusedDate.getFullYear() - 1;
                      changeShownDate(currentFocusedDate.setFullYear(year));
                    }}
                  >
                    PREV
                  </i>
                  {currentFocusedDate.getFullYear()}
                  <i
                    className="next"
                    onClick={() => {
                      const year = currentFocusedDate.getFullYear() + 1;
                      changeShownDate(currentFocusedDate.setFullYear(year));
                    }}
                  >
                    NEXT
                  </i>
                </div>
              </div>
            );
          }}
          editableDateInputs={!isLocked}
          showMonthAndYearPickers={false}
          ranges={[selectionRange]}
          dateDisplayFormat="dd/MM/yyyy"
          onChange={!isLocked ? onChange : () => {}}
        />
      </div>
    </div>
  );
}

TrainingCalendar.propTypes = {
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  keyCalendar: PropTypes.string,
  isLocked: PropTypes.bool,
};

TrainingCalendar.defaultProps = {
  keyCalendar: 'selection',
  isLocked: false,
};

export default TrainingCalendar;
