import React from 'react';
import { NavLink } from 'react-router-dom';
import TextInput from '../../common/textInput.jsx';
import DatePicker from 'react-datepicker';
import EntityRepository from '../../../repositories/EntityRepository.js';
import ErrorRepository from '../../../repositories/ErrorRepository.js';
import { connect } from 'react-redux';
import { success, danger } from '../../../redux/actions/notify';
import AgreeChanges from '../../modals/modal-agree-changes';
import Autosuggest from '@/components/common/autosuggest';
import date from '@/helpers/date';

class TraineeCreate extends React.Component {
  constructor(props) {
    super();
    this.state = {
      formData: {
        first_name: '',
        last_name: '',
        birth_place: '',
        birth_date: null,
        id_number: '',
        email: '',
        company: '',
        notification: false,
        skill: [],
      },
      count: 0,
      errors: [],
      agreeModal: false,
      changes: false,
    };
  }

  createTrainee = async (event) => {
    event.preventDefault();
    let data = this.state.formData;
    try {
      const response = await EntityRepository.createEntity('/api/trainees/create', data);
      if (response.create === true) {
        this.props.success('User successfully created');
        this.props.history.push({
          pathname: '/settings/users/trainee',
          reference: response.user,
        });
      } else if (response.errors) {
        this.setState((state) => ({ ...state, errors: response.errors }));
      }
    } catch (error) {
      this.props.danger(error.message);
    }
  };

  clearErrorField = (fieldName) => {
    const { errors } = this.state;
    if (errors[fieldName] && errors[fieldName] != '') {
      this.setState({ errors: { ...errors, [fieldName]: '' } });
    }
  };

  setFormField = (name, value) => {
    this.clearErrorField(name);
    this.setState({
      changes: true,
      formData: { ...this.state.formData, [name]: value },
    });
  }

  handleChange = ({ target: { name, value } }) => this.setFormField(name, value);

  dateChange = (date) => this.setFormField('birth_date', date);

  changeIdNumber = ({ target: { name, value } }) => this.setFormField(name, value.toUpperCase());

  goBack = (event) => {
    event.preventDefault();

    if (this.state.changes) {
      return this.setState({ agreeModal: true });
    }

    return this.props.history.push('/settings/users/trainee');
  };

  confirmAgree = () => {
    this.setState({ agreeModal: false, changes: false });

    return this.props.history.push('/settings/users/trainee');
  };

  cancelAgree = () => {
    return this.setState({ agreeModal: false });
  };

  async componentDidMount() {}

  render() {
    const { errors, agreeModal, formData } = this.state;

    return (
      <>
        <section className="create-new-user users-edit-wrapper">
          <div className="container">
            <ul className="users-edit__breadcrumb">
              <li>
                <NavLink exact to={'/settings/users'}>
                  User
                </NavLink>
              </li>
              <li>
                <NavLink exact to={'/settings/users/trainee'}>
                  Trainee
                </NavLink>
              </li>
              <li className="active">
                <span>New trainee</span>
              </li>
            </ul>
            <div className="create-new-user__wrapper">
              <div className="create-new-user__top-box">
                <div className="create-new-user__badge">
                  <span>new</span>
                </div>
              </div>
              <div className="create-new-user__board">
                <form className="create-new-user__form">
                  <div className="create-new-user__board-wrapper">
                    <div
                      className={
                        'icon-box' +
                        ErrorRepository.getErrorClassName(errors, 'first_name')
                      }
                    >
                      <TextInput
                        inputName={'first_name'}
                        inputLabel={'First Name'}
                        changeInput={this.handleChange}
                        errors={errors}
                      />
                    </div>
                    <div
                      className={
                        'icon-box' +
                        ErrorRepository.getErrorClassName(errors, 'last_name')
                      }
                    >
                      <TextInput
                        inputName={'last_name'}
                        inputLabel={'Last Name'}
                        changeInput={this.handleChange}
                        errors={errors}
                      />
                    </div>
                    <div
                      className={`icon-box create-user-birth${ErrorRepository.getErrorClassName(
                        errors,
                        'birth_date'
                      )}`}
                    >
                      <label htmlFor="create-trainee-birth">
                        Date of birth
                      </label>
                      <DatePicker
                        selected={formData.birth_date}
                        onChange={this.dateChange}
                        dateFormat={date.inputDateFormat}
                        showYearDropdown
                        yearDropdownItemNumber={15}
                        maxDate={new Date()}
                      />
                      <span className="wrror-masage">
                        This field is incorrect
                      </span>
                    </div>
                    <div
                      className={`icon-box${ErrorRepository.getErrorClassName(
                        errors,
                        'birth_place'
                      )}`}
                    >
                      <TextInput
                        inputName={'birth_place'}
                        inputLabel={'Place of birth'}
                        changeInput={this.handleChange}
                        errors={errors}
                      />
                    </div>
                    <div
                      className={`icon-box${ErrorRepository.getErrorClassName(
                        errors,
                        'id_number'
                      )}`}
                    >
                      <TextInput
                        inputName={'id_number'}
                        inputLabel={'ID number'}
                        inputValue={formData.id_number}
                        changeInput={this.changeIdNumber}
                        errors={errors}
                      />
                    </div>
                    <div
                      className={`icon-box${ErrorRepository.getErrorClassName(
                        errors,
                        'email'
                      )}`}
                    >
                      <TextInput
                        inputName={'email'}
                        inputLabel={'Email'}
                        changeInput={this.handleChange}
                        errors={errors}
                      />
                    </div>
                    <div
                      className={`icon-box ${ErrorRepository.getErrorClassName(
                        errors,
                        'company'
                      )}`}
                    >
                      <Autosuggest
                        url="/api/users"
                        getFilters={(value) => ({
                          company: value,
                          users: 'trainee',
                        })}
                        inputProps={{
                          value: formData.company || '',
                          placeholder: 'Search by company…',
                          name: 'company',
                          onChange: this.handleChange,
                        }}
                        onSuggestionSelected={(event, { suggestion }) => {
                          this.setFormField('company', suggestion.company);
                        }}
                        theme={{
                          container: 'input-box',
                        }}
                        getSuggestionValue={(suggestion) => suggestion.company}
                        renderSuggestion={(suggestion) => (
                          <span key={suggestion.id}>
                            {suggestion.company}
                          </span>
                        )}
                      >
                        { inputProps => (
                          <>
                            <label htmlFor="company">Company</label>
                            <input
                              {...inputProps}
                            />
                            { (errors.company && errors.company != '') ? (
                              <span className="wrror-masage">{errors.company}</span>
                            ) : null}
                          </>
                        )}
                      </Autosuggest>
                    </div>
                  </div>
                  <div className="create-new-user__buttons">
                    <button
                      className="cancel-btn btn-param"
                      onClick={this.goBack}
                    >
                      Cancel
                    </button>
                    <button
                      className="create-btn btn-param"
                      onClick={this.createTrainee}
                    >
                      Create
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        {agreeModal ? (
          <AgreeChanges
            cancelAgree={this.cancelAgree}
            confirmAgree={this.confirmAgree}
          />
        ) : (
          ''
        )}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    success: (message) => dispatch(success(message)),
    danger: (message) => dispatch(danger(message)),
  };
}

export default connect(null, mapDispatchToProps)(TraineeCreate);
