
function tableReducer(state, action) {
  switch (action.type) {
    case 'addSelectedId':
      return {
        ...state,
        selectedIds: state.selectedIds.add(action.id),
      };
    case 'deleteSelectedId':
      state.selectedIds.delete(action.id);
      return {
        ...state
      };
    case 'setSelectedIds':
      return {
        ...state,
        selectedIds: new Set(action.ids),
      };
    case 'setIsAllChecked':
      return {
        ...state,
        isAllChecked: action.checked,
      };
    case 'addId':
      return {
        ...state,
        ids: state.ids.add(action.id),
      };
    case 'deleteId':
      state.ids.delete(action.id);
      return {
        ...state,
      };
  }
}

export default tableReducer;
