import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';

const assessmentStatusStyles = {
  // validated
  1: { root: 'assessment-status_validated', icon: 'fa-user-plus' },
  // remedial
  2: { root: 'assessment-status_remedial', icon: 'fa-user-times' },
  // pending
  3: { root: 'assessment-status_pending', icon: 'fa-user' },
};

const TraineesRow = ({ item, match, filter, onChange }) => {
  const { trainee, taskCount, traineeGroup, assessmentStatus } = item;
  const { url } = match;
  const path = `${url}/${trainee.id}`;
  const assessmentStatusStyle = assessmentStatusStyles[assessmentStatus] || {
    root: 'assessment-status_pending', icon: 'fa-user',
  };

  function getColumn(filter) {
    switch (filter) {
      case 'all':
        return (
          <>
            <td>
              <NavLink to={path} className="users-table__tbody-link">
                {taskCount.AT.validated}
              </NavLink>
            </td>
            <td>
              <NavLink to={path} className="users-table__tbody-link">
                {taskCount.AT.remaining}
              </NavLink>
            </td>
            <td>
              <NavLink to={path} className="users-table__tbody-link">
                {taskCount.MT.validated}
              </NavLink>
            </td>
            <td>
              <NavLink to={path} className="users-table__tbody-link">
                {taskCount.MT.remaining}
              </NavLink>
            </td>
            <td>
              <NavLink to={path} className="users-table__tbody-link">
                {taskCount.MT.validated_percent}%
              </NavLink>
            </td>
          </>
        );
        break;
      case 'at':
        return (
          <>
            <td>
              <NavLink to={path} className="users-table__tbody-link">
                {taskCount.AT.validated}
              </NavLink>
            </td>
            <td>
              <NavLink to={path} className="users-table__tbody-link">
                {taskCount.AT.remaining}
              </NavLink>
            </td>
          </>
        );
        break;
      case 'mt':
        return (
          <>
            <td>
              <NavLink to={path} className="users-table__tbody-link">
                {taskCount.MT.validated}
              </NavLink>
            </td>
            <td>
              <NavLink to={path} className="users-table__tbody-link">
                {taskCount.MT.remaining}
              </NavLink>
            </td>
            <td>
              <NavLink to={path} className="users-table__tbody-link">
                {taskCount.MT.validated_percent}%
              </NavLink>
            </td>
          </>
        );
        break;
    }
  }

  return (
    <tr
      className={`group-${traineeGroup.name} ${
        taskCount.MT.is_reached_validation_threshold ? 'valid-vait' : ''
      }`}
    >
      <td style={{ borderLeft: `6px solid ${traineeGroup.color}` }}>
        <NavLink to={path} className="users-table__tbody-link">
          {traineeGroup.name}
        </NavLink>
      </td>
      <td>
        <NavLink to={path} className="users-table__tbody-link">
          {trainee.last_name}
        </NavLink>
      </td>
      <td>
        <NavLink to={path} className="users-table__tbody-link">
          {trainee.first_name[0]}.
        </NavLink>
      </td>
      {getColumn(filter)}
      <td>
        <NavLink to={path} className="users-table__tbody-link">
          <span className="insufficient-level fa fa-minus"></span>
          <span className="enough-level fa fa-calendar-check-o"></span>
          <span className="validated fa fa-check"></span>
        </NavLink>
      </td>
      <td>
        <NavLink to={path} className="users-table__tbody-link">
          <span className={`assessment-status ${assessmentStatusStyle.root}`}>
            <i className={`fa ${assessmentStatusStyle.icon}`}></i>
          </span>
        </NavLink>
      </td>
      <td>
        <label className="table-checkbox">
          <input
            type="checkbox"
            className="trainee-checkbox"
            data-id={trainee.id}
            onChange={({ target: { checked } }) => {
              onChange(checked, item);
            }}
          />
          <span className="table-custom-checkbox"></span>
        </label>
      </td>
    </tr>
  );
};

export default withRouter(TraineesRow);
