import React from "react";

class RememberPassword extends React.Component {
    constructor (props) {
        super(props);
    }
    render() {
        const { onChange, sendEmail } = this.props;
        return (
                <>
                    <div className={this.props.overlayClass}>
                        <div className={this.props.modalClass} id="remember-password-modal">
                            <div className="modal-act-element__wrapper">
                                <div  onClick={() => this.props.closeModal()} className="modal__close">&times;</div>
                                <div className="modal-act-element__title">
                                    enter your email
                                </div>
                                <form className="password-recovery__form">
                                    <input type="email" onChange={e => {onChange(e.target.value)}}/>
                                    <span className="pointer" onClick={sendEmail}>Send</span>
                                    <span className="wrror-masage">
                                        This field is incorrect
								    </span>
                                </form>
                            </div>
                        </div>
                    </div>
                </>
        );
    }
}

export default RememberPassword;