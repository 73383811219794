import React, {createContext, useContext} from 'react';

const SyllabusContext = createContext({});

export const SyllabusProvider = ({syllabus, children}) =>(
  <SyllabusContext.Provider value={syllabus}>
    {children}
  </SyllabusContext.Provider>
);

export const useSyllabus = () => useContext(SyllabusContext);
