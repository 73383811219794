import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import date from '../../helpers/date';
import TableCheckbox from '../common/table/table-checkbox';
import TableRowFlag from '@/components/common/table/table-row-flag';

function TrainingRow({ row, match }) {
  const { url } = match;
  const editPass = `${url}/${row.id}/edit`;
  const instructorLimit = 4;
  const isManyInstructors = row.instructor_names.length > instructorLimit;
  let trClassName;

  if (row.completed) {
    trClassName = 'archived-table-data';
  } else {
    trClassName = '';
  }

  return (
    <tr className={trClassName}>
      <td>
        <TableCheckbox id={row.id} />
      </td>
      <td>
        <NavLink to={editPass} className="users-table__tbody-link">
          <TableRowFlag id={row.id}/>#{row.session_number}
        </NavLink>
      </td>
      <td>
        <NavLink to={editPass} className="users-table__tbody-link">
          {row.course_reference_number}
        </NavLink>
      </td>
      <td>
        <NavLink to={editPass} className="users-table__tbody-link">{row.syllabus_title}</NavLink>
      </td>
      <td>
        <NavLink to={editPass} className="users-table__tbody-link users-table__tbody-link--auto_height">
          {
            row.instructor_names.slice(0, instructorLimit).map((name, index) => {
              return (
                <React.Fragment key={index}>
                  <span className="instructor-name">{name}</span>
                  {
                    isManyInstructors && index === (instructorLimit - 1) && <span className="instructor-name" key={index}>...</span>
                  }
                </React.Fragment>
              );
            })
          }
        </NavLink>
      </td>
      <td>
        <NavLink to={editPass} className="users-table__tbody-link">
          <span className="trainee-number">{row.trainee_count}</span>
        </NavLink>
      </td>
      <td>
        <NavLink to={editPass} className="users-table__tbody-link">
          <span className="date-from">{date.getShortFormatted(row.date_start)}</span>
          {'>'}
          <span className="date-to">{date.getShortFormatted(row.date_end)}</span>
        </NavLink>
      </td>
      <td>
        <NavLink to={editPass}>
          <button title="Edit" className="edit-data"></button>
        </NavLink>
      </td>
    </tr>
  );
}

export default withRouter(TrainingRow);
