import React from "react";
import { connect } from "react-redux";
import { login, logout } from "../redux/actions/auth";
import { success, warning } from '../redux/actions/notify';
import { change as changeNotifications } from '../redux/actions/notification';
import RememberPassword from "./modals/remember-password.jsx";
import Request from "../helpers/request";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      error: "",
      overlayClass: "overlay hide",
      modalClass: "modal-act-element hide",
      eyeOpen: false,
      emailForgot: ''
    };
  }

  componentDidMount() {
    // redirect to home if already logged in
    if (this.props.auth.loggedIn) {
      this.props.history.push("/");
    }
  }

  closeModal = () => {
    this.setState({
      overlayClass: "overlay hide",
      modalClass: "modal-act-element hide",
    });
  };

  openModal = () => {
    this.setState({ overlayClass: "overlay", modalClass: "modal-act-element" });
  };

  changeField = (event) => {
    const { name, value } = event.target;
    this.setState((state) => ({
      ...state,
      error: "",
      [name]: value,
    }));
  };

  login = async (event) => {
    event.preventDefault();
    const { email, password } = this.state;

    await this.props.login(email, password);
    const { auth } = this.props;

    if (auth.loggedIn) {
      const notifications = await Request.get('/api/notifications/received');
      if (notifications && notifications.length) {
        this.props.changeNotifications(notifications);
      }
      this.props.history.push("/");
    } else if (!auth.loggedIn && auth.error) {
      this.setState((state) => ({
        ...state,
        error: auth.error.message,
      }));
    }
  };

  changeType = () => {
    const { eyeOpen } = this.state;
    document.querySelector('#sign-in-password').type = eyeOpen ? 'password' : 'text';
    this.setState({
      eyeOpen: eyeOpen ? false : true
    })
  };

   async sendEmail(){
    const { emailForgot } = this.state;
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailForgot)){
      const response = await Request.post('/api/forgot-pass', {email: emailForgot})
      if(response.send === true){
        this.props.success('Mail was sended successfully');
        this.closeModal();
      }else{
        this.props.warning(response.message);
      }
    }else{
      this.props.warning("Pleas, write a right mail format.");
    }
  }

  onChange = (value) => {
    this.setState({
      emailForgot: value
    })
  }

  render() {
    const { error, email, password, eyeOpen } = this.state;
    return (
      <>
        <header className="sign-in-page__header">
          <div className="header-logo">
            <a href="#">
              <img src={"/adminApp/src/assets/img/white-logo.svg"} alt="logo" />
            </a>
          </div>
        </header>
        <section className="login-container">
          <div className="login-container__wrapper">
            <div className="login__subtitle">E-syllabus</div>
            <div className="login__title">log in</div>
            <form className="login__form">
              <label htmlFor="sign-in-email">Email address</label>
              <input
                id="sign-in-email"
                type="email"
                name="email"
                value={email}
                onChange={this.changeField}
                className="form-control"
              />
              <label htmlFor="sign-in-password">Password</label>
              <div className={"password__wrappew"}>
                <input
                  type="password"
                  className={"form-control"}
                  id="sign-in-password"
                  name="password"
                  value={password}
                  onChange={this.changeField}
                />
                <span className={`password-icon ${eyeOpen ? 'active' : ''}`} id="open-pass" onClick={this.changeType}></span>
              </div>
              {error != "" && (
                <div className="icon-box error">
                  <span className="wrror-masage">{error}</span>
                </div>
              )}
              <button type="submit" onClick={this.login}>
                Log in
              </button>
            </form>
            <div className="login__remember-password">
              <button
                onClick={this.openModal}
                className="remember-password__btn"
              >
                Forgotten password?
              </button>
            </div>
          </div>
        </section>
        <RememberPassword
          overlayClass={this.state.overlayClass}
          modalClass={this.state.modalClass}
          closeModal={this.closeModal}
          onChange={this.onChange}
          sendEmail={this.sendEmail.bind(this)}
        />
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    auth: state.authReducer,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    login: (email, password) => dispatch(login(email, password)),
    logout: () => dispatch(logout()),
    success: (message) => dispatch(success(message)),
    warning: (message) => dispatch(warning(message)),
    changeNotifications: (notifications) => dispatch(changeNotifications(notifications)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Login);
