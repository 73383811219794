import React from 'react';
import PropTypes from 'prop-types';
import date from '@/helpers/date';

function ModalResult({
  sessionNumber = null,
  isCompleted = false,
  message,
  onCancel = () => {},
}) {
  const text = message ? message : (isCompleted
    ? 'Assessment have been successfully completed!'
    : 'Training not completed');

  return (
    <div className="overlay" onClick={onCancel}>
      <div className="modal-act-element" id="validation-assessment-modal">
        <div className="modal-act-element__wrapper">
          <div className="modal-act-element__head">
            <div className="modal-act-element__title">
              <div
                className={isCompleted ? 'modal-archiving-element__icon' : 'modal-failure-element__icon'}
              >
              </div>
              <div>
                <div className="modal-act-element__training-name">
                  Training session {
                    sessionNumber
                      ? (
                        <span>#{sessionNumber}</span>
                      )
                      : null
                  }
                </div>
                <div className="modal-act-element__subtitle">
                  Assessment validation
                </div>
              </div>

            </div>
            <div className="modal-act-element__sesion-date">
              <span>{date.getFormatted()}</span>
            </div>
          </div>
          <div className={`modal-delete-element__warning modal-delete-element__warning--${isCompleted ? 'success' : 'failure'}`}>
            {text}
          </div>
        </div>
      </div>
    </div>
  );
}

ModalResult.propTypes = {
  onCancel: PropTypes.func.isRequired,
  isCompleted: PropTypes.bool,
  sessionNumber: PropTypes.string,
  message: PropTypes.string,
};

ModalResult.defaultProps = {
  isCompleted: false,
};

export default ModalResult;
