import React, {useCallback, createRef, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import PubSub from 'pubsub-js';
import { useDispatch } from 'react-redux';
import Request from '../../helpers/request';
import { success, danger, warning } from '../../redux/actions/notify';

function ImportDropzone({
  url = '', importResult, setImportResult
}) {
  const dispatch = useDispatch();
  const [file, setFile] = useState({});


  const acceptedSizeInMb = 1;
  const acceptedSizeInBytes = acceptedSizeInMb * 1024 * 1024;
  const onDrop = useCallback((acceptedFiles) => {
    if (!acceptedFiles.length) return;
    const acceptedFile = acceptedFiles[0];
    setFile(acceptedFile);

    const sendData = async () => {
      const formData = new FormData();
      formData.append('file', acceptedFile);
      setImportResult({
        ...importResult,
        inProgress: true,
      });
      try {
        const response = await Request.post(url, formData);
        const { isSuccess } = response;
        if(isSuccess && response.importedCount > 0) {
          PubSub.publish('Update Table', true);
          dispatch(success('File successfully imported'));
        } else if (isSuccess && response.importedCount === 0) {
          dispatch(warning('Don`t have any rows for update'));
        } else if (response.errors) {
          dispatch(danger(response.errors[0]));
        }
      } catch (error) {
        dispatch(danger(error.errors ? error.errors[0] : 'Server error'));
      } finally {
        setImportResult({
          ...importResult,
          inProgress: false,
        });
      }
    };
    sendData();
  }, []);

  const dropzoneRef = createRef();

  const ImportStatus = () => {
    if (!file.name) {
      return (<span className="import-btn"></span>);
    }

    if (importResult.inProgress) {
      return (<i className="import-preloader__loader"></i>);
    }

    if (importResult.totalCount) {
      return (
        <>
          <p>{file.name} imported.</p>
          <p>Total rows count: {importResult.totalCount}</p>
          <p>Imported rows count: {importResult.importedCount}</p>
        </>
      );
    }

    return (<span>{file.name}</span>);
  };

  return (
    <div className="adding-panel">
      <Dropzone ref={dropzoneRef} onDrop={onDrop}>
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div className={`import-files__box ${isDragActive ? 'drag-drop-class' : ''}`} {...getRootProps()}>
            <input {...getInputProps({
              maxsize: acceptedSizeInBytes,
              multiple: false,
              accept: '.csv',
            })} />
            <ImportStatus />
          </div>
        )}
      </Dropzone>
      <button
        className="import-files__btn"
        onClick={() => {
          dropzoneRef.current.open();
        }}
      >
        Import
      </button>
    </div>
  );
}

ImportDropzone.propTypes = {
  url: PropTypes.string.isRequired,
};

export default ImportDropzone;
