import React from "react";

function IconDownload() {
  return (
    <svg
      width="17px"
      height="17px"
      viewBox="0 0 17 17"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
      >
        <g
          transform="translate(-1213.000000, -287.000000)"
          stroke="#FFFFFF"
          strokeWidth="2"
        >
          <g transform="translate(0.000000, 180.000000)">
            <g>
              <g id="B" transform="translate(1079.000000, 98.000000)">
                <g id="A" transform="translate(74.000000, 0.000000)">
                  <g transform="translate(51.000000, 0.000000)">
                    <g transform="translate(10.000000, 9.000000)">
                      <g
                        id="DL"
                        transform="translate(0.000000, 10.000000)"
                        strokeLinejoin="round"
                      >
                        <polyline
                          id="Stroke-4"
                          points="15 0 15 6 0 6 0 0"
                        ></polyline>
                      </g>
                      <g transform="translate(4.000000, 0.000000)">
                        <line
                          x1="3.49850719"
                          y1="0.989061123"
                          x2="3.49850719"
                          y2="12.4899998"
                          id="Stroke-1"
                          strokeLinejoin="round"
                        ></line>
                        <polyline
                          id="Stroke-3"
                          points="6.38377594 9.53266667 3.49850719 12.4919167 0.613238438 9.53266667"
                        ></polyline>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default IconDownload;
