import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Pages from './pages/pages';
import SyllabusMain from './syllabus-main';
import TrainingHeader from '@/components/frontend/trainings/common/TrainingHeader';

function Syllabus() {
  const { path } = useRouteMatch();

  return (<>
    <TrainingHeader/>
    <section className="training-session-trainee-list">
      <div className="container">
        <Switch>
          <Route path={`${path}/`} component={SyllabusMain} exact/>
          <Route path={`${path}/pages`} component={Pages}/>
        </Switch>
        <div className="copyright">
          © Société Air France - All rights reserved
        </div>
      </div>
    </section>
  </>);
}

export default Syllabus;
