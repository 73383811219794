import React, { useState, useEffect } from 'react';
import Request from '@/helpers/request';
import { ROLE_INSTRUCTOR } from '@/helpers/role';
import Select from 'react-select';
import TextInput from '@/components/common/textInput';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { danger } from '@/redux/actions/notify';

const RenderRows = ({ data, remove }) => {

  return (
    <ul className="syllabus-parts__descr-list">
      {data.map((item, key) => (
        <li key={key}>
          {item.number} | {item.syllabus_title}
          {item.instructors.map((instructor, index) => (
            <span key={index}>{instructor.first_name} {instructor.last_name}</span>
          ))}
          <button className="remove-btn" onClick={()=> remove(item.id)}>&times;</button>
        </li>
      ))}
    </ul>
  );
};

RenderRows.propTypes = {
  data: PropTypes.array,
  remove: PropTypes.func,
};

function CourseReference() {
  const dispatch = useDispatch();
  const [courseReference, setCourseReference] = useState([]);
  const [syllabus, setSyllabus] = useState([]);
  const [instructors, setInstructors] = useState([]);
  const [data, setData] = useState({
    number: '',
    syllabus: 0,
    instructors: [],
  });

  const handleChange = (e) => {
    setData({ ...data, number: e.target.value });
  };

  const handleChangeSelect = (e, isMulti = false) => {
    let array = [];
    if (isMulti) {
      if (e === null) {
        setData({ ...data, instructors: [] });

        return null;
      }

      e.map(item => {
        array.push(item.value);
      });
      setData({ ...data, instructors: array });
    }else{
      if (e === null) {
        setData({ ...data, syllabus: 0 });

        return null;
      }
      setData({ ...data, syllabus: e.value });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const [responseCourseReference, responseSyllabus, responseInstructors] = await Promise.all([
        Request.get('api/course-reference'),
        Request.get(`api/syllabi?filters=${JSON.stringify({ courseReference: true, start: 0, limit: 100000 })}`),
        Request.get(`api/users?filters=${JSON.stringify({ start: 0, limit: 100000, role: ROLE_INSTRUCTOR })}`),
      ]);

      const syllabusOptions = responseSyllabus.items
        .map(item => ({
          value: item.id, label: item.title,
        }));

      const instructorsOptions = responseInstructors.items
        .map(item => ({
          value: item.id, label: `${item.first_name} ${item.last_name}`,
        }));

      setSyllabus(syllabusOptions);
      setInstructors(instructorsOptions);
      setCourseReference(responseCourseReference);
    };
    fetchData();
  }, []);

  const save = async () => {
    const response = await Request.post('/api/course-reference', data);

    if (response.create) {
      window.location.reload();
    }else{
      dispatch(danger(`Number: ${response.errors.number}`));
    }
  };

  const remove = async (id) => {
    const response = await Request.delete(`/api/course-reference/${id}`);
    if (response.delete) {
      window.location.reload();
    }
  };

  return (
    <>
      <div className="training-settings__wrapper">
        <div className="user-interface__subheader">
          <div className="container">
            <div className="user-interface__subheader-wrapper">
              <div className="user-interface__subheader-title">Course reference</div>
            </div>
          </div>
        </div>
      </div>
      <section className="account">
        <div className="container">
          <div>
            <div className="account__password-wrap course-reference__item">
              <div className="password__wrappew">
                <TextInput
                  inputName={'number'}
                  inputLabel={'Course reference'}
                  changeInput={(e) => handleChange(e)}
                  inputValue={data.number}
                />
              </div>
              <div className="password__wrappew">
                <Select
                  isClearable
                  isSearchable
                  options={syllabus}
                  onChange={(e) => handleChangeSelect(e)}
                />
              </div>
              <div className="password__wrappew">
                <Select
                  isMulti
                  isClearable
                  isSearchable
                  options={instructors}
                  onChange={(e) => handleChangeSelect(e, true)}
                />
              </div>
              <div className="password__wrappew">
                <button
                  className="save-btn btn-param pointer"
                  onClick={() => save()}
                >
                  Save
                </button>
              </div>
            </div>
          </div>

          <div className="course-reference__item-inner">
            <div className="account__password-wrap">
              <div className="syllabus-parts__descr">
                <RenderRows data={courseReference} remove={(id) => remove(id)}/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CourseReference;
