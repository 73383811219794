import React, { useState } from "react";

function SelectGroup({
  groups = [],
  activeIndex = -1,
  onSelect = () => {},
}) {
  const [isOpen, setIsOpen] = useState(false);
  const activeGroup = groups[activeIndex] || {};
  return (
    <div className={`custom-select ${isOpen ? 'active' : ''}`}>
      <div className="select__header" onClick={() => setIsOpen(!isOpen)}>
        <div className="select__curent">
          <span className="select__bage" style={{backgroundColor: activeGroup.color}}>
            {activeGroup.name}
          </span>
        </div>
      </div>
      <div className="select__body">
        {
          groups.map((group, index) => {
            return (
              <div 
                key={index}
                className="select__item" 
                onClick={() => {
                  onSelect(index);
                  setIsOpen(false);
                }}>
                <span 
                  className="select__bage" 
                  style={{backgroundColor: group.color || '#dddddd'}}>
                    {group.name || ''}
                </span>
              </div>
            );
          })
        }
      </div>
    </div>
  );
}

export default SelectGroup;
