import { SHOW, HIDE } from "../actions/loader";

const initialState = {
  showLoader : 0
};

export default function loaderReducer(state = initialState, action) {

  switch (action.type) {
    case SHOW:
      return {
        showLoader: 1
      };
    case HIDE:
      return {
        showLoader: 0
      };
  }
  return state;
}