export const FILTER_SAVE = "SAVE";

export function save(path, data) {
  return {
    type: FILTER_SAVE,
    path,
    data,
  };
}

