import React from "react";

function Progressbar({ value = 0 }) {
  const radius = 52;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (value / 100) * circumference;

  return (
    <div className="statistic-card__progress-bar">
      <svg className="progress-ring" width="112" height="112">
        <circle
          className="progress-ring__circle"
          stroke="#50E3C2"
          strokeWidth="8"
          cx="56"
          cy="56"
          r={radius}
          fill="#6C81A2"
          style={{
            strokeDasharray: `${circumference} ${circumference}`,
            strokeDashoffset: offset,
          }}
        ></circle>
      </svg>
      <div className="progress-per">
        <input type="text" className="percent" value={value} disabled />
      </div>
    </div>
  );
}

export default Progressbar;
