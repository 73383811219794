import React from "react";
import date from "../../helpers/date";
import { ACTION_RESTORE } from './constants';

const ModalArchiving = ({
  config,
  cancelPopup,
  confirmPopup,
}) => {
  const eventTitle = config.action == ACTION_RESTORE ? 'Restoring' : 'Archiving';
  return (
    <div className="overlay" data-event={config.action}>
        <div
          className="modal-act-element"
          id="archiving-element-modal"
        >
          <div className="modal-act-element__wrapper">
            <div className="modal-act-element__head">
              <div className="modal-act-element__title">
                <div className="modal-archiving-element__icon">
                  <span></span>
                </div>
                <div>
                  <div className="modal-act-element__training-name">
                    {config.entityName}{" "}
                    <span>{(config.entityTitles && config.entityTitles.length) ? `#${config.entityTitles.join(', #')}` : ''}</span>
                  </div>
                  <div className="modal-act-element__subtitle">
                    {eventTitle}{" "}
                    {config.popupName}
                  </div>
                </div>
              </div>
              <div className="modal-act-element__sesion-date">
                <span>{date.getFormatted()} </span>
              </div>
            </div>
            <div className="modal-delete-element__warning">
              Are you sure you want to {config.action} this{" "}
              {config.popupName}?
            </div>
          </div>
          <div className="modal-act-element__btn">
          <button className="no-delete__btn btn-param" onClick={cancelPopup}>Cancel</button>
          <button
            className="archive__btn btn-param"
            onClick={confirmPopup}
        >Yes, {config.action} it</button>
          </div>
        </div>
      </div>
  );
};

export default ModalArchiving;
