import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  NavLink,
  useHistory,
  useLocation,
  useParams
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTraining } from './training-context';
import date from '../../../../helpers/date.js';
import ModalRevision from '../syllabus/modals/modal-revision';
import IconCalendar from '../../../common/icons/icon-calendar';
import {
  ROLE_TRAINEE
} from '@/helpers/role';
import Pdf from '@/components/common/training-pdf';
import {Tooltip} from "@mui/material";

const SyllabusLink = ({ title }) => {
  const { trainingId, traineeId } = useParams();

  if (trainingId && traineeId) {
    return (
      <Tooltip title="Syllabus">
        <span>
          <NavLink to={`/trainings/${trainingId}/trainees/${traineeId}/syllabus`} className="syllabus__code">{title}</NavLink>
        </span>
      </Tooltip>
    );
  } else if (trainingId) {
    return (
        <Tooltip title="Syllabus">
          <span>
            <NavLink to={`/trainings/${trainingId}/syllabus`} className="syllabus__code">{title}</NavLink>
          </span>
        </Tooltip>
      )
  }

  return <span className="syllabus__code">{title}</span>;
};

SyllabusLink.propTypes = {
  title: PropTypes.string,
};

function TrainingHeader() {
  const { goBack, push: historyPush } = useHistory();
  const { roles } = useSelector(state => state.authReducer.user);
  const isTrainee = roles.includes(ROLE_TRAINEE);
  const { pathname } = useLocation();
  const trainingDetail = useTraining();
  const hasSyllabus = pathname.includes('/syllabus');
  const [showModalRevision, setShowModalRevision] = useState(false);
  const { trainingId, traineeId } = useParams();
  const [download, setDownload] = useState(false);

  const sessionBadge = (
    <>
      {trainingDetail.session_number}
      <span className="training-session__status-bage">
        {trainingDetail.status}
      </span>
    </>
  );

  return (
    <div className="training-settings__wrapper">
      <div className="user-interface__subheader">
        <div className="container">
          <div className={`user-interface__subheader-wrapper ${hasSyllabus ? 'validation-assessment__subheader-wrapper' : ''}`}>
            <ul
              className={`training-validation__breadcrumb ${trainingDetail.completed ? 'completed-training-session' : 'current-training-session'}`}>
              <li>
                <i className="fa fa-angle-left pointer" onClick={() => goBack()}></i>
                {
                  isTrainee ? (
                    <span className="training-session__number">
                      {sessionBadge}
                    </span>
                  ) : (
                    <NavLink to={`/trainings/${trainingId}/trainees`} className="training-session__number">
                      {sessionBadge}
                    </NavLink>

                  )
                }

              </li>
              <li>
                <SyllabusLink title={trainingDetail.syllabus_title}/>
              </li>
              {
                hasSyllabus
                  ? (<>
                    <li><span className="version__code">{`Version ${('0' + trainingDetail.syllabus_version).slice(-2)}`}</span></li>
                    <li className="prev-version-box">
                      <Tooltip title="Revisions" >
                        <button
                          className="prev-version__btn"
                          onClick={() => setShowModalRevision(true)}></button>
                      </Tooltip>
                    </li>
                    <li>
                      <Pdf buttonClass="download-file__btn" trainee={traineeId} training={trainingId} isTrainee={isTrainee}/>
                    </li>
                  </>)
                  : (
                    <>
                      <li>
                        <Tooltip title="Statistic" >
                          <a
                            className="statistics__btn"
                            onClick={() => {
                              historyPush({
                                pathname: '/stats',
                                state: { trainingId },
                              });
                            }}>
                          </a>
                        </Tooltip>
                      </li>
                      <li>
                        <Pdf buttonClass="download-file__btn" styles={{ margin: '0px 0px 4px 4px' }} trainee={traineeId} training={trainingId} isTrainee={isTrainee}/>
                      </li>
                    </>)
              }
            </ul>
            <div className="session-period">
              <span className="calendar-icon">
                <IconCalendar />
              </span>
              <span className="date-from">{date.getShortFormatted(trainingDetail.date_start)}</span>
              <span className="date-to">{date.getShortFormatted(trainingDetail.date_end)}</span>
            </div>
          </div>
        </div>
      </div>
      {
        showModalRevision
          ? <ModalRevision
            syllabusId={trainingDetail.syllabus_id}
            onCancel={() => setShowModalRevision(false)} />
          : null
      }
    </div>
  );
}

export default TrainingHeader;
