import React from 'react';
import Request from '../../helpers/request';
import Tabs from './common/syllabus-tabs.jsx';

import TabContext from './tabs/tab-context.js';
import TabDescription from './tabs/tab-description.jsx';
import TabIntroduction from './tabs/tab-introduction.jsx';
import TabTaskEdition from './tabs/tab-task-edition.jsx';
import TabPart1 from './tabs/tab-part1.jsx';
import TabPart2 from './tabs/tab-part2.jsx';

import CreateRevisionModal from './common/modal-create-revision.jsx';
import ModalArchiving from '../common/modal-archiving.jsx';
import ModalRevision from './modals/modal-revision.jsx';
import { ACTION_ARCHIVE, ACTION_RESTORE } from '../common/constants.js';
import EntityRepository from '../../repositories/EntityRepository';
import { connect } from 'react-redux';
import { hide, show } from '../../redux/actions/loader';
import { success, danger, warning } from '../../redux/actions/notify';
import AgreeChanges from '../modals/modal-agree-changes';
import {
  ROLE_ADMIN,
  ROLE_PRODUCT_MANAGER,
  ROLE_QUALITY_MANAGER,
  ROLE_INSTRUCTOR
} from '@/helpers/role';
import ImportRevision from '@/components/syllabus/inputs/ImportRevision.jsx';
import Pdf from "@/components/common/syllabus-pdf";
import {Tooltip} from "@mui/material";

class SyllabusEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        id: null,
        uid: null,
        model: '',
        course_reference_number: '',
        title: '',
        introduction: '',
        revision_comment: '',
        practical: '',
        description: '',
        validation_rules: '',
        assessment_part: '',
        assessment_description: '',
        assessment_criteria: [],
        validation: 0,
        date: null,
        version: 0,
        ata_ids: [],
        tasks: [],
        revisions: [],
        is_draft: true,
        is_new_version: true,
      },
      isArchived: false,
      errors: {},
      modalArchivingConfig: {
        showModal: false,
      },
      modalRevisionConfig: {
        showModal: false,
        revisions: [],
      },
      activeTab: {},
      createRevisionModal: false,
      comment: '',
      isDisable: true,
      logo: '',
      photo: '',
      agreeModal: false,
      changes: false,
      isFetch: false,
    };

    this.tabs = [
      {
        active: true,
        key: 'description',
        title: 'Description',
        isValidated: true,
      },
      {
        active: false,
        key: 'introduction',
        title: 'Introduction',
        isValidated: true,
      },
      {
        active: false,
        key: 'part1',
        title: 'Ata',
        wrapperClass: 'create-syllabus-dash-wrapper',
        isValidated: true,
      },
      {
        active: false,
        key: 'edition',
        title: 'Task edition',
      },
      {
        active: false,
        key: 'part2',
        title: 'Assessment',
        wrapperClass: 'create-syllabus-dash-wrapper',
        isValidated: true,
      },
    ];

    this.ataIdToCode = {};
    this.tabValidation = null;
    const { roles } = props.user;
    this.canEdit = roles.includes(ROLE_ADMIN) ||
      roles.includes(ROLE_PRODUCT_MANAGER) ||
      roles.includes(ROLE_INSTRUCTOR) ||
      roles.includes(ROLE_QUALITY_MANAGER);
  }

  clearError = (fieldName) => {
    const { errors } = this.state;

    if (errors[fieldName] && errors[fieldName] != '') {
      this.setError(fieldName, '');
    }
  };

  updateData = () => {
    this.componentDidMount();
  };

  async componentDidMount() {
    const { uid } = this.props.match.params;
    this.uid = uid;
    this.props.loaderShow();
    this.setState({
      activeTab: this.tabs[0],
    });
    const response = await Request.get(`/api/syllabi/${uid}`);
    this.setFormDataFromResponse(response);
    this.props.loaderHide();
  }

  setFormDataFromResponse = (data) => {
    const ataIds = [];
    const ataIdToCode = {};
    const { atas } = data;

    if (Array.isArray(atas)) {
      for (const ata of atas) {
        ataIds.push(ata.id);
        ataIdToCode[ata.id] = ata.code;
      }
    }

    const formData = {
      id: data.id,
      uid: data.u_id,
      model: data.model || '',
      course_reference_number: data.course_reference_number || '',
      title: data.title || '',
      photo: data.photo || '',
      logo: data.logo || '',
      introduction: data.introduction || '',
      edition: data.edition || '',
      practical: data.practical || '',
      description: data.description || '',
      validation_rules: data.validation_rules || '',
      assessment_part: data.assessment_part || '',
      assessment_description: data.assessment_description || '',
      assessment_criteria: data.assessment_criteria || [],
      validation: data.validation || 0,
      version: data.version || 0,
      date: new Date(data.date),
      ata_ids: ataIds,
      tasks: Array.isArray(data.tasks) ? data.tasks : [],
      revisions: Array.isArray(data.revisions) ? data.revisions : [],
      is_draft: data.is_draft,
      is_new_version: true,
    };

    for (const task of formData.tasks) {
      ataIdToCode[task.ata_id] = task.ata_code;
    }

    this.ataIdToCode = ataIdToCode;

    this.setState({
      isArchived: data.archived,
      formData,
    });
  };

  setFormField = (field, value) => {
    this.setState({ changes: true });
    this.clearError(field);
    this.setState((state) => ({
      ...state,
      formData: { ...this.state.formData, [field]: value },
    }));
  };

  setError = (field, message) => {
    this.setState((state) => ({
      ...state,
      errors: { ...state.errors, [field]: message },
    }));
  };

  handleChange = (e) => {
    this.setState({ changes: true });

    const { name, value, checked } = e.target;

    if (name === 'is_draft' || name === 'is_new_version') {
      this.setFormField(name, checked);
    } else {
      this.setFormField(name, value);
    }
  };

  setActiveTab = (tab) => {
    this.setTabValidation(null);
    this.setState((state) => ({ ...state, activeTab: tab }));
  };

  createRevision = (event) => {
    event.preventDefault();
    if (!this.state.formData.is_new_version) {
      this.save();
    } else {
      this.setState({ createRevisionModal: true });
    }
  };

  setCommitRevision = (text) => {
    this.setState({ comment: text });
  };

  closeCteateRevision = () => {
    this.setState({ createRevisionModal: false });
    this.setState({ comment: '' });
  };

  save = async () => {
    this.setState({ isFetch: true });
    const { logo, photo } = this.state;
    let images = new FormData();

    images.append('logo', logo);
    images.append('photo', photo);

    if (!this.checkTabValidation()) return;
    for (const tab of this.tabs) {
      if (!tab.isValidated) {
        this.setActiveTab(tab);
        if (!this.checkTabValidation()) {
          return;
        }
      }
    }
    const { formData } = this.state;

    formData.revision_comment = this.state.comment;

    delete formData.logo;
    delete formData.photo;

    const response = await Request.put(
      `/api/syllabi/${formData.uid}`,
      formData
    );
    if (response.id) {
      const res = await Request.post(
        `/api/syllabi/${response.id}/save-images`,
        images
      );
      if (res.upload === false) {
        this.props.warning(res.message);
      }
      this.props.success('Syllabus successfully saved');

      return this.props.history.push('/settings/syllabi');
    } else {
      this.props.danger('Server error');
    }
    this.setState({ isFetch: false });
  };

  setTabValidation = (tabValidation) => {
    this.tabValidation = tabValidation;
  };

  checkTabValidation = () => {
    const { activeTab } = this.state;
    const validationResult =
      !this.tabValidation || this.tabValidation(this.setError);
    activeTab.isValidated = validationResult;

    return validationResult;
  };

  openModalRevision = async () => {
    const { modalRevisionConfig, formData } = this.state;
    let revisions = modalRevisionConfig.revisions;

    if (!revisions.length) {
      const loadedRevisions = await Request.get(
        `/api/syllabi/${formData.uid}/revisions`
      );

      if (loadedRevisions && loadedRevisions.length) {
        revisions = loadedRevisions;
      } else {
        return;
      }
    }

    this.setState({
      modalRevisionConfig: {
        showModal: true,
        revisions,
      },
    });
  };

  cancelModalRevision = () => {
    this.setState((state) => ({
      ...state,
      modalRevisionConfig: {
        ...state.modalRevisionConfig,
        showModal: false,
      },
    }));
  };

  openModalArchiving = () => {
    const { formData, isArchived } = this.state;
    let action;
    let eventTitle;

    if (isArchived) {
      action = ACTION_RESTORE;
      eventTitle = 'Restoring';
    } else {
      action = ACTION_ARCHIVE;
      eventTitle = 'Archiving';
    }

    if (formData.id !== undefined) {
      this.setState({
        modalArchivingConfig: {
          showModal: true,
          popupName: 'syllabus',
          entityName: formData.title,
          entityTitles: [formData.id],
          selectedIds: [formData.id],
          eventTitle,
          action,
        },
      });
    }
  };

  confirmModaArchiving = async () => {
    const { selectedIds, action } = this.state.modalArchivingConfig;

    if (action === ACTION_ARCHIVE) {
      const response = await EntityRepository.archiveEntities(
        'api/syllabi',
        selectedIds
      );
      if (response.archived === true) {
        this.props.history.push('/settings/syllabi');
      }
    } else if (action === ACTION_RESTORE) {
      const response = await EntityRepository.restoreEntities(
        'api/syllabi',
        selectedIds
      );
      if (response.restored === true) {
        this.props.history.push('/settings/syllabi');
      }
    }
    this.cancelModaArchiving();
  };

  cancelModaArchiving = () => {
    this.setState({
      modalArchivingConfig: {
        showModal: false,
        entityName: '',
        entityTitles: [],
        selectedIds: [],
      },
    });
  };
  setImage = (field, value) => {
    this.setState({ [field]: value });
  };

  goBack = (event) => {
    event.preventDefault();

    if (this.state.changes) {
      return this.setState({ agreeModal: true });
    }

    return this.props.history.push('/settings/syllabi');
  };

  confirmAgree = () => {
    this.setState({ agreeModal: false, changes: false });

    return this.props.history.push('/settings/syllabi');
  };

  cancelAgree = () => {
    return this.setState({ agreeModal: false });
  };

  setRevisions = (revisions) => {
    this.setState({ changes: true });
    this.setFormField('revisions', revisions);
  }

  render() {
    const {
      activeTab,
      formData,
      errors,
      isArchived,
      modalArchivingConfig,
      modalRevisionConfig,
      comment,
      createRevisionModal,
      isDisable,
      agreeModal,
      isFetch,
    } = this.state;

    const { id, uid, title, version, is_draft } = formData;
    const { key: activeTabKey, wrapperClass = '' } = activeTab;

    return (
      <section className="edit-trainee__wrapper users-edit-wrapper">
        <div className="container">
          <div className="users-edit-head__inner">
            <ul className="users-edit__breadcrumb">
              <li>
                <a href="#/settings/syllabi">Syllabus</a>
              </li>
              <li className="active">
                <span>{title}</span>
              </li>
              <li className="version-number__wrapper">
                <span className="version-number">{
                  !is_draft
                    ? `Version ${(
                      '0' + version
                    ).slice(-2)}`
                    : 'Draft'
                }</span>
              </li>
            </ul>
            <div className="version-panel">
              <div className="version-panel__item">
                <div className="create-new-user__badge">
                  <span>Draft:
                    <input
                      className="version-checkbox"
                      type="checkbox"
                      name="is_draft"
                      onChange={this.handleChange}
                      checked={formData.is_draft}
                    />
                  </span>
                </div>
              </div>
              <div className="version-panel__item">
                <div className="create-new-user__badge">
                  <span>create new version:
                    <input
                      className="version-checkbox"
                      type="checkbox"
                      name="is_new_version"
                      onChange={this.handleChange}
                      defaultChecked={formData.is_draft}
                    />
                  </span>
                </div>
              </div>
              <div className="version-panel__item">
                <div className="create-new-user__badge">
                  <ImportRevision syllabus={id} setRevisions={this.setRevisions}/>
                </div>
              </div>
            </div>
          </div>
          <div className="create-new-user__wrapper">
            <div className="create-new-user__top-box">
              <div className="create-new-user__badge">
                <span>editing</span>
              </div>
              <div className="users-edit__btn-box">
                <Tooltip title={"Training"}>
                  <button
                    className="preview"
                    onClick={() => this.props.history.push(`/syllabus-preview/${uid}`)}
                  >
                    <span></span>
                  </button>
                </Tooltip>
                <Tooltip title={"Revisions"}>
                  <button
                    className="prev-version"
                    onClick={this.openModalRevision}
                  >
                    <span></span>
                  </button>
                </Tooltip>
                  <Pdf buttonClass="save-file-btn" syllabus={formData}/>
                <Tooltip title={"Archive"}>
                  <button
                    className="add-archive-btn"
                    onClick={this.openModalArchiving}
                  >
                  <span
                    className={isArchived ? 'restore-archive' : 'add-archive'}
                  ></span>
                  </button>
                </Tooltip>
              </div>
            </div>
            <div className="new-syllabus__main-panel edit-syllabus__main-panel">
              <TabContext.Provider
                value={{
                  handleChange: this.handleChange,
                  setFormField: this.setFormField,
                  formData,
                  errors,
                  setValidation: this.setTabValidation,
                  ataIdToCode: this.ataIdToCode,
                  setImage: this.setImage,
                  canEdit: this.canEdit,
                }}
              >
                <div className="new-syllabus__tabs-wrapper">
                  <Tabs
                    checkValidation={this.checkTabValidation}
                    tabs={this.tabs}
                    activeTab={activeTab}
                    setActiveTab={this.setActiveTab}
                  />
                  <div className="new-syllabus__tabs-content">
                    <div
                      className={`create-new-user-dash-wrapper ${wrapperClass}`}
                    >
                      {{
                        description: <TabDescription/>,
                        introduction: <TabIntroduction/>,
                        part1: <TabPart1/>,
                        edition: <TabTaskEdition/>,
                        part2: <TabPart2/>,
                      }[activeTabKey] || null}
                      <div className="create-new-user__buttons">
                        <button
                          className="cancel-btn btn-param"
                          onClick={this.goBack}
                        >
                          Cancel
                        </button>
                        <button
                          className="save-btn btn-param"
                          onClick={this.createRevision}
                          disabled={!this.canEdit}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </TabContext.Provider>
            </div>
          </div>
          {modalArchivingConfig.showModal ? (
            <ModalArchiving
              config={modalArchivingConfig}
              confirmPopup={this.confirmModaArchiving}
              cancelPopup={this.cancelModaArchiving}
            />
          ) : null}
          {modalRevisionConfig.showModal ? (
            <ModalRevision
              revisions={modalRevisionConfig.revisions}
              onCancel={this.cancelModalRevision}
              updateData={this.updateData}
            />
          ) : null}
          {createRevisionModal ? (
            <CreateRevisionModal
              isFetch={isFetch}
              handleChange={(e) => this.setCommitRevision(e.target.value)}
              confirmAction={this.save}
              close={this.closeCteateRevision}
              isDisable={isDisable}
              comment={comment}
            />
          ) : null}
        </div>
        {agreeModal ? (
          <AgreeChanges
            cancelAgree={this.cancelAgree}
            confirmAgree={this.confirmAgree}
          />
        ) : (
          ''
        )}
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.authReducer.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    success: (message) => dispatch(success(message)),
    danger: (message) => dispatch(danger(message)),
    warning: (message) => dispatch(warning(message)),
    loaderShow: () => dispatch(show()),
    loaderHide: () => dispatch(hide()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SyllabusEdit);
