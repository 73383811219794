import React from "react";
import date from "../../../helpers/date";

function ModalDeleteTask({onConfirm, onCancel, task}) {
  return (
    <div className="overlay">
      <div className="modal-act-element show" id="delete-element-modal">
        <div className="modal-act-element__wrapper">
          <div className="modal-act-element__head">
            <div className="modal-act-element__title">
              <div className="modal-delete-element__icon">
                <i className="fa fa-trash"></i>
              </div>
              <div>
                <div className="modal-act-element__training-name">
                  Task number <span>#{task.order_number}</span>
                </div>
                <div className="modal-act-element__subtitle">
                  Deleting element
                </div>
              </div>
            </div>
            <div className="modal-act-element__sesion-date">
              <span>{date.getFormatted()}</span>
            </div>
          </div>
          <div className="modal-delete-element__warning">
            Are you sure you want to delete this element?
          </div>
        </div>
        <div className="modal-act-element__btn">
          <button className="no-delete__btn btn-param" onClick={onCancel}>
            No
          </button>
          <button className="delete__btn btn-param" onClick={onConfirm}>
            Yes
          </button>
        </div>
      </div>
    </div>
  );
}

export default ModalDeleteTask;
