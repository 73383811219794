import React, { useState } from 'react';

const TaskCategory = ({ classModifier = '', onFilter, options = [], parentClass }) => {
  const [activeIndex, setActiveIndex] = useState(-1);

  return (
    <div className={parentClass}>
    {
        options.map(({ className = '', value, title = '', isActive = false }, index) => {
          if (activeIndex == -1 && isActive) {
            setActiveIndex(index);
          }

          return (
            <button 
              key={index}
              className={`${className} ${(activeIndex === index) ? 'active' : ''}`} 
              onClick={() => { 
                  if (activeIndex == index) return;
                  setActiveIndex(index); 
                  onFilter(value); 
                }}>
                {title}
            </button>
          );
        })
      }
    </div>
  );
}; 

export default TaskCategory;