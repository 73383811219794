import React, { useEffect, useState, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Request from '../../../helpers/request';
import date from '../../../helpers/date';
import TableFilterGroup from '../../common/table/table-filter-group.jsx';
import { useSelector } from 'react-redux';
import { ROLE_TRAINEE } from '@/helpers/role';
import Pdf from '@/components/common/training-pdf';

function TraineeTrainings() {
  const { id } = useParams();
  const history = useHistory();
  const { roles } = useSelector(state => state.authReducer.user);
  const isTrainee = roles.includes(ROLE_TRAINEE);
  const [trainings, setTrainings] = useState([]);
  const [filtersString, setFiltersString] = useState('');

  const filtersObject = useRef({});
  const setFilter = (name, value) => {
    filtersObject.current[name] = value;
    setFiltersString(JSON.stringify(filtersObject.current));
  };

  useEffect(() => {
    const fetchData = async () => {
      const result = await Request.get(`/api/trainees/${id}/trainings?filters=${filtersString}`);
      setTrainings(result);
    };
    fetchData();
  }, [filtersString]);

  return (
    <div className="users-edit-history-session__wrapper">
      <div className="users-edit-history-session__title">
        History of training sessions
      </div>
      <div className="users-edit-history-session__content">
        <div className="users-edit-history-session__active-head">
          {/* <div className="icon-box data-search">
            <i className="search-icon fa fa-search"></i>
            <input
              className="data-search__field"
              type="text"
              placeholder="Search by dates…"
            />
          </div> */}
          <div className="users-search__buttons-container">
            <TableFilterGroup
              onFilter={(value) => setFilter('state', value)}
              options={[
                {
                  className: 'unfinished-users-btn',
                  title: 'unfinished',
                  value: 'unfinished',
                },
                {
                  className: 'completed-users-btn',
                  title: 'completed',
                  value: 'completed',
                },
                {
                  className: 'all-users-btn',
                  title: 'all',
                  value: 'all',
                  isActive: true,
                },
              ]}/>
          </div>
        </div>
        <div className="users-edit-history-session-table__wrapper">
          <div className="users-edit-table__inner">
            <table
              className="users-table__data"
              id="trainee-edit-history-session-table"
            >
              <thead className="users-table__thead">
                <tr>
                  <th>N#</th>
                  <th>T. Code</th>
                  <th>Syllabus</th>
                  <th>Dates</th>
                  <th>Status</th>
                  <th colSpan="2">Actions</th>
                </tr>
              </thead>
              <tbody className="users-table__tbody">
                {
                  trainings.map(({ training, completed }, index) => (
                    <tr className={completed ? 'checked-session-trainee' : ''} key={index}>
                      <td>
                        <span className="table-archive-flag"></span>
                        <span className="session-number">#{training.session_number}</span>
                      </td>
                      <td>{training.training_code}</td>
                      <td>{training.syllabus_title}</td>
                      <td>
                        <span className="date-from"> {date.getShortFormatted(training.date_start)} </span> &gt;{' '}
                        <span className="date-to">{date.getShortFormatted(training.date_end)} {date.getYear(training.date_end)}</span>
                      </td>
                      <td className="session-status">
                        <span></span>
                      </td>
                      <td>
                        <button className="look-more-btn" onClick={() => {
                          history.push(`/trainings/${training.id}/trainees/${id}`);
                        }}>
                          <span></span>
                        </button>
                      </td>
                      <td>
                        <Pdf buttonClass="download-file-btn" trainee={id} training={training.id} isTrainee={isTrainee}/>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TraineeTrainings;
