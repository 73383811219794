import React, {createContext, useContext, useReducer } from 'react';
import tableReducer from "./table-reducer";

const initialState = () => ({
  isAllChecked: false,
  ids: new Set(),
  selectedIds: new Set(),
});

const TableContext = createContext({
  state: initialState(),
  dispatch: () => {},
});

export const TableProvider = ({children}) =>
{
  const [state, dispatch] = useReducer(tableReducer, initialState());

  return (
    <TableContext.Provider value={{
      state, dispatch
    }}>
      {children}
    </TableContext.Provider>
  )
};

export const useTable = () => useContext(TableContext);
