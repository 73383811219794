import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import date from '@/helpers/date';

const styles = StyleSheet.create({
  title: {
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    width: '20%',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 20,
  },
  header: {
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    fontSize: 11,
    width: '33.3%',
    textAlign: 'center',
  },
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginTop: 10,
    padding: 4,
    alignContent: 'center',
    justifyContent: 'center',
  },
  td: {
    fontSize: 11,
    width: '33.3%',
    textAlign: 'center',
    borderWidth: 1,
    borderTop: 'none',
    borderStyle: 'solid',
    borderColor: '#000000',
    height: '100%',
    paddingTop: 5,
    paddingBottom: 5,
    paddingRight: 2,
    paddingLeft: 2,
  },
});

const RevisionRows = (prop) => {
  const { data } = prop;

  const rows = data.map(revision => {
    return (
      <View style={{ flexDirection: 'row' }} key={revision.id.toString()}>
        <Text style={[styles.td, { width: '10%' }]}>{revision.version}</Text>
        <Text style={[styles.td, { width: '10%' }]}>{date.getFormatted(revision.date)}</Text>
        <Text style={[styles.td, { width: '80%' }]}>{revision.reason_for_change}</Text>
      </View>
    );
  });

  return <Fragment>{rows}</Fragment>;
};
const Revisions = (props) => {
  return (
    <Fragment>
      <View style={styles.row}>
        <Text style={styles.title}>List of revisions: </Text>
      </View>
      <View style={styles.tableContainer}>
        <Text style={[styles.header, { width: '10%' }]}>Version</Text>
        <Text style={[styles.header, { width: '10%' }]}>Date</Text>
        <Text style={[styles.header, { width: '80%' }]}>Reason of change</Text>
      </View>
      <RevisionRows {...props}/>
    </Fragment>
  );
};

export default Revisions;
