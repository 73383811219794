import React from 'react';
import { useSyllabus } from '../common/syllabus-context';

function Introduction() {
  const syllabus = useSyllabus();
  const { introduction = '' } = syllabus;
  const introductionParts = introduction.split('\n').filter((s) => s != '');

  return (
    <div className="assessment-sheet__wrapper syllabus-part-introduction">
      <div className="syllabus-introduction__item">
        <div className="assessment-sheet__title">Introduction</div>
        <div className="syllabus-introduction__descr scrollbar-style">
          {
            introductionParts.map((introductionPart, index) => (
              <p key={index}>{introductionPart}</p>
            ))
          }
        </div>
      </div>

      <div className="syllabus-introduction__item">
        {
          syllabus.photo
            ? <img className="syllabus-introduction__img" src={syllabus.photo} alt="" />
            : null
        }
      </div>
    </div>
  );
}

export default Introduction;
