import authService from "../../services/auth-service.js";
import { LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT, UPDATE_USER } from "../actions/auth";

const user = authService.getUser();
const initialState = user ? { loggedIn: true, user } : {};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user,
      };
    case LOGIN_FAILURE:
      return {
        loggedIn: false,
        error: action.error,
      };
    case UPDATE_USER:
      return {
        ...state,
        user: action.user,
      };
    case LOGOUT:
      return {};
  }

  return state;
}
