import { FILTER_SAVE } from "../actions/filter";

const initialState = {
  path: null,
  data: {},
};

export default function filterReducer(state = initialState, action) {
  const { type, path, data } = action;
  switch (type) {
    case FILTER_SAVE:
      return {
        path,
        data: (
          path === state.path
          ? { ...state.data, ...data }
          : data
        )
      };
  }

  return state;
}
