import React from 'react';
import { SKILL_B1, SKILL_B2 } from '../common/constants';
import TextInput from './../common/textInput.jsx';
import Select from './../common/select.jsx';
import SelectInstructor from './inputs/SelectInstructor';
import EntityRepository from '../../repositories/EntityRepository';
import ErrorRepository from '../../repositories/ErrorRepository';
import ModalTraineeView from './modals/ModalTraineeView';
import ModalTraineeEdit from './modals/modal-trainee-edit.jsx';
import ModalGroupEdit from './modals/modal-group-edit.jsx';
import Request from '../../helpers/request';
import TrainingCalendar from './inputs/TrainingCalendar.jsx';
import ButtonTraineesList from './buttons/button-trainees-list.jsx';
import { connect } from 'react-redux';
import { hide, show } from '../../redux/actions/loader';
import { success, danger } from '../../redux/actions/notify';
import ImportTrainees from './inputs/ImportTrainees.jsx';
import {
  ROLE_INSTRUCTOR
} from '../../helpers/role';

class TrainingEdit extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      formData: {
        id: null,
        syllabus: '',
        training_code : '',
        session_number : '',
        suggestions: [],
        instructors: [],
        trainees: [],
        trainee_groups: [],
        date_start: new Date(),
        date_end: new Date(),
        skill: [],
      },
      value: '',
      baseGroupTraineeIds: [],
      trainees: {},
      instructors: [],
      syllabi : [],
      data    : [],
      errors  : {},
      showModalTraineeView: false,
      showModalTraineeEdit: false,
      showModalGroupEdit: false,
      isLockedAll: false,
      traineeGroupIndex: -1,
    };
  }

  async componentDidMount() {
    const { id } = this.props.match.params;
    this.props.loaderShow();
    const response = await Request.get(`/api/trainings/${id}`);
    this.setFormDataFromResponse(response);
    this.getSyllabuses();
    this.props.loaderHide();
  }

    setFormDataFromResponse = (data) => {
      const baseTraineeGroup = (Array.isArray(data.trainee_groups) && data.trainee_groups.length)
        ? data.trainee_groups.shift()
        : {};
      const formData = {
        id: data.id,
        syllabus: data.syllabus_id,
        course_reference_number: data.course_reference_number,
        training_code : data.training_code,
        session_number : data.session_number,
        trainee_count: data.trainee_count,
        date_start: new Date(data.date_start),
        date_end: new Date(data.date_end),
        skill: data.skill,
      };
      const now = new Date();
      now.setHours(0, 0, 0, 0) ;
      const isLockedAll = formData.date_end.getTime() <= now.getTime();

      formData.trainee_groups = data.trainee_groups
        ? data.trainee_groups.map((traineeGroup) => ({
          id: traineeGroup.id,
          name: traineeGroup.name,
          color: traineeGroup.color,
          createdAt: traineeGroup.created_at,
          trainees: traineeGroup.trainee_ids,
        }))
        : [];

      const trainees = {};
      for (const trainee of data.trainees) {
        trainees[trainee.id] = {
          id: trainee.id,
          lastNameShort: trainee.last_name.substr(0, 1) + '.',
          firstName: trainee.first_name,
          idNumber: trainee.id_number,
          company: trainee.company,
        };
      }

      formData.trainees = Object.keys(trainees).map(Number);
      this.setState(
        {
          isLockedAll,
          isArchived: data.archived,
          formData: {
            ...this.state.formData,
            ...formData,
          },
          trainees: { ...trainees },
          baseGroupTraineeIds: Array.isArray(baseTraineeGroup.trainee_ids) ? baseTraineeGroup.trainee_ids : [],
          syllabi: [{ value: data.syllabus_id, title: data.syllabus_title }],
        }
      );

      for(let i in data.instructors){
        this.addInstructor(data.instructors[i]);
      }
    };

  openModalTraineeView = () => {
    this.setState({
      showModalTraineeView: true,
    });
  };

  closeModalTraineeView = () => {
    this.setState({
      showModalTraineeView: false,
    });
  };

  closeModalTraineeEdit = () => {
    this.setState({
      showModalTraineeEdit: false,
    });
  };

  openModalTraineeEdit = () => {
    this.setState({
      showModalTraineeEdit: true,
    });
  };

  closeModalGroupEdit = () => {
    this.setState({
      showModalGroupEdit: false,
      traineeGroupIndex: -1,
    });
  };

  openModalGroupEdit = (index = -1) => {
    this.setState({
      showModalGroupEdit: true,
      traineeGroupIndex: index,
    });
  };

  openModalGroupCreate = () => {
    this.openModalGroupEdit();
  }

    setTrainees = (trainees = {}) => {
      const {
        baseGroupTraineeIds,
        formData: {
          trainees: oldTraineeIds,
          trainee_groups,
        } } = this.state;
      const selectedTraineeIds = Object.keys(trainees).map(Number);
      const newTraineeIds = selectedTraineeIds.filter((selectedTraineeId) => !oldTraineeIds.includes(selectedTraineeId));
      const removedTraineeIds = oldTraineeIds.filter((oldTraineeId) => !selectedTraineeIds.includes(oldTraineeId));

      const newTraineeGroups = trainee_groups.map((traineeGroup) => {
        traineeGroup.trainees = traineeGroup.trainees.filter((trainee) => !removedTraineeIds.includes(trainee));

        return traineeGroup;
      });

      const newBaseGroupTraineeIds = baseGroupTraineeIds.filter(
        (baseGroupTraineeId) => !removedTraineeIds.includes(baseGroupTraineeId)
      );

      for (const newTraineeId of newTraineeIds) {
        if (!baseGroupTraineeIds.includes(newTraineeId)) {
          newBaseGroupTraineeIds.push(newTraineeId);
        }
      }

      this.setState((state) => (
        {
          ...state,
          formData: {
            ...this.state.formData,
            trainees: selectedTraineeIds,
            trainee_groups: newTraineeGroups,
          },
          trainees: { ...trainees },
          baseGroupTraineeIds: newBaseGroupTraineeIds,
        }
      ));
    };

  handleChange = (e) => {
    const { name, value } = e.target;

    if(name == 'syllabus') {
      this.setFormField(name, Number(value));
      this.state.syllabi.map((item) => {
        if (item.value === Number(value)) {
          this.setState((state) => ({ ...state, course_reference_number: item.course_reference_number }));
          this.setState((state) => ({ ...state, instructors: [] }));
          this.setFormField('instructors', []);
        }
      });
    }else{
      this.setFormField(name, value);
    }
  };

  skillChange = ({ target: { checked, value } }) => {
    const { skill } = this.state.formData;
    const newArray = checked
      ? skill.includes(value)
        ? skill
        : [...skill, value]
      : skill.filter((item) => item !== value);

    this.setFormField('skill', newArray);
  };

  async getSyllabuses() {
    const syllabusId = this.state.formData.syllabus;
    const data = await EntityRepository.getEntities('api/syllabi',
      { start: 0, limit: 200000, archive: 'active' });
    const syllabi = data.items
      .filter(item => (item.id != syllabusId))
      .map(item => ({
        value: item.id, title: item.title, course_reference_number: item.course_reference_number,
      }));
    this.setState((state) => ({ ...state, syllabi: [...state.syllabi, ...syllabi] }));
  }

    addInstructor = (instructor) => {
      const { instructors: instructorIds } = this.state.formData;

      if (instructorIds.includes(instructor.id)) {
        return;
      }

      this.setState((state) => (
        {
          ...state,
          formData: {
            ...state.formData,
            instructors: [...instructorIds, instructor.id],
          },
          instructors: [
            ...state.instructors,
            {
              username: instructor.first_name + ' ' + instructor.last_name,
              id: instructor.id,
            },
          ],
        }
      ));
    };

  deleteInstructor = (deletedId) => {
    const { instructors, formData } = this.state;

    this.setState((state) => (
      {
        ...state,
        formData: {
          ...this.state.formData,
          instructors: formData.instructors.filter((id) => (id !== deletedId)),
        },
        instructors: instructors.filter((instructor) => (instructor.id !== deletedId)),
      }
    ));
  };

  setTraineeGroups(groups = []) {
    this.setState((state) => (
      {
        ...state,
        formData: {
          ...this.state.formData,
          trainee_groups: [...groups],
        },
      }
    ));
  }

  deleteTraineeGroup(index) {
    const {
      formData: {
        trainee_groups,
      },
      baseGroupTraineeIds,
    } = this.state;

    const groupTrainees = trainee_groups[index].trainees;
    trainee_groups.splice(index, 1);
    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        trainee_groups: [
          ...trainee_groups,
        ],
      },
      baseGroupTraineeIds: baseGroupTraineeIds.concat(groupTrainees),
    }));
  }

    setError = (field, message) => {
      this.setState((state) => (
        {
          ...state,
          errors: { ...state.errors, [field]: message },
        }
      ));
    };

    clearError = (fieldName) => {
      const { errors } = this.state;

      if (errors[fieldName] && errors[fieldName] != '') {
        this.setError(fieldName, '');
      }
    }

    setFormField = (field, value) => {
      this.clearError(field);
      this.setState((state) => (
        {
          ...state,
          formData: { ...this.state.formData, [field]: value },
        }
      ));
    };

    goBack = () => {
      this.props.history.goBack();
    }

    save = async () => {
      const { formData } = this.state;
      formData.date_start = new Date(formData.date_start).toDateString();
      formData.date_end = new Date(formData.date_end).toDateString();
      const response = await Request.post(`/api/trainings/${formData.id}`, formData);
      if (response.id) {
        this.props.success('Training successfully saved');
        this.props.history.push('/settings/trainings');
      } else {
        this.props.danger('Server error');
      }
    };

    getClearError = (fieldName) => {
      let errors = [... this.state.errors];
      let index = 0;
      switch (fieldName) {
        case 'code': index = 0;break;
        case 'description': index = 1;break;
      }
      if(errors.length > 0){
        errors[index].message = '';
        this.setState({ errors : errors });
      }
    };

    render() {
      const {
        formData,
        instructors,
        trainees,
        baseGroupTraineeIds,
        showModalTraineeView,
        showModalTraineeEdit,
        showModalGroupEdit,
        traineeGroupIndex,
        isLockedAll,
        errors,
      } = this.state;
      const { user: { roles } } = this.props;
      const isLockedPartially = roles.includes(ROLE_INSTRUCTOR) || isLockedAll;

      return (
        <section className="edit-trainee__wrapper users-edit-wrapper">
          <div className="container">
            <div className="users-edit-head__inner">
              <ul className="users-edit__breadcrumb">
                <li>
                  <a onClick={this.goBack} className="pointer">
                  Trainings
                  </a>
                  {/* <NavLink exact to="/settings/trainings">Trainings </NavLink> */}
                </li>
                <li className="active">
                  <span>Training session #{formData.session_number}</span>
                </li>
              </ul>
            </div>

            <div className="create-new-user__wrapper">
              <div className="create-new-user__top-box">
                <div className="create-new-user__badge">
                  <span>editing</span>
                </div>
                <div className="users-edit__btn-box"></div>
              </div>

              <div className="new-training__main-panel">
                <div className="training__wrapper">
                  <div className="training__item">
                    <div className="training-inform">
                      <div
                        className={`icon-box${
                          isLockedPartially && ' disabled-box'
                        } select-box`}
                      >
                        <Select
                          label="Entitled Syllabus"
                          name="syllabus"
                          onChange={this.handleChange}
                          value={this.state.formData.syllabus}
                          id={'create-training-select-syllabus'}
                          options={this.state.syllabi}
                        />
                      </div>
                      <div className="training-inform__box">
                        <div
                          className={
                            `icon-box ${
                              isLockedPartially
                                ? 'disabled-box'
                                : 'text-input-box'
                            }${ErrorRepository.getErrorClassName(errors, 'training_code')}`
                          }
                        >
                          <TextInput
                            className="event-disabled"
                            inputName={'course_reference_number'}
                            inputLabel={'Course Reference'}
                            inputValue={formData.course_reference_number}
                          />
                        </div>
                        <div
                          className={`icon-box ${
                            isLockedPartially ? ' disabled-box' : 'text-input-box'
                          } ${ErrorRepository.getErrorClassName(errors, 'session_number')}`}
                        >
                          <TextInput
                            inputName={'session_number'}
                            inputLabel={'Session number'}
                            inputValue={formData.session_number}
                            changeInput={this.handleChange}
                            errors={errors}
                          />
                        </div>
                      </div>
                      <div
                        className={
                          'icon-box staff-skill__box' +
                        ErrorRepository.getErrorClassName(errors, 'skill')
                        }
                      >
                        <label>Skill</label>
                        <div className="staff-skill__wrapper">
                          <div className="staff-skill__inner">
                            <label className="category-checkbox">
                              <input
                                type="checkbox"
                                name="skill"
                                value={SKILL_B1}
                                onChange={this.skillChange}
                                checked={formData.skill.includes(SKILL_B1)}
                              />
                              <span className="category-custom-checkbox"></span>
                            </label>
                            <span className="category-name">B1</span>
                          </div>
                          <div className="staff-skill__inner">
                            <label className="category-checkbox">
                              <input
                                type="checkbox"
                                name="skill"
                                value={SKILL_B2}
                                onChange={this.skillChange}
                                checked={formData.skill.includes(SKILL_B2)}
                              />
                              <span className="category-custom-checkbox"></span>
                            </label>
                            <span className="category-name">B2</span>
                          </div>
                        </div>
                        <span className="wrror-masage">
                          {errors.skill}
                        </span>
                      </div>
                      <SelectInstructor
                        onSelect={this.addInstructor}
                        skill={formData.skill}
                        courseReferenceNumber={formData.course_reference_number}
                        selectedIds={formData.instructors}
                        isLocked={isLockedPartially || !formData.skill.length}/>
                      <div className="search-result__wrapper">
                        {instructors.map(({ id, username }) => (
                          <span key={id} className="instructor-name">
                            {username}
                            {!isLockedPartially && (
                              <button
                                className="delete-instructor-name"
                                onClick={() => this.deleteInstructor(id)}
                              >
                                <i className="fa fa-close"></i>
                              </button>
                            )}
                          </span>
                        ))}
                      </div>
                    </div>
                    <div className="training-trainee-list">
                      {formData.trainee_groups.length ? (
                        <div className="training-btn__container">
                          <div className="tarinee-edits__box">
                            <label>Trainee list</label>
                            <ButtonTraineesList
                              number={formData.session_number}
                              viewOnClick={this.openModalTraineeView}
                              editOnClick={this.openModalTraineeEdit}
                              className="tarinee-edits__panel"
                              isLocked={isLockedAll}
                            />
                          </div>
                          <div className="tarinee-edits__box">
                            <label>Group</label>
                            <div className="tarinee-edits__panel">
                              <div className="group-bages">
                                {formData.trainee_groups.map((group, index) => (
                                  <div
                                    className="group-bage"
                                    key={index}
                                    style={{
                                      backgroundColor: group.color,
                                    }}
                                  >
                                    <span
                                      className="group-name"
                                      onClick={() =>
                                        !isLockedAll &&
                                      this.openModalGroupEdit(index)
                                      }
                                    >
                                      {group.name}
                                    </span>
                                    <span
                                      className="group-delete__btn"
                                      onClick={() =>
                                        !isLockedAll &&
                                      this.deleteTraineeGroup(index)
                                      }
                                    >
                                    ×
                                    </span>
                                  </div>
                                ))}
                              </div>
                              {!isLockedAll && baseGroupTraineeIds.length ? (
                                <span
                                  className="add-group__btn"
                                  onClick={this.openModalGroupCreate}
                                ></span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          <label>Trainee list</label>
                          <div className="training-btn__container">
                            {formData.trainee_count > 0 ? (
                              <>
                                <ButtonTraineesList
                                  number={formData.session_number}
                                  viewOnClick={this.openModalTraineeView}
                                  editOnClick={this.openModalTraineeEdit}
                                  isLocked={isLockedAll}
                                />
                                {!isLockedAll && (
                                  <button
                                    className="create-group__btn btn-param"
                                    onClick={this.openModalGroupCreate}
                                  >
                                  Create group
                                  </button>
                                )}
                              </>
                            ) : (
                              !isLockedAll && (
                                <>
                                  <button
                                    onClick={this.openModalTraineeEdit}
                                    className="add-trainees__btn btn-param"
                                  >
                                  Add trainees
                                  </button>
                                  <span>or</span>
                                  <ImportTrainees
                                    setTrainees={this.setTrainees}
                                  />
                                </>
                              )
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <TrainingCalendar
                    startDate={formData.date_start}
                    endDate={formData.date_end}
                    onChange={({ selection }) => {
                      this.setState({
                        formData: {
                          ...formData,
                          date_start: selection.startDate,
                          date_end: selection.endDate,
                        },
                      });
                    }}
                    isLocked={isLockedPartially}
                  />
                </div>
                <div className="create-new-user__buttons">
                  <button className="cancel-btn btn-param" onClick={this.goBack}>
                  Cancel
                  </button>
                  <button
                    className="save-btn btn-param"
                    onClick={this.save}
                    disabled={isLockedAll}
                  >
                  Save
                  </button>
                </div>
              </div>
            </div>
          </div>
          {showModalTraineeView && (
            <ModalTraineeView
              sessionNumber={formData.session_number}
              trainees={trainees}
              onCancel={this.closeModalTraineeView}
            />
          )}
          {showModalTraineeEdit && (
            <ModalTraineeEdit
              onConfirm={(trainees) => {
                this.setTrainees(trainees);
                this.closeModalTraineeEdit();
              }}
              onCancel={this.closeModalTraineeEdit}
              trainees={trainees}
            />
          )}
          {showModalGroupEdit ? (
            <ModalGroupEdit
              onConfirm={(groups) => {
                this.setTraineeGroups(groups);
                this.closeModalGroupEdit();
              }}
              onCancel={this.closeModalGroupEdit}
              selectTraineeIds={baseGroupTraineeIds}
              trainees={trainees}
              traineeGroups={formData.trainee_groups}
              traineeGroupIndex={traineeGroupIndex}
            />
          ) : null}
        </section>
      );
    }
}

function mapStateToProps(state) {
  return {
    user: state.authReducer.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    success: (message) => dispatch(success(message)),
    danger: (message) => dispatch(danger(message)),
    loaderShow: () => dispatch(show()),
    loaderHide: () => dispatch(hide()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TrainingEdit);
