import React from 'react';
import { connect } from 'react-redux';
import { logout } from '../../redux/actions/auth';
import { remove } from '../../redux/actions/notification';
import date from '../../helpers/date';
import {
  NavLink,
  withRouter
} from 'react-router-dom';
import {
  ROLE_ADMIN,
  ROLE_TRAINEE,
  ROLE_INSTRUCTOR,
  ROLE_QUALITY_MANAGER,
  ROLE_PRODUCT_MANAGER
} from '../../helpers/role';
import Request from '../../helpers/request';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      overlay: false,
      menu: false,
      notify: false,
    };
  }

  logout = (event) => {
    event.preventDefault();
    this.closeMenu();
    this.props.logout();
  }

  menuToogle = () => {
    const isOpen = !this.state.menu;

    if (isOpen) {
      this.openMenu();
    } else {
      this.closeMenu();
    }
  }

  openMenu = () => {
    document.body.classList.add('open-modal');
    this.setState({
      menu: true,
      overlay: true,
      notify: false,
    });
  }

  closeMenu = () => {
    document.body.classList.remove('open-modal');
    this.setState({
      menu: false,
      overlay: false,
      notify: false,
    });
  }

  notifyToogle = () => {
    this.setState({
      menu : false,
      overlay : this.state.notify ? false : true,
      notify : this.state.notify ? false : true,
    });

  }

  openMenuLink = (url) => {
    const { history } = this.props;
    this.closeMenu();
    history.push(url);
  }

  readNotification = (notification) => {
    const { removeNotification } = this.props;
    this.openMenuLink(notification.link);
    removeNotification(notification);
    Request.post(`/api/notifications/${notification.id}/mark-read`);
  }

  render() {
    const { user, notifications } = this.props;
    const { overlay, menu, notify } = this.state;
    const { roles } = user;
    const notificationsCount = notifications.length;

    return (
      <header className="stafe-board__header">
        <div className="container">
          <div className="header__wrapper">
            <div className="header-item">
              <div className="home-ikon__link">
                <NavLink exact to="/">
                  <img src="/adminApp/src/assets/icons/home-off.svg" alt="Home page"/>
                </NavLink>
              </div>
              <div className="header__title">dashboard</div>
              <div className="header__user-bage">
                <span className="staff-bage">{roles.includes(ROLE_TRAINEE) ? 'trainee' : 'staff'}</span>
              </div>
              <div className="header__date">{date.getFormatted()}</div>
            </div>
            <div className="header-item">
              <div className="header__notif">
                <button
                  className="notif-btn"
                  onClick={this.notifyToogle}
                >
                  <i className="fa fa-bell"></i>
                  {
                    notificationsCount
                      ? <span className="notif-on"></span>
                      : null
                  }
                </button>
              </div>
              <div className="header__user-dada">
                <span className="user-name">{`${user.first_name} ${user.last_name}`}</span>
                <span className="user-status">{user.role_title}</span>
              </div>
              <div className="user__icon"><img src={
                user.avatar != null ? user.avatar :
                  '/adminApp/src/assets/icons/user-icon.svg'
              } alt=""/></div>
              <div className="drop-menu-btn">
                <button
                  className={`drop-arrow ${menu ? 'active' : ''}`}
                  onClick={this.menuToogle}
                ></button>
              </div>
            </div>
          </div>

        </div>
        <div className={`header-overlay ${overlay ? 'show' : 'hide'}`} onClick={this.closeMenu}>
          <div className={`header-drop-down-menu ${menu ? 'show' : 'hide'}`}>
            <ul className="header-drop-down-menu__list">
              {
                roles.includes(ROLE_ADMIN) || roles.includes(ROLE_INSTRUCTOR) || roles.includes(ROLE_PRODUCT_MANAGER)
                  ? <li>
                    <span className="trainings-link pointer" onClick={() => {
                      this.openMenuLink('/trainings');
                    }}>
                            Trainings
                    </span>
                  </li>
                  : null
              }
              <li>
                <span className="account-link pointer" onClick={() => {
                  this.openMenuLink('/account');
                }}>
                      Account
                </span>
              </li>
              {
                roles.includes(ROLE_TRAINEE)
                  ? <li>
                    <span className="trainings-link pointer" onClick={() => {
                      this.openMenuLink('/training-session');
                    }}>
                            Training session
                    </span>
                  </li>
                  : null
              }
              {
                roles.includes(ROLE_ADMIN) || roles.includes(ROLE_QUALITY_MANAGER) || roles.includes(ROLE_PRODUCT_MANAGER)
                  ? <li>
                    <span className="stats-link pointer" onClick={() => {
                      this.openMenuLink('/stats');
                    }}>
                          Stats
                    </span>
                  </li>
                  : null
              }
              {
                roles.includes(ROLE_ADMIN)
                  ? <li>
                    <span className="stats-link pointer" onClick={() => {
                      this.openMenuLink('/course-reference');
                    }}>
                          Course reference
                    </span>
                  </li>
                  : null
              }
            </ul>
            <div className="header-settings__btn">
              {
                roles.includes(ROLE_ADMIN)
                    || roles.includes(ROLE_QUALITY_MANAGER)
                    || roles.includes(ROLE_PRODUCT_MANAGER)
                    || roles.includes(ROLE_INSTRUCTOR)
                  ? <button className="training-settings__btn btn-param" onClick={() => {
                    this.openMenuLink('/settings');
                  }}>
                        Training settings
                  </button>
                  : null
              }
            </div>
            <div className="header-log-out__btn">
              <button className="log-out__btn" onClick={this.logout}>Log out</button>
            </div>
          </div>
        </div>
        <div className="container container-position">
          <div className={`notif-popup ${notify ? 'show' : 'hide'}`}>
            <div className="notif-popup__title-wrapper">
              <div className="notif-popup__title">
                    Notifications
                <span className="notif-popup__bage-numb">{notificationsCount}</span>
              </div>
              <span className="notif-popup__icon"></span>
            </div>
            {
              notifications.map(({
                link,
                training_code = '',
                title = '',
                message = '',
              }, index) => (
                <div
                  className="notif-popup-content"
                  key={index}
                  onClick={() =>
                    this.readNotification(notifications[index])
                  }>
                  <div className="notif-popup-content__title">
                    <span className="notif-code_bage">{training_code}</span>
                    {title}
                  </div>
                  <div className="notif-popup-content__wrapper">
                    <div className="notif-popup-content__text">
                      {message}
                    </div>
                    {
                      link && link !== ''
                        ? <button className="notif-popup__btn">
                          <i className="fa fa-angle-right"></i>
                        </button>
                        : null
                    }
                  </div>
                </div>
              ))
            }
          </div>

        </div>
      </header>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.authReducer.user,
    notifications: state.notificationReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch(logout()),
    removeNotification: (notification) => dispatch(remove(notification)),
  };
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
