import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  title: {
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    width: '100%',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 20,
  },
  text: {
    width: '100%',
    fontSize: 12,
    marginTop: 10,
  },
});

const Introduction = (props) => {
  // eslint-disable-next-line react/prop-types
  const { data } = props;

  return (
    <Fragment>
      <View style={styles.row}>
        <Text style={styles.title}>Introduction: </Text>
      </View>
      <View style={styles.row}>
        {/* eslint-disable-next-line react/prop-types */}
        <Text style={styles.text}>{data.introduction}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.title}>Description of this practical training element: </Text>
      </View>
      <View style={styles.row}>
        {/* eslint-disable-next-line react/prop-types */}
        <Text style={styles.text}>{data.description}</Text>
      </View>
    </Fragment>
  );
};

export default Introduction;
