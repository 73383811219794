import React from 'react';
import { NavLink } from 'react-router-dom';
import ImportDropZone from '../ImportDropzone';

function TableSidebar({
  content, importResult, setImportResult
}) {
  return (
    <div className="user-dashboard__aside-panel">
      {/*<div className="user-inform">*/}
      {/*  <div className="aside-panel__title">{content.infoTitle}</div>*/}
      {/*  <div className="aside-panel__descr">*/}
      {/*    <div className="descr-title">Description</div>*/}
      {/*    <div className="descr-text">*/}
      {/*      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor*/}
      {/*      incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className="add-user">
        <div className="aside-panel__title">{content.createTitle}</div>
        <div className="adding-panel__wrapper">
          <ImportDropZone url={content.importUrl} importResult={importResult} setImportResult={setImportResult}/>
        </div>
        {
          content.createButtonUrl && (
            <div className="creat-btn__box">
              <NavLink className="creat-btn" to={content.createButtonUrl}>Create</NavLink>
            </div>
          )
        }
      </div>
    </div>
  );
}

export default TableSidebar;
