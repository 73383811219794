import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import EntityRepository from '@/repositories/EntityRepository';

const TrainingsFilter = ({ onFilter }) => {
  const [activeIndex, setActiveIndex] = useState(-1);
  const [syllabi, setSyllabi] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await EntityRepository.getEntities('api/syllabi',
        { start: 0, limit: 200, archive: 'active' });
      if (data && data.items) setSyllabi(data.items);
    };

    fetchData();
  }, []);

  const statusOptions = [
    {
      className: 'coming-filter-btn',
      title: 'coming',
      value: 'coming',
    },
    {
      className: 'unfinished-users-btn',
      title: 'current',
      value: 'current',
      isActive: true,
    },
    {
      className: 'airbus-filter-btn',
      title: 'completed',
      value: 'completed',
    },
    {
      className: 'all-users-btn',
      title: 'all',
      value: 'all',
    },
  ];

  return (
    <div className="training-settings__wrapper">
      <div className="user-interface__subheader">
        <div className="container">
          <div className="user-interface__subheader-wrapper">
            <div className="user-interface__subheader-title">
              Training sessions
              <span className="user-interface__subheader-subtitle">
                Assigned
              </span>
            </div>
            <div className="icon-box select-box">
              <select
                name="syllabus"
                onChange={({ target: { value } }) =>
                  onFilter('syllabusId', value ? Number(value) : null)
                }
              >
                <option value="">Select Syllabus</option>
                {
                  syllabi.map(({ id, title }) => (
                    <option key={id} value={id}>{title}</option>
                  ))
                }
              </select>
            </div>
            <div className="users-search__buttons-container">
              <div className="data-search__buttons-filter training-filter__buttons">
                {statusOptions.map(
                  (
                    { className = '', value, title = '', isActive = false },
                    index
                  ) => {
                    if (activeIndex == -1 && isActive) {
                      setActiveIndex(index);
                    }

                    return (
                      <button
                        key={index}
                        className={`${className} ${
                          activeIndex === index ? 'active' : ''
                        }`}
                        onClick={() => {
                          if (activeIndex == index) return;
                          setActiveIndex(index);
                          onFilter('status', value);
                        }}
                      >
                        {title}
                      </button>
                    );
                  }
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

TrainingsFilter.propTypes = {
  onFilter: PropTypes.func.isRequired,
};

export default TrainingsFilter;
