import React from 'react';
import { useSyllabus } from '../common/syllabus-context';

function Description() {
  const syllabus = useSyllabus();
  const { description = '', practical = '', validation_rules = '' } = syllabus;
  const descriptionParts = description.split('\n\n');
  const practicalParts = practical.split('\n\n');
  const validationRulesParts = validation_rules.split('\n\n');

  return (
    <div className="syllabus-parts__wrapper">
      <div className="syllabus-parts__inner scrollbar-style">
        <div className="assessment-sheet__title">
          Description of this practical training
        </div>
        <div className="syllabus-parts__descr">
          {descriptionParts.map((descriptionPart, index) => (
            <p key={index}>
              {descriptionPart.split('\n').map((text, n) => (
                <React.Fragment key={n}>
                  {text}
                  <br />
                </React.Fragment>
              ))}
            </p>
          ))}
        </div>
        <div className="assessment-sheet__title">
          Practical training element - instruction for use
        </div>
        <div className="syllabus-parts__descr">
          {practicalParts.map((practicalPart, index) => (
            <p key={index}>
              {practicalPart.split('\n').map((text, n) => (
                <React.Fragment key={n}>
                  {text}
                  <br />
                </React.Fragment>
              ))}
            </p>
          ))}
        </div>
        <div className="assessment-sheet__title">
          Validation and assessment rules
        </div>
        <div className="syllabus-parts__descr">
          {validationRulesParts.map((validationRulesPart, index) => (
            <p key={index}>
              {validationRulesPart.split('\n').map((text, n) => (
                <React.Fragment key={n}>
                  {text}
                  <br />
                </React.Fragment>
              ))}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Description;
