
const inputDateFormat = 'd/MM/yyyy';

function getMonthNames() {
  return [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
}

function getShortMonthNames() {
  return [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
}

function getFormatted(date = null, useShortMonths = false) {
  const monthNames = useShortMonths ? getShortMonthNames() : getMonthNames();
  const newDate = date != null ? new Date(date) : new Date();
  const month = newDate.getMonth();

  return `${newDate.getDate()} ${monthNames[month]}, ${newDate.getFullYear()}`;
}

function getShortFormatted(inputDate = null) {
  const monthNames = getShortMonthNames();
  const newDate = inputDate != null ? new Date(inputDate) : new Date();
  const date = ('0' + newDate.getDate()).slice(-2);
  const month = newDate.getMonth();

  return `${monthNames[month]} ${date}`;
}

function getNumberFormatted(dateString) {
  const newDate = dateString ? new Date(dateString) : new Date();
  const month = ('0' + (newDate.getMonth() + 1)).slice(-2);
  const date = ('0' + newDate.getDate()).slice(-2);
  const year = newDate.getFullYear().toString().slice(-2);

  return `${date}\xa0/\xa0${month}\xa0/\xa0${year}`;
}

function getYear(inputDate) {
  const newDate = inputDate != null ? new Date(inputDate) : new Date();

  return newDate.getFullYear();
}

const date = {
  inputDateFormat,
  getMonthNames,
  getShortMonthNames,
  getFormatted,
  getShortFormatted,
  getNumberFormatted,
  getYear,
};

export default date;
