import React from 'react';
import TraineesRow from './trainees-row.jsx';
import PubSub from 'pubsub-js';
import { connect } from 'react-redux';
import { hide, show } from '../../../../redux/actions/loader';
import { success, warning } from '../../../../redux/actions/notify';
import EntityRepository from '../../../../repositories/EntityRepository.js';
import TraineesFilter from '../common/trainees-filter.jsx';
import { NavLink } from 'react-router-dom';
import { GET_LIST_REQUEST_PARAMS } from '../../../common/constants';
import ValidateModal from '../common/validate-modal.jsx';
import TrainingHeader from '../common/TrainingHeader.jsx';
import {
  ROLE_PRODUCT_MANAGER
} from '../../../../helpers/role';

class TraineesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      traineeIds: [],
      count: 0,
      trainingId: this.props.match.params.trainingId,
      isLoading: false,
      filter: 'all',
      validateModal: {
        isOpen: false,
        selectedTrainees: [],
      },
    };

    this.path = `/api/trainings/${this.state.trainingId}/trainee-list`;
    this.requestParams = { ...GET_LIST_REQUEST_PARAMS, group: 0 };
  }

  loadFilterData(value = '') {
    this.setState((state) => ({ ...state, filter: value }));
  }

  modalSettings(courseReference) {
    this.modal = { ...this.modal, code: courseReference };
  }

  resetPagination() {
    this.requestParams = {
      ...this.requestParams,
      ...GET_LIST_REQUEST_PARAMS,
    };
  }
  onScrollList = async (event) => {
    const { data, count } = this.state;
    const scrollBottom =
      event.target.scrollTop + event.target.offsetHeight ==
      event.target.scrollHeight;
    const loadedCount = data.length;
    if (scrollBottom && loadedCount < count) {
      this.requestParams = { ...this.requestParams, start: loadedCount };
      this.loadData();
    }
  };

  async loadData() {
    const data = await EntityRepository.getEntities(
      this.path,
      this.requestParams
    );
    if (data.items) {
      this.setState((state) => ({
        ...state,
        data: [],
        count: 0,
      }));
      if (!this.requestParams.start) {
        this.setState((state) => ({
          ...state,
          data: [...data.items],
          count: data.count,
        }));
      } else {
        // additional data fetch when scrolling down
        this.setState((state) => ({
          ...state,
          data: [...state.data, ...data.items],
          count: data.count,
        }));
      }
      this.setState({ groupsData: data.trainingGroups });
      this.setState({ courseReference: data.courseReference });
      this.setState({ syllabusId: data.syllabusId });
    }
  }

  async componentDidMount() {
    this.props.loaderShow();
    this.token = PubSub.subscribe(
      'Update Table',
      this.updateComponent.bind(this)
    );
    await this.loadData();
    this.getGroups();
    this.props.loaderHide();
  }

  updateComponent = async () => {
    this.loadData();
  };

  componentWillUnmount() {
    PubSub.unsubscribe(this.token);
  }

  renderRows = () => {
    const { data, filter } = this.state;
    const path = this.path;

    return data.map((item, i) => (
      <TraineesRow
        key={i}
        item={item}
        filter={filter}
        path={path}
        onChange={this.addTrainee}
      />
    ));
  };

  addTrainee = (checked, item) => {
    const { traineeIds } = this.state;
    const { trainee } = item;
    const id = trainee.id;
    let arrayIds = traineeIds;

    document.querySelector('#table-checkbox-main').checked = false;

    if (!arrayIds.includes(parseInt(id)) && checked === true) {
      arrayIds.push(parseInt(id));
      this.setState({ ...this.state, traineeIds: arrayIds });
    } else {
      let removeId = arrayIds.filter((e) => {
        return e != id;
      });

      this.setState({ ...this.state, traineeIds: removeId });
    }
  };

  switchTableHeader(filter) {
    switch (filter) {
      case 'all':
        return (
          <>
            <th>
              <span className="table-bage at-bage">at</span>
              Validated
            </th>
            <th>Remaining</th>
            <th>
              <span className="table-bage mt-bage">mt</span>
              Validated
            </th>
            <th>Remaining</th>
            <th>Percentage</th>
          </>
        );
        break;
      case 'at':
        return (
          <>
            <th>
              <span className="table-bage at-bage">at</span>
              Validated
            </th>
            <th>Remaining</th>
          </>
        );
        break;
      case 'mt':
        return (
          <>
            <th>
              <span className="table-bage mt-bage">mt</span>
              Validated
            </th>
            <th>Remaining</th>
            <th>Percentage</th>
          </>
        );

        break;
    }
  }

  getGroups = async () => {
    const { groupsData } = this.state;
    let groupsList = [];
    groupsData.map(function (item, i) {
      groupsList.push(
        <option key={i} value={item.id}>
          Group {item.name}
        </option>
      );
    });
    this.setState({ groupsList: groupsList });
  };

  changeGroup = (e) => {
    this.props.loaderShow();
    const { value, name } = e.target;
    this.resetPagination();
    this.requestParams = { ...this.requestParams, [name]: value };
    this.loadData();
    this.props.loaderHide();
  };

  confirmPopup = () => {
    const { trainingId } = this.state;
    const {
      validateModal: { selectedTrainees },
    } = this.state;

    const path = `/trainings/${trainingId}/validations/part-2`;
    this.props.history.push({
      pathname: path,
      state: { selectedTrainees },
    });
  };

  cancelPopup = () => {
    this.setState((state) => ({
      ...state,
      validateModal: {
        isOpen: false,
        selectedTrainees: [],
      },
    }));
  };

  validateAssessment = () => {
    const { data, traineeIds } = this.state;

    let selectedTrainees = [];

    if (!traineeIds.length) {
      this.props.warning('Please select trainees.');

      return;
    }

    for (const item of data) {
      const { trainee, taskCount } = item;
      if (traineeIds.includes(trainee.id)) {
        if (!taskCount.MT.is_reached_validation_threshold) {
          const traineeName = `${trainee.last_name[0]}. ${trainee.first_name}`;
          this.props.warning(
            `${traineeName} hasn't reached validation threshold`
          );

          return;
        }
        selectedTrainees.push(trainee);
      }
    }

    this.setState((state) => ({
      ...state,
      validateModal: {
        isOpen: true,
        selectedTrainees,
      },
    }));
  };

  goValidationTasks() {
    const { trainingId, traineeIds, data } = this.state;
    if (!traineeIds.length) {
      this.props.warning('Please select trainees.');
    }

    const traineeNames = [];
    data.map(({ trainee }) => {
      traineeIds.includes(trainee.id)
        ? traineeNames.push(`${trainee.last_name[0]}. ${trainee.first_name}`)
        : null;
    });

    const path = `/trainings/${trainingId}/validations/part-1`;
    if (traineeIds.length > 0) {
      this.props.history.push({
        pathname: path,
        state: { ids: traineeIds, traineeNames },
      });
    }
  }

  selectAllTrainees(e) {
    const { checked } = e.target;
    const ids = document.querySelectorAll('.trainee-checkbox');
    let traineeIds = [];
    ids.forEach((item) => {
      if (checked) {
        item.checked = checked;
        traineeIds.push(parseInt(item.getAttribute('data-id')));
      } else {
        item.checked = checked;
      }
    });
    this.setState({ traineeIds: traineeIds });
  }

  render() {
    const {
      trainingId,
      count,
      filter,
      groupsList,
      courseReference,
      validateModal,
    } = this.state;
    const { user } = this.props;
    const { roles } = user;

    return (
      <>
        <TrainingHeader/>
        <section className="training-session-trainee-list">
          <div className="container">
            <div className="training-session-trainee-list__wrapper">
              <div className="training-session-trainee-list__head">
                <div className="compositions-data">
                  <span className="training-session-trainee-list__title">Trainee list</span>
                  <span className="number">{count}</span>
                  <NavLink className="list-edit__btn" to={`/settings/trainings/${trainingId}/edit`}></NavLink>
                </div>
                <div className="sort-panel">
                  <div className="data-sort__box">
                    <span>Filter by</span>
                    <select onChange={this.changeGroup} name="group" value={this.requestParams.group}>
                      <option value="0">All groups</option>
                      {groupsList}
                    </select>
                  </div>
                  <TraineesFilter
            				onFilter={this.loadFilterData.bind(this)}
            				options={[
              					{
                					className: 'active-users-btn',
                					title: 'at',
                					value: 'at',
              					},
              					{
                					className: 'archived-users-btn',
                					title: 'mt',
                					value: 'mt',
              					},
              					{
                					className: 'all-users-btn',
                					title: 'all',
                					value: 'all',
                					isActive: true,
              					},
          					]}
            			/>
                </div>
              </div>
              <div className="valid-trainee__wrapper">
                <div className="users-table__wrapper" onScroll={event => this.onScrollList(event)} style={{ transition: 'ease 0.5s' }}>
                  <table className="users-table__data valid-trainee-list">
                    <thead className="users-table__thead">
                      <tr>
                        <th>Group</th>
                        <th>Name</th>
                        <th>First name</th>
                        {this.switchTableHeader(filter)}
                        <th>State</th>
                        <th>Assessment</th>
                        <th>
                          <label className="table-checkbox">
                            <input type="checkbox" id="table-checkbox-main" onClick={this.selectAllTrainees.bind(this)}/>
                            <span className="table-custom-checkbox"></span>
                          </label>
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody className="users-table__tbody">
                      {this.renderRows()}
                    </tbody>
                  </table>
                </div>
                {!roles.includes(ROLE_PRODUCT_MANAGER) ?
                  <div className="valid-trainee__buttons">
                    <button className="validate-tasks__btn btn-param" onClick={this.goValidationTasks.bind(this)}>Validate tasks</button>
                    <button className="validate-assessment__btn btn-param" onClick={this.validateAssessment}>Validate assessment</button>
                  </div>
                  : null}
              </div>
            </div>
          </div>
        </section>
        {validateModal.isOpen ? (
          <ValidateModal
            code={courseReference}
            confirmPopup={this.confirmPopup}
            cancelPopup={this.cancelPopup}
          />
        ) : null}
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    user: state.authReducer.user,
    showLoader: state.loaderReducer.showLoader,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    success: (message) => dispatch(success(message)),
    warning: (message) => dispatch(warning(message)),
    loaderShow: () => dispatch(show()),
    loaderHide: () => dispatch(hide()),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(TraineesList);
