import React, { useContext } from "react";
import TabContext from "./tab-context.js";
import TextArea from "../../common/textarea.jsx";
import ErrorRepository from "../../../repositories/ErrorRepository.js";

function TabIntroduction() {
  const {
    handleChange,
    formData = {},
    errors = {},
    setValidation,
    canEdit = true,
  } = useContext(TabContext);

  setValidation((setError) => {
    const fields = ['introduction', 'practical', 'description', 'validation_rules'];
    let result = true;

    for (const field of fields) {
      const value = formData[field];
      const errorMessage = ErrorRepository.checkNotEmpty(value);
      if (errorMessage !== '') {
        setError(field, errorMessage);
        result = false;
      }
    }

    return result;
  });

  return (
    <div className="syllabus-introduction__wrapper">
      <form
        className="syllabus-introduction-form__wrapper"
        id="create-new-syllabus-introduction-form"
      >
        <div className="syllabus-introduction__form">
          <div className={`icon-box form__inner ${ErrorRepository.getErrorClassName(errors, 'introduction')}`}>
            <TextArea
              id={"new-syllabus-introduction"}
              label={"Introduction"}
              inputName={"introduction"}
              inputValue={formData.introduction}
              onChange={handleChange}
              errors={errors}
              disabled={!canEdit}
            />
          </div>
          <div className={`icon-box form__inner ${ErrorRepository.getErrorClassName(errors, 'practical')}`}>
            <TextArea
              id={"new-syllabus-introduction-instruction"}
              label={"Practical training element - instruction for use"}
              inputName={"practical"}
              inputValue={formData.practical}
              onChange={handleChange}
              errors={errors}
              disabled={!canEdit}
            />
          </div>
          <div className={`icon-box form__inner ${ErrorRepository.getErrorClassName(errors, 'description')}`}>
            <TextArea
              id={"new-syllabus-introduction-descr"}
              label={"Description of this practical training element"}
              inputName={"description"}
              inputValue={formData.description}
              onChange={handleChange}
              errors={errors}
              disabled={!canEdit}
            />
          </div>
          <div className={`icon-box form__inner ${ErrorRepository.getErrorClassName(errors, 'validation_rules')}`}>
            <TextArea
              id={"new-syllabus-introduction-rules"}
              label={"Validation and assessment rules"}
              inputName={"validation_rules"}
              inputValue={formData.validation_rules}
              onChange={handleChange}
              errors={errors}
              disabled={!canEdit}
            />
          </div>
        </div>
        {/* <div className="create-new-user__buttons">
              <button className="cancel-btn btn-param">Cancel</button>
              <button className="create-btn btn-param">Create</button>
          </div> */}
      </form>
    </div>
  );
};

export default TabIntroduction;
