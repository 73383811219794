import React from "react";
import {
  Route,
  Redirect,
  Switch,
  useRouteMatch
} from "react-router-dom";
import Subheader from "./subheader.jsx";
import Users from "../user/users.jsx";
import Ata from "../ata/ata.jsx";
import Trainings from "../trainings/trainings.jsx";
import Syllabus from "../syllabus/syllabus.jsx";

import TraineeUpdate from "../user/trainee/trainee-update.jsx";
import StaffCreate from "../user/staff/staff-create.jsx";
import StaffEdit from "../user/staff/staff-edit.jsx";
import TraineeCreate from "../user/trainee/trainee-create.jsx";
import AtaCreate from "../ata/ata-create.jsx";
import AtaEdit from "../ata/ata-edit.jsx";
import SyllabusCreate from "../syllabus/syllabus-create.jsx";
import SyllabusEdit from "../syllabus/syllabus-edit.jsx";
import TrainingCreate from "../trainings/training-create.jsx";
import TrainingEdit from "../trainings/training-edit.jsx";
import PrivateRoute from "../router/private-route.jsx";
import {
  ROLE_ADMIN,
  ROLE_PRODUCT_MANAGER,
  ROLE_QUALITY_MANAGER,
  ROLE_INSTRUCTOR
} from "../../helpers/role";

function Settings() {
  const { path } = useRouteMatch();
  return (
    <>
      <Subheader />
      <Switch>
        <PrivateRoute roles={[ROLE_ADMIN]} path={`${path}/users/staff/create`} component={StaffCreate} />
        <PrivateRoute roles={[ROLE_ADMIN, ROLE_QUALITY_MANAGER, ROLE_PRODUCT_MANAGER]} path={`${path}/users/staff/:id/edit`} component={StaffEdit} />
        <PrivateRoute roles={[ROLE_ADMIN]} path={`${path}/users/trainee/create`} component={TraineeCreate} />
        <PrivateRoute roles={[ROLE_ADMIN, ROLE_QUALITY_MANAGER, ROLE_PRODUCT_MANAGER]} path={`${path}/users/trainee/:id/edit`} component={TraineeUpdate} />
        <PrivateRoute roles={[ROLE_ADMIN, ROLE_PRODUCT_MANAGER, ROLE_QUALITY_MANAGER, ROLE_INSTRUCTOR]} path={`${path}/users`} component={Users} />
        <PrivateRoute roles={[ROLE_ADMIN, ROLE_QUALITY_MANAGER]} path={`${path}/atas/create`} component={AtaCreate} />
        <PrivateRoute roles={[ROLE_ADMIN, ROLE_QUALITY_MANAGER]} path={`${path}/atas/:uid/edit`} component={AtaEdit} />
        <PrivateRoute roles={[ROLE_ADMIN, ROLE_QUALITY_MANAGER]} path={`${path}/atas`} component={Ata} />
        <PrivateRoute roles={[ROLE_ADMIN, ROLE_QUALITY_MANAGER, ROLE_PRODUCT_MANAGER]} path={`${path}/syllabi/create`} component={SyllabusCreate} />
        <PrivateRoute roles={[ROLE_ADMIN, ROLE_QUALITY_MANAGER, ROLE_PRODUCT_MANAGER]} path={`${path}/syllabi/:uid/edit`} component={SyllabusEdit} />
        <PrivateRoute roles={[ROLE_ADMIN, ROLE_QUALITY_MANAGER, ROLE_PRODUCT_MANAGER]} path={`${path}/syllabi`} component={Syllabus} />
        <PrivateRoute roles={[ROLE_ADMIN, ROLE_PRODUCT_MANAGER, ROLE_INSTRUCTOR]} path={`${path}/trainings/create`} component={TrainingCreate} />
        <PrivateRoute roles={[ROLE_ADMIN, ROLE_PRODUCT_MANAGER, ROLE_INSTRUCTOR]} path={`${path}/trainings/:id/edit`} component={TrainingEdit} />
        <PrivateRoute roles={[ROLE_ADMIN, ROLE_PRODUCT_MANAGER, ROLE_INSTRUCTOR]} path={`${path}/trainings`} component={Trainings} />
        <Route exact path={path}>
          <Redirect to={`${path}/users`} />
        </Route>
      </Switch>
    </>
  );
}

export default Settings;
