import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import date from '../../../helpers/date';
import SelectGroup from '../inputs/select-group';
import SelectColor from '../inputs/select-color';
import TextInput from '../../common/textInput';
import ErrorRepository from '../../../repositories/ErrorRepository';

function ModalGroupEdit({
  selectTraineeIds: initTraineeIds = [],
  traineeGroups = [],
  traineeGroupIndex: initTraineeGroupIndex = -1,
  trainees: allTrainees = {},
  onConfirm = () => {},
  onCancel = () => {},
}) {
  const isCreateMode = initTraineeGroupIndex === -1;
  const [selectTraineeIds, setSelectTraineeIds] = useState([]);
  const [traineeGroupIndex, setTraineeGroupIndex] = useState(initTraineeGroupIndex);
  const [traineeGroup, setTraineeGroup] = useState(traineeGroups[initTraineeGroupIndex] || {
    name: '',
    color: '',
    trainees: [],
  });
  const usedColors = traineeGroups.map(traineeGroup => traineeGroup.color);

  const [errors, setErrors] = useState({});

  useEffect(() => {
    setSelectTraineeIds([...initTraineeIds]);
  }, []);

  const addTrainee = (id) => {
    const { trainees: groupTrainees } = traineeGroup;

    if (!Number.isInteger(id) && groupTrainees.includes(id)) return;

    if (selectTraineeIds.includes(id)) {
      selectTraineeIds.splice(selectTraineeIds.indexOf(id), 1);
    }

    setTraineeGroup(state => ({
      ...state,
      trainees: [...groupTrainees, id],
    }));
  };

  const removeTrainee = (id) => {
    const { trainees: groupTrainees } = traineeGroup;

    if (!selectTraineeIds.includes(id)) {
      selectTraineeIds.push(id);
    }

    setTraineeGroup(state => ({
      ...state,
      trainees: groupTrainees.filter(groupTrainee => groupTrainee != id),
    }));
  };

  const clearError = (field) => {
    if (errors[field] && errors[field] != '') {
      setErrors((errors) => ({ ...errors, [field]: '' }));
    }
  };

  const checkValidation = () => {
    const fields = ['name', 'color'];
    let result = true;

    for (const field of fields) {
      const value = traineeGroup[field];
      const errorMessage = ErrorRepository.checkNotEmpty(value);
      if (errorMessage !== '') {
        setErrors((errors) => ({ ...errors, [field]: errorMessage }));
        result = false;
      }
    }

    if (!traineeGroup.trainees.length) {
      setErrors((errors) => ({ ...errors, trainees: 'Add trainees please' }));
    }

    return result;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    clearError(name);
    setTraineeGroup((traineeGroup) => ({ ...traineeGroup, [name]: value }));
  };

  const changeInitTraineeIds = () => {
    const { trainees: groupTrainees } = traineeGroup;

    for (const selectTraineeId of selectTraineeIds) {
      if (!initTraineeIds.includes(selectTraineeId)) {
        initTraineeIds.push(selectTraineeId);
      }
    }

    for (const groupTrainee of groupTrainees) {
      const initTraineeIdIndex = initTraineeIds.indexOf(groupTrainee);
      if (initTraineeIdIndex !== -1) {
        initTraineeIds.splice(initTraineeIdIndex, 1);
      }
    }
  };

  return (
    <div className="overlay">
      <div
        className="modal-act-element__create-task scrollbar-style"
        id="create-new-group-modal"
      >
        <div className="modal-act-element__wrapper">
          <div className="modal-act-element__head">
            {
              isCreateMode
                ? (
                  <div className="modal-act-element__title">
                    New group
                    <span className="trainees-modal__subtitle">
                      Select one or more trainees
                    </span>
                  </div>
                )
                : (
                  <div className="modal-act-element__title">
                    Edit group
                  </div>
                )
            }
            <div className="modal-act-element__sesion-date">
              <span>{traineeGroup.createdAt ? date.getFormatted(traineeGroup.createdAt) : date.getFormatted()}</span>
            </div>
          </div>
          <div className="trainees-modal__wrapper group-modal__wrapper">
            {
              !isCreateMode
                ? (
                  <div className="select-group__box">
                    <div className="icon-box select-box">
                      <label>Select Group</label>
                      <SelectGroup
                        activeIndex={traineeGroupIndex}
                        groups={traineeGroups}
                        onSelect={(index) => {
                          traineeGroups[traineeGroupIndex] = traineeGroup;
                          setTraineeGroupIndex(index);
                          setTraineeGroup({...traineeGroups[index]});
                        }}
                      />
                    </div>
                  </div>
                )
                : null
            }
            <div className="new-group-inform__wrapper">
              <div className={`icon-box text-input-box ${ErrorRepository.getErrorClassName(errors, 'name')}`}>
                <TextInput
                  id="create-group-name"
                  inputName="name"
                  inputLabel="Group Name"
                  inputValue={traineeGroup.name}
                  changeInput={handleChange}
                  errors={errors}
                />
              </div>
              <div className={`icon-box color-input-box ${ErrorRepository.getErrorClassName(errors, 'color')}`}>
                <label htmlFor="create-group-color">Group color</label>
                <SelectColor
                  usedColors={usedColors}
                  color={traineeGroup.color}
                  onSelect={(color) => {
                    clearError('color');
                    setTraineeGroup((group) => ({ ...group, color }));
                  }}
                />
                {
                  errors.color != ''
                    ? <span className="wrror-masage">{errors.color}</span>
                    : null
                }
              </div>
            </div>
            <div className="modal-list__title">Trainee list</div>
            <div className="users-edit-history-session__content">
              <div className="users-edit-history-session__active-head">
                <div className={`icon-box select-box ${ErrorRepository.getErrorClassName(errors, 'trainees')}`}>
                  <select
                    id="add-trainee-select"
                    onChange={({ target }) => {
                      const { value } = target;
                      clearError('trainees');
                      if (value !== '') {
                        addTrainee(Number(value));
                      }
                      target.value = '';
                    }}
                  >
                    <option value="">Add one or more trainees…</option>
                    {
                      selectTraineeIds.map((id, index) => (
                        <option value={id} key={index}>{`${allTrainees[id].lastNameShort} ${allTrainees[id].firstName}`}</option>
                      ))
                    }
                  </select>
                  {
                    errors.trainees != ''
                      ? <span className="wrror-masage">{errors.trainees}</span>
                      : null
                  }
                </div>
              </div>
              <div className="users-edit-history-session-table__wrapper">
                <div className="trainees-modal__table-title">
                  Selected trainees
                  <span className="trainees-modal__table-bage">{traineeGroup.trainees.length}</span>
                </div>
                <div className="users-edit-table__inner">
                  <table
                    className="users-table__data"
                    id="new-group-trainee-table-modal"
                  >
                    <tbody className="users-table__tbody">
                      {
                        traineeGroup.trainees.map((id, index) => (
                          <tr key={index}>
                            <td>{allTrainees[id].lastNameShort}</td>
                            <td>{allTrainees[id].firstName}</td>
                            <td>{allTrainees[id].idNumber}</td>
                            <td>{allTrainees[id].company}</td>
                            <td>
                              <span
                                className="close"
                                onClick={() => removeTrainee(Number(id))}
                              >
                                <i className="fa fa-close"></i>
                              </span>
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="create-new-user__buttons">
            <button className="cancel-btn btn-param" onClick={() => onCancel()}>
              Cancel
            </button>
            {
              isCreateMode
                ? (
                  <button
                    className="create-btn btn-param"
                    onClick={() => {
                      if (checkValidation()) {
                        changeInitTraineeIds();
                        onConfirm([...traineeGroups, traineeGroup]);
                      }
                    }}
                  >
                  Create
                  </button>
                )
                : (
                  <button
                    className="save-btn btn-param"
                    onClick={() => {
                      if (checkValidation()) {
                        changeInitTraineeIds();
                        traineeGroups[traineeGroupIndex] = traineeGroup;
                        onConfirm(traineeGroups);
                      }
                    }}
                  >
                  Save
                  </button>
                )
            }
          </div>
        </div>
      </div>
    </div>
  );
}

ModalGroupEdit.propTypes = {
  selectTraineeIds: PropTypes.array.isRequired,
  traineeGroups: PropTypes.array.isRequired,
  traineeGroupIndex: PropTypes.number.isRequired,
  trainees: PropTypes.array.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

ModalGroupEdit.defaultProps = {
  selectTraineeIds: [],
  traineeGroups: [],
  traineeGroupIndex: -1,
  trainees: [],
};

export default ModalGroupEdit;
