import React, {useState} from 'react';
import { useSelector } from 'react-redux';
import Interface from '../common/interface.jsx';
import Dashboard from '../common/dashboard.jsx';
import TableSidebar from '../common/table/table-sidebar.jsx';
import SyllabusTable from './syllabus-table.jsx';
import {
  ROLE_ADMIN,
  ROLE_PRODUCT_MANAGER,
  ROLE_QUALITY_MANAGER,
  ROLE_INSTRUCTOR,
} from '@/helpers/role';
import TableSitebar from "@/components/common/table/table-sidebar";

const Syllabus = ({ match }) => {
  const { url } = match;
  const { roles } = useSelector(state => state.authReducer.user);
  const [importResult, setImportResult] = useState({
    inProgress: false,
    processed: false,
    importedCount: 0,
    totalCount: 0,
  });

  const sidebarContent = {
    infoTitle: 'Syllabus',
    createTitle: 'Add new syllabus',
    createButtonUrl: (roles.includes(ROLE_ADMIN) || roles.includes(ROLE_PRODUCT_MANAGER) || roles.includes(ROLE_INSTRUCTOR)) && `${url}/create`,
    importUrl: '/api/import/syllabi',
  };

  return (
    <Interface className="syllabus-interface">
      <TableSidebar content={sidebarContent} importResult={importResult} setImportResult={setImportResult}/>
      <Dashboard title="SYLLABUS LIST">
        <SyllabusTable importResult={importResult}/>
      </Dashboard>
    </Interface>
  );
};

export default Syllabus;
