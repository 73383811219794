import Request from "../../helpers/request";
import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import ModalRevision from "./common/modal-revision.jsx";
import CreateRevisionModal from "./common/modal-create-revision.jsx";
import TextInput from "./../common/textInput.jsx";
import TextArea from "./../common/textarea.jsx";
import EntityRepository from "../../repositories/EntityRepository";
import ErrorRepository from "../../repositories/ErrorRepository";
import ModalArchiving from "./common/modal-archiving.jsx";
import { success, warning, danger } from "../../redux/actions/notify";
import AgreeChanges from "../modals/modal-agree-changes";
import { connect } from "react-redux";
import { hide, show } from "../../redux/actions/loader";
import ButtonSinglePdf from "../common/table/button-single-pdf";
import {Tooltip} from "@mui/material";

class AtaEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uid: props.match.params.uid,
      ataData: {},
      revisions: [],
      errors: [],
      modalRevision: false,
      popupConfig: {
        isOpen: false,
        action: "",
      },
      createRevisionModal: false,
      comment: "",
      isDisable: true,
      agreeModal: false,
      changes: false,
    };
    this.openModalInfo = this.openModalInfo.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.confirmAction = this.confirmAction.bind(this);
  }

  openModalInfo = () => {
    this.setState({ modalRevision: true });
  };

  closeModal = () => {
    this.setState({ modalRevision: false });
  };

  createRevision = (event) => {
    event.preventDefault();
    this.setState({ createRevisionModal: true });
  };

  setCommitRevision = (comment) => this.setState({ comment });

  closeCteateRevision = () => {
    this.setState({ createRevisionModal: false });
    this.setState({ comment: "" });
  };

  confirmAction = async (event) => {
    let data = this.state.ataData;
    data.revision_comment = this.state.comment;
    let response = await EntityRepository.updateEntity(
      "/api/atas",
      this.state.uid,
      data
    );

    if (response.update === true) {
      this.props.success("Ata successfully updated");
      this.props.history.push("/settings/atas");
    } else {
      this.props.danger("Server error");
      this.setState({ errors: response.errors });
    }
  };

  async componentDidMount() {
    this.props.loaderShow();
    let data = await EntityRepository.getEntity("/api/atas", this.state.uid);
    this.setState({
      popupConfig: {
        ...this.state.popupConfig,
        action: data.is_archived ? "restore" : "archive",
      },
    });
    this.setState({
      popupConfig: { ...this.state.popupConfig, revisionNumber: data.version },
    });
    this.setAtaData(data);
    this.props.loaderHide();
  }

  setAtaData = async (data) => {
    this.setState({
      ataData: data,
      revisionData: data,
      revisionId: data.id,
      uid: data.u_id,
      created_at: data.created_at,
    });
    this.getAllRevision().then((result) =>
      this.setState({
        revisions: result,
      })
    );
    this.props.history.push(
      this.props.match.path.replace(":uid", this.state.uid)
    );
  };

  getAllRevision = async () => {
    return await Request.get(`/api/atas/${this.state.uid}/revisions`);
  };

  nextAta = async () => {
    const data = await Request.get(`/api/atas/${this.state.ataData.id}/next`);
    this.setAtaData(data);
  };

  prevAta = async () => {
    const data = await Request.get(`/api/atas/${this.state.ataData.id}/prev`);
    this.setAtaData(data);
  };

  handleChange = (e) => {
    this.setState({ changes: true });

    const { name, value } = e.target;
    this.clearErrorField(name);

    this.setState({
      ataData: {
        ...this.state.ataData,
        [name]: value,
      },
    });
  };

  openPopup = () => {
    this.setState({ popupConfig: { ...this.state.popupConfig, isOpen: true } });
  };

  cancelPopup = () => {
    this.setState({
      popupConfig: { ...this.state.popupConfig, isOpen: false },
    });
  };

  confirmPopup = async () => {
    if (this.state.popupConfig.action == "archive") {
      const response = await EntityRepository.archiveEntities(
        "/api/atas",
        this.state.revisionId
      );
      if (response.archived === true) {
        this.props.history.push("/settings/atas");
      }
    } else {
      const response = await EntityRepository.restoreEntities(
        "/api/atas",
        this.state.revisionId
      );
      if (response.restored === true) {
        this.props.history.push("/settings/atas");
      }
    }
  };

  openPopup = () => {
    this.setState({ popupConfig: { ...this.state.popupConfig, isOpen: true } });
  };

  cancelPopup = () => {
    this.setState({
      popupConfig: { ...this.state.popupConfig, isOpen: false },
    });
  };

  clearErrorField = (fieldName) => {
    const { errors } = this.state;
    if (errors[fieldName] && errors[fieldName] != "") {
      this.setState({ errors: { ...errors, [fieldName]: "" } });
    }
  };

  goBack = (event) => {
    event.preventDefault();

    if (this.state.changes) {
      return this.setState({ agreeModal: true });
    }

    let filter = this.props.location.state !== undefined ? this.props.location.state.filter : "all";

    return this.props.history.push({
      pathname: "/settings/atas",
      state: { filter: filter },
    });
  };

  confirmAgree = () => {
    this.setState({ agreeModal: false, changes: false });
    return this.props.history.push("/settings/atas");
  };

  cancelAgree = () => {
    return this.setState({ agreeModal: false });
  };

  render() {
    const {
      uid,
      errors,
      createRevisionModal,
      comment,
      isDisable,
      agreeModal,
      ataData,
    } = this.state;

    return (
      <>
        <section className="edit-trainee__wrapper users-edit-wrapper">
          <div className="container">
            <div className="users-edit-head__inner">
              <ul className="users-edit__breadcrumb">
                <li>
                  <NavLink exact to={"/settings/atas"}>
                    ATA
                  </NavLink>
                </li>
                <li className="active">
                  <span>ATA #{ataData.code}</span>
                </li>
                <li>
                  <span className="version-number">
                    Version 0{ataData.version}
                  </span>
                </li>
                <li>
                  <span className="version-number">{ataData.short_name}</span>
                </li>
              </ul>
              <div className="users-edit-head__buttons">
                <button className="prev-user__btn" onClick={this.prevAta}>
                  <i className="fa fa-angle-left"></i>
                </button>
                <button className="next-user__btn" onClick={this.nextAta}>
                  <i className="fa fa-angle-right"></i>
                </button>
              </div>
            </div>

            <div className="create-new-user__wrapper">
              <div className="create-new-user__top-box">
                <div className="create-new-user__badge">
                  <span>editing</span>
                </div>
                <div className="users-edit__btn-box">
                  <Tooltip title="Revisions">
                    <button className="prev-version" onClick={this.openModalInfo}>
                      <span></span>
                    </button>
                  </Tooltip>
                  <ButtonSinglePdf resource="atas" url={`/api/atas/${uid}/generate-pdf`} />
                  <Tooltip title="Archive">
                    <button className="add-archive-btn" onClick={this.openPopup}>
                    <span
                      className={
                        this.state.popupConfig.action == "archive"
                          ? "add-archive"
                          : "restore-archive"
                      }
                    ></span>
                    </button>
                  </Tooltip>
                </div>
              </div>
              <div className="create-new-user__board edit-staff__board">
                <form
                  className="create-new-user__form  ata-form"
                  id="edit-ata-form"
                >
                  <div className="create-new-user__board-wrapper">
                    <div
                      className={
                        "icon-box" +
                        ErrorRepository.getErrorClassName(errors, "code")
                      }
                    >
                      <TextInput
                        inputName={"code"}
                        inputLabel={"ATA code"}
                        inputValue={ataData.code}
                        errors={errors}
                        changeInput={this.handleChange}
                        disabled={true}
                        className={"disable"}
                      />
                    </div>
                    <div
                      className={
                        "icon-box ata-descr-box" +
                        ErrorRepository.getErrorClassName(errors, "description")
                      }
                    >
                      <TextArea
                        id={"create-ata-descr"}
                        label={"Description"}
                        inputName={"description"}
                        onChange={this.handleChange}
                        inputValue={this.state.ataData.description}
                        errors={errors}
                      />
                    </div>
                  </div>
                  <div className="create-new-user__buttons">
                    <button
                      className="cancel-btn btn-param"
                      onClick={this.goBack}
                    >
                      Cancel
                    </button>
                    <button
                      className="save-btn btn-param"
                      onClick={this.createRevision}
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        {this.state.popupConfig.isOpen ? (
          <ModalArchiving
            config={this.state.popupConfig}
            cancelPopup={this.cancelPopup}
            confirmPopup={this.confirmPopup}
          />
        ) : (
          ""
        )}
        {this.state.modalRevision ? (
          <ModalRevision
            closeModal={this.closeModal}
            revisions={this.state.revisions}
            lastRevision={this.state.revisionData}
          />
        ) : null}

        {createRevisionModal ? (
          <CreateRevisionModal
            handleChange={(e) => this.setCommitRevision(e.target.value)}
            confirmAction={this.confirmAction}
            close={this.closeCteateRevision}
            isDisable={isDisable}
            comment={comment}
          />
        ) : null}
        {agreeModal && (
          <AgreeChanges
            cancelAgree={this.cancelAgree}
            confirmAgree={this.confirmAgree}
          />
        )}
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    user: state.authReducer.user,
    notifications: state.notificationReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loaderShow: () => dispatch(show()),
    loaderHide: () => dispatch(hide()),
    success: (message) => dispatch(success(message)),
    warning: (message) => dispatch(warning(message)),
    danger: (message) => dispatch(danger(message)),
  };
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AtaEdit)
);
