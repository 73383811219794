import React, { useState } from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { useTraining } from '../common/training-context';
import ModalTraineeList from './modals/modal-trainee-list';
import ModalTrainee from '../trainees/modals/modal-trainee';
import Slider from '@bit/akiran.react-slick.slider';

function SyllabusMain() {
  const training = useTraining();
  const { url } = useRouteMatch();
  const { instructors = [] } = training;
  const [showModalTraineeList, setShowModalTraineeList] = useState(false);
  const [showModalTrainee, setShowModalTrainee] = useState(false);
  const [selectedTraineeId, setSelectedTraineeId] = useState(null);
  return (
    <>
      <div className="syllabus-inform-panel">
        <div className="syllabus-inform__logo">
          <img src="/adminApp/src/assets/img/white-logo.svg" alt="logo" />
        </div>
        <div className="syllabus-inform__item">
          <div className="syllabus-inform__subtitle">Syllabus</div>
          <div className="syllabus-inform__name">{training.syllabus_title}</div>
        </div>

        <div className="syllabus-inform__item">
          <div className="syllabus-inform__subtitle">Instructor(s)</div>
          <div className="syllabus-inform__instructors">
            {instructors.map((instructor, index) => (
              <span
                className="instructor-name"
                key={index}
              >{`${instructor.first_name} ${instructor.last_name}`}</span>
            ))}
          </div>
        </div>

        <div className="syllabus-inform__item syllabus-inform__trainee">
          <div className="syllabus-inform__inner">
            <div className="syllabus-inform__subtitle">Trainees</div>
            <button
              className="open-list__btn"
              onClick={() => setShowModalTraineeList(true)}
            >
              See list
            </button>
          </div>
          <div className="number">{training.trainee_count}</div>
        </div>
      </div>
      <div className="syllabus-main-panel__head">
        <div className="syllabus-inform__subtitle">
          Practical training element
        </div>
        <div className="syllabus-main-panel__qualification">
          {training.syllabus_type}
        </div>
      </div>
      <div className="syllabus-main-panel">
        <h1 className="syllabus-main-panel__title">
          {training.syllabus_title}
        </h1>
      </div>
      {/* syllabus-main-panel__buttons */}
      <div className="syllabus-main-panel__buttons">
        {/* <div className="slick-container">
          <Slider
            {...{
              dots: false,
              infinite: true,
              speed: 500,
              slidesToShow: 3,
              slidesToScroll: 3,
              arrows: false,
              slide: "div",
              variableWidth: false,
              nextArrow: (
                <button className="prev-user__btn">
                  <i className="fa fa-angle-left"></i>
                </button>
              ),
              prevArrow: (
                <button className="next-user__btn">
                  <i className="fa fa-angle-right"></i>
                </button>
              ),
            }}
          > */}
        <div className="syllabus-main-panel__btn">
          <NavLink
            to={`${url}/pages/introduction`}
            className="syllabus-main-panel__link"
          >
                Introduction
          </NavLink>
        </div>
        <div className="syllabus-main-panel__btn">
          <NavLink
            to={`${url}/pages/part-1`}
            className="syllabus-main-panel__link"
          >
                Tasks
          </NavLink>
        </div>
        <div className="syllabus-main-panel__btn">
          <NavLink
            to={`${url}/pages/part-2`}
            className="syllabus-main-panel__link"
          >
                Assessment
          </NavLink>
        </div>
        {/* <div className="syllabus-main-panel__btn">
              <NavLink
                className="user-interface__subheader-btn"
                activeClassName="subheader-btn-active"
                to={`${url}/description`}
              >
                Description
              </NavLink>
            </div>
            <div className="syllabus-main-panel__btn">
              <NavLink
                className="user-interface__subheader-btn"
                activeClassName="subheader-btn-active"
                to={`${url}/summary-table`}
              >
                Summary table
              </NavLink>
            </div>
            <div className="syllabus-main-panel__btn">
              <NavLink
                className="user-interface__subheader-btn"
                activeClassName="subheader-btn-active"
                to={`${url}/assessment-part`}
              >
                Assessment Part
              </NavLink>
            </div>
            <div className="syllabus-main-panel__btn">
              <NavLink
                className="user-interface__subheader-btn"
                activeClassName="subheader-btn-active"
                to={`${url}/revisions`}
              >
                Revisions
              </NavLink>
            </div> */}
        {/* </Slider>
        </div> */}
      </div>
      {showModalTrainee ? (
        <ModalTrainee
          traineeId={selectedTraineeId}
          onCancel={() => setShowModalTrainee(false)}
        />
      ) : null}
      {showModalTraineeList ? (
        <ModalTraineeList
          showTrainee={(traineeId) => {
            setShowModalTraineeList(false);
            setSelectedTraineeId(traineeId);
            setShowModalTrainee(true);
          }}
          onCancel={() => setShowModalTraineeList(false)}
        />
      ) : null}
    </>
  );
}

export default SyllabusMain;
