import React from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import date from '@/helpers/date';

function ModalTraineeView({ sessionNumber = '', trainees = [], onCancel }) {
  const { id: trainingId } = useParams();

  return (
    <div className="overlay">
      <div
        className="modal-act-element__create-task scrollbar-style"
        id="trainee-list-modal"
      >
        <div className="modal-act-element__wrapper">
          <div className="modal-act-element__head">
            <div className="modal-act-element__title">Trainee list</div>
            <div className="modal-act-element__sesion-date">
              <span>{date.getFormatted()}</span>
            </div>
          </div>
          <div className="trainees-modal__wrapper trainee-list-modal__wrapper">
            <div className="modal-list__title">List #{sessionNumber}</div>
            <div className="users-edit-history-session__content">
              <div className="users-edit-history-session-table__wrapper">
                <div className="trainees-modal__table-title">
                  Total trainees
                  <span className="trainees-modal__table-bage">
                    {Object.keys(trainees).length}
                  </span>
                </div>
                <div className="users-edit-table__inner">
                  <table
                    className="users-table__data"
                    id="edit-training-trainee-list-modal-table"
                  >
                    <tbody className="users-table__tbody">
                      {Object.keys(trainees).map((id) => (
                        <tr key={id}>
                          <td>{trainees[id].lastNameShort}</td>
                          <td>{trainees[id].firstName}</td>
                          <td>{trainees[id].idNumber}</td>
                          <td>{trainees[id].company}</td>
                          <td>
                            <Link to={`/trainings/${trainingId}/trainees/${id}`}>
                              <span className="table-see-more__btn"></span>
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-act-element__btn">
            <button className="back__btn btn-param" onClick={onCancel}>
              Thanks, go back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

ModalTraineeView.propTypes = {
  sessionNumber: PropTypes.string,
  trainees: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
};

ModalTraineeView.defaultProps = {
  sessionNumber: '',
  trainees: [],
};

export default ModalTraineeView;
