import React, { useEffect, useState } from 'react';
import Table from '../common/table/table.jsx';
import TableSearch from '../common/table/table-search.jsx';
import TableBody from '../common/table/table-body.jsx';
import TableHeader from '../common/table/table-header.jsx';
import { ACTION_ARCHIVE, ACTION_RESTORE, GET_LIST_REQUEST_PARAMS } from '../common/constants';
import TrainingRow from './training-row.jsx';
import EntityRepository from '../../repositories/EntityRepository.js';
import Request from '@/helpers/request';
import { archivingActions } from '@/components/common/table/table-actions';

const TrainingsTable = ({ importResult }) => {
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [overlay, setOverlay] = useState({
    showModal: false,
    selectedIds: [],
    entityTitles: [],
  });

  const [modalRevision, setModalRevision] = useState({
    isOpen: false,
    revisions: [],
  });

  const [requestParams, setRequestParams] = useState({
    ...GET_LIST_REQUEST_PARAMS, status: 'current',
  });

  const thead = ['N# Session', 'Course Reference', 'Syllabus', 'Instructor(s)', 'Trainee(s)', 'Dates'];
  const path = '/api/trainings';
  const pathPdf = '/api/trainings/generate-training-pdf';

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const data = await EntityRepository.getEntities(
        path,
        requestParams
      );
      if (data) {
        if (!requestParams.start) {
          setData(data.items);
          setCount(data.count);
        } else {
          setData(state => ([...state, ...data.items]));
          setCount(data.count);
        }
        setIsLoading(false);
      }
    };
    fetchData();
  }, [requestParams, importResult]);

  const openModalRow = (row, action = ACTION_ARCHIVE) => {
    if (row.id !== undefined) {
      setOverlay({
        showModal: true,
        popupName: 'training',
        entityName: row.title,
        entityTitles: [row.id],
        selectedIds: [row.id],
        eventTitle: action === ACTION_RESTORE ? 'Restoring' : 'Archiving',
        action,
      });
    }
  };

  const openModalTable = (selectedIds, action = ACTION_ARCHIVE) => {
    setOverlay({
      showModal: true,
      popupName: 'training',
      entityName: 'Training',
      entityTitles: selectedIds,
      eventTitle: action === ACTION_RESTORE ? 'Restoring' : 'Archiving',
      selectedIds,
      action,
    });
  };

  const renderRows = () => {

    const rows = data.map((item, i) => (
      <TrainingRow key={i} row={item} path={path} openModal={openModalRow}/>
    ));

    if (isLoading) {
      const loadingRow = <tr key="loadingRow">
        <td colSpan="8"><i className="fa fa-spin fa-circle-o-notch"></i></td>
      </tr>;

      if (requestParams.start) {
        rows.push(loadingRow);
      } else {
        return loadingRow;
      }
    }

    return rows;
  };

  const onScrollList = (event) => {
    const scrollBottom =
      Math.ceil((event.target.scrollHeight - event.target.scrollTop) - 100) <= event.target.clientHeight;
    const loadedCount = data.length;
    if (scrollBottom && !isLoading && loadedCount < count) {
      loadAdditionalData();
    }
  };

  const loadAdditionalData = () => {
    setRequestParams(data => ({
      ...data,
      start: requestParams.start + requestParams.limit,
    }));
  };

  const loadFilterData = (field = 'like', value = '') => {
    resetPagination();
    setRequestParams(data => ({ ...data, [field]: value }));
  };

  const resetPagination = () => {
    setRequestParams({
      ...GET_LIST_REQUEST_PARAMS, status: 'current',
    });
  };

  return (
    <Table>
      <TableHeader count={count} title="Trainings">
        <TableSearch
          onArchiveFilter={(e) => loadFilterData('status', e)}
          onSearch={(e) => loadFilterData('like', e)}
          getFilters={requestParams}
          pdfUrl={pathPdf}
          archiveField="status"
          archiveClass="training-filter__buttons"
          archiveFilterOptions={[
            {
              className: 'coming-btn',
              title: 'coming',
              value: 'coming',
              isActive: true,
            },
            {
              className: 'curent-btn',
              title: 'current',
              value: 'current',
              isActive: true,
            },
            {
              className: 'complete-btn',
              title: 'completed',
              value: 'completed',
            },
            {
              className: 'all-users-btn',
              title: 'all',
              value: 'all',
            },
          ]}
        />
      </TableHeader>
      <TableBody
        actions={archivingActions(openModalTable)}
        overlay={overlay}
        onScroll={event => onScrollList(event)}
        path={path}
        setOverlay={setOverlay}
        thead={thead}
        lastThColSpan={2}
        tableId="training-list-table"
      >
        {renderRows()}
      </TableBody>
    </Table>
  );
};

export default TrainingsTable;
