import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import TableFilterGroup from './table-filter-group.jsx';
import ButtonPdf from './button-pdf.jsx';

const TableSearch = ({
  onSearch,
  getFilters = () => {},
  pdfUrl = '',
  archiveClass = '',
  archiveField = 'archive',
  archiveFilterOptions = [
    {
      className: 'active-users-btn',
      title: 'active',
      value: 'active',
      isActive: true,
    },
    {
      className: 'archived-users-btn',
      title: 'archived',
      value: 'archived',
    },
    {
      className: 'all-users-btn',
      title: 'all',
      value: 'all',
    },
  ],
  onArchiveFilter,
  children }) => {
  const [search, setSearch] = useState('');
  const filter = useSelector(state => state.filterReducer);
  const location = useLocation();

  useEffect(() => {
    const filterValue = filter.data.like;
    if (filter.path === location.pathname && search !== filterValue) {
      setSearch(filterValue);
    }
  }, []);

  return (
    <div className="data-search__wrapper">
      <div className="data-search">
        <i className="search-icon fa fa-search"></i>
        <input
          className="data-search__field"
          type="text"
          placeholder="Search"
          value={search}
          onChange={({ target: { value } }) => {
            setSearch(value);
            onSearch(value);
          }}
        />
      </div>
      {children}
      <div className="data-search__buttons-container">
        <TableFilterGroup classModifier={archiveClass} field={archiveField} onFilter={onArchiveFilter} options={archiveFilterOptions}/>
        <ButtonPdf url={pdfUrl} getFilters={getFilters}/>
      </div>
    </div>
  );
};

export default TableSearch;
