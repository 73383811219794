import React from 'react';
import {NavLink} from 'react-router-dom';
import TextInput from '../../common/textInput.jsx';
import DatePicker from 'react-datepicker';
import EntityRepository from '../../../repositories/EntityRepository';
import ErrorRepository from '../../../repositories/ErrorRepository';
import ModalArchiving from '../../common/modal-archiving.jsx';
import {
  SKILL_B1,
  SKILL_B2,
  ACTION_ARCHIVE,
  ACTION_RESTORE
} from '../../common/constants.js';
import {connect} from 'react-redux';
import {hide, show} from '../../../redux/actions/loader';
import {success, danger} from '../../../redux/actions/notify';
import SearchCategories from '../common/auto-search';
import {
  ROLE_ADMIN,
  ROLE_INSTRUCTOR,
  ROLE_PRODUCT_MANAGER,
  ROLE_QUALITY_MANAGER,
  ROLE_TRAINEE
} from '../../../helpers/role';
import ButtonSinglePdf from '../../common/table/button-single-pdf';
import AgreeChanges from '../../modals/modal-agree-changes';
import date from '@/helpers/date';
import {Tooltip} from "@mui/material";

class StaffEdit extends React.Component {
  constructor(props) {
    super();
    this.state = {
      id: Number(props.match.params.id),
      formData: {
        email: '',
        password: null,
        id_number: '',
        first_name: '',
        last_name: '',
        role_title: '',
        birth_place: '',
        skill: [],
        user_categories: [],
        role: ROLE_ADMIN,
      },
      errors: [],
      user_categories: [],
      categoryAndSkills: true,
      modalArchivingConfig: {
        showModal: false,
      },
      agreeModal: false,
      changes: false,
    };
  }

  updateUser = async (event) => {
    event.preventDefault();
    let data = this.state.formData;
    data.birth_date = data.birth_date.toLocaleString();
    let response = await EntityRepository.updateEntity(
      '/api/staff',
      this.state.id,
      data
    );

    if (response.update === true) {
      this.props.success('User successfully saved');
      this.props.history.push('/settings/users/staff');
    } else if (response.errors) {
      this.setState({errors: response.errors});
    } else {
      this.props.danger('Server error');
    }
  };

  setUserData = async (data) => {
    this.setState({
      categoryAndSkills: data.roles[0] === ROLE_INSTRUCTOR,
      formData: {
        role: data.roles[0],
        email: data.email || '',
        password: null,
        id_number: data.id_number || '',
        first_name: data.first_name || '',
        last_name: data.last_name || '',
        role_title: data.role_title || '',
        birth_date: data.birth_date || null,
        birth_place: data.birth_place || '',
        skill: data.skill,
        user_categories: data.user_categories || [],
      },
    });
  };

  async componentDidMount() {
    this.props.loaderShow();
    let data = await EntityRepository.getEntity('/api/users', this.state.id);
    this.setUserData(data);
    this.props.loaderHide();
  }

  clearErrorField = (fieldName) => {
    const {errors} = this.state;
    if (errors[fieldName] && errors[fieldName] != '') {
      this.setState({errors: {...errors, [fieldName]: ''}});
    }
  };

  setFormField = (name, value) => {
    this.clearErrorField(name);
    this.setState({
      changes: true,
      formData: {...this.state.formData, [name]: value},
    });
  }

  handleChange = ({target: {name, value}}) => this.setFormField(name, value);

  dateChange = (date) => this.setFormField('birth_date', date);

  changeIdNumber = ({target: {name, value}}) => this.setFormField(name, value.toUpperCase());

  skillChange = ({target: {checked, value}}) => {
    const {skill} = this.state.formData;
    const newArray = checked
      ? skill.includes(value)
        ? skill
        : [...skill, value]
      : skill.filter((item) => item !== value);

    this.setFormField('skill', newArray);
  };

  openModalArchiving = () => {
    const {formData} = this.state;
    let action = ACTION_ARCHIVE;
    let eventTitle;

    if (formData.is_archived) {
      action = ACTION_RESTORE;
      eventTitle = 'Restoring';
    } else {
      action = ACTION_ARCHIVE;
      eventTitle = 'Archiving';
    }

    if (formData.id !== undefined) {
      this.setState({
        modalArchivingConfig: {
          showModal: true,
          popupName: 'user',
          entityName: `${formData.last_name} ${formData.first_name}`,
          entityTitles: [formData.id_number],
          selectedIds: [formData.id],
          eventTitle,
          action,
        },
      });
    }
  };

  confirmModaArchiving = async () => {
    const {selectedIds, action} = this.state.modalArchivingConfig;

    if (action === ACTION_ARCHIVE) {
      const response = await EntityRepository.archiveEntities(
        'api/users',
        selectedIds
      );
      if (response.archived === true) {
        this.props.history.push('/settings/users/staff');
      }
    } else if (action === ACTION_RESTORE) {
      const response = await EntityRepository.restoreEntities(
        'api/users',
        selectedIds
      );
      if (response.restored === true) {
        this.props.history.push('/settings/users/staff');
      }
    }
    this.cancelModaArchiving();
  };

  cancelModaArchiving = () => {
    this.setState({
      modalArchivingConfig: {
        showModal: false,
        entityName: '',
        entityTitles: [],
        selectedIds: [],
      },
    });
  };

  addCategory = (newData) => {
    let suggestion = this.state.formData.user_categories;

    if (suggestion.filter(e => e === newData.name).length > 0) {
      return;
    }
    suggestion.push(newData.name);

    let categories = this.state.user_categories;
    let tempArr = {
      name: newData.name,
    };
    categories.push(tempArr);

    this.setState((state) => (
      {
        ...state,
        formData: {...this.state.formData, ['user_categories']: suggestion},
        user_categories: categories,
      }
    ));
  };

  deleteCategory = (e) => {
    let formcategories = [...this.state.formData.user_categories];
    let categories = [...this.state.user_categories];
    let deletedId = e.target.parentElement.getAttribute('data-name');

    formcategories.map((data, index) => {
      if (data == deletedId) {
        formcategories.splice(formcategories.indexOf(data.name), 1);
        categories.splice(categories.indexOf(data.name), 1);
      }
    });

    this.setState((state) => (
      {
        ...state,
        formData: {...this.state.formData, user_categories: formcategories},
        user_categories: categories,
      }
    ));
  };

  createCategory = (value) => {
    this.setState({changes: true});

    this.addCategory({name: value});
  }

  goBack = (event) => {
    event.preventDefault();

    if (this.state.changes) {
      return this.setState({agreeModal: true});
    }

    return this.props.history.push('/settings/users/staff');
  };

  confirmAgree = () => {
    this.setState({agreeModal: false, changes: false});

    return this.props.history.push('/settings/users/staff');
  };

  cancelAgree = () => {
    return this.setState({agreeModal: false});
  };

  render() {
    const {
      id,
      errors,
      modalArchivingConfig,
      formData,
      categoryAndSkills,
      agreeModal,
    } = this.state;
    const {user} = this.props;

    const canSave = () => {
      if (user.roles.includes(ROLE_ADMIN)) {
        return true;
      }

      if (user.roles.includes(ROLE_QUALITY_MANAGER)) {
        if (formData.role === ROLE_QUALITY_MANAGER || formData.role === ROLE_PRODUCT_MANAGER) {
          return true;
        }
      }

      if (user.roles.includes(ROLE_PRODUCT_MANAGER)) {
        if (formData.role === ROLE_QUALITY_MANAGER || formData.role === ROLE_PRODUCT_MANAGER) {
          return true;
        }
      }

      return false;
    }

    return (
      <section className="edit-trainee__wrapper users-edit-wrapper">
        <div className="container">
          <div className="users-edit-head__inner">
            <ul className="users-edit__breadcrumb">
              <li>
                <NavLink exact to={'/settings/users'}>
                  User
                </NavLink>
              </li>
              <li>
                <NavLink exact to={'/settings/users/staff'}>
                  Staff
                </NavLink>
              </li>
              <li className="active">
                <span>{this.state.formData.first_name}</span>
              </li>
            </ul>
            <div className="users-edit-head__buttons"></div>
          </div>

          <div className="create-new-user__wrapper">
            <div className="create-new-user__top-box">
              <div className="create-new-user__badge">
                <span>editing</span>
              </div>
              <div className="edit-staff__user-status">
                <input
                  type="text"
                  value={this.state.formData.role_title}
                  disabled
                />
              </div>
              <div className="users-edit__btn-box">
                <ButtonSinglePdf url={`/api/staff/${id}/generate-pdf`} className="save-file-btn"/>
                <Tooltip title={"Archive"}>
                  <button
                    className="add-archive-btn"
                    onClick={this.openModalArchiving}
                  >
                <span
                  className={
                    this.state.formData.is_archived
                      ? 'restore-archive'
                      : 'add-archive'
                  }
                ></span>
                  </button>
                </Tooltip>
              </div>
            </div>
            <div className="create-new-user__board edit-staff__board">
              <form className="create-new-user__form" id="edit-staff-form">
                <div className="create-new-user__board-wrapper">
                  <div
                    className={
                      'icon-box' +
                      ErrorRepository.getErrorClassName(errors, 'first_name')
                    }
                  >
                    <TextInput
                      inputName={'first_name'}
                      inputValue={this.state.formData.first_name}
                      inputLabel={'Name'}
                      errors={errors}
                      changeInput={this.handleChange}
                    />
                  </div>
                  <div
                    className={
                      'icon-box' +
                      ErrorRepository.getErrorClassName(errors, 'last_name')
                    }
                  >
                    <TextInput
                      inputName={'last_name'}
                      inputValue={this.state.formData.last_name}
                      inputLabel={'Last Name'}
                      errors={errors}
                      changeInput={this.handleChange}
                    />
                  </div>
                  <div
                    className={
                      'icon-box create-user-birth' +
                      ErrorRepository.getErrorClassName(errors, 'birth_date')
                    }
                  >
                    <label htmlFor="create-staff-birth">Date of birth</label>
                    <DatePicker
                      selected={Date.parse(this.state.formData.birth_date)}
                      dateFormat={date.inputDateFormat}
                      onChange={this.dateChange}
                      showYearDropdown
                      yearDropdownItemNumber={15}
                      maxDate={new Date()}
                    />
                    <span className="wrror-masage">This field is unvalid.</span>
                  </div>
                  <div
                    className={
                      'icon-box' +
                      ErrorRepository.getErrorClassName(errors, 'birth_place')
                    }
                  >
                    <TextInput
                      inputName={'birth_place'}
                      inputValue={this.state.formData.birth_place}
                      inputLabel={'Place of Birth'}
                      errors={errors}
                      changeInput={this.handleChange}
                    />
                  </div>
                  {categoryAndSkills ? (
                    <>
                      <div className="category-user-search">
                        <SearchCategories onSelect={this.addCategory} createCategory={this.createCategory}/>
                        <div className="search-result__wrapper">
                          {formData.user_categories.map((name, index) => (
                            <span className="category-name">
                            {name}
                              <button
                                className="delete-category-name"
                                data-name={name}
                                onClick={this.deleteCategory}
                              >
                              <i className="fa fa-close"></i>
                            </button>
                          </span>
                          ))}
                        </div>
                      </div>
                      <div
                        className={
                          'icon-box staff-skill__box' +
                          ErrorRepository.getErrorClassName(errors, 'skill')
                        }
                      >
                        <label>Skill</label>
                        <div className="staff-skill__wrapper">
                          <div className="staff-skill__inner">
                            <label className="category-checkbox">
                              <input
                                type="checkbox"
                                name={'skill'}
                                value={SKILL_B1}
                                onChange={this.skillChange}
                                checked={formData.skill.includes(SKILL_B1)}
                              />
                              <span className="category-custom-checkbox"></span>
                            </label>
                            <span className="category-name">B1</span>
                          </div>
                          <div className="staff-skill__inner">
                            <label className="category-checkbox">
                              <input
                                type="checkbox"
                                name={'skill'}
                                value={SKILL_B2}
                                onChange={this.skillChange}
                                checked={formData.skill.includes(SKILL_B2)}
                              />
                              <span className="category-custom-checkbox"></span>
                            </label>
                            <span className="category-name">B2</span>
                          </div>
                        </div>
                        <span className="wrror-masage">
                          This field is unvalid.
                      </span>
                      </div>
                    </>
                  ) : (
                    ''
                  )}

                  <div
                    className={
                      'icon-box' +
                      ErrorRepository.getErrorClassName(errors, 'id_number')
                    }
                  >
                    <TextInput
                      inputName={'id_number'}
                      inputValue={this.state.formData.id_number}
                      inputLabel={'ID number'}
                      errors={errors}
                      changeInput={this.changeIdNumber}
                    />
                  </div>
                  <div
                    className={
                      'icon-box' +
                      ErrorRepository.getErrorClassName(errors, 'email')
                    }
                  >
                    <TextInput
                      inputName={'email'}
                      errors={errors}
                      inputValue={this.state.formData.email}
                      inputLabel={'Email address'}
                      changeInput={this.handleChange}
                    />
                  </div>
                  <div
                    className={
                      'icon-box' +
                      ErrorRepository.getErrorClassName(
                        this.state.errors,
                        'password'
                      )
                    }
                  >
                    <TextInput
                      inputName="password"
                      inputLabel="Password"
                      errors={errors}
                      inputType={user.id !== id ? 'text' : 'password'}
                      changeInput={this.handleChange}
                    />
                  </div>
                </div>
                <div className="create-new-user__buttons">
                  <button
                    className="cancel-btn btn-param"
                    onClick={this.goBack}
                  >
                    Cancel
                  </button>
                  <button
                    className="save-btn btn-param"
                    onClick={this.updateUser}
                    disabled={!canSave()}
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
          {modalArchivingConfig.showModal ? (
            <ModalArchiving
              config={modalArchivingConfig}
              confirmPopup={this.confirmModaArchiving}
              cancelPopup={this.cancelModaArchiving}
            />
          ) : null}
        </div>
        {agreeModal ? (
          <AgreeChanges
            cancelAgree={this.cancelAgree}
            confirmAgree={this.confirmAgree}
          />
        ) : (
          ''
        )}
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.authReducer.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    success: (message) => dispatch(success(message)),
    danger: (message) => dispatch(danger(message)),
    loaderShow: () => dispatch(show()),
    loaderHide: () => dispatch(hide()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StaffEdit);
