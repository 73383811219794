import React from 'react';
import PropTypes from 'prop-types';

function ButtonTraineesList({
  className = 'icon-box tarinee-list__box',
  number = '1',
  viewOnClick,
  editOnClick,
  isLocked = false,
}) {
  return (
    <div className={className}>
      <div className="trainee-list__number">List #{number}</div>
      <div className="trainee-list__buttons">
        <button
          className="open-trainee-list__btn"
          onClick={viewOnClick}
        ></button>
        {
          !isLocked && (
            <button
              className="edit-trainee-list__btn"
              onClick={editOnClick}
            ></button>
          )
        }
      </div>
    </div>
  );
}

ButtonTraineesList.propTypes = {
  className: PropTypes.string,
  number: PropTypes.string.isRequired,
  viewOnClick: PropTypes.func.isRequired,
  editOnClick: PropTypes.func.isRequired,
  isLocked: PropTypes.bool,
};

ButtonTraineesList.defaultProps = {
  className: 'icon-box tarinee-list__box',
  isLocked: false,
};

export default ButtonTraineesList;
