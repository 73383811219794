import React, { useEffect, useState } from 'react';
import { NavLink, useParams, useRouteMatch } from 'react-router-dom';
import EntityRepository from '@/repositories/EntityRepository';

function SyllabusMain() {
  const { syllabusId } = useParams();
  const { url } = useRouteMatch();
  const [syllabus, setSyllabus] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const result = await EntityRepository.getEntity('api/syllabi', syllabusId);
      setSyllabus({ ...result });
    };

    fetchData();
  }, []);

  return (
    <>
      <div className="syllabus-inform-panel">
        <div className="syllabus-inform__logo">
          <img src="/adminApp/src/assets/img/white-logo.svg" alt="logo" />
        </div>
        <div className="syllabus-inform__item">
          <div className="syllabus-inform__subtitle">Syllabus</div>
          <div className="syllabus-inform__name">{syllabus.title}</div>
        </div>
      </div>
      <div className="syllabus-main-panel__head">
        <div className="syllabus-inform__subtitle">
          Practical training element
        </div>
        <div className="syllabus-main-panel__qualification">
          {syllabus.type}
        </div>
      </div>
      <div className="syllabus-main-panel">
        <h1 className="syllabus-main-panel__title">
          {syllabus.title}
        </h1>
      </div>
      {/* syllabus-main-panel__buttons */}
      <div className="syllabus-main-panel__buttons">
        <div className="syllabus-main-panel__btn">
          <NavLink
            to={`${url}/pages/introduction`}
            className="syllabus-main-panel__link"
          >
                Introduction
          </NavLink>
        </div>
        <div className="syllabus-main-panel__btn">
          <NavLink
            to={`${url}/pages/part-1`}
            className="syllabus-main-panel__link"
          >
                Tasks
          </NavLink>
        </div>
        <div className="syllabus-main-panel__btn">
          <NavLink
            to={`${url}/pages/part-2`}
            className="syllabus-main-panel__link"
          >
                Assessment
          </NavLink>
        </div>
      </div>
    </>
  );
}

export default SyllabusMain;
