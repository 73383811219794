import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const TableFilterGroup = ({ field, classModifier = '', onFilter, options = [] }) => {
  const filter = useSelector(state => state.filterReducer);
  const [activeValue, setActiveValue] = useState(null);
  const location = useLocation();

  useEffect(() => {
    if (!field) return;
    const filterValue = filter.data[field];
    if (filter.path === location.pathname && activeValue !== filterValue) {
      setActiveValue(filterValue);
    }
  }, []);

  return (
    <div className={`data-search__buttons-filter ${classModifier}`}>
      {
        options.map(({ className = '', value, title = '', isActive = false }, index) => {
          if (activeValue === null && isActive) {
            setActiveValue(value);
          }

          return (
            <button
              key={index}
              className={`${className} ${(activeValue === value) ? 'active' : ''}`}
              onClick={() => {
                if (activeValue === value) return;
                setActiveValue(value);
                onFilter(value);
              }}>
              {title}
            </button>
          );
        })
      }
    </div>
  );
};

export default TableFilterGroup;
